import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core"
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox';
import UserIcon from "../../assets/icons/user";
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import EllipsisMenu from "../ellipsismenu";
import { IconButton } from "../button";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    fontSizeLarge: {
        width:'27px',
        height:'27px',
    },
    name: {
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
    },
    caption: {
        marginBottom: '0px',
        color: theme.palette.grey[600],
    },
    iconGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tooltip: {
        padding: theme.spacing(1.5,2)
    },
    noShare: {
        fontSize: theme.typography.fontSize * 0.6875,
        fontWeight: 400,
        color: theme.palette.grey[600],
    },
    fontSizeSmall: {
        width: '16px',
        height: '16px',
    },
    lineBreak: {
        marginTop: theme.spacing(1.5),
        width: '100%',
        height: '1px',
        background: theme.palette.grey[200]
    },
}))

const FamilyListItem = (props) => {
    const classes = useStyles()
    const [tooltip, setTooltip] = useState(false)

    return (
        <Box paddingTop={1} paddingBottom={1} width="100%">
            <Grid container spacing={1}>
                {props.check &&
                    <Grid item xs={1}>
                        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                            {!props.disabled &&
                                <Checkbox onChange={props.check_callback} color="primary"/>
                            }
                        </Box>
                    </Grid>
                }
                <Grid item xs={1} style={{cursor: props.check ? 'initial' : 'pointer'}} onClick={props.on_click}>
                    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                        <UserIcon active={1} classes={{fontSizeLarge: classes.fontSizeLarge}} fontSize="large"/>
                    </Box>
                </Grid>
                <Grid item xs={props.check ? 9 : 10} style={{cursor: props.check ? 'initial' : 'pointer'}} onClick={props.on_click}>
                    <Box height="100%" width="100%" justifyContent="center" display="flex" alignItems="center">
                        <Grid container>
                            <Grid item xs={props.disabled ? 6 : 12}>
                                <Typography variant="h6" className={classes.name}>
                                    {props.title}
                                </Typography>
                            </Grid>
                            {props.disabled &&
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" width="100%" height="100%">
                                        <Typography variant="caption" className={classes.noShare}>
                                            Cannot Share
                                        </Typography>
                                        <ClickAwayListener onClickAway={() => setTooltip(false)}>
                                            <span>
                                                <LightTooltip
                                                    arrow
                                                    disableTouchListener
                                                    disableFocusListener
                                                    onOpen={() => setTooltip(true)}
                                                    onClose={() => setTooltip(false)}
                                                    open={tooltip}
                                                    placement="top"
                                                    title={
                                                        <>
                                                            <Box className={classes.tooltip} maxWidth="276px">
                                                                <Typography variant="caption">Only primary guardians can share permission to a dependant profile and records.</Typography>
                                                            </Box>
                                                        </>
                                                    }
                                                >
                                                    <IconButton onClick={(e) => {e.stopPropagation(); setTooltip(!tooltip);}} size="small">
                                                        <InfoOutlinedIcon classes={{fontSizeSmall: classes.fontSizeSmall}} fontSize="small"/>
                                                    </IconButton>
                                                </LightTooltip>
                                            </span>
                                        </ClickAwayListener>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={1} className={classes.iconGrid}>
                    <EllipsisMenu items={props.ellipsisItems} listItem={props.listItem}/>
                </Grid>
            </Grid>
            {props.divider && <div className={classes.lineBreak} />}
        </Box>
    );}

export default FamilyListItem

