import SvgIcon from '@material-ui/core/SvgIcon';

function SharingFilledIcon() {
    return (
        <SvgIcon>
            <path d="M17.4286 21V17.707C17.4286 16.6874 17.084 15.7484 16.505 15H16.7128C19.0803 15 21 16.5422 21 18.4457V20C21 20.5523 20.5523 21 20 21H17.4286Z" />
            <path d="M7.42857 13.279C4.98296 13.279 3 15.261 3 17.707V19.9896C3 20.5419 3.44772 20.9896 4 20.9896H15.4286C15.9809 20.9896 16.4286 20.5419 16.4286 19.9896V17.707C16.4286 15.261 14.4456 13.279 12 13.279H7.42857Z" />
            <path d="M9.5 3.5C7.29127 3.5 5.5 5.29015 5.5 7.5C5.5 9.70984 7.29127 11.5 9.5 11.5C11.7087 11.5 13.5 9.70984 13.5 7.5C13.5 5.29015 11.7087 3.5 9.5 3.5Z" />
            <path d="M17 9C15.6193 9 14.5 10.1193 14.5 11.5C14.5 12.8807 15.6193 14 17 14C18.3807 14 19.5 12.8807 19.5 11.5C19.5 10.1193 18.3807 9 17 9Z" />
        </SvgIcon>
    );
}

export default SharingFilledIcon
