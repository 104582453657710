import { VaccinationStatus } from "./vaccination_status"
import { HeaderTitle } from "./page_sections"
import { VaccinationProgress, VaccinationStatus as VaccinationStatusApi, getProgressProps } from "./vaccination_progress"
import { spacing } from '@material-ui/system';
import styled from 'styled-components';

const Box = styled.div`${spacing}`;

type VaccineInfoProps = {
    /** Disease name (friendly) */
    title: string

    diseaseId: string

    /** Vaccination status as returned by the rules engine */
    vaccinationStatus?: VaccinationStatusApi

    /** Contents of the drawer.*/
    children?: React.ReactNode
}

/** Information displayed for vaccination and disease  */
export const VaccineInfo = ({
    title, diseaseId, vaccinationStatus, children
}: VaccineInfoProps) => {
    let actionStatus, progressProps
    if (vaccinationStatus) {
        actionStatus = vaccinationStatus.action_status
        progressProps = getProgressProps(diseaseId, vaccinationStatus)
    }

    return <>
        <HeaderTitle>
            {title}
        </HeaderTitle>
        {actionStatus && <>
            <Box mt={-1} mb={3}>
                <VaccinationStatus actionStatus={actionStatus}/>
            </Box>
            {progressProps && actionStatus !== 'UNAVAILABLE' &&
                <VaccinationProgress {...progressProps} />
            }
        </>}
        {children}
    </>
}
