import SvgIcon from '@material-ui/core/SvgIcon';

function SimpleUserIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill="currentColor" d="M18 20H6v2h12v-2ZM6 20v-1.8H4V20h2Zm3.2-5h5.6v-2H9.2v2Zm8.8 3.2V20h2v-1.8h-2ZM14.8 15a3.2 3.2 0 0 1 3.2 3.2h2a5.2 5.2 0 0 0-5.2-5.2v2ZM6 18.2A3.2 3.2 0 0 1 9.2 15v-2A5.2 5.2 0 0 0 4 18.2h2ZM6 20H4c0 1.1.9 2 2 2v-2Zm12 2a2 2 0 0 0 2-2h-2v2Z"/>
            <path fill="none" stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M15.5 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd"/>
        </SvgIcon>
    );
}

export default SimpleUserIcon
