import { makeStyles, Theme, createStyles, Checkbox } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { useState } from "react"

type StyleProps = {
    checked?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => createStyles({
    label_root: {
        border: '1px solid',
        borderColor: ({checked}) => (
            checked ? theme.palette.primary.main : theme.palette.grey[400]
        ),
        borderRadius: '12px',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    label: {
        flex: 1,
        marginRight: theme.spacing(2),
    },
    checkbox: {
        padding: 0,
    }
}))

type CheckboxCardProps = {
    // Required label, value, selected for the radio card.
    label: React.ReactNode,
    value: string,
    default?: boolean
    onChange?: (event: React.ChangeEvent) => void
}

const CheckboxCard = (props: CheckboxCardProps) => {
    const [checked, setChecked] = useState(props.default)
    const classes = useStyles({checked})

    return (
        <FormControlLabel
            label={props.label}
            value={props.value}
            data-action="Checkbox"
            control={<Checkbox
                defaultChecked={props.default}
                data-action="Checkbox"
                color="primary"
                size="small"
                onChange={(e) => {
                    const checked = e.target.checked
                    setChecked(checked)
                    if (props.onChange) props.onChange(e)
                }}
                classes={{
                    root: classes.checkbox
                }}/>}
            labelPlacement='start'
            classes={{
                root: classes.label_root,
                label: classes.label,
            }}
        />
    )
}

export default CheckboxCard
