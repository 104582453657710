import { SvgIcon, SvgIconProps } from "@material-ui/core";

function DropDownMenuIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <circle cx="10" cy="10" r="10" fill={props.fill || "#FFBF00"} />
            <path d="M6.5 9.25L10 12.75L13.5 9.25" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        </SvgIcon>
    )
}

export default DropDownMenuIcon
