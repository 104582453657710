import SvgIcon from '@material-ui/core/SvgIcon';

function CancelCircleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M8.14286 8.14286L15.8571 15.8571M8.14286 15.8571L15.8571 8.14286M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="none" stroke={props.strokecolor || '#333'} strokeWidth="2"/>
        </SvgIcon>
    );
}

export default CancelCircleIcon