import { spacing, sizing, display, flexbox } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Typography from './typography';
import Link from '@material-ui/core/Link';
import { BaseContainer } from './container';
import bubble from '../assets/img/bubble_bg.png';
import mail from "../assets/img/icon_mail.png";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    background: {
        background: `url(${bubble})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        maxHeight: "270px",
    },
    bottomText: {
        marginTop: 'auto',
        marginBottom: theme.spacing(2)
    }
}))

const CheckEmail = (props) => {
    const classes = useStyles()

    return (
        <BaseContainer {...props}>
            <Box width="100%" height="100%" display="flex" flex={1} flexDirection="column">
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" className={classes.background}>
                    <img width="100%" height="100%" style={{objectFit: "contain"}}src={mail}/>
                </Box>
                <Box width="100%">
                    <Typography align="center" variant="modalHeader" gutterBottom>
                        Please check your inbox
                    </Typography>
                </Box>
                <Box width="100%" marginBottom={4} marginTop={7}>
                    <Typography align="center" variant="bodyTextLarge" gutterBottom>
                        We have sent a password recovery link to <strong>{props.email}</strong>.
                        It is only valid for 30 minutes.
                    </Typography>
                </Box>
                {/* <Box width="100%" marginBottom={2}>
                <Button variant="contained" color="primary" fullWidth>
                    Open email app
                </Button>
            </Box> */}
                <Typography align="center" variant="bodyTextMedium" className={classes.bottomText}>
                    Didn’t get the e-mail? Check your spam filter, or {' '}
                    <Link href="/forgot-password">try with a different email address</Link>
                </Typography>
            </Box>
        </BaseContainer>
    )
}

export default CheckEmail
