import SvgIcon from '@material-ui/core/SvgIcon';

function RecordsDotIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <circle cx="16.5029" cy="11" r="5" fill="#FBD202"/>
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="M15.5 4.50031H17.25C17.7141 4.50031 18.1592 4.68468 18.4874 5.01287C18.8156 5.34106 19 5.78618 19 6.25031V18.5003C19 18.9644 18.8156 19.4096 18.4874 19.7377C18.1592 20.0659 17.7141 20.2503 17.25 20.2503H6.75C6.28587 20.2503 5.84075 20.0659 5.51256 19.7377C5.18437 19.4096 5 18.9644 5 18.5003V6.25031C5 5.78618 5.18437 5.34106 5.51256 5.01287C5.84075 4.68468 6.28587 4.50031 6.75 4.50031H8.5" strokeWidth="1.7"/>
                <path d="M14.6299 2.74969H9.37994C8.89669 2.74969 8.50494 3.14145 8.50494 3.62469V5.37469C8.50494 5.85794 8.89669 6.24969 9.37994 6.24969H14.6299C15.1132 6.24969 15.5049 5.85794 15.5049 5.37469V3.62469C15.5049 3.14145 15.1132 2.74969 14.6299 2.74969Z" strokeWidth="1.8"/>
                <path d="M13.5581 8.875L15.5025 10.8194L10.4469 15.875H8.5025V13.9306L13.5581 8.875Z" strokeWidth="1.5"/>
            </g>

        </SvgIcon>
    );
}

export default RecordsDotIcon
