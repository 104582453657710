import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '../typography';
import { ModalTitle, PageSection } from '../page_sections';
import imunisIcon from '../../assets/img/imunis_app.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIosIcon from '../../assets/icons/share_ios';
import { IconButton } from "../button";

const useStyles = makeStyles<Theme>((theme) => createStyles({
    modal: {
        padding: theme.spacing(5, 0),
        textAlign: 'center',
    },
    content: {
        padding: theme.spacing(0, 3)
    },
    addText: {
        padding: theme.spacing(0, 2)
    },
    close: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    },
    icon: {
        fontSize: '24px',
        verticalAlign: 'middle',
    },
}))

type PWAProps = {
    /** Whether to open the dialog */
    open: boolean

    /** Close dialog function */
    close: (e: React.MouseEvent) => void
}

export const PWADialog = (props: PWAProps) => {
    const classes = useStyles()
    const isIOS = /iPhone|iPad/i.test(navigator.userAgent)
    const Icon = isIOS ? ShareIosIcon : MoreVertIcon

    return (
        <Dialog open={props.open}>
            <IconButton aria-label="Close dialog" className={classes.close} onClick={props.close} size="small">
                <Close className={classes.icon} />
            </IconButton>
            <div className={classes.modal}>
                <div className={classes.content}>
                    <img src={imunisIcon} width="50px" alt="Imunis App Logo" />
                    <ModalTitle>
                        Add Imunis to Home screen
                    </ModalTitle>
                    <PageSection>
                        <Typography variant='bodyTextLarge' gutterBottom>
                            View your records quickly and easily by adding Imunis to your Home screen.
                        </Typography>
                        {isIOS && <Typography variant='bodyTextSmall'>
                            Only supported on Safari.
                        </Typography>}
                    </PageSection>
                </div>
                <div className={classes.addtext}>
                    <Typography variant='bodyTextLarge'>
                        <span>
                            Tap <Icon className={classes.icon}/> and <b>"Add to Home Screen"</b>
                        </span>
                    </Typography>
                </div>
            </div>
        </Dialog>
    )
}
