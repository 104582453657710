import SvgIcon from '@material-ui/core/SvgIcon';

function ImunisAppIcon(props) {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <path d="M12 15.6019C12.6015 15.6019 13.0892 16.0736 13.0892 16.6553C13.0892 17.2371 12.6015 17.7087 12 17.7087C11.3984 17.7087 10.9108 17.2371 10.9108 16.6553C10.9108 16.0736 11.3984 15.6019 12 15.6019Z" fill="#333333"/>
                <path d="M12 6.29126C12.6015 6.29126 13.0892 6.76289 13.0892 7.34466C13.0892 7.92644 12.6015 8.39806 12 8.39806C11.3984 8.39806 10.9108 7.92644 10.9108 7.34466C10.9108 6.76289 11.3984 6.29126 12 6.29126Z" fill="#333333"/>
                <path d="M12.6676 9.99515C12.6676 9.63858 12.3687 9.34952 12 9.34952C11.6313 9.34952 11.3324 9.63858 11.3324 9.99515C11.3324 10.3517 11.6313 10.6408 12 10.6408C12.3687 10.6408 12.6676 10.3517 12.6676 9.99515Z" fill="#333333"/>
                <path d="M13.827 10.4369C14.1957 10.4369 14.4946 10.726 14.4946 11.0825C14.4946 11.4391 14.1957 11.7282 13.827 11.7282C13.4583 11.7282 13.1594 11.4391 13.1594 11.0825C13.1594 10.726 13.4583 10.4369 13.827 10.4369Z" fill="#333333"/>
                <path d="M10.8405 11.0825C10.8405 10.726 10.5416 10.4369 10.173 10.4369C9.80427 10.4369 9.50539 10.726 9.50539 11.0825C9.50539 11.4391 9.80427 11.7282 10.173 11.7282C10.5416 11.7282 10.8405 11.4391 10.8405 11.0825Z" fill="#333333"/>
                <path d="M12 13.6311C12.3687 13.6311 12.6676 13.9201 12.6676 14.2767C12.6676 14.6333 12.3687 14.9223 12 14.9223C11.6313 14.9223 11.3324 14.6333 11.3324 14.2767C11.3324 13.9201 11.6313 13.6311 12 13.6311Z" fill="#333333"/>
                <path d="M14.4946 13.1214C14.4946 12.7648 14.1957 12.4757 13.827 12.4757C13.4583 12.4757 13.1594 12.7648 13.1594 13.1214C13.1594 13.4779 13.4583 13.767 13.827 13.767C14.1957 13.767 14.4946 13.4779 14.4946 13.1214Z" fill="#333333"/>
                <path d="M10.173 12.4757C10.5416 12.4757 10.8405 12.7648 10.8405 13.1214C10.8405 13.4779 10.5416 13.767 10.173 13.767C9.80427 13.767 9.50539 13.4779 9.50539 13.1214C9.50539 12.7648 9.80427 12.4757 10.173 12.4757Z" fill="#333333"/>
                <path d="M8.87296 9.7233C8.87296 9.14153 8.38531 8.6699 7.78377 8.6699C7.18223 8.6699 6.69458 9.14153 6.69458 9.7233C6.69458 10.3051 7.18223 10.7767 7.78377 10.7767C8.38531 10.7767 8.87296 10.3051 8.87296 9.7233Z" fill="#333333"/>
                <path d="M16.2162 8.6699C16.8177 8.6699 17.3054 9.14153 17.3054 9.7233C17.3054 10.3051 16.8177 10.7767 16.2162 10.7767C15.6147 10.7767 15.127 10.3051 15.127 9.7233C15.127 9.14153 15.6147 8.6699 16.2162 8.6699Z" fill="#333333"/>
                <path d="M8.87296 14.2767C8.87296 13.6949 8.38531 13.2233 7.78377 13.2233C7.18223 13.2233 6.69458 13.6949 6.69458 14.2767C6.69458 14.8585 7.18223 15.3301 7.78377 15.3301C8.38531 15.3301 8.87296 14.8585 8.87296 14.2767Z" fill="#333333"/>
                <path d="M16.2162 13.2233C16.8177 13.2233 17.3054 13.6949 17.3054 14.2767C17.3054 14.8585 16.8177 15.3301 16.2162 15.3301C15.6147 15.3301 15.127 14.8585 15.127 14.2767C15.127 13.6949 15.6147 13.2233 16.2162 13.2233Z" fill="#333333"/>
                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#333333" strokeWidth="1.5"/>
            </g>
        </SvgIcon>
    );
}

export default ImunisAppIcon
