import SvgIcon from '@material-ui/core/SvgIcon';

function SchoolIcon(props) {
    return (
        <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
            <ellipse opacity="0.6" cx="34.1252" cy="17.9977" rx="12" ry="12.0978" fill="#FBD202"/>
            <path fill="none" d="M32.9915 10.9564V41.9476H12.9971V10.9625" stroke={props.strokecolor || "#333"} strokeWidth="2.19938" strokeMiterlimit="10"/>
            <path fill="none" d="M12.9972 41.9476H3V18.739C3.03191 18.5041 3.15004 18.2914 3.32937 18.146C3.50869 18.0005 3.73512 17.9338 3.96071 17.9599H12.9972" stroke={props.strokecolor || "#333"} strokeWidth="2.19938" strokeMiterlimit="10"/>
            <path fill="none" d="M31.9918 17.9543H41.9315C42.5221 17.9543 42.9887 18.3014 42.9887 18.7336V41.9476H31.9918" stroke={props.strokecolor || "#333"} strokeWidth="2.19938" strokeMiterlimit="10"/>
            <path fill="none" d="M17.2493 39.1476V29.0816C17.2471 29.0328 17.2581 28.9842 17.2815 28.9385C17.3049 28.8928 17.3404 28.851 17.3858 28.8154C17.4312 28.7798 17.4857 28.7511 17.5462 28.731C17.6067 28.711 17.6719 28.6998 17.7383 28.6983H28.159C28.2253 28.6998 28.2906 28.711 28.3511 28.731C28.4116 28.7511 28.4661 28.7798 28.5115 28.8154C28.5569 28.851 28.5923 28.8928 28.6157 28.9385C28.6392 28.9842 28.6501 29.0328 28.648 29.0816V39.1476" stroke={props.strokecolor || "#333"} strokeWidth="1.36569" strokeMiterlimit="10"/>
            <path fill="none" d="M22.7437 23.5973C24.729 23.5973 26.3385 21.9747 26.3385 19.9732C26.3385 17.9717 24.729 16.3491 22.7437 16.3491C20.7583 16.3491 19.1489 17.9717 19.1489 19.9732C19.1489 21.9747 20.7583 23.5973 22.7437 23.5973Z" stroke={props.strokecolor || "#333"} strokeWidth="1.29768" strokeMiterlimit="10"/>
            <path fill="none" d="M16.2991 41.0475V39.3376C16.2991 39.2326 16.3841 39.1476 16.4891 39.1476H29.4082C29.5131 39.1476 29.5982 39.2326 29.5982 39.3376V41.0475" stroke={props.strokecolor || "#333"} strokeWidth="1.23491" strokeMiterlimit="10"/>
            <path fill="none" d="M22.9486 28.6983V39.1476" stroke={props.strokecolor || "#333"} strokeWidth="1.36569" strokeMiterlimit="10"/>
            <path fill="none" d="M35.2706 8.74969H10.9214C10.7436 8.74969 10.5995 8.89039 10.5995 9.06394V11.4346C10.5995 11.6082 10.7436 11.7488 10.9214 11.7488H35.2706C35.4483 11.7488 35.5924 11.6082 35.5924 11.4346V9.06394C35.5924 8.89039 35.4483 8.74969 35.2706 8.74969Z" stroke={props.strokecolor || "#333"} strokeWidth="1.89987" strokeMiterlimit="10"/>
            <path fill="none" d="M39.9895 22.108H35.9907V27.1473H39.9895V22.108Z" stroke={props.strokecolor || "#333"} strokeWidth="1.09552" strokeMiterlimit="10"/>
            <path fill="none" d="M39.9895 31.1789H35.9907V36.2182H39.9895V31.1789Z" stroke={props.strokecolor || "#333"} strokeWidth="1.09552" strokeMiterlimit="10"/>
            <path fill="none" d="M9.99808 22.108H5.99921V27.1473H9.99808V22.108Z" stroke={props.strokecolor || "#333"} strokeWidth="1.09552" strokeMiterlimit="10"/>
            <path fill="none" d="M9.99808 31.1789H5.99921V36.2182H9.99808V31.1789Z" stroke={props.strokecolor || "#333"} strokeWidth="1.09552" strokeMiterlimit="10"/>
            <path fill="none" d="M22.3013 20.9263L24.7531 19.9007" stroke={props.strokecolor || "#333"} strokeWidth="0.520484" strokeMiterlimit="10" strokeLinecap="round"/>
            <path fill="none" d="M22.3013 18.6535L22.3014 20.9262" stroke={props.strokecolor || "#333"} strokeWidth="0.520484" strokeMiterlimit="10" strokeLinecap="round"/>
            <path fill="none" d="M22.9943 9.03931V4" stroke={props.strokecolor || "#333"} strokeWidth="1.09552" strokeMiterlimit="10" strokeLinecap="round"/>
            <path fill="none" d="M26.9339 4H23.1627C23.0697 4 22.9943 4.07601 22.9943 4.16978V6.54066C22.9943 6.63443 23.0697 6.71044 23.1627 6.71044H26.9339C27.0269 6.71044 27.1023 6.63443 27.1023 6.54066V4.16978C27.1023 4.07601 27.0269 4 26.9339 4Z" stroke={props.strokecolor || "#333"} strokeWidth="0.910457" strokeMiterlimit="10" strokeLinecap="round"/>
        </SvgIcon>
    );
}

export default SchoolIcon