import { isCancel } from "axios"
import { useState, useEffect, ReactElement } from "react"
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import { AppHeader } from "../../../components/header"
import { BaseContainer } from "../../../components/container"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { HeaderTitle } from "../../../components/page_sections"
import ErrorDialog from "../../../components/dialogs/error_dialog"
import Loading from "../../../components/loading"
import { ImageCarousel, Profile, RejectionReason, Submission, toTimelineProps } from "./utils"
import SubmissionTimeline from "../../../components/submission_timeline"
import { InfoPanelAction, InfoPanelCollapsible } from "../../../components/info_panel_collapsible"
import ChatIcon from "../../../assets/icons/chat"
import Typography from "../../../components/typography"
import { makeStyles } from "@material-ui/core"
import { formatDateLocal, pluralString, possessiveCheck, trackPageView } from "../../../utils/common"
import { Divider } from "../../../components/divider"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { Button } from "../../../components/button"
import { Page } from "./page"
import { InfoPanel } from "../../../components/info_panel"

const useStatusContentStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(3, 0),
        }
    },
    comments: {
        whiteSpace: 'break-spaces',
    }
}))

const showComments = ({ comments, rejectInfo }: Submission) => {
    return Boolean(comments || rejectInfo)
}

const StatusContent = (submission: Submission) => {
    const { state, rejectReason, rejectInfo, comments, vaccineEvents, subject, self, history } = submission
    const classes = useStatusContentStyles()
    let title, content, messageFromImunis: ReactElement | undefined, additionalContent

    switch (state) {
        case 'NEW':
        case 'RESUBMITTED':
            content = 'This submission is queued for processing.'
            break
        case 'PROCESSING':
            content = 'This submission is being processed.'
            break
        case 'DIGITISED': {
            title = 'Submission digitised'

            const subjectLabel = <>
                {' '}
                {self ? 'your' : possessiveCheck(subject.displayName)}
                {' '}
            </>

            content = <>
                This submission has been processed and
                {' '}
                <Typography variant="bodyTextLargeBold" color="primary" component="span">
                    {vaccineEvents?.length}
                </Typography>
                {' '}
                vaccination {pluralString('record', vaccineEvents?.length)} added to {subjectLabel}
                Imunis profile.
            </>

            additionalContent = <p>
                Please note - this may have resulted in changes to {subjectLabel} vaccination status.
            </p>
        }
            break
        case 'REJECTED':
            switch (rejectReason) {
                case RejectionReason.MULTIPLE_DOCUMENTS:
                    title = 'Multiple documents submitted'
                    content = 'More than one vaccination document was scanned as part of this submission. A new submission should be made for each vaccination document, with patient name & date of birth visible on at least one scanned page of each document. Please re-submit all documents.'
                    break
                case RejectionReason.NOT_VACCINE_DOCUMENT:
                    title = 'Document is not a vaccination record'
                    content = 'One or more pages of the submission are not recognised as a valid vaccination document. Please start a new submission and attach valid documents.'
                    break
                case RejectionReason.NON_ENGLISH_DOCUMENT:
                    title = 'Non-English language document'
                    content = 'One or more pages of the submission cannot be interpreted in English language. At this time, we can only process submissions with an English language entry. If you hold English-language versions, please start a new submission attaching those documents.'
                    break
                case RejectionReason.POOR_IMAGE_QUALITY:
                    title = 'Poor quality image - illegible'
                    content = 'One or more pages submitted is illegible due to image quality. Please make a new submission with improved quality documents if possible.'
                    break
                case RejectionReason.CROPPED_IMAGE:
                    title = 'Image is cropped'
                    content = 'One or more pages submitted is illegible due to image cropping. Please make a new submission capturing the full document if possible.'
                    break
                case RejectionReason.MISSING_PII:
                    title = 'Missing personal information'
                    content = 'The submitted document does not contain the mandatory patient data required to identify it as belonging to the patient, i.e. full name and date of birth.\n' +
                    'Please re-submit with mandatory data.'
                    break
                case RejectionReason.PII_MISMATCH:
                    title = 'Non-matching personal information'
                    content = 'The submitted document contained non-matching mandatory personal information, therefore the document cannot be processed.'
                    break
                case RejectionReason.OTHER:
                    title = ''
                    content = 'There was an issue with this submission - please read message for details'
                    break
                case RejectionReason.UNKNOWN_ENTRIES:
                    title = 'Illegible / ambiguous entries'
                    content = 'If documents can be re-uploaded in legible format, please create a new submission.\n' +
                    'If source document is illegible, unfortunately Imunis is unable to digitise this document.'
                    break
                case RejectionReason.ADDITIONAL_INFO:
                    title = 'Additional information needed'
                    content = 'Personal information provided as part of this submission was insufficient to match the document to the Imunis profile holder. '
                    break
            }
    }

    if (showComments(submission)) {
        messageFromImunis = (
            <InfoPanelCollapsible
                severity="warning"
                icon={<ChatIcon/>}
                collapsible
                defaultCollapsed
                title="Message - please read"
                actions={(isCollapsed) => (
                    <InfoPanelAction>
                        {isCollapsed ? "Show details" : "Hide details"}
                    </InfoPanelAction>
                )}>
                {(isCollapsed) => (
                    isCollapsed ? (
                        'You have feedback on your submission'
                    ) : <span className={classes.comments}>{comments || rejectInfo}</span>
                )}
            </InfoPanelCollapsible>
        )
    }

    const updates = history?.map(({ timestamp, removed, added, updated }) => (
        <InfoPanel key={timestamp} severity="info">
            <p>
                This submission was updated on {formatDateLocal(timestamp)} to improve record accuracy.
            </p>
            <ul>
                {(added || updated) ? <li>
                    {`${added+updated} ${pluralString('record', added+updated)} added or updated`}
                </li> : null}
                {removed ? <li>
                    {`${removed} ${pluralString('record', removed)} removed`}
                </li> : null}
            </ul>
            <p>
                The changes are applied to the original submission detailed below.
            </p>
        </InfoPanel>
    ))

    return <div className={classes.root}>
        <Typography variant="bodyTextLargeBold">
            {title}
        </Typography>

        {updates}

        <p>{content}</p>

        {messageFromImunis}

        {additionalContent}

        <Divider />

        <p>
            You may email <a href="mailto:contact@imunis.com">
                <Typography variant="bodyTextLarge" color="primary" component="span">contact@imunis.com</Typography>
            </a> with any queries.
        </p>
    </div>
}

export const SubmissionView = () => {
    const [submission, setSubmission] = useState<Submission>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const history = useHistory()
    const match = useRouteMatch<{
        id: string,
        pageNumber?: string,
    }>()
    const location = useLocation<{
        submission?: Submission
        /**
         * Explicitly set to false on the link from the Submission History page.
         * If not false (or not set), the "Continue to home" button will be shown.
         */
        continueHome?: false
    }>()

    const submissionId = match.params.id

    useEffect(() => {
        if (location.state?.submission) {
            setSubmission(location.state.submission)
        }

        setLoading(true)
        axiosAuthedInstance.get<{
            submission: Submission
        }>(`/user/paper-record/submission/${submissionId}`)
            .then((response) => {
                const submission = response.data.submission
                setSubmission(submission)

                const {state, rejectReason} = submission
                trackPageView({
                    state,
                    reject_reason: rejectReason,
                    has_comments: showComments(submission)
                })
            }).catch((error) => {
                if (isCancel(error)) return
                console.error(error)
                setError('Server error. Please try again later.')
            }).finally(() => {
                setLoading(false)
            })
    }, [location.state?.submission, submissionId])

    if (match.params.pageNumber && submission) {
        const pageNumber = parseInt(match.params.pageNumber) - 1
        const imageUrl = submission?.pages[pageNumber]

        if (!imageUrl) {
            history.replace(`/paper-records/submission/${submissionId}`)
            return null
        } else {
            return <Page
                imageUrl={imageUrl}
                onClose={() => history.push(`/paper-records/submission/${submissionId}`)} />
        }
    }

    let actions

    if (submission?.state === 'REJECTED') {
        const canResubmit = submission?.rejectReason === RejectionReason.ADDITIONAL_INFO
        actions = <>
            <Button onClick={() => history.push('/paper-records/new')}>
                Start a new submission
            </Button>
            {canResubmit && (
                <Button onClick={() => history.push('/paper-records/new/summary', {
                    subject: {
                        id: submission.subject.id,
                        fullName: submission.subject.fullName,
                    },
                    images: submission.pages,
                    submissionId,
                })}>
                    Update and resubmit
                </Button>
            )}
        </>
    } else if (submission?.state === 'DIGITISED' && location.state?.continueHome !== false) {
        actions = <>
            <Button fullWidth onClick={() => history.push('/home')}>
                Continue to home
            </Button>
        </>
    }

    return (
        <BaseContainer
            header={
                <AppHeader onBack={() => history.push('/paper-records/history')} />
            }
            {...(actions && {
                showCta: true,
                extraComponent: (
                    <StickyFooterGroup>
                        {actions}
                    </StickyFooterGroup>
                )
            })}
        >
            <HeaderTitle>Submission #{submissionId}</HeaderTitle>

            { submission &&
                <Profile
                    name={submission.subject.fullName}
                    {...(submission.state === 'DIGITISED' && {
                        viewRecordsAction: () => {
                            history.push(`/records/${submission.subject.id}/health`, {
                                addedRecords: submission.vaccineEvents
                            })
                        }
                    })}
                />
            }
            { submission && <SubmissionTimeline {...toTimelineProps(submission)} /> }
            { submission && <StatusContent {...submission} />}
            { submission && <ImageCarousel
                imageUrls={submission.pages}
                onClick={i => (
                    history.push(`/paper-records/submission/${submissionId}/page/${i+1}`)
                )}
            />}

            <Loading loading={loading} />

            <ErrorDialog
                error={error}
                open={Boolean(error)}
                setOpen={setError}
            />
        </BaseContainer>
    )
}
