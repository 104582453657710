import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { isValidHKID } from '../utils/common';

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const NAME_START_REGEX = /^[a-z]/i;
const NAME_REGEX = /^[a-z][a-z\s()'-]*$/i;
const PWD_LENGTH_REGEX = /^.{8,}$/;
const HKID_REGEX = /^([A-Z]{1,2})([\d]{6})([A\d])$/g
const HKID_BRACKET_CHECK = /[(|)]/g

export function validateName(text: string) {
    if (!NAME_START_REGEX.test(text)) {
        return 'Name must start with a letter (a-z)'
    }

    if (!NAME_REGEX.test(text)) {
        return 'Name must only contain letters (a-z), hyphens, and spaces'
    }
}

export function validateEmail(text: string) {
    if (text.match(EMAIL_REGEX) === null) {
        return 'Please enter a valid email'
    }
}

export function validatePassword(text: string) {
    if (text.match(PWD_LENGTH_REGEX) === null) {
        return 'Password should contain at least 8 characters'
    }
}

export function validateHKID (text: string) {
    if (text) {
        if (text.match(HKID_BRACKET_CHECK)) {
            return "Write HKID without brackets '()'"
        } else if (text.match(HKID_REGEX) === null) {
            return 'Invalid HKID format'
        } else if (!isValidHKID(text)) {
            return 'Invalid HKID'
        }
    }
}

export function validateConfirmPassword(pw1: string, pw2: string) {
    if (pw1 !== pw2) {
        return 'Confirm password does not match with above'
    }
    return ''
}

type Props = {
    fullWidth: boolean,
    withMargin: boolean,
    name: string,
    max: number,
}

const TextInput: React.FunctionComponent<Props> = ({fullWidth, withMargin, name, max=-1, ...props}) => {
    const textProps: TextFieldProps = {
        margin: withMargin? "normal" : undefined,
        id: name,
        name: name,
        size: 'small',
        fullWidth: fullWidth,
        inputProps: {
            style: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
            },
            maxLength: max,

        },
        InputLabelProps: {
            style: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '16px',
            }
        },
        ...props
    }

    return (
        <TextField {...textProps}/>
    )
}

export default TextInput
