import {
    makeStyles,
    Theme,
    Button as MuiButton,
    ButtonBase,
    ButtonProps as MuiButtonProps,
    CircularProgress,
    Fab as MuiFab,
    FabProps,
    LinearProgress,
    IconButtonProps,
    IconButton as MuiIconButton
} from '@material-ui/core/'
import { styles as typographyStyles } from "./typography"
import { cx, trackLink, trackAction } from '../utils/common'
import { ComponentProps, ComponentPropsWithRef, HTMLAttributes, PropsWithChildren, ReactElement, ReactNode, forwardRef, isValidElement } from 'react'

const useStyles = makeStyles<Theme, ButtonProps>((theme) => {
    const typography = typographyStyles(theme)
    return {
        button: {
            borderColor: theme.palette.grey[400],
            color: theme.palette.grey[600],
        },
        smallButton: {
            ...typography.bodyTextSmall,
            borderColor: theme.palette.primary.main,
            color: ({primary}) => primary ? theme.palette.common.white : theme.palette.grey[800],
        },
        smallButtonStartIcon: {

            color: ({primary}) => primary ? '' : theme.palette.primary.main,
            marginRight: '4px',
            '& > *:first-child': {
                fontSize: '16px',
            }
        },
        textButton: {
            fontFamily: 'inherit',
            ...typography.bodyTextButton,
            cursor: 'pointer',
            textDecoration: 'underline',
            color: ({primary}) => primary ? theme.palette.primary.main : '',
        },
        fab: {
            position:'fixed',
            right: '2em',
            bottom: '2em',
            zIndex: 1,
        },
        progress: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderRadius: '0 0 4px 4px',
        }
    }
})

type ButtonProps = {
    /** If `true`, styles the button as a primary call to action */
    primary?: boolean,

    /** If `true`, indicates a form submission is in progress. A spinner will be displayed. */
    loading?: boolean,

    /** Displays a progress bar under the button. Value is a percentage between 0-100 */
    loadingProgress?: number
} & MuiButtonProps

/**
 * Inner / recursive function. There's also a library for this, but it doesn't handle the second
 * case (aria-label):
 * https://github.com/fernandopasik/react-children-utilities/blob/v2.9.0/docs/only-text.md
 */
const _buttonText = (props: {children?: ReactNode} & HTMLAttributes<HTMLElement>): string | undefined => {
    /**
     * <Button aria-label="Next">
     *  <RightChevronIcon/>
     * </Button>
     * // => "Next"
     */
    if (props['aria-label']) return props['aria-label']

    /**
     * <Button>Click me</button>
     * // => "Click me"
     */
    if (typeof props?.children === 'string') return props.children

    /**
     * <Button>
     *  <Typography>Fancy text</Typography>
     * </Button>
     * // => "Fancy text"
     */
    if (isValidElement(props.children)) {
        return _buttonText(props.children.props as PropsWithChildren<unknown>)
    }

    /**
     * <Button>
     *  <UserIcon />
     *  <Typography>Styled</Typography>
     *  text
     * </Button>
     * // => "Styled text"
     */
    if (Array.isArray(props.children)) {
        return props.children.map((child: ReactElement) => {
            if (typeof child === 'string') return child
            return _buttonText(child.props as PropsWithChildren<unknown>)
        }).filter(Boolean).join('')
    }
}

/**
 * Finds a text label associated with the button and returns it.
 *
 * A button's children aren't always strings -- we allow arbitrary content (ReactNodes) -- so we
 * need to so some searching in those cases.
 * This approach should be good enough as buttons should have text content or an `aria-label`
 * attribute anyway.
 */
const buttonText = (props: ButtonProps): string => {
    const foundText = _buttonText(props)

    if (foundText) return foundText

    console.error("Could not determine button label:", props)
    return '(unavailable)'
}

export const Button = (props: ButtonProps) => {
    const { children, primary, loading, disabled, onClick, loadingProgress, ...rest } = props
    const classes = useStyles(props)

    let startIcon
    if (loading) {
        startIcon = <CircularProgress size={16} color="inherit"/>
    }

    return (
        <MuiButton
            variant={primary ? 'contained' : 'outlined'}
            color={primary ? 'primary' : undefined}
            disabled={disabled || loading}
            startIcon={startIcon}
            onClick={(e) => {
                trackLink(buttonText(props), 'button')
                trackAction('Click', 'Button', buttonText(props))
                if (onClick) onClick(e)
            }}
            {...rest}>
            {children}
            {loading && typeof loadingProgress == 'number' ?
                <LinearProgress
                    variant="determinate"
                    className={classes.progress}
                    value={loadingProgress} />
                : null}
        </MuiButton>
    )
}

export const SmallButton = (props: ButtonProps) => {
    const { children, loading, startIcon, disabled, primary, onClick, ...rest } = props
    const classes = useStyles(props)

    return (
        <MuiButton
            variant={primary ? 'contained' : 'outlined'}
            color={primary ? 'primary' : undefined}
            className={cx(classes.button, classes.smallButton)}
            classes={{
                iconSizeSmall: classes.smallButtonStartIcon
            }}
            size="small"
            startIcon={loading ? <CircularProgress size={16} color="inherit" /> : startIcon}
            disabled={disabled || loading}
            onClick={(e) => {
                trackLink(buttonText(props), 'small_button')
                trackAction('Click', 'Button', buttonText(props))
                if (onClick) onClick(e)
            }}
            {...rest}
        >
            {children}
        </MuiButton>
    )
}

export const TextButton = (props: ButtonProps) => {
    const classes = useStyles(props)
    const { children, primary, className, onClick, ...rest } = props

    return (
        <ButtonBase
            className={cx(className, classes.textButton)}
            color={primary ? 'primary' : undefined}
            disableRipple={true}
            onClick={(e) => {
                // 'button' seems more suitable than 'link' for this
                trackLink(buttonText(props), 'button')
                trackAction('Click', 'Button', buttonText(props))
                if (onClick) onClick(e)
            }}
            {...rest}>
            {children}
        </ButtonBase>
    )
}

/**
 * Floating action button
 * https://www.figma.com/file/CIs2Lv5g9sql6koYxlinoO/Imunis_Library?node-id=2639-37103
 * https://v4.mui.com/components/floating-action-button/
 */
export const Fab = (props: { 'aria-label': string } & FabProps) => {
    const classes = useStyles({})
    const { 'aria-label': label, children, className, onClick, ...rest } = props

    return (
        <MuiFab
            className={cx(className, classes.fab)}
            color="primary"
            aria-label={label}
            onClick={e => {
                trackLink(label, 'fab')
                trackAction('Click', 'FAB', label)
                if (onClick) onClick(e)
            }}
            {...rest}>
            {children}
        </MuiFab>
    )
}

export const IconButton = forwardRef<
HTMLButtonElement,
{ 'aria-label': string } & IconButtonProps
>(function IconButton(props, ref) {
    const { 'aria-label': label, children, onClick, ...rest } = props

    return (
        <MuiIconButton
            ref={ref}
            aria-label={label}
            onClick={e => {
                if (label) trackAction('Click', 'Icon', label)
                if (onClick) onClick(e)
            }}
            {...rest}>
            {children}
        </MuiIconButton>
    )
})
