import { Link } from "react-router-dom"
import { BaseContainer } from "../../components/container"

const pathname = '/records/634c5934-9852-4c2f-9d0e-ced1aba1d0db/health'
const testCases = [
    ['Default link to health page', {}],
    ['Only expand a disease', {
        addedRecords: [],
        openDiseases: ['840539006'],
    }],
    ['Highlight 1 record', {
        addedRecords: [
            'EU/1/20/1528,2021-11-05'
        ],
    }],
    ['Highlight multiple records', {
        addedRecords: [
            'EU/1/20/1528,2021-11-05',
            'EU/1/20/1528,2021-03-25',
            'EU/1/20/1528,2021-03-02',
        ]
    }],
    ['Highlight record in previous season', {
        addedRecords: [
            'IMMU-GENERIC-FLU-NORTH,2019-10-01',
        ]
    }],
    ['Multiple records, different diseases', {
        addedRecords: [
            'IMMU-GENERIC-MMR,1980-07-01',
            'HK-44952,1979-01-30',
            'HK-55037,2019-07-01',
        ]
    }],
]

/**
 * URL: http://frontend.imunis.local:3000/test-deep-links
 */
export const TestDeepLinks = () => {
    return (
        <BaseContainer noHeader>

            <p>
                Note this only works when logged in as Alan Parent as we're using a hardcoded URL to the health page
            </p>

            <ul>
                {testCases.map((([label, state], i) => (
                    <li key={i}>
                        <Link to={{ pathname, state }}>
                            {label}
                        </Link>
                    </li>
                )))}
            </ul>
        </BaseContainer>
    )
}
