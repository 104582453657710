import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { spacing, sizing, display, flexbox } from '@material-ui/system';
import styled from 'styled-components';
import { BaseContainer } from "../../components/container";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import ErrorDialog from "../../components/dialogs/error_dialog";
import {
    default as RemovePermSuccessDialog,
    default as RemoveDepSuccessDialog,
} from "../../components/dialogs/success_dialog";
import {
    default as RemovePermissionDialog,
    default as RemoveDependantDialog,
} from "../../components/dialogs/action_dialog";
import CTAButton from "../../components/buttons/cta";
import { getGenderLabel, formatDate } from "../../utils/common";
import { getUUID } from "../../utils/auth";
import Typography from '../../components/typography'
import { Button } from '../../components/button'
import UserTabs from './user_tabs'
import { UserHeader, UserContext } from "./user_header";
import { EditProfile } from "./edit_profile";
import Tabs from "../../components/tabs/tabs";
import Tab from "../../components/tabs/tab";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const ProfileCtaBtn = (props) => (
    <CTAButton fullWidth id={props.id} onClick={props.callback}>
        {props.btn_text}
    </CTAButton>
)

const ContentRow = (props) => {
    return (
        <>
            <Typography variant="bodyTextSmall" >
                {props.field_name}
            </Typography>
            <Typography variant="bodyTextLarge" >
                {props.value}
            </Typography>
        </>
    )
}

export const ProfilePage = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [openRemovePermDialog, setOpenRemovePermDialog] = useState(false)
    const [openRemoveDepDialog, setOpenRemoveDepDialog] = useState(false)
    const [removeDepSuccess, setRemoveDepSuccess] = useState('')
    const [removeDepSuccessOpen, setRemoveDepSuccessOpen] = useState(false)
    const [removePermSuccess, setRemovePermSuccess] = useState('')
    const [removePermSuccessOpen, setRemovePermSuccessOpen] = useState(false)
    const [tab, setTab] = useState(0)

    const history = useHistory();

    const { user, setUser } = useContext(UserContext)
    const { name, preferredName, idType, idNumber, gender, dob } = user

    const handleChange = (event, newValue) => {
        setTab(newValue)
    }

    const updateProfileState = (user) => {
        // update user in UserContext such that health tab data depending on user.dob is also updated
        setUser(prevUser => ({
            ...prevUser,
            name: user.full_name,
            preferredName: user.preferred_name,
            idType: user.id_type.toUpperCase(),
            idNumber: user.id_number,
            gender: user.gender,
            dob: user.dob
        }))
    }

    async function deleteDependant(uuid) {
        setLoading(true)
        try {
            await axiosAuthedInstance.delete(`/user/dependants/remove/${uuid}/`);
            setRemoveDepSuccess('Deleted dependant successfully!')
            setRemoveDepSuccessOpen(true)
        } catch (error) {
            let msg = 'Cannot delete dependant.'
            if (error.response?.data?.error) {
                msg = error.response.data.error
                console.error(error)
            }
            setError(msg)
            setErrorOpen(true)
        }
        setLoading(false)
    }

    async function deletePermission(uuid) {
        setLoading(true);
        try {
            await axiosAuthedInstance.delete(`/user/dependant/permissions/remove/${uuid}/`)
            setRemovePermSuccess('Disconnected family member successfully!')
            setRemovePermSuccessOpen(true)
        } catch (error) {
            let msg = 'Cannot disconnect family member.'
            if (error.response?.data?.error) {
                msg = error.response.data.error
                console.error(error)
            }
            setError(msg)
            setErrorOpen(true)
        }
        setLoading(false);
    }

    let editButton = null
    if (props.match.params.uuid == getUUID() || user.relationship == 'PG') {
        editButton = <Box pt={5}>
            <Button
                id="edit"
                style={{ width: '95px', height: '35px' }}
                onClick={() => {
                    setEditMode(true)
                }}
            >Edit</Button>
        </Box>
    }

    let profileContent = <>
        <Box>
            <ContentRow field_name="Full Name" value={name} />
        </Box>
        <Box pt={2}>
            <ContentRow field_name="Display Name" value={preferredName} />
        </Box>
        <Box pt={2}>
            <ContentRow
                field_name={idType === "PASSPORT" ? 'Passport' : 'HKID'}
                value={idNumber || "N/A"} />
        </Box>
        <Box pt={2}>
            <ContentRow field_name="Gender" value={getGenderLabel(gender)} />
        </Box>
        <Box pt={2}>
            <ContentRow field_name="Date of Birth" value={formatDate(dob)} />
        </Box>
    </>

    let baseContainerProps = {}
    if (props.match.params.uuid !== getUUID() && user.relationship == 'SG') {
        baseContainerProps.showCta = true;
        baseContainerProps.extraComponent = (
            <ProfileCtaBtn
                id="disconnect_member"
                btn_text="Remove dependant"
                callback={() => setOpenRemovePermDialog(true)} />
        )
    } else if (props.match.params.uuid != getUUID() && user.relationship == 'PG') {
        baseContainerProps.showCta = true;
        baseContainerProps.extraComponent = (
            <ProfileCtaBtn
                id="delete_member"
                btn_text="Delete dependant"
                callback={() => { setOpenRemoveDepDialog(true) }} />
        )
    }

    if (editMode) {
        return <EditProfile
            user={user}
            onSaveProfile={updateProfileState}
            setEditMode={setEditMode}
            isDependant={props.match.params.uuid != getUUID()}
        />
    } else {
        return (
            <BaseContainer {...props} noHeader showCta {...baseContainerProps}>
                <UserHeader uuid={props.match.params.uuid} />
                <UserTabs active="profile" uuid={props.match.params.uuid} />
                <Box width="100%">
                    <Tabs value={tab} onChange={handleChange}>
                        <Tab label={'Details'} />
                    </Tabs>

                    {/* FIXME: loading spinner */}

                    <Box id="profile_detail" marginTop={3}>
                        {profileContent}
                        {editButton}
                    </Box>
                    <RemovePermissionDialog
                        title="Remove dependant shared with you?"
                        content={"You will no longer be able to view the dependant's information and records. " +
                            "In order to view the records in the future, you will need to receive and accept a new sharing " +
                            "invite from the managing account holder."}
                        action_label="Remove"
                        cancel
                        openDialog={openRemovePermDialog}
                        setOpenDialog={setOpenRemovePermDialog}
                        action_callback={() => deletePermission(user.rel_uuid)}
                    />
                    <RemovePermSuccessDialog
                        success={removePermSuccess}
                        setSuccess={setRemovePermSuccess}
                        open={removePermSuccessOpen}
                        setOpen={setRemovePermSuccessOpen}
                        dismiss_label={'Got it'}
                        dismiss_callback={() => { history.goBack() }}
                    />
                    <RemoveDependantDialog
                        title={`Delete dependant?`}
                        content="All data and records will be removed."
                        action_label="Delete"
                        cancel
                        openDialog={openRemoveDepDialog}
                        setOpenDialog={setOpenRemoveDepDialog}
                        action_callback={() => deleteDependant(props.match.params.uuid)}
                    />
                    <RemoveDepSuccessDialog
                        success={removeDepSuccess}
                        setSuccess={setRemoveDepSuccess}
                        open={removeDepSuccessOpen}
                        setOpen={setRemoveDepSuccessOpen}
                        dismiss_label={'Got it'}
                        dismiss_callback={() => { history.goBack() }}
                    />
                    <ErrorDialog
                        error={error}
                        setError={setError}
                        open={errorOpen}
                        setOpen={setErrorOpen} />
                </Box>
            </BaseContainer>
        )
    }
}
