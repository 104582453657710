import { SvgIcon } from '@material-ui/core';

export default function NewsIcon() {
    return (
        <SvgIcon>
            <path fill="currentColor" d="M19.6 4c.2 0 .4.2.4.4v10.4c0 .1 0 .2-.1.3l-4.8 4.8c-.1.1-.2.1-.3.1H4.4c-.2 0-.4-.2-.4-.4V4.4c0-.2.2-.4.4-.4h15.2m0-2H4.4C3.1 2 2 3.1 2 4.4v15.2C2 20.9 3.1 22 4.4 22h10.4c.6 0 1.2-.3 1.7-.7l4.8-4.8c.5-.5.7-1.1.7-1.7V4.4C22 3.1 20.9 2 19.6 2z" />
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M6 7h12" />
                <path d="M15 21v-5.7c0-.2.1-.3.3-.3H21" />
            </g>
        </SvgIcon>
    )
}
