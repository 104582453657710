import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "./button";
import Typography from "./typography";
import { cx } from "../utils/common";

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        border: '0.5px solid #ECEFF2',
        boxShadow: '0px 2px 2px 0px #97979733',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(1, 0),
        width: '157px',
    },
    active: {
        borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
    icon: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1.5),
        width: '24px',
        height: '24px',
    },
}))

type Props = {
    title: string,
    icon: typeof React.Component,
    active?: boolean,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const TabButton = (props: Props) => {
    const Icon = props.icon
    const classes = useStyles()

    return (
        <Button className={cx(classes.container, props.active && classes.active)} onClick={props.onClick}>
            <Icon className={classes.icon} active={props.active ? 1 : 0} />
            <Typography variant={props.active ? 'bodyTextLargeBold' : 'bodyTextLarge'} component="span">
                {props.title}
            </Typography>
        </Button>
    )
}

export default TabButton
