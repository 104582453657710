import { makeStyles } from "@material-ui/core"

type Props = {
    children: React.ReactNode
}

const useStyles = makeStyles(() => ({
    stickyFooter: {
        display: 'grid',
        width: '100%',
        maxWidth: '400px',
        alignContent: 'center',
        margin: '0 auto',
        gap: '10px',
        padding: '10px',
        paddingBottom: '16px',
        // Sets all items in the footer to have the same height
        gridAutoRows: '1fr',
        alignItems: 'center',
    }
}));

export const StickyFooterGroup = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.stickyFooter}>
            {children}
        </div>
    )
}
