import { makeStyles, Theme, createStyles } from "@material-ui/core"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { cx } from "../utils/common";

type Props = {
    backgroundColor?: string

    /** Content to display inside info box */
    children: React.ReactNode

    /** Extra stylings to add to box */
    className?: string,
}

const useStyles = makeStyles<Theme, Props>((theme) => createStyles({
    boxWrapper: {
        padding: theme.spacing(1.5),
        background: props => props.backgroundColor || theme.palette.info.light,
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: '4px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: theme.spacing(1),
        color: theme.palette.grey[600],
    }
}));

export const InfoBox = (props: Props) => {
    const {children, className} = props
    const classes = useStyles(props)
    return (
        <div className={cx(classes.boxWrapper, className)}>
            <InfoOutlinedIcon className={classes.icon} />
            {children}
        </div>
    )
}
