import { makeStyles } from "@material-ui/core"
import styled from 'styled-components';
import { spacing, sizing, flexbox, display } from '@material-ui/system';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import EllipsisMenu from "../ellipsismenu";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: theme.typography.fontSize * 0.9375,
        fontWeight: 600,
    },
    list_item_wrapper: {
        cursor: 'pointer'
    },
    Caption_12: {
        marginBottom: '0px',
        color: theme.palette.grey[600],
    },
    iconGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
    },
    lineBreak: {
        marginLeft: '-8px',
        marginTop: theme.spacing(1.5),
        width: '100%',
        height: '1px',
        background: theme.palette.grey[200]
    },
}))

const AccountListItem = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.list_item_wrapper} paddingTop={1.5} paddingBottom={2} width="100%">
            <Grid container spacing={1} >
                <Grid item xs={11}>
                    <Box height="100%" display="flex" justifyContent="center" flexDirection="column" onClick={props.onClick}>
                        <Typography variant="h6" className={classes.name}>
                            {props.title}
                        </Typography>
                        <Typography variant="caption" className={classes.Caption_12}>
                            {props.caption}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1} className={classes.iconGrid}>
                    <EllipsisMenu items={props.ellipsisItems} listItem={props.listItem}/>
                </Grid>
            </Grid>
            {props.divider && <div className={classes.lineBreak} />}
        </Box>
    )}

export default AccountListItem
