import { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { IconButton } from '../button';
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid'

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    tooltip: {
        padding: theme.spacing(1.5,2)
    },
}))


const Tooltips = (props) => {
    const classes = useStyles()
    const [tooltip, setTooltip] = useState(false)

    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" flex={1} margin={1}>
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            Tooltip
                        </Typography>
                        <ClickAwayListener onClickAway={() => setTooltip(false)}>
                            <span>
                                <LightTooltip
                                    arrow
                                    disableTouchListener
                                    disableFocusListener
                                    onOpen={() => setTooltip(true)}
                                    onClose={() => setTooltip(false)}
                                    open={tooltip}
                                    placement="top"
                                    title={
                                        <>
                                            <Box className={classes.tooltip} maxWidth="276px">
                                                <Typography variant="caption">Here's the tooltip text that will show whenever you hover over this tooltip or if you're mobile tap on it.</Typography>
                                            </Box>
                                        </>
                                    }
                                >
                                    <IconButton onClick={() => setTooltip(!tooltip)} size="small"><InfoOutlinedIcon fontSize="small"/></IconButton>
                                </LightTooltip>
                            </span>
                        </ClickAwayListener>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Tooltips
