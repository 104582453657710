import SvgIcon from '@material-ui/core/SvgIcon';

function FamilyIcon(props) {
    const opacityVal = props.active ? 1 : 0.25
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <circle opacity={opacityVal} cx="17.7861" cy="7.5" r="5.5" fill="#FBD202"/>
            <path fill="none" d="M17.1869 21V19.1016C17.1869 18.0946 16.7869 17.1289 16.0749 16.4169C15.3629 15.7049 14.3971 15.3048 13.3902 15.3048H5.79668C4.78972 15.3048 3.82401 15.7049 3.11198 16.4169C2.39995 17.1289 1.99994 18.0946 1.99994 19.1016V21" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M9.59643 11.5935C11.6933 11.5935 13.3932 9.8936 13.3932 7.79672C13.3932 5.69983 11.6933 3.99997 9.59643 3.99997C7.49954 3.99997 5.79968 5.69983 5.79968 7.79672C5.79968 9.8936 7.49954 11.5935 9.59643 11.5935Z" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M22.0031 20.8766V18.9782C22.0024 18.137 21.7225 17.3197 21.2071 16.6549C20.6917 15.99 19.97 15.5151 19.1555 15.3048" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M16.2377 4.12457C17.0544 4.33368 17.7783 4.80865 18.2952 5.47461C18.8122 6.14057 19.0927 6.95963 19.0927 7.80267C19.0927 8.64571 18.8122 9.46477 18.2952 10.1307C17.7783 10.7967 17.0544 11.2717 16.2377 11.4808" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default FamilyIcon