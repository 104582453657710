import { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../components/typography';
import {spacing, sizing, display, flexbox} from '@material-ui/system';
import styled from 'styled-components';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import {axiosAuthedInstance} from "../../utils/axiosApi";
import {BaseContainer} from "../../components/container";
import Loading from "../../components/loading";
import ActionDialog from "../../components/dialogs/action_dialog";
import {getUUID, getPreferredName} from "../../utils/auth";
import ErrorDialog from "../../components/dialogs/error_dialog";
import Card from "../../components/card";
import RecordsDotIcon from "../../assets/icons/records_dot";
import { PageDescription } from "../../components/page_sections";
import { TooltipDrawer } from "../../components/tooltip_drawer";
import { DrawerContent } from "../../components/drawer_content";
import SimpleUserIcon from "../../assets/icons/simple_user";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { Button } from "../../components/button";
import { EmptyUsers } from "../../components/empty_content";
import { isCancel } from "axios";


const useStyles = makeStyles(() => ({
    icon: {
        width: '24px',
        height: '24px',
    }
}))

const Records = (props) => {
    const [loading, setLoading] = useState(true);
    const [pgDependants, setPgDependants] = useState([])
    const [sgDependants, setSgDependants] = useState([])
    const [showAddDialog, setShowAddDialog] = useState(false)

    const [error, setError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)

    const classes = useStyles()
    const history = useHistory();

    async function getDependants() {
        setLoading(true)
        try {
            const response = await axiosAuthedInstance.get('/user/dependants/');
            setDependent(response.data.data)
        } catch (error) {
            if (isCancel(error)) return

            console.error(error)
            setError(error.response.data.error || 'Error getting dependants.')
            setErrorOpen(true)
        }
        setLoading(false);
    }

    function setDependent(dependants) {
        const sg = []
        const pg = []
        dependants.map(dependant => {
            if (dependant.relationship === 'PG') {
                pg.push(dependant)
            } else if (dependant.relationship === 'SG') {
                sg.push(dependant)
            }
        })
        setPgDependants(pg)
        setSgDependants(sg)
    }

    useEffect(() => {
        getDependants();
    }, []);

    const primaryTitle = "Dependants you manage"
    const primaryContent = "You have edit access to the profiles you manage - you can create their profiles, delete them, and add or delete their Covid-19 records."
    const secondaryTitle = "Dependants shared with you"
    const secondaryContent = "You have view-only access to the profiles shared with you that are managed by another Imunis account holder. You are not authorised to add or delete their records, or their profile. You can disconnect from sharing in which case you will no longer be able to view their records."

    return (
        <BaseContainer showNav records showCta {...props} extraComponent={
            <StickyFooterGroup>
                <Button fullWidth onClick={() => setShowAddDialog(true)}>
                    Add dependant
                </Button>
            </StickyFooterGroup>
        }>
            {loading && <Loading loading={loading}/>}

            <Box display="flex" mb={1.5}>
                <Box marginRight={1}>
                    <RecordsDotIcon/>
                </Box>
                <Typography variant="modalHeader">Records</Typography>
            </Box>

            <PageDescription>
                <Card
                    title={getPreferredName()}
                    icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                    onClick={() => {
                        history.push(`/records/${getUUID()}/health`)
                    }}/>
            </PageDescription>

            {!loading && <div id="dependants_list">

                <PageDescription>
                    <div id="primary_dependants_list">
                        <TooltipDrawer title={primaryTitle}>
                            <DrawerContent title={primaryTitle} content={primaryContent}/>
                        </TooltipDrawer>
                        {pgDependants.length > 0 ? pgDependants.map(dependant => (
                            <Card
                                key={dependant.uuid}
                                title={dependant.preferred_name}
                                icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                                onClick={() => {
                                    history.push(`/records/${dependant.uuid}/health`)
                                }}/>
                        )) : <EmptyUsers emptyText="No profile you manage"/>}
                    </div>
                </PageDescription>

                <PageDescription>
                    <div id="secondary_dependants_list">
                        <TooltipDrawer title={secondaryTitle}>
                            <DrawerContent title={secondaryTitle} content={secondaryContent}/>
                        </TooltipDrawer>
                        {sgDependants.length > 0 ? sgDependants.map(dependant => (
                            <Card
                                key={dependant.uuid}
                                title={dependant.preferred_name}
                                icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                                onClick={() => {
                                    history.push(`/records/${dependant.uuid}/health`)
                                }}/>
                        ))  : <EmptyUsers emptyText="No profile shared with you"/>}
                    </div>
                </PageDescription>

            </div>}

            <ActionDialog
                cancel
                title='Add a new dependant'
                content='You can either scan the QR code of a Primary Guardian or enter their information manually.'
                action_label='Scan QR code'
                action_callback={() => history.push('/dependants/scan')}
                cancel_label='Enter information manually'
                cancel_callback={() => history.push('/dependants/add')}
                openDialog={showAddDialog}
                setOpenDialog={setShowAddDialog}
            />
            <ErrorDialog
                error={error}
                setError={setError}
                open={errorOpen}
                setOpen={setErrorOpen}/>
        </BaseContainer>
    )
}
export default Records;
