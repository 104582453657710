import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import styled from 'styled-components';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { getUUID, getPreferredName } from "../../utils/auth";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import { axiosAuthedInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 1.5,
        marginBottom: theme.spacing(2)
    },
    share_text: {
        marginBottom: theme.spacing(2)
    },
    secondary_text: {
        fontSize: '0.8rem',
        lineHeight: '0.9rem',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    },
}))

const TestSendNotification = (props) => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [recipientUuid, setRecipientUuid] = useState(getUUID());
    const [category, setCategory] = useState('FAMILY');
    const [content, setContent] = useState('');
    const [params, setParams] = useState('');
    const [url, setUrl] = useState('');

    const classes = useStyles();

    async function getUsers() {
        try {
            const response = await axiosAuthedInstance.get('/test/get-other-users/');
            setUsers(response.data.data)
            // set first user as recipientUuid
            // setRecipientUuid(response.data.data[0].uuid);
            setLoading(false);
        } catch (error) {
            console.warn(error)
            setLoading(false);
        }
    }
    async function sendNotification() {
        setLoading(true);
        try {
            const response = await axiosAuthedInstance.post(`/test/notification/send/`, {
                uuid: recipientUuid,
                category: category,
                content: content,
                params: params,
                url: url
            });
            alert(response.data.message);
            setLoading(false);
        } catch (error) {
            console.error(error);
            alert(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <BaseContainer {...props}>
            {loading && <Loading loading={loading} />}
            <Typography variant="h5" className={classes.heading}>
                Generate new notification
            </Typography>
            <Typography className={classes.share_text}>Step1: select target user</Typography>
            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                <FormControl variant="outlined" >
                    <InputLabel>End User</InputLabel>
                    <Select
                        classes={{ select: classes.select }}
                        open={openSelect}
                        onOpen={() => setOpenSelect(true)}
                        onClose={() => setOpenSelect(false)}
                        native
                        value={recipientUuid}
                        onChange={(event) => {
                            setRecipientUuid(event.target.value)
                        }}
                        label="Recipient">
                        <option key={getUUID()} value={getUUID()}>{getPreferredName()} (Me)</option>
                        {users.map((user, i) => <option key={i} value={user.uuid}>{user.full_name}</option>)}
                    </Select>

                </FormControl>
            </ClickAwayListener>
            <Typography className={classes.share_text}>Step2: Select category</Typography>
            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                <FormControl variant="outlined" >
                    <InputLabel>Message Category</InputLabel>
                    <Select
                        classes={{ select: classes.select }}
                        open={openSelect}
                        onOpen={() => setOpenSelect(true)}
                        onClose={() => setOpenSelect(false)}
                        native
                        value={category}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setCategory(event.target.value)
                        }}
                        label="Message Category">
                        <option key="FAMILY" value="FAMILY">FAMILY</option>
                        <option key="VACCINE" value="VACCINE">VACCINE</option>
                        <option key="NEWS" value="NEWS">NEWS</option>
                        <option key="CONNECTIONS" value="CONNECTIONS">CONNECTIONS</option>
                    </Select>
                </FormControl>
            </ClickAwayListener>
            <Typography className={classes.share_text}>Step3: input information</Typography>
            <FormControl variant="outlined" >
                <TextField
                    id="content"
                    name="content"
                    label="Content"
                    fullWidth
                    margin="normal"
                    multiline
                    value={content}
                    onChange={(event) => {
                        setContent(event.target.value)
                    }}
                />
            </FormControl>
            <FormControl variant="outlined" >
                <TextField
                    id="params"
                    name="params"
                    label="Params (Optional)"
                    fullWidth
                    margin="normal"
                    multiline
                    value={params}
                    onChange={(event) => {
                        setParams(event.target.value)
                    }}
                />
            </FormControl>
            <FormControl variant="outlined" >
                <TextField
                    id="url"
                    name="url"
                    label="Url (Optional)"
                    fullWidth
                    margin="normal"
                    value={url}
                    onChange={(event) => {
                        setUrl(event.target.value)
                    }}
                />
            </FormControl>
            <Box mt={2} mb={1}>
                <Button variant='contained' color='primary' onClick={() => sendNotification()}> send </Button>
            </Box>
        </BaseContainer>
    )
}
export default TestSendNotification;
