import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '../assets/icons/chevron_right';
import { Button } from './button';
import Typography from './typography';

const useStyles = makeStyles<Theme>((theme) => createStyles({
    modal: {
        padding: theme.spacing(5, 5, 3),
    },
    title: {
        margin: theme.spacing(0, 0, 4)
    },
    truncateText: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    listItem: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
    },
    listTitle: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
    },
    cancel: {
        width: '80%',
        alignSelf: 'center',
        margin: theme.spacing(0, 0, 2)
    }
}))

type AddRecordModalProps = {
    /** Whether to open dialog */
    open: boolean

    /** Close dialog function */
    close: () => void

    /**
     * Depending on where the modal got triggered from, we show extra information on the scanner page
     * Defaults to true. On the homepage, we already show this info so this will be false then.
     */
    showCovidIntro: boolean

    /**
     * Account holder or Dependant
     * Note this is the user from the UserContext (slightly different from the AddRecordContext)
     */
    user: {
        uuid: string
        preferredName: string
    }
}

export const AddRecordModal = (props: AddRecordModalProps) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Dialog open={props.open}>
            <div className={classes.modal}>
                <Typography align="center" variant="modalHeader" className={classes.title}>
                    Add vaccination record
                </Typography>
                <div aria-label="action" className={classes.listItem} onClick={() => {
                    history.push({
                        pathname: '/scan',
                        state: {
                            showIntro: props.showCovidIntro,
                            user: {
                                preferredName: props.user.preferredName,
                                uuid: props.user.uuid
                            }
                        }
                    })
                }}>
                    <div className={classes.listTitle}>
                        <Typography className={classes.truncateText} variant="bodyTextMediumBold">
                            Covid-19 (scan QR code)
                        </Typography>
                    </div>
                    <ChevronRightIcon/>
                </div>
                <Divider/>
                <div aria-label="action" className={classes.listItem} onClick={() => {
                    history.push(`/add-record/${props.user.uuid}/location`)
                }}>
                    <div className={classes.listTitle}>
                        <Typography className={classes.truncateText} variant="bodyTextMediumBold">
                            Influenza (enter manually)
                        </Typography>
                    </div>
                    <div style={{'marginLeft': '20px'}}>
                        <ChevronRightIcon/>
                    </div>
                </div>
                <Divider/>
            </div>
            <Button onClick={props.close} className={classes.cancel}>
                Cancel
            </Button>
        </Dialog>
    )
}
