const Svg = () => (
    <svg viewBox="0 0 300 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="26.5" y="48.5" width={246} height={66} rx="25.5" fill="white" stroke="#E7E7E7" />
        <text fill="#767676" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={12} letterSpacing="0px"><tspan x={49} y="95.6553">Connected on 1 Mar 2022</tspan></text>
        <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={14} fontWeight={600} letterSpacing="0px"><tspan x={49} y="77.9312">Clinic</tspan></text>
        <path fillRule="evenodd" clipRule="evenodd" d="M245.394 63.0804C245.902 63.296 246.137 63.8787 245.919 64.3819L237.919 82.8839C237.702 83.387 237.114 83.6201 236.606 83.4045C236.098 83.1888 235.863 82.6061 236.081 82.103L244.081 63.601C244.298 63.0978 244.886 62.8648 245.394 63.0804Z" fill="#333333" />
        <path fillRule="evenodd" clipRule="evenodd" d="M240.813 66.965H234.333C230.836 66.965 228 69.7755 228 73.2424C228 76.7094 230.836 79.5199 234.333 79.5199H235.384L236.242 77.5375H234.333C231.94 77.5375 230 75.6145 230 73.2424C230 70.8703 231.94 68.9473 234.333 68.9473H239.956L240.813 66.965ZM242.044 77.5375H247.667C250.06 77.5375 252 75.6145 252 73.2424C252 70.8703 250.06 68.9473 247.667 68.9473H245.758L246.616 66.965H247.667C251.164 66.965 254 69.7755 254 73.2424C254 76.7094 251.164 79.5199 247.667 79.5199H241.187L242.044 77.5375Z" fill="#333333" />
    </svg>
)

export default Svg
