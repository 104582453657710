import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const RecordsIcon = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
            <path strokeWidth="1.7" d="M15.5 4.5h1.75A1.75 1.75 0 0 1 19 6.25V18.5a1.75 1.75 0 0 1-1.75 1.75H6.75A1.75 1.75 0 0 1 5 18.5V6.25A1.75 1.75 0 0 1 6.75 4.5H8.5"/>
            <path strokeWidth="1.8" d="M14.63 2.75H9.38c-.48 0-.88.4-.88.87v1.75c0 .49.4.88.88.88h5.25c.48 0 .88-.4.88-.88V3.62c0-.48-.4-.87-.88-.87Z"/>
            <path strokeWidth="1.5" d="m13.56 8.88 1.94 1.94-5.05 5.05H8.5v-1.94l5.06-5.05Z"/>
        </g>
    </SvgIcon>
)

export default RecordsIcon
