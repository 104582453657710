import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import styled from 'styled-components';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import { axiosAuthedInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 1.5,
        marginBottom: theme.spacing(2)
    },
    share_text: {
        marginBottom: theme.spacing(2)
    },
    secondary_text: {
        fontSize: '0.8rem',
        lineHeight: '0.9rem',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    },
}))

const TestSharingDependants = (props) => {
    const [loading, setLoading] = useState(true);
    const [dependants, setDependants] = useState([]);
    const [users, setUsers] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [recipientUuid, setRecipientUuid] = useState('');

    const classes = useStyles();

    async function getDependants() {
        try {
            const response = await axiosAuthedInstance.get('/user/dependants/');
            setDependants(response.data.data.filter(dep => dep.relationship == 'PG'))
            setLoading(false);
        } catch (error) {
            console.warn(error)
            setLoading(false);
        }
    }
    async function getUsers() {
        try {
            const response = await axiosAuthedInstance.get('/test/get-other-users/');
            setUsers(response.data.data)
            // set first user as recipientUuid
            setRecipientUuid(response.data.data[0].uuid);
            setLoading(false);
        } catch (error) {
            console.warn(error)
            setLoading(false);
        }
    }
    async function sharePermissions(uuid) {
        setLoading(true);
        try {
            const response = await axiosAuthedInstance.post(`/test/share-dependants-to/${uuid}/`);
            alert(response.data.message);
            setLoading(false);
        } catch (error) {
            console.error(error);
            alert(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getDependants();
        getUsers();
    }, []);

    return (
        <BaseContainer {...props}>
            {loading && <Loading loading={loading}/>}
            <Typography variant="h5" className={classes.heading}>
                Test Share Dependants
            </Typography>
            <Typography className={classes.share_text}>Share my dependants ({dependants.map(dep => dep.preferred_name).join(', ')}) to:</Typography>
            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                <FormControl variant="outlined" >
                    <InputLabel>End User</InputLabel>
                    <Select
                        classes={{select: classes.select}}
                        open={openSelect}
                        onOpen={() => setOpenSelect(true)}
                        onClose={() => setOpenSelect(false)}
                        native
                        value={recipientUuid}
                        onChange={(event) => {
                            setRecipientUuid(event.target.value)
                        }}
                        label="Recipient"
                    >
                        {users.map((user,i) => <option key={i} value={user.uuid}>{user.full_name}</option>)}
                    </Select>
                </FormControl>
            </ClickAwayListener>
            <Box mt={2} mb={1}>
                <Button variant='contained' color='primary' onClick={() => sharePermissions(recipientUuid)}> Share </Button>
            </Box>
            <Typography className={classes.secondary_text}>1. Clicking 'Share' will create an 'opened' SharingInvite with the recipient set to the end user selected above.</Typography>
            <Typography className={classes.secondary_text}>2. After Clicking 'Share', you can then login as the recipient to accept/decline the SharingInvite.</Typography>
        </BaseContainer>
    )
}
export default TestSharingDependants;
