import { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Typography from './typography';
import Drawer from '@material-ui/core/Drawer';
import { IconButton } from "./button";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { axiosAuthedInstance } from "../utils/axiosApi";
import { isLoggedIn, getUserFullName, getEmail, getUUID } from "../utils/auth";
import { notifications_polling_interval } from "../utils/constants";
import { GlobalContext } from "./context/globalState";
import Logo from "../assets/img/imunis-logo.svg";
import ActionDialog from "./dialogs/action_dialog";
import NotificationBellIcon from "../assets/icons/notification_bell";
import ProfileIcon from "../assets/icons/profile";
import PrivacyPolicyIcon from "../assets/icons/privacy";
import SignOutIcon from "../assets/icons/sign_out";
import ImunisIcon from "../assets/icons/imunis_symbol"
import { LegalDrawer } from "./legal";
import { ButtonBase } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import TermsIcon from "../assets/icons/terms";
import ImunisAppIcon from "../assets/icons/imunis_app";
import { FaqDrawer } from "./faq";
import FAQIcon from "../assets/icons/faq_icon";
import SettingsIcon from "../assets/icons/settings";
import { isCancel } from "axios";

const drawerWidth = 330;

const useStyles = makeStyles((theme) => ({
    header: {
        margin: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    logo: {
        marginRight: 'auto',
    },
    centeredIcon: {
        justifyContent: 'center',
        '& > svg': {
            fontSize: '42px'
        },
    },
    drawer: {
        display: 'block',
        width: drawerWidth,
        paddingTop: `calc(env(safe-area-inset-top) + ${theme.spacing(3)}px)`,
        paddingBottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing(3)}px)`,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    navItem: {
        textAlign: 'start',
        display: "flex",
        gap: theme.spacing(1),
        alignItems: "center",
        justifyContent: 'flex-start',
        margin: theme.spacing(2, -3),
        padding: theme.spacing(0, 3),
        boxSizing: 'content-box',
        width: '100%',
    },
    nameSection: {
        display: 'inline-grid',
        gridTemplateColumns: '80% 20%',
        width: '100%',
        alignItems: 'center'
    },
    closeIcon: {
        justifySelf: 'right',
        width: '24px',
        height: '24px'
    },
    email: {
        margin: theme.spacing(0, 0, 4)
    }
}));

const NavButton = ({ navigate, children, ...props }) => {
    const onClick = event => {
        if (!navigate) return

        event.preventDefault()
        navigate()
    }

    return (
        <ButtonBase component="a" onClick={onClick} {...props}>
            {children}
        </ButtonBase>
    )
}

export const Header = (props) => {
    const history = useHistory();
    const { state, dispatch } = useContext(GlobalContext)
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showSignOutDialog, setShowSignOutDialog] = useState(false)

    const loggedIn = isLoggedIn() && !!getUUID();

    const fetchNotificationsCount = () => {
        axiosAuthedInstance.get('/user/notifications-count/').then(result => {
            dispatch({ type: 'SET_NOTIFICATIONS', payload: (result.data.count || 0) })
        }).catch(err => {
            if (isCancel(err)) return

            console.error(err)
        })
    }

    const startNotificationsCountPolling = () => {
        // short polling notifications count every 30 seconds
        return setInterval(() => {
            fetchNotificationsCount()
        }, notifications_polling_interval)
    }

    useEffect(() => {
        if (loggedIn) {
            fetchNotificationsCount()
            const intervalId = startNotificationsCountPolling()
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [])

    const onDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const loggedInContent = loggedIn ? <>
        <IconButton
            aria-label="Open notifications"
            size="small"
            onClick={() => history.push('/notifications')}
        >
            <NotificationBellIcon count={state.notification_count} fontSize="small" />
        </IconButton>
        <IconButton
            id="drawer-icon"
            className={classes.icon}
            aria-label="Open application menu"
            size="small"
            onClick={onDrawerToggle}
        >
            <ProfileIcon />
        </IconButton>

        <Drawer
            variant="temporary"
            anchor='right'
            open={drawerOpen}
            onClose={onDrawerToggle}
            classes={{
                paper: classes.drawer,
            }}
            ModalProps={{
                BackdropProps: {
                    "aria-label": "Close application menu"
                }
            }}
        >
            <nav aria-label="Application">
                <div className={classes.nameSection}>
                    <Typography variant="bodyTextLargeBold">
                        {getUserFullName()}
                    </Typography>
                    <CloseIcon onClick={onDrawerToggle} className={classes.closeIcon} />
                </div>
                <Typography variant="bodyTextSmall" className={classes.email}>
                    {getEmail()}
                </Typography>

                {/* only visible if the navbar is also visible; some pages don't show navbar */}
                {props.showNav &&
                    <>
                        <NavButton className={classes.navItem}
                            onClick={() => {
                                onDrawerToggle()
                                props.showOnboarding()
                            }}>
                            <ImunisIcon />
                            <Typography variant="bodyTextLargeBold">
                                What's in Imunis
                            </Typography>
                        </NavButton>
                        <Divider />
                    </>
                }

                {props.canShowInstall &&
                    <>
                        <NavButton className={classes.navItem}
                            onClick={() => {
                                onDrawerToggle()
                                props.showAddDialog()
                            }}>
                            <ImunisAppIcon />
                            <Typography variant="bodyTextLargeBold">
                                Add Imunis to Home screen
                            </Typography>
                        </NavButton>
                        <Divider />
                    </>
                }

                <LegalDrawer document="privacy_policy">
                    <NavButton className={classes.navItem}>
                        <PrivacyPolicyIcon />
                        <Typography variant="bodyTextLargeBold">
                            Privacy
                        </Typography>
                    </NavButton>
                </LegalDrawer>

                <Divider />

                <LegalDrawer document="terms_conditions">
                    <NavButton className={classes.navItem}>
                        <TermsIcon />
                        <Typography variant="bodyTextLargeBold">
                            Terms & conditions
                        </Typography>
                    </NavButton>
                </LegalDrawer>

                <Divider />

                <FaqDrawer>
                    <NavButton className={classes.navItem}>
                        <FAQIcon />
                        <Typography variant="bodyTextLargeBold">
                            FAQ
                        </Typography>
                    </NavButton>
                </FaqDrawer>

                <Divider />

                <NavButton className={classes.navItem}
                    onClick={() => history.push('/account-settings')}>
                    <SettingsIcon />
                    <Typography variant="bodyTextLargeBold">
                        Account settings
                    </Typography>
                </NavButton>

                <Divider />

                <NavButton className={classes.navItem}
                    onClick={() => setShowSignOutDialog(true)}>
                    <SignOutIcon />
                    <Typography variant="bodyTextLargeBold">
                        Sign out
                    </Typography>
                </NavButton>
            </nav>
        </Drawer>

        <ActionDialog
            title=''
            content='Are you sure you want to sign out?'
            action_label='Sign out'
            action_callback={() => history.push('/logout')}
            openDialog={showSignOutDialog}
            setOpenDialog={setShowSignOutDialog} />
    </> : null;

    return (
        <div className={classes.header}>
            <Link to="/" className={classes.logo}>
                <img src={Logo} width="128px" alt="Imunis Logo" />
            </Link>

            {loggedInContent}
        </div>
    )
}
