import { createContext, useEffect, useReducer } from 'react';
import { globalReducer } from './globalReducer'

import { getNotificationCount } from '../../utils/common'
import { isLoggedIn } from '../../utils/auth';
import { axiosAuthedInstance } from '../../utils/axiosApi';
import { isCancel } from 'axios';

// initial value for the notification_count is fetched from localStorage
// we persist the notification_count in localStorage so that it does not have to render as 0 notifications everytime on page load
const initialState = {
    notification_count: getNotificationCount()
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, initialState)

    /**
     * On initial mount, retrieve user details and place it in global context for any component to
     * access.
     *
     * Notes:
     * - The same data is also saved to local storage. Consider that deprecated -- we should
     *   set/read from global context (maybe global store, some day) so we get reactivity
     * - On other actions
     */
    useEffect(() => {
        if (isLoggedIn()) {
            axiosAuthedInstance
                .get("user/details/")
                .then((response) => {
                    /*
                    type UserDetailsResponse = {
                        data: {
                            uuid: string
                            email: string
                            full_name: string
                            preferred_name: string
                        }
                    }
                    */
                    const user = response.data.user

                    dispatch({
                        type: "SET_USER",
                        payload: user
                    })
                })
                .catch((error) => {
                    if (isCancel(error)) return

                    console.error(error)
                })
        }
    }, [])

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>
            { children }
        </GlobalContext.Provider>
    )
}
