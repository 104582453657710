import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function TickCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path stroke="currentColor" fill="none" strokeLinecap="round" strokeWidth="2" d="M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0Z"/>
            <path fill="currentColor" d="M8.3 12.4a1 1 0 0 0-.7-.2 1 1 0 0 0-.6.2l-.3.6c0 .3 0 .5.2.7l2.2 2 .3.2a1 1 0 0 0 1-.2l6.7-6.2.2-.3a.8.8 0 0 0 0-.7l-.3-.2-.3-.2a1 1 0 0 0-1 .2l-6 5.5-1.4-1.4Z"/>
        </SvgIcon>
    );
}

export default TickCircleIcon
