import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders, palette } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}${palette}`;

const Colors = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Box display="flex" flexDirection="column" >
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Colors
                </Typography>
                <Box display="flex" flexDirection="row" mb={2}>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.primary.main} onClick={()=>props.setColor(theme.palette.primary.main)} />
                        <Typography variant='subtitle2'>Primary</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.primary.light} onClick={()=>props.setColor(theme.palette.primary.light)} />
                        <Typography variant='subtitle2'>~Light</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.error.main} onClick={()=>props.setColor(theme.palette.error.main)} />
                        <Typography variant='subtitle2'>Error</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.success.main} onClick={()=>props.setColor(theme.palette.success.main)} />
                        <Typography variant='subtitle2'>Success</Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" mb={2}>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.secondary.dark} onClick={()=>props.setColor(theme.palette.secondary.dark)} />
                        <Typography variant='subtitle2'>Brand1</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.secondary.main} onClick={()=>props.setColor(theme.palette.secondary.main)} />
                        <Typography variant='subtitle2'>Brand2</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.secondary.light} onClick={()=>props.setColor(theme.palette.secondary.light)} />
                        <Typography variant='subtitle2'>Brand3</Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" mb={2}>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.grey[800]} onClick={()=>props.setColor(theme.palette.grey[800])} />
                        <Typography variant='subtitle2'>Dark</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.grey[600]} onClick={()=>props.setColor(theme.palette.grey[600])} />
                        <Typography variant='subtitle2'>Grey</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.grey[400]} onClick={()=>props.setColor(theme.palette.grey[400])} />
                        <Typography variant='subtitle2'>Grey2</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.grey[200]} onClick={()=>props.setColor(theme.palette.grey[200])} />
                        <Typography variant='subtitle2'>Grey3</Typography>
                    </Box>
                    <Box>
                        <Box width="50px" height="50px" mr={2} bgcolor={theme.palette.common.white} onClick={()=>props.setColor(theme.palette.common.white)} />
                        <Typography variant='subtitle2'>White</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Colors
