import { useEffect, useState } from "react"
import { BackButton } from "../components/buttons/back_btn"
import { BaseContainer } from "../components/container"
import Loading from "../components/loading"
import Typography from "../components/typography"
import Card from "../components/card";
import ChevronRightIcon from "../assets/icons/chevron_right"
import { HeaderTitle, PageSection, PageSubsection } from "../components/page_sections"
import { makeStyles } from "@material-ui/core"
import { AddRecordModal } from "../components/add_record_modal";
import { axiosAuthedInstance } from "../utils/axiosApi"
import { getPreferredName, getUUID, getUserFullName } from "../utils/auth"
import ErrorDialog from "../components/dialogs/error_dialog"
import { useHistory } from "react-router-dom"
import { isCancel } from "axios"
import { AppHeader } from "../components/header"
import { Step as PaperRecordStep } from "./records/paper/new"
import { StickyFooterGroup } from "../components/sticky_footer_group"
import { Button } from "../components/button"

const useStyles = makeStyles((theme) => ({
    text: {
        margin: theme.spacing(1, 0)
    },
    section: {
        margin: theme.spacing(3, 0)
    }
}))

type User = {
    uuid: string
    preferred_name: string
    full_name: string
    relationship: string
}

type DependantsResponse = {
    data: User[]
}

const useDependants = () => {
    const [users, setUsers] = useState<User[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        setLoading(true)
        axiosAuthedInstance.get<DependantsResponse>('/user/dependants/')
            .then((response) => {
                setUsers([
                    {
                        uuid: getUUID() as string,
                        preferred_name: getPreferredName() as string,
                        full_name: getUserFullName() as string,
                        relationship: 'SELF'
                    },
                    ...response.data.data
                ])
            }).catch((error) => {
                if (isCancel(error)) return

                console.error(error)
                setError('Server error. Please try again later.')
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    const selfAndChildren = users ? users.filter(user => user.relationship !== 'SG') : []
    const sharedDependants = users ? users.filter(user => user.relationship === 'SG') : []

    return { selfAndChildren, sharedDependants, loading, error, setError }
}

const SharedDependants = ({ sharedDependants}: { sharedDependants: User[]}) => {
    if (sharedDependants.length === 0) return null

    return (
        <PageSection>
            <Typography variant="bodyTextLarge">
                You can add records to dependant profiles that you manage
                but not to those that are shared with you by another account holder.
            </Typography>

            {sharedDependants.map(user => (
                <Card
                    key={user.uuid}
                    title={user.preferred_name}
                    disable={true}
                />
            ))}
        </PageSection>
    )
}

export const AddVaccineRecord = () => {
    const classes = useStyles()
    const history = useHistory()
    const [showAddRecord, setShowAddRecord] = useState(false)
    const [targetUser, setTargetUser] = useState<User>()

    const {selfAndChildren, sharedDependants, loading, error, setError} = useDependants()

    return (
        <BaseContainer noHeader>

            <BackButton onClick={() => {
                history.push('/home')
            }} />

            <HeaderTitle>
                Add Covid-19 or Influenza record
            </HeaderTitle>

            {loading && <Loading loading={loading} />}

            <Typography variant="bodyTextLarge">
                Here you may self-record vaccination records for Covid-19 (QR code scan) or influenza (hand key). All other records at this time need to be added by your health provider, or by uploading paper records which Imunis will add to your account (exit and choose ‘Digitise paper records’).
            </Typography>

            <Typography variant="bodyTextLargeBold" component="h2" className={classes.text}>
                Covid-19 records
            </Typography>

            <Typography variant="bodyTextLarge" className={classes.text}>
                These may be added via QR code scanning for records issued by the following entities:
            </Typography>

            <ul>
                <li> Hong Kong SAR </li>
                <li> NHS Digital </li>
                <li> EU Green Pass </li>
            </ul>

            <Typography variant="bodyTextLargeBold" component="h2" className={classes.text}>
                Influenza records
            </Typography>

            <Typography variant="bodyTextLarge" className={classes.text}>
                These may be manually keyed into Imunis.
            </Typography>

            <div className={classes.section}>
                <Typography variant="bodyTextMediumPrimary" className={classes.text}>
                    Select Profile:
                </Typography>

                {selfAndChildren.map(user => (
                    <Card
                        key={user.uuid}
                        title={user.preferred_name}
                        extra={<ChevronRightIcon fontSize="small" />}
                        onClick={() => {
                            setShowAddRecord(true)
                            setTargetUser({
                                uuid: user.uuid,
                                preferred_name: user.preferred_name,
                                full_name: user.full_name,
                                relationship: user.relationship
                            })
                        }}
                    />
                ))}
            </div>

            <SharedDependants sharedDependants={sharedDependants} />

            <AddRecordModal
                open={showAddRecord}
                user={{
                    uuid: targetUser?.uuid as string,
                    preferredName: targetUser?.preferred_name as string
                }}
                close={() => setShowAddRecord(false)}
                showCovidIntro={false}
            />

            <ErrorDialog
                error={error}
                open={Boolean(error)}
                setOpen={setError}
            />
        </BaseContainer>
    )
}

export const AddPaperRecords = () => {
    const history = useHistory()
    const {selfAndChildren, sharedDependants, loading, error, setError} = useDependants()

    return (
        <BaseContainer
            header={
                <AppHeader onBack={() => history.push('/')} />
            }
            showCta extraComponent={
                <StickyFooterGroup>
                    <Button fullWidth onClick={() => history.push('/paper-records/history', {
                        backTo: '/paper-records/new'
                    })}>
                        View submission history
                    </Button>
                </StickyFooterGroup>
            }
        >
            <HeaderTitle>
                Digitise Paper Records
            </HeaderTitle>

            <PageSubsection>
                Here you may upload images of historic vaccination records for members of your account,
                which will be digitised and added to their Imunis profile.
            </PageSubsection>

            <PageSubsection>
                <Typography variant="bodyTextMediumPrimary">
                    Select Profile:
                </Typography>

                {selfAndChildren.map(user => (
                    <Card
                        key={user.uuid}
                        title={user.preferred_name}
                        extra={<ChevronRightIcon fontSize="small" />}
                        onClick={() => {
                            history.push(`/paper-records/new/${PaperRecordStep.Scan}`, {
                                subject: {
                                    id: user.uuid,
                                    fullName: user.full_name
                                }
                            })
                        }}
                    />
                ))}
            </PageSubsection>

            <SharedDependants sharedDependants={sharedDependants} />

            <Loading loading={loading} />

            <ErrorDialog
                error={error}
                open={Boolean(error)}
                setOpen={setError}
            />
        </BaseContainer>
    )
}
