import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../records/user_header";
import { formatDate } from '../../utils/common'
import { BaseContainer } from "../../components/container";
import ErrorDialog from "../../components/dialogs/error_dialog";
import Loading from "../../components/loading";
import { BackButton } from "../../components/buttons/back_btn";
import ListItem from "../../components/lists/list_item";
import Typography from "../../components/typography";
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { Divider } from "../../components/divider";
import { SourceModal } from "../../components/source_modal";
import { mergeVaccineRecords } from "../records/health";
import { VaccineEventIcon, conflictLabel, vaccineEventName } from "../records/utils";

const FLU_NORTH_ID = '6142004-NORTH'

/**
 * type PastRecords {
 *   disease: '6142004-NORTH' | '6142004-SOUTH'
 *   // List of vaccine event ids that will be highlighted on page load
 *   addedRecords: Set(["IMMU-GENERIC-FLU-NORTH,2019-10-02"])
 * }
 */

const PastRecords = ({disease, onClose, ...props}) => {
    const [sourcesList, setSourcesList] = useState(null)
    const [error, setError] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const history = useHistory()
    const { user, vaccineEvents, diseases, sources, loading } = useContext(UserContext)

    onClose = onClose || history.goBack
    disease = disease || props.location.state?.disease

    useEffect(() => {
        if (scrollPosition) {
            window.scrollTo(0, scrollPosition)
            setScrollPosition(0)
        }
    }, [scrollPosition])

    const openRecord = (vaccine, selectedSourceIdx = null) => {
        setScrollPosition(window.pageYOffset)

        // Format the list of source events for the prompt to display
        if (selectedSourceIdx === null && vaccine.source_records.length > 1) {
            setSourcesList({
                vaccine: vaccine,
                sources: vaccine.source_records.map((id, i) => {
                    const { type, issuer, country } = sources[id]
                    return {
                        id: i,
                        type: type,
                        label: issuer,
                        country: country,
                    }
                })
            })
            return
        } else {
            // Source record index specified or not applicable with only one source
            selectedSourceIdx = selectedSourceIdx || 0
        }

        const sourceRecordId = vaccine.source_records[selectedSourceIdx]

        history.push(`/records/${user.uuid}/vaccine`, {
            vaccine: mergeVaccineRecords(sourceRecordId, vaccine, sources[sourceRecordId]),
            referrer: `/records/${user.uuid}/past-records`
        })
    }

    let content = null

    if (!loading) {
        const vaccineEventKeys = diseases[disease].vaccine_events.filter(v => !v.in_season).map(v => v.key)
        if (vaccineEventKeys.length) {
            content = vaccineEventKeys.map(k => {
                const vaccine = vaccineEvents[k]

                const title = <>
                    {formatDate(vaccine.vaccine_date)}
                    {conflictLabel(vaccine)}
                </>

                let highlight
                if (vaccine.unspecified_product) {
                    highlight = 'neutral'
                } else if (props.addedRecords?.has(k)) {
                    highlight = 'success'
                }

                return (
                    <ListItem
                        key={k}
                        title={title}
                        subtitle={vaccineEventName(vaccine, sources)}
                        onClick={() => openRecord(vaccine)}
                        extra={VaccineEventIcon(vaccine, sources)}
                        highlight={highlight}
                        divider={true}
                    />
                )
            })
        } else {
            content = <>
                <Divider/>
                <div style={{marginTop: '24px', marginBottom: '24px'}}>
                    <Typography align="center" variant="bodyTextSmall">
                        No available records
                    </Typography>
                </div>
                <Divider/>
            </>
        }
    }

    return (
        <BaseContainer noHeader>
            <BackButton onClick={onClose} />

            {!!loading && <Loading loading={true}/>}

            <HeaderTitle>
                Past records
            </HeaderTitle>

            <PageDescription>
                {/* FIXME: show the current season start date below */}
                <Typography variant="bodyTextMedium">
                    {`Past records include those relating to previous ${disease === FLU_NORTH_ID ? 'Northern' : 'Southern'} Hemisphere seasons, plus those for unspecified seasons (season unknown) and for H1N1 records.`}
                </Typography>
            </PageDescription>

            {content}

            <SourceModal
                open={!!sourcesList}
                sources={sourcesList?.sources}
                onClick={(id) => {
                    setSourcesList(null)
                    openRecord(sourcesList.vaccine, id)
                }}
                close={() => setSourcesList(null)}
            />

            <ErrorDialog
                error={error}
                setError={setError}
                open={errorOpen}
                setOpen={setErrorOpen}
            />
        </BaseContainer>
    )
}
export default PastRecords;
