import { TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "../../components/button"
import { BackButton } from "../../components/buttons/back_btn"
import { BaseContainer } from "../../components/container"
import ErrorDialog from "../../components/dialogs/error_dialog"
import SuccessDialog from "../../components/dialogs/success_dialog"
import Loading from "../../components/loading"
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { validateEmail } from "../../components/text_input"
import Typography from "../../components/typography"
import { axiosAuthedInstance } from "../../utils/axiosApi"
import { logEvent, reduceUserPrefNames } from "../../utils/common"

const ShareEmail = (props) => {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [error, setError] = useState(null)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [noPermission, setNoPermission] = useState(false)
    const dependants = props.location.state && props.location.state.dependants || []

    const depString = reduceUserPrefNames(dependants)

    useEffect(() => {
        if (!dependants || !dependants.length) {
            setNoPermission(true)
            setError('No dependants to share found!')
        }
    }, [])

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setEmailError(validateEmail(event.target.value))
    }

    const sendEmail = () => {
        setLoading(true)
        axiosAuthedInstance.post('user/dependants/share/email-invite/', {dependants, email})
            .then(() => {
                setLoading(false)
                setShowSuccess(true)
                logEvent('share_dependant', { journey: 'email' })
            }).catch(err => {
                let msg = 'Error sending email, please try again later.'
                if (err.response?.data?.error || err.response?.data?.detail) {
                    msg = err.response?.data?.error || err.response?.data?.detail
                    console.error(err)
                }
                setLoading(false)
                setShowError(true)
                setError(msg)
            })
    }

    const enabled = !!email && !emailError

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth onClick={sendEmail} disabled={!enabled}>
                    Send Invitation
            </Button>
        </StickyFooterGroup>
        }>
            {loading && <Loading loading={loading}/>}

            <BackButton />

            <HeaderTitle>
                Invite to View Records
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    Please enter the recipient email address for the sharing invitation of {depString}
                </Typography>
            </PageDescription>

            <TextField
                id="email"
                name="email"
                label="Email address"
                placeholder="Recipient's email address"
                fullWidth
                margin="normal"
                value={email}
                error={!!emailError}
                helperText={emailError}
                onChange={handleEmailChange}/>

            <SuccessDialog
                success={"Invitation sent successfully"}
                content={<>
                    <span>
                        {'The invitation email has been sent to '}
                        <span style={{fontWeight: 600}}>{email}</span>
                    </span>
                    <br/>
                    <span>The invitation is only valid for 24 hours</span>
                </>}
                open={showSuccess}
                setOpen={setShowSuccess}
                dismiss_callback={() => history.replace('/sharing')}
            />

            <ErrorDialog open={showError} error={error} setOpen={setShowError} dismiss_callback={() => {
                if (noPermission) {
                    history.replace('/sharing')
                }
            }} />


        </BaseContainer>
    )
}

export default ShareEmail
