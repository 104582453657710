import { Theme, makeStyles } from "@material-ui/core"
import { useRef, ChangeEvent, useEffect, HTMLAttributes, useState } from "react"
import SimpleUserIcon from "../../../assets/icons/simple_user"
import { TwoLineText } from "../../../components/two_line_text"
import Typography from "../../../components/typography"
import { Button, TextButton } from "../../../components/button"
import { Swiper, SwiperSlide } from "swiper/react"
import TSwiper, { FreeMode, Mousewheel, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import { TDateISODateTime } from "../../../utils/common"
import { SubmissionTimelineProps } from "../../../components/submission_timeline"

export enum RejectionReason {
    MULTIPLE_DOCUMENTS = 'MULTIPLE_DOCUMENTS',
    NOT_VACCINE_DOCUMENT = 'NOT_VACCINE_DOCUMENT',
    NON_ENGLISH_DOCUMENT = 'NON_ENGLISH_DOCUMENT',
    POOR_IMAGE_QUALITY = 'POOR_IMAGE_QUALITY',
    CROPPED_IMAGE = 'CROPPED_IMAGE',
    MISSING_PII = 'MISSING_PII',
    PII_MISMATCH = 'PII_MISMATCH',
    OTHER = 'OTHER',
    UNKNOWN_ENTRIES = 'UNKNOWN_ENTRIES',
    ADDITIONAL_INFO = 'ADDITIONAL_INFO',
}

export type Submission = {
    /** Submission number. Unique per submitter. */
    id: string
    state: 'NEW' | 'PROCESSING' | 'REJECTED' | 'DIGITISED' | 'RESUBMITTED'

    submittedAt: TDateISODateTime
    /**
     * Equivalent to "processed at", "digitised at" or "rejected at"
     * when the state is PROCESSING, DIGITISED or REJECTED respectively.
     */
    updatedAt: TDateISODateTime

    resubmittedAt?: TDateISODateTime

    subject: {
        id: string
        fullName: string
        displayName: string
    }

    /** True if subject is the submitter */
    self?: boolean

    rejectReason?: RejectionReason
    rejectInfo?: string
    comments?: string

    /**
     * VaccineEvents are a composite key <GENERIC>,<DATE>, e.g.:
     * `IMMU-GENERIC-COVID-RNA,2021-03-02`
     */
    vaccineEvents: string[]

    /**
     * List of image names, ordered by page number, e.g.:
     * [ '7fb7caa7-54b6-42f7-9f3d-4abf76ec3d58-1.png' ]
     */
    pages: string[],

    history: {
        timestamp: TDateISODateTime
        updated: number
        added: number
        removed: number
    }[]
}

export const toTimelineProps = ({
    state, submittedAt, updatedAt, resubmittedAt
}: Submission): SubmissionTimelineProps => {
    return {
        submittedAt,
        ...(state === 'PROCESSING' && { processedAt: updatedAt }),
        ...(state === 'DIGITISED' && { digitisedAt: updatedAt }),
        ...(state === 'REJECTED' && { rejectedAt: updatedAt }),
        ...(state === 'RESUBMITTED' && { resubmittedAt: resubmittedAt }),
    }
}

/** Button that triggers a file picker */
export const FileInputButton = ({ onChange, ...props }: {
    onChange: (file: File) => void
} & Omit<React.ComponentProps<typeof Button>, 'onChange'>) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return
        }
        const file = event.target.files[0]

        if (!file.type.startsWith('image/')) {
            alert('Please make sure you have selected a valid image.')
            return
        }

        onChange(file)

        // Reset to initial value so that you can re-select the previously select item
        event.target.value = ''
    }

    {/* Opens with an option to choose file picker or camera */ }
    return <>
        <Button onClick={() => inputRef.current?.showPicker()} {...props}>
            {props.children}
        </Button>
        <input
            ref={inputRef}
            hidden
            type="file"
            accept="image/png, image/jpeg"
            onChange={onInputChange}
        />
    </>
}

const useProfileStyles = makeStyles<Theme, ProfileProps>((theme) => ({
    container: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'min-content auto max-content',
        gap: ({size}) => theme.spacing(size === 'small' ? 0.5 : 1),
        margin: ({size}) => size === 'small' ? '' : theme.spacing(3, 0),
    },
    icon: {
        fontSize: ({size}) => size === 'small' ? 20 : 24,
    }
}))

type ProfileProps = {
    name: string
    size?: "small"
    viewRecordsAction?: () => void
}

/** User profile widget, with optional 'View records' link */
export const Profile = (props: ProfileProps) => {
    const { name, size, viewRecordsAction } = props
    const classes = useProfileStyles(props)

    return (
        <div className={classes.container}>
            <SimpleUserIcon className={classes.icon} />

            <Typography variant={size === 'small' ? 'bodyTextSmallBoldDark' : 'bodyTextLargeBold'}>
                <TwoLineText>{name}</TwoLineText>
            </Typography>

            {viewRecordsAction ? (
                <TextButton onClick={viewRecordsAction} primary>
                    View records
                </TextButton>
            ) : null}
        </div>
    )
}


const useImageCarouselStyles = makeStyles((theme) => ({
    swiper: {
        paddingBottom: theme.spacing(1.5),
        margin: theme.spacing(2, 0)
    },
    image: {
        height: '240px'
    },
    slide: {
        width: 'auto',
    },
}))

export type ScrollPosition = {
    progress?: number
    index?: number
}

export const ImageCarousel = ({ imageUrls, onClick, scrollPosition }: {
    imageUrls: string[]
    onClick?: (imageIdx: number, scrollPosition: ScrollPosition) => void
    scrollPosition?: ScrollPosition
}) => {
    const classes = useImageCarouselStyles()
    const swiperRef = useRef<TSwiper>()

    const sp = useRef<ScrollPosition>()
    useEffect(() => {
        sp.current = scrollPosition
    }, [scrollPosition])

    return (
        <Swiper
            data-action="(swipe)"
            className={classes.swiper}
            mousewheel
            freeMode
            scrollbar
            slidesPerView="auto"
            spaceBetween={16}
            onSwiper={(swiper) => {
                swiperRef.current = swiper
                swiper.on('resize', () => {
                    if (scrollPosition?.progress) {
                        swiper.setProgress(scrollPosition.progress, 0)
                    }
                })

                if (onClick) {
                    swiper.on('click', (e) => {
                        // clickedIndex can be undefined if clicking gap between slides
                        if (typeof e.clickedIndex === 'number') {
                            onClick(e.clickedIndex, {
                                progress: swiper.progress
                            })
                        }
                    })
                }
            }}
            modules={[Mousewheel, FreeMode, Scrollbar]}>
            {imageUrls.map((imageUrl, i) => {
                const imageProps = {
                    className: classes.image,
                    src: imageUrl,
                    onLoad: () => {
                        if (swiperRef.current && sp.current?.index === i) {
                            swiperRef.current.slideTo(sp.current.index)
                        }
                    }
                }

                return <SwiperSlide
                    key={imageUrl.split('/').pop()}
                    className={classes.slide}
                >
                    <SubmissionImage {...imageProps} size="medium"/>
                </SwiperSlide>
            })}
        </Swiper>
    )
}

export const SubmissionImage: React.FC<{
    /**
     * Either a path to an uploaded image (e.g. "/5c3d1c9e-4803-4c1e-8997-91625947a1af-1.jpg")
     * Or a URL for an in-memory file not yet uploaded ("blob:http://...")
     */
    src: string,
    size?: 'small' | 'medium'
} & HTMLAttributes<HTMLDivElement>> = ({
    src, size, ...props
}) => {
    const [imageSrc, setImageSrc] = useState('')

    useEffect(() => {
        if (src.startsWith('blob:')) {
            setImageSrc(src)
            return
        }

        let param = ''
        switch (size) {
            case 'small':
                param = '?sm'
                break
            case 'medium':
                param = '?md'
                break
        }

        axiosAuthedInstance.get<{
            image: string
        }>(`${window._env_.API_URL}user/paper-record/media/${src}${param}`)
            .then(response => setImageSrc(`data:image/*;base64,${response.data.image}`))
            .catch(console.error)
    }, [src, size])

    return imageSrc ? <img src={imageSrc} {...props} /> : null
}
