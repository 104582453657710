const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="125" y="265">Influenza</tspan>
        </text>
        <g filter="url(#filter1_dd_3_3209)">
            <rect x="100" y="275" width="240" height="76" rx="12" fill="white"/>
        </g>
        <text xmlSpace="preserve" fill="#767676" fontFamily="Open Sans" fontSize="14" fontWeight="400">
            <tspan x="124" y="335">Sanofi - Vaxigrip Tetra</tspan>
        </text>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="125" y="307">1 Jan 2022</tspan>
        </text>
        <defs>
            <filter id="filter1_dd_3_3209" x="96" y="274" width="248" height="85" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_3209"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_3_3209" result="effect2_dropShadow_3_3209"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3_3209" result="shape"/>
            </filter>
        </defs>
    </svg>
)

export default Svg
