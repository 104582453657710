import SvgIcon from '@material-ui/core/SvgIcon';

function NotificationBellIcon(props) {
    return (
        <SvgIcon {...props}>
            {props.count && props.count > 0 ? props.count > 9 ?
                <g>
                    <path fill="none" d="M3.0625 16.875H17.9375M4.77885 16.875V11.25C4.77885 8.1434 7.34029 5.625 10.5 5.625C13.6597 5.625 16.2212 8.1434 16.2212 11.25V16.875M8.21154 18.5625V19.125C8.21154 20.3676 9.23612 21.375 10.5 21.375C11.7639 21.375 12.7885 20.3676 12.7885 19.125V18.5625" stroke={props.strokecolor || '#333'} strokeWidth="2"/>
                    <rect x="9" y="1" width="14" height="11" rx="5.5" fill="#FF5300"/>
                    <text x="12" y="9" fill="white" fontSize="8px" fontFamily='sans-serif'>9+</text>
                </g>
                :
                <g>
                    <path fill="none" d="M3.0625 16.875H17.9375M4.77885 16.875V11.25C4.77885 8.1434 7.34029 5.625 10.5 5.625C13.6597 5.625 16.2212 8.1434 16.2212 11.25V16.875M8.21154 18.5625V19.125C8.21154 20.3676 9.23612 21.375 10.5 21.375C11.7639 21.375 12.7885 20.3676 12.7885 19.125V18.5625" stroke={props.strokecolor || '#333'} strokeWidth="2"/>
                    <circle cx="17.5" cy="6.5" r="5.5" fill="#FF5300"/>
                    <text x="15" y="9" fill="white" fontSize="8px" fontFamily='sans-serif'>{props.count}</text>
                </g>
                :
                <path fill="none" d="M3.5 15.8571H20.5M5.46154 15.8571V9.42857C5.46154 5.87817 8.38891 3 12 3C15.6111 3 18.5385 5.87817 18.5385 9.42857V15.8571M9.38462 17.7857V18.4286C9.38462 19.8487 10.5556 21 12 21C13.4444 21 14.6154 19.8487 14.6154 18.4286V17.7857" stroke={props.strokecolor || '#333'} strokeWidth="2"/>
            }
        </SvgIcon>
    );
}

export default NotificationBellIcon