import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function ChevronRightIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path d="M16.5 12.03a1.49 1.49 0 0 1-.43 1.06l-6 5.97a1.5 1.5 0 0 1-2.46-1.63c.07-.18.19-.35.32-.49l4.97-4.91L8.13 7.1a1.5 1.5 0 0 1 1.68-2.44c.19.1.35.22.48.38l5.79 5.97c.26.28.41.64.42 1.02Z" />
        </SvgIcon>
    );
}

export default ChevronRightIcon
