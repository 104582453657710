import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders, palette } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}${palette}`;

const Layout = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <Box display="flex" flexDirection="row" border={2} borderRadius={16} m={2} p={2}>
            <Box display="flex" flexGrow={0.5} flexDirection="column" mr={6}>
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Grid
                </Typography>
                <Grid spacing={2} container >
                    <Grid item xs={3}><Box height='100px' bgcolor={theme.palette.secondary.light}></Box></Grid>
                    <Grid item xs={3}><Box height='100px' bgcolor={theme.palette.secondary.light}></Box></Grid>
                    <Grid item xs={3}><Box height='100px' bgcolor={theme.palette.secondary.light}></Box></Grid>
                    <Grid item xs={3}><Box height='100px' bgcolor={theme.palette.secondary.light}></Box></Grid>
                </Grid>
            </Box>
            <Box display="flex" flexDirection="column" >
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Padding
                </Typography>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={1} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>1</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={2} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>2</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={3} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>3</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={4} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>4</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={5} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>5</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box display="flex" p={6} mr={1} bgcolor={theme.palette.secondary.light}/>
                        <Typography>6</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Layout
