import jsQR from "jsqr";
import { pdfjs } from "react-pdf"
import { forwardRef } from "react";

type FileUploadProps = {
    /** Callback on successful scan*/
    onSuccess(data: string): void

    /** Callback that receives an error title and message if a failure occurs */
    onError(errorTitle: string, errorMessage?: string): void

    /** Extra steps such as stopping scanning */
    beforeLoad?(): void
}

/**
 * Represents a user-facing error message on various "expected" QR code scanning failures, e.g.:
 * - Image contains no QR code
 * - Unsupported file type
 */
class CertificateScanError {
    constructor(
        public title: string,
        public message?: string
    ) { }
}

const VALID_TYPES = [
    "image/png",
    "image/jpeg",
    "application/pdf",
]

const makeCanvas = (input: {width: number, height: number}) => {
    // Same as the image logic
    const canvas = document.createElement('canvas')
    const canvasContext = canvas.getContext('2d')

    // Theoretically, getContext() can return null.
    if (!canvasContext) {
        throw "Canvas.getContext() returned null"
    }

    canvas.width = input.width
    canvas.height = input.height

    return canvasContext
}

const handleImage = async (file: File) => {
    const url = URL.createObjectURL(file)

    try {
        const img = await new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image()
            img.addEventListener('load', (_event) => resolve(img) )
            img.addEventListener('error', (_event) => {
                // Could be a bunch of reasons, but we only get a largely useless Event object here
                // rather than an Error:
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement#errors
                reject(new Error("Failed to read uploaded file"))
            })
            img.src = url
        })

        const imgWidth = img.width
        const imgHeight = img.height

        const canvasContext = makeCanvas(img)

        canvasContext.drawImage(img, 0, 0, imgWidth, imgHeight)
        const imageData = canvasContext.getImageData(0, 0, imgWidth, imgHeight)

        const scanResult = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert'
        })

        if (scanResult?.data) {
            return scanResult.data
        } else {
            console.info('No QR code found on image uploaded')
            throw new CertificateScanError(
                'No QR code found',
                'A QR code could not be recognised in the selected image'
            )
        }
    } finally {
        URL.revokeObjectURL(url)
    }
}

const handlePDF = async (file: File) => {
    const url = URL.createObjectURL(file)

    try {
        const pdf = await pdfjs.getDocument(url).promise

        for (let pageNo = 1; pageNo < pdf.numPages + 1; pageNo++){
            console.info(`Checking page ${pageNo} of ${pdf.numPages}`)

            const page = await pdf.getPage(pageNo)

            // scale=1 results in dimensions of 595 x 842 px for an A4 document which is probably too small
            // to recognise a QR code from. 2x seems to work well for HK certs
            const viewport = page.getViewport({scale: 2})

            const canvasContext = makeCanvas(viewport)

            await page.render({ canvasContext, viewport }).promise

            const imageData = canvasContext.getImageData(0, 0, viewport.width, viewport.height)

            // Uncomment below to see what we actually rendered to the canvas
            // canvas.toBlob((blob) => window.open(URL.createObjectURL(blob!), '_blank'))

            const scanResult = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert'
            })

            if (scanResult?.data) {
                return scanResult.data
            }
        }

        console.info('No QR code found on PDF uploaded')
        throw new CertificateScanError(
            'No QR code found',
            'A QR code could not be recognised in the selected PDF'
        )
    } finally {
        URL.revokeObjectURL(url)
    }
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
    function FileUpload({onSuccess, onError, beforeLoad}, ref) {
        const fileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files || event.target.files.length === 0) {
                return
            }
            const file = event.target.files[0]

            if (!VALID_TYPES.includes(file.type)) {
                onError(
                    'Error loading file',
                    'Please make sure you have selected a valid image. Supported formats are images (PNG, JPEG) and PDF.'
                )
                return
            }

            if (beforeLoad) beforeLoad()

            new Promise((resolve) => {
                if (file.type === 'application/pdf') {
                    resolve(handlePDF(file))
                } else {
                    resolve(handleImage(file))
                }
            }).then(onSuccess).catch(e => {
                if (e instanceof CertificateScanError) {
                    onError(e.title, e.message)
                } else {
                    const { name, size, type } = file
                    console.error(e, { name, size, type })
                    onError(
                        'An unexpected error occurred',
                        'The selected file could not be read. Please try again later.'
                    )
                }
            })
        }

        return <input
            ref={ref}
            id="file-upload"
            type="file"
            hidden
            accept={VALID_TYPES.join(',')}
            onChange={(event) => {
                fileUpload(event)
                // Reset to initial value so that you can re-select the previously select item
                event.target.value = ''
            }}
        />
    }
)
