import { useState } from "react";
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "../../components/button";
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { axiosInstance } from "../../utils/axiosApi";
import Alert from '../../components/alert';
import {nestFlexStyle, BaseContainer} from '../../components/container'
import CheckEmail from "../../components/check_email";
import { validateEmail } from '../../components/text_input'
import CTAButton from "../../components/buttons/cta";
import Typography from "../../components/typography";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.grey[600],
        margin: theme.spacing(2, 0, 3),
    },
    submit: {
        marginTop: 'auto',
        marginBottom: theme.spacing(2),
    },
}));

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('')
    const [form_error, setFormError] = useState('')
    const [email_error, setEmailError] = useState('')
    const [error_open, setErrorOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showEmail, setShowEmail] = useState(false)
    const classes = useStyles()

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setEmailError(validateEmail(event.target.value))
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        // Validate form
        if (!email) {
            setFormError('Please submit your email')
            setErrorOpen(true)
            return;
        }

        setLoading(true)

        axiosInstance.post('/user/forgot-password/', {email}).then(() => {
            setLoading(false)
            setShowEmail(true)
        }).catch(err => {
            setLoading(false)
            console.error(err)
            setFormError("Error sending email")
            setErrorOpen(true)
        }).finally(() => {
            setLoading(false)
        })
    }

    if (showEmail) {
        return (
            <CheckEmail email={email}/>
        )
    }

    const enabled = email && !email_error

    return (
        <BaseContainer {...props} showCta extraComponent={
            <CTAButton
                fullWidth
                primary
                type="submit"
                form="forgot-password"
                disabled={!enabled}
                loading={loading}
            >
                Confirm
            </CTAButton>
        }>
            <Box style={nestFlexStyle} position="relative" width="100%">
                <Typography component="h1" variant="onboardingHeader" gutterBottom>
                    Forgot your password?
                </Typography>
                <Typography variant="bodyTextMedium" className={classes.text}>
                    Enter the email associated with your account and we’ll send an email with instructions to reset your password.
                </Typography>

                {/* Form Errors */}
                <Collapse in={error_open}>
                    <Alert
                        severity='error'
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {form_error}
                    </Alert>
                </Collapse>

                <form id="forgot-password" onSubmit={handleSubmit}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email address"
                        type="email"
                        placeholder="Email address"
                        fullWidth
                        margin="normal"
                        value={email}
                        error={!!email_error}
                        helperText={email_error}
                        onChange={handleEmailChange}
                    />
                </form>
            </Box>
        </BaseContainer>
    )
}
export default ForgotPassword;
