import { Dispatch, createContext, useReducer } from 'react';
import { TDateISODate } from '../../utils/common';

export enum OnboardingJourney {
    CLINIC_ACCOUNT_HOLDER,
    CLINIC_DEPENDANT,
    SCHOOL_DEPENDANT,
}

// Describe the shape of the context data
export type ConnectionInviteContextType = {
    token: string

    /** The Clinic or School's name, e.g. "Hong Kong Clinic" */
    organisation: string

    /** The Clinic's phone number. The School model doesn't currently support this field */
    organisation_phone: string

    consentGiven?: boolean

    // These will be available only after the security check step
    dob?: TDateISODate
    gender?: "M" | "F"
    idType?: "HKID" | "PASSPORT" | "UNKNOWN"
    /** ID number. Can be empty */
    idNumber?: string

    // Present only for account holder clinic invites
    /** Clinic invite email */
    email?: string
    /** Account holder's full name */
    account_holder?: string

    // Present for dependant clinic or school connections
    /** invite_type will not exist for account holder journeys */
    invite_type?: "clinic" | "school"
    /** Dependant's (School|Clinic)Profile ID */
    uuid?: string
    /** Dependant's full name  */
    full_name?: string
    year?: string
    /** An existing dependant selected for match { full_name, dob, etc... } */
    dependant?: DependantMatch
    /** This is only present for dependant journeys */
    organisation_email: string

    journey: OnboardingJourney
}

export type DependantMatch = {
    uuid: string
    preferred_name: string
    full_name: string
    dob: TDateISODate
    gender: 'M' | 'F'
    id_type: 'hkid' | 'passport' | 'unknown'
    id_number: string
    /** The primary guardian's full name */
    guardian: string
    match_status: 'MATCH_OK' | 'EXISTING_CONNECTION' | 'DOB_CONFLICT' | 'NOT_PRIMARY_GUARDIAN'
}

// Describe the actions that can modify context data
export type ActionType =
    | {
        type: "GIVE_CONSENT"
    }
    | {
        type: "SET_SIGN_UP_DATA"
        payload: {
            dob: NonNullable<ConnectionInviteContextType['dob']>,
            gender: ConnectionInviteContextType['gender']
            idType: 'hkid' | 'passport' | 'unknown'
            idNumber: string
        }
    }
    | {
        type: "SET_DEPENDANT"
        payload: DependantMatch
    }
    | { type: "UNSET_DEPENDANT" }


// Define the reducing function
export const connectionReducer = (state: ConnectionInviteContextType, action: ActionType) => {
    switch (action.type) {
        case 'GIVE_CONSENT':
            return {
                ...state,
                consentGiven: true
            }
        case 'SET_SIGN_UP_DATA':
            return {
                ...state,
                dob: action.payload.dob,
                gender: action.payload.gender,
                // We have to cast this due to the issue described here:
                // https://github.com/microsoft/TypeScript/issues/44268#issuecomment-885886245
                idType: action.payload.idType?.toUpperCase() as ConnectionInviteContextType['idType'],
                idNumber: action.payload.idNumber
            }
        case 'SET_DEPENDANT':
            return {
                ...state,
                dependant: action.payload,
            }
        case 'UNSET_DEPENDANT':
            return {
                ...state,
                dependant: undefined
            }
        default:
            return state;
    }
}

// Boilerplate
export const ConnectionInviteContext = createContext<{
    state: ConnectionInviteContextType
    dispatch: Dispatch<ActionType>
}>({
    state: {} as ConnectionInviteContextType,
    dispatch: () => null
})

export const ConnectionInviteProvider: React.FC<{ value: ConnectionInviteContextType }> = ({
    value, children
}) => {
    const [state, dispatch] = useReducer(connectionReducer, value)

    return (
        <ConnectionInviteContext.Provider value={{ state, dispatch }}>
            {children}
        </ConnectionInviteContext.Provider>
    )
}
