import { makeStyles, Theme, createStyles } from "@material-ui/core"
import { ReactNode } from "react"
import { pluralString } from "../utils/common"
import { TwoLineText } from "./two_line_text"
import Typography from "./typography"

const captionStyles = makeStyles<Theme, CaptionProps>((theme) => createStyles({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    caption: {
        lineHeight: '22px',
        paddingTop: '2px'
    }
}))

type CaptionProps = {
    /** Amount of "items"*/
    amount?: number

    /** String defining what item it is */
    item: string
}

export const CardCaption = (props: CaptionProps) => {
    const classes = captionStyles(props)
    const {amount, item} = props
    let text
    if (amount !== undefined) {
        text = `${amount} ${pluralString(item, amount)}`
    } else {
        text = item
    }

    return (
        <div className={classes.container}>
            <Typography className={classes.caption} variant="bodyTextSmall" >
                {text}
            </Typography>
        </div>
    )
}

const subtitleStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        paddingTop: theme.spacing(0.5),
    },
}))


type SubtitleProps = {
    children: ReactNode
}

export const CardSubtitle = ({children}: SubtitleProps) => {
    const classes = subtitleStyles()
    return (
        <TwoLineText>
            <Typography className={classes.root} variant="bodyTextMediumDark" component="div">
                {children}
            </Typography>
        </TwoLineText>
    )
}
