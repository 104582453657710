import SvgIcon from '@material-ui/core/SvgIcon';

const MailIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <path fill="none" d="M4 8.5L12 13.1667L20 8.5M5.14286 5H18.8571C19.4883 5 20 5.52233 20 6.16667V17.8333C20 18.4777 19.4883 19 18.8571 19H5.14286C4.51168 19 4 18.4777 4 17.8333V6.16667C4 5.52233 4.51167 5 5.14286 5Z" stroke={props.strokecolor || "#333"} strokeWidth="2"/>
        </SvgIcon>
    )
}

export default MailIcon
