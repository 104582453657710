import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Button from "@material-ui/core/Button";
import Typography from './typography';
import StarIcon from "../assets/icons/star";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles<Theme>((theme) => ({
    label: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    iconWrapper: {
        backgroundColor: theme.palette.grey[100],
        width: '66px',
        height: '66px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'relative',
    },
    icon: {
        width: '24px',
        height: 'auto'
    },
    newBadge: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        padding: '2px 4px',
        position: 'absolute',
        top: '-2px',
        right: '-1em',
        borderRadius: '10px',
        background: theme.palette.success.light,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        color: theme.palette.success.main,
        '& > svg': {
            fontSize: 16,
        }
    }
}))

type QuickActionProps = {
    icon: React.ComponentType<SvgIconProps>
    children: string
    onClick?: (event: React.MouseEvent) => void
    newBadge?: boolean
    placeholder?: boolean
}

const QuickAction = ({ icon: Icon, children, onClick, newBadge, placeholder }: QuickActionProps) => {
    const classes = useStyles()

    if (placeholder) {
        return (
            <Button classes={{ label: classes.label }}>
                <Skeleton variant="circle" width={66} height={66}/>
                <Skeleton variant="text" width={50}/>
            </Button>
        )
    }

    return (
        <Button
            classes={{
                label: classes.label
            }}
            onClick={onClick}
        >

            <div className={classes.iconWrapper}>
                {newBadge ? (
                    <div className={classes.newBadge}>
                        <StarIcon />
                        <Typography variant="statusText" component="span">
                            new
                        </Typography>
                    </div>
                ): null}
                <Icon className={classes.icon} />
            </div>

            <Typography variant="bodyTextSmallBoldDark">
                {children}
            </Typography>

        </Button>
    )
}

export default QuickAction
