import SvgIcon from '@material-ui/core/SvgIcon';

function EmptyListIcon(props) {
    return (
        <SvgIcon {...props}>
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M16.9583 3.85714H20.5V20.1429C20.5 20.8924 19.8657 21.5 19.0833 21.5H4.91667C4.13426 21.5 3.5 20.8924 3.5 20.1429V3.85714H7.04167M7.75 2.5H16.25V5.21429C16.25 5.96381 15.6157 6.57143 14.8333 6.57143H9.16667C8.38426 6.57143 7.75 5.96381 7.75 5.21429V2.5Z"/>
            </g>
        </SvgIcon>
    )
}

export default EmptyListIcon
