const Svg = () => (
    <svg viewBox="0 0 300 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask1_22_6957" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={0} y={0} width={300} height={150}>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H296C298.209 0 300 1.79086 300 4V150H0V4Z" fill="#FFD8C7" />
        </mask>
        <g mask="url(#mask1_22_6957)">
            <rect x="19.5" y="14.5" width={229} height={89} rx="11.5" fill="white" stroke="#C4C4C4" />
            <path d="M224.98 49.0041C224.849 49.0049 224.72 48.9796 224.598 48.9299C224.477 48.8801 224.367 48.8068 224.274 48.7141L220.293 44.7141C220.2 44.6209 220.126 44.5102 220.076 44.3883C220.026 44.2665 220 44.1359 220 44.0041C220 43.8722 220.026 43.7417 220.076 43.6198C220.126 43.498 220.2 43.3873 220.293 43.2941C220.385 43.2009 220.496 43.1269 220.617 43.0764C220.738 43.026 220.868 43 220.999 43C221.131 43 221.261 43.026 221.382 43.0764C221.503 43.1269 221.613 43.2009 221.706 43.2941L224.98 46.6041L228.265 43.4241C228.356 43.3218 228.468 43.2396 228.592 43.1826C228.717 43.1256 228.852 43.0951 228.989 43.0929C229.126 43.0907 229.262 43.1169 229.388 43.1699C229.514 43.2229 229.629 43.3015 229.723 43.4009C229.818 43.5002 229.891 43.6181 229.939 43.7472C229.986 43.8763 230.006 44.0138 229.998 44.1511C229.99 44.2885 229.954 44.4227 229.892 44.5453C229.83 44.668 229.744 44.7765 229.638 44.8641L225.657 48.7241C225.475 48.9004 225.233 49.0005 224.98 49.0041Z" fill="#767676" />
            <circle cx={47} cy={72} r={10} fill="#E5F2EE" />
            <path d="M43.2918 72.4283C43.109 72.2784 42.8709 72.197 42.6251 72.2006C42.3794 72.2041 42.1442 72.2923 41.9667 72.4475C41.7893 72.6026 41.6827 72.8132 41.6683 73.0371C41.654 73.2609 41.733 73.4815 41.8894 73.6544L44.0514 75.7246C44.1419 75.8112 44.2507 75.8802 44.3712 75.9276C44.4917 75.9749 44.6214 75.9995 44.7526 76C44.8831 76.0007 45.0124 75.9774 45.1328 75.9317C45.2533 75.8859 45.3624 75.8185 45.4538 75.7334L52.0566 69.5141C52.1461 69.4301 52.2166 69.3309 52.2641 69.222C52.3116 69.1131 52.3351 68.9968 52.3333 68.8796C52.3314 68.7625 52.3044 68.6468 52.2536 68.5392C52.2027 68.4316 52.1292 68.3342 52.0371 68.2525C51.945 68.1708 51.8362 68.1065 51.7169 68.0632C51.5976 68.0199 51.47 67.9984 51.3416 68.0001C51.2132 68.0017 51.0864 68.0264 50.9685 68.0728C50.8505 68.1192 50.7437 68.1863 50.6542 68.2703L44.7623 73.8321L43.2918 72.4283Z" fill="#008040" />
            <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={14} letterSpacing="0px"><tspan x={63} y="77.4312">Up-to-date</tspan></text>
            <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={16} fontWeight={600} letterSpacing="0px"><tspan x={35} y="51.207">Influenza</tspan></text>
            <rect x="61.5" y="46.5" width={219} height={89} rx="11.5" fill="white" stroke="#C4C4C4" />
            <text fill="#767676" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={12} letterSpacing="0px"><tspan x={193} y="82.6553">1/2 doses</tspan></text>
            <path d="M256.98 81.0041C256.849 81.0049 256.72 80.9796 256.598 80.9299C256.477 80.8801 256.367 80.8068 256.274 80.7141L252.293 76.7141C252.2 76.6209 252.126 76.5102 252.076 76.3883C252.026 76.2665 252 76.136 252 76.0041C252 75.8722 252.026 75.7417 252.076 75.6198C252.126 75.498 252.2 75.3873 252.293 75.2941C252.385 75.2009 252.496 75.1269 252.617 75.0764C252.738 75.026 252.868 75 252.999 75C253.131 75 253.261 75.026 253.382 75.0764C253.503 75.1269 253.613 75.2009 253.706 75.2941L256.98 78.6041L260.265 75.4241C260.356 75.3218 260.468 75.2396 260.592 75.1826C260.717 75.1256 260.852 75.0951 260.989 75.0929C261.126 75.0907 261.262 75.1169 261.388 75.1699C261.514 75.2229 261.629 75.3015 261.723 75.4009C261.818 75.5002 261.891 75.6181 261.939 75.7472C261.986 75.8763 262.006 76.0138 261.998 76.1511C261.99 76.2885 261.954 76.4227 261.892 76.5453C261.83 76.668 261.744 76.7765 261.638 76.8641L257.657 80.7241C257.475 80.9004 257.233 81.0005 256.98 81.0041Z" fill="#767676" />
            <path d="M86.3305 95.514L79.6038 108.631C78.58 110.627 80.0297 113 82.2733 113H95.7267C97.9702 113 99.4199 110.627 98.3961 108.631L91.6694 95.514C90.5542 93.3393 87.4458 93.3393 86.3305 95.514Z" fill="#FBEAE7" />
            <rect x={88} y={108} width={2} height={2} fill="#D82C0D" />
            <rect x={88} y={100} width={2} height={6} fill="#D82C0D" />
            <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={14} letterSpacing="0px"><tspan x={105} y="109.431">Eligible for vaccination</tspan></text>
            <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={16} fontWeight={600} letterSpacing="0px"><tspan x={77} y="83.207">Measles</tspan></text>
        </g>
    </svg>
)

export default Svg
