import axios, { CanceledError } from 'axios'
import { onTokenRefresh, isLoggedIn, updateSessionExpiry, clearStorage } from './auth';
import { HTTP_STATUS } from './constants';
export { AxiosError } from 'axios'

const login_url = '/login';

// this line getting access_token from localStorage is only executed on page refreshes or at the start of a new tab
const access_token = localStorage.getItem('access_token');

const axiosConfig = {
    baseURL: window._env_.API_URL,
    timeout: 10000,
    // The headers are important. In base.py the SIMPLE_JWT dict sets the AUTH_HEADER_TYPES as ‘JWT’,
    // so for the Authorization header here it has to be the same.
    // Don’t neglect to add the space after JWT in axiosAPI.js. Also do NOT add a space in base.py.
    // We initiate the creation of the Axios instance by getting the access token from local storage.
    // If there’s no token in local storage, don’t even worry about it for the header. It will be set every time a user logs in.
    headers: {
        'Authorization': `JWT ${access_token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',

        // Required when using cloudflare zero trust, as the default behaviour for unauthenticated
        // requests is to do a 302 redirect to the login page. For API calls, this obviously won't
        // work. With this header added, cloudflare will instead return a 401, which will cause our
        // application to reload the login page (and hence end up requesting the main app again and
        // getting a login page which the user can use)
        'X-Requested-With': 'XMLHttpRequest'
    },
    // Required when using cloudflare zero trust as it adds cookies to the requests to track
    // logged in state
    withCredentials: true
};

// axiosInstances are only created on page refreshes or at the start of a new tab
const axiosInstance = axios.create(axiosConfig);
const axiosAuthedInstance = axios.create(axiosConfig);

// Add version checker logic to both Axios instances
const APP_VERSION = window._env_.APP_VERSION;
const APP_VERSION_HEADER = 'x-app-version'

const versionCheckHandler = (frontend, backend) => {
    if (frontend === backend) {
        return
    }

    // Reload. We should receive the latest frontend most of the time
    // Set a 5 minute interval between reloads so we don't infini-reload active users during rollouts
    const lastRefresh = sessionStorage.getItem('lastRefresh')
    const t = new Date().getTime()
    if (!lastRefresh || lastRefresh < t - 1000 * 60 * 5) {
        console.info(`App version mismatch: frontend: ${frontend}, backend: ${backend}`)
        sessionStorage.setItem('lastRefresh', t)
        window.location.reload()
    }
}

const versionCheckResponse = response => {
    if (APP_VERSION) {
        versionCheckHandler(APP_VERSION, response.headers[APP_VERSION_HEADER])
    }
    return response
}

const versionCheckError = error => {
    if (APP_VERSION && error.response) {
        versionCheckHandler(APP_VERSION, error.response.headers[APP_VERSION_HEADER])
    }

    return Promise.reject(error);
}

axiosAuthedInstance.interceptors.response.use(versionCheckResponse, versionCheckError)
axiosInstance.interceptors.response.use(versionCheckResponse, versionCheckError)

/**
 * Rationale of the axios response error handler:
 *
 * This error handler should do two things(or more in the future):
 * 1. Intercept 401 responses and depends on the situation, either refreshes the access token or redirect user to login page
 * 2. Intercept and preprocess errors which come from requests that don't return a response
 * Other errors such as 400 Bad Request, 403 Permission Denied, or other 4xx/5xx errors should be passed down to the original axios request caller
 */
const axiosResponseErrorHandler = (error, history, instance) => {
    if (axios.isAxiosError(error)) {
        // We'll have access to config, request, and response here

        if (axios.isCancel(error)) {
            console.log('Request canceled', error);
            //REQUEST_STATUS Client Closed Request
            error.response = { status: HTTP_STATUS.CLIENT_CLOSED_REQUEST, data: {} }
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        console.log('Original request url:', originalRequest.url)

        /**
         * Handle the case when no response is available. Possible causes for this can include:
         * - Navigating away from the page outside of the application (e.g. window.location.href = ...)
         * - No network connection
         * - API server is offline or unreachable
         * - Network timeout
         *
         * Note that rejecting the promise here with a "fake" 500 response is a code smell, we
         * should not do this.
         */
        if (!error.response) {
            console.log('Intercepted error (no response obj):', error.message)
            error.response = { status: 500, data: {} }
            return Promise.reject(error)
        }

        /**
         * Then handle error cases where the response is returned
         *
         * IMPORTANT: error.response.data.error is populated instead of **.detail because of the imunis_exception_handler we implemented,
         * check the EXCEPTION_HANDLER setting under REST_FRAMEWORK setting in base.py
         *
         * Errors due to unauthentication/unauthorization that need to be intercepted:
         * 1. Access Token expired: 401, error.response.data.error: "Given token not valid for any token type", error.response.data.code: "token_not_valid"
         * 2. Refresh Token expired: 401, error.response.data.error: "Token is invalid or expired", error.response.data.code: "token_not_valid"
         * 3. User not found: 401, error.response.data.error: "User not found", error.response.data.code: "user_inactive"
         * 4. User inactive: 401, error.response.data.error: "User is inactive", error.response.data.code: "user_not_found"
         * 5. Wrong credentials: 401, error.response.data.error: "No active account found with the given credentials", error.response.data.code: undefined
         * 6. Auth credentials not provided: 401, error.response.data.error: "Authentication credentials were not provided.", error.response.data.code: undefined
         *
         * For user related errors(3-4), even after token refresh, repeating the original request is futile since the user_id in the access_token stays the same
         *
         */

        // error case 1:
        // We need axios to automatically calls the token refresh api if the access token has expired
        if (error.response.status === HTTP_STATUS.HTTP_UNAUTH &&
            error.response.data.code == 'token_not_valid' &&
            originalRequest.url !== '/token/refresh/') {
            console.log('Intercepted error 1:', originalRequest.url, error.response.status, error.response.data.code);
            const refresh_token = localStorage.getItem('refresh_token');

            return instance
                .post('/token/refresh/', { refresh: refresh_token })
                .then((response) => {
                    console.log('Renewed access token successfully fetched.');

                    onTokenRefresh({
                        accessToken: response.data.access,
                        refreshToken: response.data.refresh,
                    })

                    originalRequest.headers['Authorization'] = "JWT " + response.data.access;
                    return instance(originalRequest);
                });
        }

        // error case 2:
        // This is reached when another 401 is intercepted after the token refresh api is called in error case 1
        if (error.response.status === HTTP_STATUS.HTTP_UNAUTH &&
            error.response.data.code == 'token_not_valid' &&
            originalRequest.url === '/token/refresh/') {
            console.log('Intercepted error 2:', error.response.status, error.response.data.code);
            clearStorage();
            history.replace(login_url)
            throw new CanceledError("Redirecting to login page", originalRequest)
        }

        // error cases 3-4:
        // needs to redirect to the login screen since this user is either not found or inactive
        if (error.response.status === HTTP_STATUS.HTTP_UNAUTH &&
            (error.response.data.code == 'user_inactive' || error.response.data.code == 'user_not_found')) {
            console.log('Intercepted error:', error.response.status, error.response.data.code);
            clearStorage();
            history.replace(login_url)
            throw new CanceledError("Redirecting to login page", originalRequest)
        }

        // error case 5:
        // just rejects the error and let login.js handles it
        if (error.response.status === HTTP_STATUS.HTTP_UNAUTH &&
            originalRequest.url === '/token/obtain/' &&
            error.response.data.error == 'No active account found with the given credentials') {
            return Promise.reject(error);
        }
        // error case 6 and other 401 errors:
        // needs to redirect to the login screen
        if (error.response.status === HTTP_STATUS.HTTP_UNAUTH) {
            console.log('Intercepted error:', error.response.status, error.response.data.error);
            clearStorage();
            history.replace(login_url)
            throw new CanceledError("Redirecting to login page", originalRequest)
        }

        // error case 7: under maintenance
        // returned by the cloudflare worker when the maintenance page is enabled
        if (error.response.status === HTTP_STATUS.HTTP_SERVICE_UNAVAILABLE &&
            error.response.data.code == 'maintenance') {
            console.log('Maintenance error:', error.response.status, error.response.data.code);
            history.replace(login_url + '?nocache=' + (new Date()).getTime())
            throw new CanceledError("Redirecting to login page", originalRequest)
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        // No action needed here, we just let the error bubble up.
    }

    // Re-throw any unhandled cases and let the caller's error handler deal with it -- appropriate
    // user messaging, redirects, etc).
    throw error
}

axiosAuthedInstance.interceptors.request.use(
    config => {
        if (axiosAuthedInstance.defaults.headers['Authorization'] !== null) {
            // checks if the user is still logged in(within session expiration)
            if (!isLoggedIn()) {
                window.location.href = login_url;
            } else {
                // refresh the login session for the user
                updateSessionExpiry();
            }
            return config;
        } else {
            // code landing here most probably due to user clicking the logout button, which would clear the auth header,
            // but a call to notifications api has just been triggered by the short polling before the user clicking logout.
            // returning a config with empty headers and url should cancel the request
            return {
                headers: {},
                method: config.method,
                url: ""
            }
        }
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * Utility to provide a `history` object to the Axios response interceptors, so we can trigger
 * in-app redirects (history.replace(...)) rather than hard redirects (window.location.href = ...)
 */
const initialiseInterceptors = (history) => {
    axiosAuthedInstance.interceptors.response.use(
        response => response,
        error => axiosResponseErrorHandler(error, history, axiosAuthedInstance)
    )

    axiosInstance.interceptors.response.use(
        response => response,
        error => axiosResponseErrorHandler(error, history, axiosInstance)
    )
}

export { axiosInstance, axiosAuthedInstance, initialiseInterceptors };
