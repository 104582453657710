const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <g filter="url(#filter0_dd_3_3291)">
            <rect x="54" y="131" width="268" height="76" rx="12" fill="white"/>
        </g>
        <text xmlSpace="preserve" fill="#767676" fontFamily="Open Sans" fontSize="14" fontWeight="400">
            <tspan x="77" y="187">Connected on 1 Mar 2022</tspan>
        </text>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="77" y="166">Clinic</tspan>
        </text>
        <path fillRule="evenodd" clipRule="evenodd" d="M293.394 151.08C293.902 151.296 294.137 151.879 293.919 152.382L285.919 170.884C285.702 171.387 285.114 171.62 284.606 171.404C284.098 171.189 283.863 170.606 284.081 170.103L292.081 151.601C292.298 151.098 292.886 150.865 293.394 151.08Z" fill="#333333"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M288.813 154.965H282.333C278.836 154.965 276 157.775 276 161.242C276 164.709 278.836 167.52 282.333 167.52H283.384L284.242 165.538H282.333C279.94 165.538 278 163.615 278 161.242C278 158.87 279.94 156.947 282.333 156.947H287.956L288.813 154.965ZM290.044 165.538H295.667C298.06 165.538 300 163.615 300 161.242C300 158.87 298.06 156.947 295.667 156.947H293.758L294.616 154.965H295.667C299.164 154.965 302 157.775 302 161.242C302 164.709 299.164 167.52 295.667 167.52H289.187L290.044 165.538Z" fill="#333333"/>
        <defs>
            <filter id="filter0_dd_3_3291" x="50" y="130" width="276" height="85" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_3291"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_3_3291" result="effect2_dropShadow_3_3291"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3_3291" result="shape"/>
            </filter>
        </defs>
    </svg>
)

export default Svg
