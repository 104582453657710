import { Tabs as MuiTabs, makeStyles } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import { cx } from "../../utils/common";

const styles = {
    root: {
        borderBottom: '1px solid #C4C4C4',
        width: '100%',
        minHeight: '30px'
    },
    indicator: {
        backgroundColor: '#FF5300',
        width: '100%',
        height: '3px',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
    },
}

const useStyles = makeStyles({
    iconTabs: {
        '& .MuiTabs-flexContainer': {
            display: 'inline-grid',
            gridTemplateColumns: '58% 42%',
            padding: 0
        }
    }
})

const Tabs = ({ hasIcons, ...props }) => {
    const classes = useStyles()

    return (
        <MuiTabs {...props} className={cx((hasIcons ? classes.iconTabs : null), props.className)} />
    )
}

export default withStyles(styles)(Tabs);
