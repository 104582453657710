import SimpleUserIcon from "../assets/icons/simple_user"
import { makeStyles } from '@material-ui/core/styles'
import Typography from "./typography"
import { SvgIconProps } from "@material-ui/core"
import { cx } from "../utils/common"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.grey[400],
        height: '24px',
        width: '24px',
        marginBottom: theme.spacing(1),
    },
}))

export const EmptyContent = ({ icon: IconComponent, children, className }: {
    icon: React.ComponentType<SvgIconProps>
    children: React.ReactChild
    className?: string
}) => {
    const classes = useStyles()

    return (
        <div className={cx(classes.container, className)}>
            <IconComponent className={classes.icon} />
            <Typography variant="bodyTextSmall">{children}</Typography>
        </div>
    )
}

export const EmptyUsers = ({ emptyText }: {
    emptyText: string
}) => EmptyContent({
    icon: SimpleUserIcon,
    children: emptyText
})
