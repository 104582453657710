import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from "./button";
import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import styled from 'styled-components';
import { BaseContainer } from "./container";
import SearchDotIcon from "../assets/icons/search_dot";
import HourglassIcon from "../assets/icons/hourglass";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.typography.fontSize * 1.25,
        fontWeight: '600',
        lineHeight: '2rem',
    },
    body: {
        fontSize: theme.typography.fontSize,
        fontWeight: '400',
    },
    fontSizeLarge: {
        width:'44px',
        height:'44px',
    },
}))

const ErrorPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const icon = props.search || props.expired

    return (
        <BaseContainer {...props}>
            <Box marginBottom={10} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                {icon &&
                    <Box marginBottom={3.5}>
                        {props.search &&
                            <SearchDotIcon classes={{fontSizeLarge: classes.fontSizeLarge}} fontSize="large" />
                        }
                        {props.expired &&
                            <HourglassIcon classes={{fontSizeLarge: classes.fontSizeLarge}} fontSize="large" />
                        }
                    </Box>
                }
                <Box marginBottom={4}>
                    <Typography align="center" variant="h6" className={classes.header} gutterBottom>
                        {props.title}
                    </Typography>
                    <Typography align="center" variant="body1" className={classes.body} gutterBottom>
                        {props.content}
                    </Typography>
                </Box>
                <Button fullWidth onClick={() => history.push(props.location)}>
                    {props.cta_label}
                </Button>
            </Box>
        </BaseContainer>
    )
}
export default ErrorPage
