import { createStyles, makeStyles } from "@material-ui/core";
import { useState, cloneElement } from "react";
import { Drawer } from "./drawer";
import { DrawerSection, HeaderTitle, PageSubsection } from "./page_sections";
import { CollapsedSection } from "./collapsed_section";
import Typography from "./typography";


type FaqDrawerProps = {
    /** An element that will open the drawer when clicked */
    children: React.ReactElement
}

const useStyles = makeStyles((theme) => createStyles({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    },
}))

export const FaqDrawer = ({ children }: FaqDrawerProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (<>
        {cloneElement(children, {
            "data-action": `Open FAQ`,
            onClick: (e: React.MouseEvent) => {
                e.preventDefault()
                setOpen(true)
            }
        })}
        <Drawer
            ModalProps={{
                BackdropProps: {
                    "aria-label": `Close FAQ`,
                }
            }}
            open={open}
            onClose={() => {
                setOpen(false)
            }}>
            <HeaderTitle>
                Frequently Asked Questions
            </HeaderTitle>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Which clinics currently offer the Imunis app?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Central Health, Hong Kong, is Imunis' Beta launch partner. The clinic is currently trialing the App and the Clinic Portal.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            All Central Health patients, past and present, are able to request an Imunis Connection, via which digital vaccine records may be shared, including those provided by Central Health, and those recorded by Central Health where suitable paper or digital evidence is available of vaccination with a legitimate health provider.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis is currently working to bring other healthcare providers on to the platform.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Can I connect with a clinic if I'm not a registered patient?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            No, in order to complete a digital Clinic Connection via Imunis, a patient must be registered with an Imunis-registered Clinic, and be registered on that clinic's record system.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Similarly, in order to onboard a dependent, both the child and the guardian must be registered with the clinic in question, in order for appropriate identification checks to be performed and to support correct release of patient records.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Can a child have their own Imunis account?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            At present, Imunis doesn't allow users under 18 years of age to create an account or host their own records. An adult guardian can add a patient under 18 years of age to their account and manage the profile on their behalf. An adult guardian cannot currently add a profile for anyone over 18 years of age.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Can more than one guardian have access to a child's records?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis employs a model where one guardian manages access on behalf of a child, and can then allow other trusted parties/individuals to access the child's records.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            A clinic connection will only be issued to one guardian, and that guardian then manages record sharing with other guardians. This simplifies family sharing and administration for clinics.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Which education providers are registered on the Imunis platform?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis has developed a platform specific to education providers/employers, allowing those organisations to invite their customers to simplify & safeguard information sharing.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            We are currently working with a group of organisations to pilot the solution.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    How can I add my paper vaccination records to my Imunis account?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis accepts records direct from health providers, or scanned QR codes from a collection of sources whereby the underlying data may be verified for accuracy and reliability.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            If you are a patient of an Imunis-registered clinic, you may take evidence of vaccination history to your clinic and ask for those records to be digitised and passed through to Imunis. Clinics will require documentary evidence of vaccination with legible dates and vaccination titles in order to add records to the Imunis platform.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Why can I not add vaccination records of my own other than Covid-19 QR codes?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Accurate records are a cornerstone of good healthcare, and important for both patients and medical providers. Various official studies have identified the challenges for patients to accurately interpret/recall medical history, and this is particularly the case with vaccination records, where paper records and combination vaccines can make interpretation challenging to the lay person.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Can I or my friends/family use Imunis independently without connecting to a Clinic?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Yes, people may onboard to Imunis via <a href="https://app.imunis.com" className={classes.link} target="_blank" rel="noreferrer">app.imunis.com</a>, where they are able to create a profile for themselves, and dependents. The app may be used to record Covid-19 vaccination records using QR codes issued by the Hong Kong SAR government, the NHS, and the EU Green Pass.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            It will not be possible to use the App to manage vaccine records for diseases other than Covid-19, or for Covid-19 QR codes issued by any other authority, although Imunis is currently working with multiple official data sources providers to expand the list of accepted data sources.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Do vaccine recommendations vary in different geographies and if so, which ruleset does Imunis follow?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Vaccine recommendations may vary significantly around the world, depending on the nature of healthcare offered, vaccine-preventable disease outbreak risk & scientific discovery amongst other factors. Imunis currently follows the vaccination recommendations of Hong Kong.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            In relation to Covid-19, Imunis follows Hong Kong's health recommendations rather than public entry criteria.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis is currently developing rulesets for different jurisdictions, enabling patients to understand requirements when they travel or move around.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    How frequently are the recommendations updated?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis endeavours to update the Imunis rules engine within 5 working days of an official recommendation change.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            In some instances, where recommendations have a larger impact on the rules engine, it may be necessary to extend this window.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Will digital status be available for all diseases in the future?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis has launched with digital status tracking for Covid-19, Influenza (Northern/Southern Hemisphere), Tuberculosis and Measles, as they are all relevant for those living and working in Hong Kong.
                        </Typography>
                    </PageSubsection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            We continue to digitise the rulesets and once complete, will also launch a chronological tracker for childhood vaccination.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Does infection history and testing affect immune status?
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Natural infection can provide future protection to certain vaccine-preventable diseases, therefore Imunis will be expanding to incorporate these features in the future.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>

            <CollapsedSection
                title={<Typography variant="bodyTextLargeBold">
                    Contact
                </Typography>}>
                <DrawerSection>
                    <PageSubsection>
                        <Typography variant="bodyTextLarge">
                            Imunis is contactable at <a href="mailto:support@imunis.com" className={classes.link}>support@imunis.com</a>.
                        </Typography>
                    </PageSubsection>
                </DrawerSection>
            </CollapsedSection>
        </Drawer>
    </>)
}
