import { ReactNode, isValidElement, createElement, HTMLAttributes } from "react"
import { BlockToolData, OutputBlockData } from "@editorjs/editorjs"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { CollapsedSection } from "./collapsed_section"
import Typography, { styles as typographyStyles } from "./typography"
import { cx } from "../utils/common"

/**
 * Used by PICS / T&Cs markup
 */
const legalStyles = (theme: Theme) => ({
    "& ol, & ul": {
        margin: 0,
        padding: 0,
    },

    "& li": {
        margin: theme.spacing(2, 0, 1, 2.5),
        paddingLeft: '1em',
    },

    "& ol": {
        listStyleType: 'none',
        counterReset: 'item',
    },

    "& ol > li": {
        counterIncrement: 'item',
        margin: theme.spacing(2, 0, 1),
        paddingLeft: 0,
    },

    "& ol > li:before": {
        content: 'counters(item, ".") ". "',
        paddingRight: '1em',
    },

    "& li ol > li:before": {
        content: 'counters(item, ".") " "',
    },

    "& li p": {
        display: 'inline',
        margin: 0,
    },

    // Special cases
    '& ol.no-marker > li:before': {
        content: 'none',
    },

    // ol.alpha uses (latin) letters
    '& ol.alpha': {
        counterReset: 'item-alpha',
    },
    '& ol.alpha > li': {
        counterIncrement: 'item-alpha',
    },
    '& ol.alpha > li:before': {
        content: '"(" counter(item-alpha, upper-alpha) ")"',
    },

    // ols inside ol.alpha use roman numerals
    '& ol.alpha > li > ol': {
        counterReset: 'item-alpha-roman',
    },
    '& ol.alpha > li > ol > li': {
        counterIncrement: 'item-alpha-roman',
    },
    '& ol.alpha > li > ol > li:before': {
        content: '"(" counter(item-alpha-roman, lower-roman) ")"',
    },
})

const useStyles = makeStyles((theme) => {
    const typography = typographyStyles(theme)

    return {
        html: {
            overflowWrap: 'break-word',

            // "&> *:first-child": {
            //     marginTop: 0,
            // },
            // "&> *:last-child": {
            //     marginBottom: 0,
            // },

            // Typography
            "& header h1": typography.onboardingHeader,
            "& header p": typography.bodyTextSmallDark,
            "& p strong": typography.bodyTextLargeBold,
            // "& p": typography.bodyTextLarge,

            // Used by disease content at the moment
            // To consider: can these be styles apply across all JSON-driven content (FAQ, legal)?

            "& h1, & h2, & h3, & h4, & h5, & h6": {
                margin: '0.5em 0',
            },

            "& h1": {
                ...typography.onboardingHeader,
                margin: '1.5em 0',
            },
            "& h2": {
                ...typography.modalHeader,
                marginTop: '1.5em',
            },
            "& h3": {
                ...typography.nameDropdown,
                marginTop: '1.5em',
            },
            "& h4": {
                ...typography.bodyTextLargeBold,
            },
            "& h5": {
                ...typography.bodyTextMediumDark,
                textDecoration: 'underline',

            },
            "& h6": {
                ...typography.bodyTextMedium,
            },

            "& hr": {
                border: 'none',
                margin: `${theme.spacing(2)}px 0`,
                height: '1px',
                background: theme.palette.grey[200],

            },

            "& a": {
                fontWeight: 600,
                cursor: 'pointer',
                textDecoration: 'underline',
                color: theme.palette.primary.main,
            },

            "& b": {
                fontWeight: 600,
            },

            "& figure": {
                margin: 0,
                "& img": {
                    width: "100%",
                },
                "& figcaption": {
                    textAlign: "center",
                },
            },


            ...legalStyles(theme),
        }
    }
})

export const StaticHTML = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles()

    return (
        <div className={cx(classes.html, className)} {...props}>
            {children}
        </div>
    )
}

type ToggleBlock = OutputBlockData<"toggle", { text: string, items: number }>
type ParagraphBlock = OutputBlockData<"paragraph", { text: string }>
type ListBlock = OutputBlockData<"list", { style: 'ordered'|'unordered', items: string[] }>
type HeaderBlock = OutputBlockData<"header", { text: string, level: number }>
type SectionBlock = OutputBlockData<"section", { text: string }>
type DelimiterBlock = OutputBlockData<"delimiter">
type ImageBlock = OutputBlockData<"image", { file: { url: string }, caption: string }>

type EditorBlocks = (ToggleBlock|ParagraphBlock|ListBlock|HeaderBlock|SectionBlock|DelimiterBlock|ImageBlock)[]

export const JsonContent = ({children}: {children: OutputBlockData[]}) => (
    <>
        {renderStaticContent(children as EditorBlocks)}
    </>
)

const slugify = (s: string) => {
    return s.toLowerCase().replaceAll(/[\W]+/g, "-").replace(/[-]+$/, "")
}

export const renderStaticContent = (blocks: EditorBlocks) => {
    const output: ReactNode[] = []

    // Basic data sanitisation - do we need more?
    if (!Array.isArray(blocks)) {
        return null
    }

    let inSection = false
    blocks.forEach(({ id, type, data }) => {
        let block;

        switch (type) {
            case 'section':
                // Create a Collapsing section widget when we see a section block.
                // The collapsing section is closed when another section block is encountered.
                block = (
                    <CollapsedSection
                        key={id}
                        title={
                            <Typography
                                variant="modalHeader"
                                dangerouslySetInnerHTML={{ __html: data.text }}
                            />
                        }>
                        {[]}
                    </CollapsedSection>
                );
                inSection = true
                output.push(block)
                return
            case 'paragraph':
                block = <p key={id} dangerouslySetInnerHTML={{__html: data.text}} />
                break
            case 'list':
                block = createElement(
                    data.style === 'ordered' ? 'ol' : 'ul',
                    { key: id },
                    data.items.map((item: string, i: number) => (
                        <li key={i} dangerouslySetInnerHTML={{__html: item}} />
                    ))
                )
                break
            case 'header':
                block = createElement(`h${data.level}`, {
                    key: id,
                    id: slugify(data.text),
                    dangerouslySetInnerHTML: {
                        __html: data.text
                    }})
                break
            case 'delimiter':
                block = <hr key={id}/>
                break
            case 'image':
                block = (
                    <figure key={id}>
                        <img src={data.file.url}/>
                        {data.caption && <figcaption>{data.caption}</figcaption>}
                    </figure>
                )
                break
            default:
                console.warn(`Unhandled type '${type as string}'`, data)
                // Returning something is useful for development, but we should not leave these in
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                block = <div key={id}>{(data as BlockToolData).text}</div>
        }

        if (!block) {
            console.warn(`No block returned for '${type}'`, data)
        }

        if (inSection) {
            // Don't append to output, append this into the last child (the section component)
            const container = output[output.length - 1]

            // Wouldn't have thought this check is needed but TS complains without it
            if (isValidElement(container)) {
                container.props.children.push(block)
            }

            return
        }

        output.push(block)
    })

    return output
}
