import { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from "./button";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    menu: {
        padding: theme.spacing(0),
        borderRadius: '4px',
    },
    menuItem: {
        padding: theme.spacing(0.5, 3, 0.5, 2),
        zIndex: 3,
        minHeight: 'auto',
    },
    text:{
        color: theme.palette.grey[800],
        fontSize: theme.typography.fontSize * 0.9375,
        fontWeight: 600,
    },
    leftIcon: {
        margin: theme.spacing(0, 2.5, 0, 0)
    },
    divider: {
        margin: theme.spacing(1, 0)
    },
}))

/*
**props
items: list of menu items to display on opening the ellipsis menu
e.g.
items: [{
    icon: UserIcon // icon to display next to menu item
    content: Account // text to display on the menu item
    action: () => history.push('/account') // what action to perform on click
}, {divider: true} // Adds a divider between items
]
listItem: identifier of row to know to what row the action should be performed on
*/
export const EllipsisMenu = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const openMenu = () => {setOpen(true)}
    const closeMenu = () => {setOpen(false)}
    const items = props.items || []
    const listItem = props.listItem || null
    return (
        <>
            <IconButton ref={anchorRef} size="small" onClick={openMenu}>
                <MoreVertIcon/>
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} style={{zIndex: 3}} placement={'bottom-end'} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom' }}
                    >
                        <ClickAwayListener onClickAway={closeMenu}>
                            <Paper className={classes.menu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    {items.map((item, k) => {
                                        const Icon = item.icon
                                        if (item.divider) return(<Divider key={k} className={classes.divider}/>)
                                        return(
                                            <MenuItem key={k} className={classes.menuItem} onClick={() => item.action(listItem)}>
                                                {Icon && <Icon className={classes.leftIcon} fontSize="small"/>}
                                                <Typography variant="button" className={classes.text}>{item.content}</Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </>
    )}

export default EllipsisMenu
