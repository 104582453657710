import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import PatientRecordsIcon from '../assets/icons/patient_records';
import QRCodeIcon from '../assets/icons/qr_icon';
import ChevronRightIcon from '../assets/icons/chevron_right';
import { formatDate, TDateISODate } from '../utils/common';
import { CircleFlag } from './flag';
import Typography from './typography';
import { ListItem, ListItemIcon, ListItemProps } from "./lists/list_item";
import ImunisIssuerIcon from "../assets/icons/imunis_issuer";
import { IconButton } from "./button";

const useStyles = makeStyles<Theme>((theme) => createStyles({
    modal: {
        padding: theme.spacing(5, 3, 3),
    },
    spacing: {
        margin: theme.spacing(2, 0)
    },
    truncateText: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginRight: 'auto',
    },
    sourceListItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(1),
        minWidth: 0,
    },
    listItem: {
        padding: theme.spacing(2, 1, 2, 0.5),
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
    },
    listTitle: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
    },
    images: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(-1),
        color: theme.palette.grey[600],
    },
    icon: {
        fontSize: '24px',
    },
    close: {
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
}))


type Source = {
    /** Source id */
    id: string

    /** Type of source */
    type: "QR" | "CLINIC" | "PATIENT" | "PAPER"

    /**
     * Dose count (total/qualifying doses taken, as opposed to number of doses in the certificate).
     * Only applicable for HK QR codes.
     * */
    doseCount?: number

    /** Date when the record was added. Only applicable for QR codes. */
    dateAdded?: TDateISODate

    /** Source label (issuer). Examples: "HKSARG", "NHS Digital"  */
    label: string

    /** For QR sources: what country that source comes from. Examples: "HK", "GB" */
    country?: string
}

type SourceModalProps = {
    /** Whether to open dialog */
    open: boolean

    /** Array of sources */
    sources: Array<Source>

    /** Function used to open source record */
    onClick: (id: string) => React.MouseEventHandler

    /** Close dialog function */
    close: () => React.MouseEventHandler
}

type SourceListItemProps = {
    /** Source info (issuer) */
    source: Source

    /** Modal onClick function */
    onClick: SourceModalProps['onClick']
} & Omit<ListItemProps, 'onClick'>

const SourceListItem = ({source, onClick, ...rest}: SourceListItemProps) => {
    const classes = useStyles()

    return (
        <ListItem onClick={() => onClick(source.id)} extra={<ListItemIcon icon={ChevronRightIcon}/>} {...rest}>
            <div className={classes.sourceListItem}>
                <Typography className={classes.truncateText} variant="bodyTextMediumBold">
                    {source.label}
                </Typography>

                {source.type === "QR" && source.country === "HK" &&
                    <div>
                        <Typography variant="bodyTextSmall">{source.doseCount} dose record</Typography>
                        {source.dateAdded && (
                            <Typography variant="bodyTextSmall">
                                added {formatDate(source.dateAdded)}
                            </Typography>
                        )}
                    </div>
                }

                {source.type === "QR" &&
                    <div className={classes.images}>
                        {source.country ? <CircleFlag country={source.country}/> : null}
                        <QRCodeIcon className={classes.icon}/>
                    </div>
                }
                {source.type === "PATIENT" &&
                    <div className={classes.images}>
                        <PatientRecordsIcon className={classes.icon}/>
                    </div>
                }
                {source.type === "PAPER" &&
                    <div className={classes.images}>
                        <ImunisIssuerIcon className={classes.icon}/>
                    </div>
                }
            </div>
        </ListItem>
    )
}

export const SourceModal = (props: SourceModalProps) => {
    const { sources=[], open } = props
    const classes = useStyles()

    return (
        <Dialog open={open}>
            <IconButton aria-label="Close dialog" className={classes.close} onClick={props.close} size="small">
                <Close className={classes.icon} />
            </IconButton>
            <div className={classes.modal}>
                <Typography align="center" variant="modalHeader">
                    Select the source
                </Typography>
                <div className={classes.spacing}>
                    <Typography align="center" variant="bodyTextLarge">
                        We received {sources.length} sources of data of this
                        dose, select the source to view details.
                    </Typography>
                </div>
                {sources.map((source, idx) => (
                    <SourceListItem
                        key={source.id}
                        source={source}
                        onClick={props.onClick}
                        divider={idx < sources.length - 1}
                    />
                ))}
            </div>
        </Dialog>
    );
}
