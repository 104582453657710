import { Box, createStyles, Link, makeStyles, Typography} from "@material-ui/core";
import { useState, cloneElement, useEffect, createRef, SetStateAction } from "react";
import PrivacyPolicyIcon from "../assets/icons/privacy";
import TermsIcon from "../assets/icons/terms";
import { Drawer } from "./drawer";
import { StaticHTML } from "./static_content";
import Tab from "./tabs/tab";
import Tabs from "./tabs/tabs";
import { trackAction, trackLink } from "../utils/common";


type LegalDrawerProps = {
    /** An element that will open the drawer when clicked */
    children: React.ReactElement

    /** The document to display */
    document: "terms_conditions" | "privacy_policy"
}

type DrawerLinkProps = {
    document: LegalDrawerProps['document']

    children: React.ReactNode
}

const useStyles = makeStyles((theme) => createStyles({
    links: {
        margin: theme.spacing(0, 0, 3),
        padding: 0,
        listStyle: 'none',

        '& li': {
            margin: theme.spacing(1.5, 0),
        }
    },
    tabs: {
        marginTop: '20px',
    },
    // custom styles to make the legal tabs look good on smaller screens e.g. iPhone SE
    privacyTab: {
        padding: '0px !important'
    },
}))

export const LegalDrawer = ({ children, document }: LegalDrawerProps) => {
    const [open, setOpen] = useState(false)
    const [currentDocument, setCurrentDocument] = useState(document)
    const contentRef = createRef<HTMLDivElement>()
    const originalDocument = document
    const initialTabIndex = document === 'privacy_policy' ? 0 : 1;
    const [value, setValue] = useState(initialTabIndex);


    const classes = useStyles()

    const DrawerLink = ({ children, document }: DrawerLinkProps): JSX.Element => (
        <Link onClick={() => setCurrentDocument(document)}>
            {children}
        </Link>
    )

    let DrawerContent, drawerLabel: string
    switch (currentDocument) {
        case 'terms_conditions':
            drawerLabel = 'Terms and Conditions'
            DrawerContent = TermsConditionsContent
            break;

        case 'privacy_policy':
            drawerLabel = 'PICS'
            DrawerContent = PrivacyPolicyContent
            break;
    }

    // Scroll to top when switching documents
    useEffect(() => {
        if (open && contentRef?.current) {
            contentRef.current.scrollTop = 0
        }
    }, [currentDocument])

    const handleChange = (event: React.FormEvent<HTMLInputElement>, newValue: SetStateAction<number>) => {
        setValue(newValue);
    };

    return (<>
        {cloneElement(children, {
            "data-action": `Open ${drawerLabel}`,
            onClick: (e: React.MouseEvent) => {
                e.preventDefault()
                trackLink(drawerLabel, 'link')
                trackAction('Click', 'Link', drawerLabel)
                setOpen(true)
            }
        })}
        <Drawer
            ModalProps={{
                BackdropProps: {
                    "aria-label": `Close ${drawerLabel}`,
                }
            }}
            open={open}
            // FIXME: no action is reported when dragging to close the drawer
            onClose={() => {
                setOpen(false)
                // This is needed when:
                // 1. Open T&C, click link to PICS, close drawer.
                // 2, Opening original T&C link should open T&C, not PICS
                setCurrentDocument(originalDocument)
            }}
            ref={contentRef}>

            <Box display="flex" flexDirection="column">
                <Tabs value={value} onChange={handleChange} className={classes.tabs} hasIcons={true}>
                    <Tab className={classes.privacyTab} onClick={() => setCurrentDocument('privacy_policy')} label={'Personal Information Collection Statement'} icon={<PrivacyPolicyIcon/>} />
                    <Tab onClick={() => setCurrentDocument('terms_conditions')} label={'Terms and Conditions'} icon={<TermsIcon/>} />
                </Tabs>
            </Box>

            <StaticHTML>
                <DrawerContent DrawerLink={DrawerLink}/>
            </StaticHTML>

        </Drawer>
    </>)
}

type LegalDrawerContentProps = {
    DrawerLink: React.FC<DrawerLinkProps>
}

const TermsConditionsContent = ({DrawerLink}: LegalDrawerContentProps) => (<>
    <header>
        <h1>Imunis App Terms and Conditions</h1>
        <p>Effective date: 18 May 2022</p>
    </header>

    <ol>
        <li>
            <p><strong>INTRODUCTION</strong></p>
            <ol>
                <li>
                    <p>These terms and conditions (these “Terms”) apply as between you, as either a user (the “User”, “you”,
                        or “your”) of the imunis app (the “Platform”), and Imunis Limited (“Imunis”, “we”, “us”, or “our”),
                        the owner of the Platform.</p>
                </li>
                <li>
                    <p>These Terms govern your access to, and use of, the Platform. By accessing and using the Platform, you
                        agree to comply with, and to be bound by, these Terms. If you do not agree to be bound by these
                        Terms, you should not download or use the Platform, and please terminate these Terms immediately by
                        removing the Platform your mobile device. These Terms should be read together with our PICS,
                        provided that, in the event of any inconsistency between these Terms and our PICS concerning matters
                        relating to the function and operation of the Platform, these Terms prevail.</p>
                </li>
                <li>
                    <p>In these Terms, unless the context otherwise requires, the following words and expressions have the
                        following meanings:</p>
                    <ol className="no-marker">
                        <li>“Authorised Purposes” means the purposes and functions of the Platform set out in Paragraph 2.1,
                            and as may be notified to the User from time to time.</li>
                        <li>“Intellectual Property Rights” means any and all intellectual property rights including (without
                            limitation) all of the following, and all rights in, arising out of, or associated therewith (in
                            each case, whether registered or not): (i) patents; (ii) know-how, trade secrets, ideas,
                            concepts, inventions, discoveries, developments, devices, methods and processes (in each case,
                            whether or not patentable); (iii) trade marks, service marks, domain names, social media user
                            names, business names and trade names; (iv) rights in any designs; (v) copyright, software,
                            source codes, object codes, specifications and other works of authorship, databases and database
                            rights; (vi) any and all other intellectual property rights, and related documentation with
                            respect to all of the foregoing; (vii) any and all registrations of, or applications to
                            register, or any rights to register or apply to register, any of the foregoing; and/or (viii)
                            any similar or analogous rights anywhere in the world.</li>
                        <li>“Password” has the meaning ascribed to it in Paragraph 4.4.</li>
                        <li>“Personal Data” has the meaning ascribed to it in our PICS.</li>
                        <li>“PICS” means the {' '}
                            <DrawerLink document="privacy_policy">
                                Imunis App Personal Information Collection Statement
                            </DrawerLink>
                        </li>
                        <li>“Platform” has the meaning ascribed to it in Paragraph 1.1.</li>
                        <li>“User Account” has the meaning ascribed to it in Paragraph 4.2.</li>
                        <li>“User Information” means your Personal Data and/or other data, information, or materials that
                            you or third parties may provide to us or upload to the Platform.</li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>PURPOSES AND FUNCTIONS OF THE PLATFORM</strong></p>
            <ol>
                <li>
                    <p>The Platform’s purposes and functions include (without limitation):</p>
                    <ol className="alpha">
                        <li>managing our relationship with you, including notifying you about changes to these Terms;</li>
                        <li>providing you with the services as stated herein;</li>
                        <li>registering you as a User on the Platform;</li>
                        <li>following registration on the Platform, accessing and managing your User Account (including,
                            without limitation, updating your User Information, changing your Password, etc.);</li>
                        <li>storing your User Information (whether physically, electronically, or any other means whether
                            now known or available in the future);</li>
                        <li>making your vaccination and immune health records available to you in digital format should you
                            wish to access them;</li>
                        <li>allowing you to make and manage appointments of medical practitioners for vaccinations;</li>
                        <li>sending you vaccination reminders and information including, without limitation, schedules and
                            dosage, etc.;</li>
                        <li>allowing you to share User Information in a more efficient, digital format (for example, to
                            share your digital COVID-19 vaccination status with a healthcare provider who did not provide
                            that vaccination and may therefore not have access to that information in their own record base)
                            with legitimate third parties;</li>
                        <li>making enquiries, complaints, and/or suggestions to us (including, without limitation, through
                            the Platform, or by telephone, text, email, mail, and/or or other media whether now known or
                            available in the future);</li>
                        <li>seeking your feedback, including through surveys, in relation to the Platform and/or our goods
                            and services (including, without limitation, through the Platform, or by telephone, text, email,
                            mail, and/or or other media whether now known or available in the future);</li>
                        <li>the normal management, operation, maintenance, and support of the Platform;</li>
                        <li>providing you with regular communications from us with details of the Platform and its benefits;
                        </li>
                        <li>delivering relevant content to you through the Platform (and measure and understand the
                            effectiveness of such content), improving the Platform and our goods and services;</li>
                        <li>allowing mobile payment via third party payment gateway;</li>
                        <li>sending you in-app notifications and/or push notifications to your mobile device, and/or emails
                            relating to the foregoing;</li>
                        <li>designing new or improving existing services provided by us to you and other Users of the
                            Platform;</li>
                        <li>data analytics, profiling, information management, and database administration;</li>
                        <li>investigation of complaints, suspected suspicious transactions, and research for service
                            improvement;</li>
                        <li>aggregated behavioural analysis, including using anonymised User Information for data science
                            studies and data mining;</li>
                        <li>other purposes ancillary or related to the above.</li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>RIGHTS, OBLIGATIONS, AND RESTRICTIONS IN RELATION TO THE PLATFORM</strong></p>
            <ol>
                <li>
                    <p>We reserve the right to amend or withdraw the Platform or services provided to you in accordance with
                        these Terms at any time and for any reason and without any liability or responsibility to you.</p>
                </li>
                <li>
                    <p>The Platform is currently made available to Users free of charge for personal, non-commercial use,
                        but we reserve the right to charge for the Platform or services provided to you in accordance with
                        these Terms at any time and for any reason. If, at such time, you decide not to pay for the Platform
                        or services provided to you, then we reserve the right to terminate these Terms and to cease to
                        allow your access to, and use of, the Platform and such services without any liability or
                        responsibility to you.</p>
                </li>
                <li>
                    <p>You acknowledge that the terms of any agreement with your respective mobile network provider (“Mobile
                        Network Provider”) will continue to apply when using the Platform. As a result, you may be charged
                        by your Mobile Network Provider for access to network connection services for the duration of the
                        connection while accessing the Platform or any such third party charges as may arise. You accept
                        responsibility for any such charges that arise.</p>
                </li>
                <li>
                    <p>This Application is available as a web application and may be available to handheld mobile devices
                        running Apple iOS or Android Operating Systems. To use the Platform, you are required to have a
                        compatible mobile smartphone or handheld device, Internet access, and the necessary minimum
                        specifications (“Software Requirements”). The Software Requirements are as follows: for web
                        browsers, Firefox [54], Google Chrome [57], Microsoft Edge [79]; for Apple iOS devices, iOS [14.0] or
                        above; or for Android devices, Android Operating Systems [10], or such version as
                        specified on the official Apple App Store or Android market where the Platform may be downloaded.
                    </p>
                </li>
                <li>
                    <p>We will use reasonable efforts to make the Platform available at all times. However, you acknowledge
                        that the Platform is provided over the Internet and mobile networks, and so the quality and
                        availability of the Platform may be affected by factors outside our reasonable control. We do not
                        accept any responsibility whatsoever for unavailability of the Platform, or any difficulty or
                        inability to download or access content or any other communication system failure or delay in
                        delivery which may result in the Platform being unavailable.</p>
                </li>
                <li>
                    <p>The version of the Platform software may be upgraded from time to time to add support for new
                        functions and services.</p>
                </li>
                <li>
                    <p>You shall not:</p>
                    <ol className="alpha">
                        <li>use the Platform as a means to post or send unsolicited advertisements or “spam”, or any
                            unlawful, threatening, defamatory, libelous, obscene, pornographic, or profane material, or any
                            material that could constitute or encourage conduct that would be considered a criminal offense
                            or endangering national security or give rise to civil liability or public disorder, or
                            otherwise violate any law or third party rights (including, without limitation, Intellectual
                            Property Rights). If we become aware of any such breach, we may or may not notify you, and we
                            reserve the right to remove such offending material immediately. In the event that you fail to
                            delete such offending material upon notice, we may take any action we deem necessary to cure or
                            prevent the violation, including, without limitation, suspending or blocking your access to, and
                            use of, the Platform, and/or the immediate removal of any such material from the Platform at any
                            time without notice and without liability or responsibility to you;</li>
                        <li>download or copy any other User’s information;</li>
                        <li>use the Platform for data mining, or with robots, or similar data gathering and extraction
                            tools;</li>
                        <li>create accounts or use any of services of the Platform through unauthorised or automated means;
                        </li>
                        <li>sell, resell, rent, or charge the Platform;</li>
                        <li>re-distribute or make available the Platform without our authorisation;</li>
                        <li>take any action that imposes an unreasonably large load on or disrupt the integrity or
                            performance of the Platform’s infrastructure;</li>
                        <li>interfere with the proper working of the Platform, or any activities on the Platform;</li>
                        <li>develop, reverse engineer, decompile, disassemble, copy, reproduce, alter, translate, exchange,
                            adapt, change, modify, add to, subtract, or create derivative works from, or otherwise modify,
                            sub-license, assign, transfer, exploit, deal with, or carry out any act otherwise restricted by
                            copyright or other Intellectual Property Right, the Platform, or attempt any of the foregoing;
                        </li>
                        <li>transmit, send, store or otherwise spread viruses, trojans, worms, malware or other harmful
                            computer code to or through the Platform; or</li>
                        <li>by-pass any security or other measures used to restrict access to the Platform, and you, as
                            applicable, shall not use the Platform to do any of the foregoing.</li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>ACCOUNT REGISTRATION AND SECURITY</strong></p>
            <ol>
                <li>
                    <p>We have the right, in our sole discretion, to approve or to reject any User of the Platform (without
                        giving any reasons), and any such User’s access to, and use of, the Platform shall be conditional on
                        such User’s acceptance of, and compliance with, these Terms (as may be amended from time to time).
                    </p>
                </li>
                <li>
                    <p>To access and use the Platform, you must register and create an account (a “User Account”). To
                        facilitate registration of your User Account, you must provide:</p>
                    <ol className="alpha">
                        <li>a username and password, provided that such username and password must not violate any person’s
                            rights (including, but not limited to, Intellectual Property Rights) or one that in our opinion
                            is offensive, improper, or inappropriate, and if you do select such a username or password, you
                            agree we may modify or delete it; and</li>
                        <li>your name, gender, date of birth, email address, Hong Kong Identity Card number or your passport
                            number and passport expiry date, </li>

                    </ol>
                    <p>or such other information as we may request from time to time.</p>
                </li>
                <li>
                    <p>You must provide true and accurate details as required in Paragraph 4.2. We will not process
                        incomplete or inaccurate applications for registration.</p>
                </li>
                <li>
                    <p>You must follow the guidance provided by us from time to time (including, without limitation, through
                        the Platform) in designating the password for your User Account for signing in to your User Account
                        and using the Application (the “Password”). You may change your Password at any time, but any change
                        will be effective only if accepted by us.</p>
                </li>
                <li>
                    <p>You are responsible and liable for:</p>
                    <ol className="alpha">
                        <li>maintaining and updating the information provided in Paragraph 4.2 to keep it accurate and
                            complete;</li>
                        <li>any and all activities in relation to, or in connection with, the access to, and use of, your
                            User Account, and you must not allow any third party to access or use your User Account;</li>
                        <li>maintaining the confidentiality of your Password and/or other account identifiers that you have
                            chosen; and</li>
                        <li>any accidental or unauthorised disclosure of your Password to any other person, and you will
                            bear the risks of your Password being used by unauthorised persons or for unauthorised purposes.
                        </li>

                    </ol>
                </li>
                <li>
                    <p>Upon notice or suspicion of your Password being disclosed to any unauthorised person or any
                        unauthorised use of your User Account being made, you shall notify us immediately in writing and,
                        until our actual receipt of such notification, you remain responsible for any and all use of your
                        User Account and the Platform by unauthorised persons or for unauthorised purposes. For the
                        avoidance of doubt, we do not accept responsibility (including, without limitation, any and all
                        loss) for any unauthorised access to your User Account due to your failure to keep your User Account
                        and/or Password secure.</p>
                </li>
                <li>
                    <p>For more detail on the submission of your account information for the purpose of completing your
                        transactions and contacting you, please refer to our PICS.</p>
                </li>
                <li>
                    <p>You may not use the Platform unless you are aged 18 or over. If you are a child under 18, please do
                        not attempt to register for or otherwise use the Platform or send us any Personal Data. If we learn
                        we have collected Personal Data from a child under 18, we will delete that Personal Data as quickly
                        as possible in accordance with our PICS. If you believe that a child under 18 may have provided
                        Personal Data to us, please contact us at <a href="mailto:privacy@imunis.com" target="_blank" rel="noreferrer">privacy@imunis.com</a>.</p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>USER INFORMATION / PERSONAL DATA (PRIVACY)</strong></p>
            <ol>
                <li>
                    <p>Our use, collection, holding, and processing of any Personal Data is governed by our PICS and in
                        compliance with applicable personal data/data protection laws (including, without limitation, the
                        Personal Data (Privacy) Ordinance (Cap. 486 of the laws of Hong Kong)).</p>
                </li>
                <li>
                    <p>If you submit or upload any User Information to the Platform, you must ensure that any such User
                        Information:</p>
                    <ol className="alpha">
                        <li>is true, complete, and accurate;</li>
                        <li>is virus-free;</li>
                        <li>is not unlawful, threatening, defamatory, libelous, obscene, pornographic, or profane material,
                            or any material that could constitute or encourage conduct that would be considered a criminal
                            offense or endangering national security or give rise to civil liability or public disorder, or
                            otherwise violate any law or third party rights (including, without limitation, Intellectual
                            Property Rights);</li>
                        <li>will not disrupt, disable, harm, modify, delete, or otherwise impede the operation of the
                            Platform, or our software, computer systems, or networks (“Systems”);</li>
                        <li>will not disable the Platform or the Systems, or impair their operation, whether based on the
                            elapsing of a period of time or otherwise;</li>
                        <li>will not permit any third party to access the Platform or the Systems for any reason.</li>

                    </ol>
                </li>
                <li>
                    <p>If it comes to your attention that any of the User Information you have uploaded is inaccurate,
                        misleading, incomplete, or otherwise cannot be relied on, you should, as soon as possible, rectify
                        and notify us through the Platform (or such other means as may be provided by us from time to time)
                        regarding the same.</p>
                </li>
                <li>
                    <p>If we become aware that any of your User Information consists of unsolicited advertisements or
                        “spam”, or any unlawful, threatening, defamatory, libelous, obscene, pornographic, or profane
                        material, or any material that could constitute or encourage conduct that would be considered a
                        criminal offense or endangering national security or give rise to civil liability or public
                        disorder, or otherwise violate any law or third party rights (including, without limitation,
                        Intellectual Property Rights), we may or may not notify you and reserve the right to remove the
                        offending material immediately. In the event that you fail to remove such offending material upon
                        notice, we may take any action we deem necessary to cure or prevent the violation, including,
                        without limitation, suspending or blocking your access to, and use of, the Platform, and/or the
                        immediate removal of any such User Information from the Platform at any time without notice and
                        without liability or responsibility to you.</p>
                </li>
                <li>
                    <p><strong>Licence of, and rights in relation to, the User Information.</strong> By uploading any User
                        Information to the Platform, you grant a non-exclusive, worldwide, sub-licensable, transferrable,
                        irrevocable, perpetual, and royalty-free licence to Imunis to use and exploit such User Information
                        for the purpose of these Terms, including (without limitation) the Authorised Purposes. You agree
                        that we have the right to use, reproduce, alter, translate, revise, condense, abridge, expand,
                        adapt, change, compile, format, and/or modify the User Information you upload to the Platform,
                        solely for the purposes set out in Paragraph 5.5. For the avoidance of doubt, we will not make
                        substantive changes to your User Information, including (without limitation) any changes which may
                        alter the meaning of, or information conveyed by, the User Information.</p>
                </li>
                <li>
                    <p><strong>No endorsement of User Information.</strong> We do not vouch for the accuracy or credibility
                        of any User Information published, posted, or transmitted through the Platform to you, and do not
                        take any responsibility or assume any liability for any actions you may take as a result of viewing
                        such User Information.</p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>INTELLECTUAL PROPERTY</strong></p>
            <ol>
                <li>
                    <p><strong>Licence to use the Platform.</strong> Subject to your compliance with these Terms, we hereby
                        grant you a personal, limited, revocable, non-exclusive, non-sublicensable, non-transferable licence
                        to access and to use the Platform on a single mobile device primarily used by you, solely for the
                        Authorised Purposes. The Platform may be used only for the Authorised Purposes, and any unauthorised
                        use by you automatically terminates the licence granted to you under this Paragraph 6.11.1 without
                        any liability or responsibility of Imunis to you.</p>
                </li>
                <li>
                    <p><strong>Ownership of the Application.</strong> You agree that we are the owner of any and all the
                        rights, title, and interests in and to Platform and any improvements, amendments, adaptations,
                        modifications, derivatives, translations, new versions created in relation to, or in connection
                        with, the Platform, and any and all Intellectual Property Rights in and to any of the foregoing
                        (“Platform IP”). Nothing in these Terms is to be construed as conferring on, or creating in, any
                        User any proprietary interest or goodwill in them.</p>
                </li>
                <li>
                    <p><strong>Restrictions.</strong> You shall not:</p>
                    <ol className="alpha">
                        <li>knowingly do, omit to do or permit any act which may damage, jeopardise or diminish the Platform
                            IP and/or any of our rights thereto;</li>
                        <li>except as expressly permitted under these Terms, use, register, or procure the registration of,
                            any Intellectual Property Rights which form part of the Platform IP or, in our absolute
                            discretion, are similar thereto, represented in any form;</li>
                        <li>develop, reverse engineer, decompile, disassemble, copy, reproduce, alter, translate, exchange,
                            adapt, change, modify, add to, subtract, or create derivative works from, or otherwise modify,
                            sub-license, assign, transfer, exploit, deal with, or carry out any act otherwise restricted by
                            copyright or other Intellectual Property Right, the Platform IP, or attempt any of the
                            foregoing;</li>
                        <li>represent that you have any right, title, and/or interest in or to the Platform IP (except as
                            expressly provided in these Terms, or assert any proprietary interest therein.</li>

                    </ol>
                </li>
                <li>
                    <p><strong>Feedback.</strong> Any feedback, recommendations, ideas, or suggestions that you provide
                        regarding the Platform, whether or not requested by us, including suggestions for new services or
                        improvements, or enhancements to the Platform (collectively “Feedback”) will be considered our
                        Intellectual Property Rights, and we shall own all rights, title, and interest in and to such
                        Feedback, and we have the right to use or incorporate (and you acknowledge and agree that you will
                        have no Intellectual Property Rights or other interest in) any Feedback to the Platform, or any
                        other of our services.</p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>SUPPORT</strong></p>
            <ol>
                <li>Technical support for using the Platform will be provided by Imunis through the Imunis official support
                    page in the Platform and/or official support email of Imunis as provided by Imunis from time to time.
                    Notwithstanding the foregoing, Users agree that Imunis provides technical support on a reasonable effort
                    basis, and does not guarantee any response time or level of services for any enquiries raised to Imunis.
                </li>

            </ol>
        </li>
        <li>
            <p><strong>THIRD PARTY LINKS</strong></p>
            <ol>
                <li>From time to time, the Platform may contain links to content, applications, platforms, services,
                    websites, and/or resources posted by us that are not owned, operated, or controlled by us (collectively,
                    “Third Party Links”). These Third Party Links are provided for your information and/or easy access only.
                    Third Party Links may have their own terms of use and/or privacy policy, or no terms of use or privacy
                    policy at all. We have no control over the contents of Third Party Links. We are not responsible for any
                    content, materials, or other information located on, or accessible through, such Third Party Links. Any
                    User’s correspondence or business dealings with third parties through any Third Party Links are solely
                    between such User and such third party. We accept no responsibility for any direct or indirect loss or
                    damage that may arise from your use of such Third Party Links, the availability of such Third Party
                    Links, any transaction conducted by you (including, without limitation, payment and purchase/service
                    order), and/or any actions taken by you upon your reliance on, or use of, the Third Party Links. If you
                    decide to access any Third Party Links from the Platform, you do so entirely at your own risk.</li>

            </ol>
        </li>
        <li>
            <p><strong>DISCLAIMERS, LIMITATION OF LIABILITY, AND REPRESENTATIONS AND WARRANTIES</strong></p>
            <ol>
                <li>
                    <p>You agree that the information provided in the Platform is intended for general information purposes
                        only. No part of the Platform or any content thereon is intended to constitute advice (including,
                        without limitation, medical advice), and the content of the Platform should not be relied upon when
                        making any decisions or taking any action of any kind. In particular, the information on the
                        Platform are intended for reference only, and we give no guarantee or assurance whatsoever as to
                        their applicability or accuracy, or to any given set of facts. You are urged to exercise your
                        independent judgment on the use of the Platform and any content thereon.</p>
                </li>
                <li>
                    <p>Without limiting the generality of Paragraph 9.1, you agree that the information provided in the
                        Platform may be based on government issued, public domain vaccination recommendations which are
                        non-manipulable and may be in a different format than as presented in the Platform. Therefore, such
                        information may be extracted from such sources and presented to you through the Platform. Imunis
                        does not guarantee and is not liable for the accuracy of this information or that such information
                        is up-to-date. Nothing in these Terms should be construed as medical advice, and you must seek
                        proper medical advice before taking any medication and/or receiving any vaccination.</p>
                </li>
                <li>
                    <p>The Platform is provided by us on an “as is” and “as available” basis. Whilst we will endeavour to
                        keep the Platform and its content up to date, we do not make any representations or warranties of
                        any kind, express or implied, as to the operation of the Platform or the accuracy, adequacy and
                        completeness of its content (including, without limitation, in relation to any User Information). To
                        the fullest extent permissible by applicable law, we disclaim all warranties, express or implied
                        thereof. We accept no liability for any disruption or non-availability of the Platform resulting
                        from external causes including, but not limited to, Internet service provider/Mobile Network
                        Provider equipment failure, push server failure, host equipment failure, communications network
                        failure, power failure, natural events, acts of war or legal restrictions and censorship.</p>
                </li>
                <li>
                    <p>We do not warrant that the functions contained in the Platform will be uninterrupted or free of
                        defects and/or faults, that defects and/or faults will be corrected, or that the Platform or the
                        server that makes it available is free of viruses or other harmful components.</p>
                </li>
                <li>
                    <p>We make no warranty or representation that the Platform and its related programmes will be compatible
                        with all equipment (hardware or software) which you may use. You agree that, to the fullest extent
                        permissible by applicable law, that we have no liability in relation to any direct or indirect loss
                        and damage caused to the relevant device (including, without limitation, mobile phones and tablet
                        computers), computer system, software, equipment, information, data or other properties suffered by
                        you or any third party arising out of, or in connection with, your downloading, installation,
                        access, or use of the Platform and/or the Platform’s related programmes.</p>
                </li>
                <li>
                    <p>To the fullest extent permissible by applicable law, we accept no liability for any direct or
                        indirect loss or damage, foreseeable or otherwise, including any indirect, consequential, special,
                        or exemplary damages arising from your access to, or use of, the Platform, or any information
                        contained therein (including, but not limited to, loss of profit or the like whether or not in the
                        contemplation of the parties, whether based on breach of contract, tort (including negligence),
                        product liability or otherwise). You agree that you access and use the Platform and its content at
                        your own risk.</p>
                </li>
                <li>
                    <p>Nothing in these Terms exclude or limit our liability for death or personal injury caused by our
                        negligence, or any other liability which cannot be excluded or limited under applicable law.</p>
                </li>
                <li>
                    <p>You hereby represent and warrant to Imunis that:</p>
                    <ol className="alpha">
                        <li>you shall comply with all applicable laws related to your access and use of the Platform;</li>
                        <li>you have the right to enter into these Terms and to perform your obligations under these Terms
                            without conflict with, or prejudice to, any other obligations to, or infringement of the rights
                            of, any third parties, and you must not, during the term of these Terms, enter into any contract
                            or accept any obligation inconsistent or incompatible with its obligations under these Terms;
                        </li>
                        <li>you have all rights, title, and interest to grant to Imunis all the rights granted under these
                            Terms, and our possession and/or use of your User Information and the rights granted under these
                            Terms do not, and will not, infringe or otherwise violate any rights of any third party
                            (including, without limitation, any Intellectual Property Rights), and there are no third party
                            rights that may impede Imunis’ use of your User Information;</li>
                        <li>any and all your User Information are original, not copied, and do not violate or infringe the
                            rights of any third party (including, without limitation, any Intellectual Property Rights);
                        </li>
                        <li>any and all of your User Information comply with these Terms (including, but not limited to,
                            Paragraph 3.7) and all applicable laws.</li>
                        <li>there are no lawsuits, arbitral, legal, administrative or other proceedings or governmental
                            investigations pending or threatened against you with respect to the subject matter of these
                            Terms or that could affect in any way: (i) your ability to enter into or perform these Terms;
                            and/or (ii) Imunis’ full and free use and exploitation of any and all parts of your User
                            Information.</li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>INDEMNITY</strong></p>
            <ol>
                <li>You agree to indemnify, defend, and hold harmless Imunis and its directors, officers, employees,
                    representatives, advisers and/or agents, fully from and against any and all claims, losses, liabilities,
                    damages, costs and/or expenses (direct and/or indirect, and including legal fees and expenses on a full
                    indemnity basis) arising out of, or resulting from, any breach, alleged breach or non-performance by you
                    of your representations, warranties, and/or obligations under these Terms, and/or any your acts of
                    omissions with respect to your access and use of the Platform.</li>

            </ol>
        </li>
        <li>
            <p><strong>TERMINATION</strong></p>
            <ol>
                <li>We may, without penalty, liability and/or responsibility to you, terminate these Terms and/or suspend or
                    terminate your User Account, and/or your access to, or use of, the Platform with immediate effect at any
                    time (with or without any reasons). In the event of such termination, we will use our reasonable efforts
                    to notify you of the same.</li>
                <li>You may terminate these Terms at any time by uninstalling the Platform and destroying any and all copies
                    of the Platform in your possession and/or control.</li>

            </ol>
        </li>
        <li>
            <p><strong>CONSEQUENCES OF TERMINATION</strong></p>
            <ol>
                <li>Subject to Paragraphs 12.2 and 12.3, upon termination of these Terms (for whatever reason), no party
                    will have any further obligations to any other under these Terms.</li>
                <li>Termination of these Terms operates without prejudice to any rights or remedies which may have accrued
                    to any party under these Terms.</li>
                <li>Any provisions of these Terms which, in order to give effect to their meaning, need to survive
                    termination) will remain in full force and effect after termination of these Terms.</li>
                <li>Upon termination of these Terms for any reason, you agree to uninstall the Platform, and destroy any and
                    all copies of the Platform, any accompanying documentation, and all other associated materials.</li>

            </ol>
        </li>
        <li>
            <p><strong>MISCELLANEOUS</strong></p>
            <ol>
                <li>
                    <p><strong>Construction.</strong> In these Terms, where the context admits:</p>
                    <ol className="alpha">
                        <li>references to “these Terms” are references to these terms and conditions, and such other
                            agreement or document as may amend, vary, supplement, modify or novate these Terms from time to
                            time;</li>
                        <li>references to “Paragraphs” in these Terms are references to the paragraphs of these Terms;</li>
                        <li>the headings and sub-headings in these Terms are inserted for convenience only and will not
                            affect the construction of these Terms;</li>
                        <li>the singular includes the plural and vice versa, references to the masculine, feminine and the
                            neuter include all such genders, and words denoting persons include natural persons, bodies
                            corporate, unincorporated associations, and partnerships;</li>
                        <li>references to any party to these Terms includes its successors and permitted assigns;</li>
                        <li>references to “including”, “includes” and “in particular” are illustrative, and none of them
                            limit the sense of the words preceding it and each of them will be deemed to incorporate the
                            expression “without limitation”; and</li>
                        <li>references to “in writing” includes typewriting, printing, lithography, photography, email
                            messages, and other modes of reproducing words in a legible and non-transitory form.</li>

                    </ol>
                </li>
                <li>
                    <p><strong>Entire agreement.</strong> These Terms constitute the entire agreement between the parties
                        relating to its subject matter, and supersede and extinguish any and all previous drafts,
                        agreements, promises, assurances, undertakings, understandings and arrangements between them of any
                        nature, whether written or oral, relating to such subject matter.</p>
                </li>
                <li>
                    <p><strong>No variation.</strong> We reserve right to modify these Terms at any time, so please review
                        them frequently. Changes and clarifications will take effect immediately upon posting on the
                        Platform. If we make material changes to these Terms, we will use our reasonable efforts to notify
                        you of such changes by an appropriate method, such as a notice on the Platform. If you do not agree
                        with any changes or additions we make, then you will not be allowed access to, or use of, the
                        Platform.</p>
                </li>
                <li>
                    <p><strong>Assignment.</strong> You may not assign any of your rights or obligations under these Terms
                        to any third party without our prior written consent. We have the right to assign any of our rights
                        or obligations under these Terms at any time. You agree that, in the event of any such assignment of
                        our obligations, you release us from any of our obligations, provided that the relevant assignee
                        agrees to assume any and all such obligations.</p>
                </li>
                <li>
                    <p><strong>Force majeure.</strong> If, by any reason of any event of force majeure or acts of God which
                        directly or indirectly cause any party to these Terms to be delayed in, or prevented from,
                        performing any of the provisions of these Terms, then such delay or non-performance will not be
                        deemed to be a breach of these Terms, and no party will be responsible or liable to the other for
                        such failure in delay or performance, and no loss or damage may be claimed by any party hereto from
                        the other by reason thereof.</p>
                </li>
                <li>
                    <p><strong>Further Assurance.</strong> The parties shall promptly do all things, execute all such
                        documents, and perform all such acts as may be reasonably required to give the full and intended
                        effect to these Terms.</p>
                </li>
                <li>
                    <p><strong>No partnership or agency.</strong> Nothing in these Terms may be construed to create a joint
                        venture or place the parties in a relationship of partners, nor do these Terms make the parties the
                        agent or legal representative of the other for any purpose whatsoever. The parties further agree
                        that no representation may be made by the parties that would create an apparent agency, employment,
                        partnership, or joint venture. No party has the power, expressed or implied, to obligate or bind the
                        other party in any manner whatsoever. No party is responsible for any act or omission of the other
                        or any employee of the other.</p>
                </li>
                <li>
                    <p><strong>Severability.</strong> The provisions of these Terms are severable, and if any provision, or
                        part-provision, of these Terms are held or declared to be illegal, invalid, or unenforceable, such
                        illegality, invalidity, or unenforceability will not affect any other provision, or part-provision,
                        of these Terms, and the remainder of these Terms, disregarding such illegal, invalid or
                        unenforceable provision, or part-provision, will continue in full force and effect as though such
                        illegal, invalid, or unenforceable provision, or part-provision, had not been contained in these
                        Terms.</p>
                </li>
                <li>
                    <p><strong>Waiver.</strong> No provision of these Terms may be deemed waived, unless such waiver is in
                        writing and signed by the party against which the waiver is sought to be enforced. The waiver by a
                        party of any breach of any provision, or part provision, of these Terms by another party will not be
                        construed to be either a waiver of any succeeding breach of any such provision or a waiver of the
                        provision itself. No single or partial exercise of any right or remedy will prevent or restrict
                        further exercise of that or any other right or remedy.</p>
                </li>
                <li>
                    <p><strong>Third party rights.</strong> These Terms do not create any right enforceable by any person
                        who is not a party to these Terms under the Contracts (Rights of Third Parties) Ordinance (Chapter
                        623 of the Laws of Hong Kong) (the “Ordinance”), but this Paragraph does not affect a right or
                        remedy of a third party that exists or is available apart from this Ordinance.</p>
                </li>
                <li>
                    <p><strong>Governing law and jurisdiction.</strong> These Terms, and any disputes, controversies,
                        differences, or claims arising out of, or in connection with, these Terms, including the formation,
                        existence, validity, interpretation, performance, breach, or termination thereof, or any dispute
                        regarding non-contractual obligations arising out of, or relating to, any of them or these Terms (a
                        “Dispute”), are, and will be, governed by, and construed in accordance with, the laws of Hong Kong.
                        The parties hereby submit to the exclusive jurisdiction of the Hong Kong courts. </p>
                </li>
            </ol>
        </li>
    </ol>
</>)

const PrivacyPolicyContent = ({DrawerLink}: LegalDrawerContentProps) => (<>
    <header>
        <h1>Imunis App Personal Information Collection Statement</h1>
        <p>Effective date: 18 May 2022</p>
    </header>

    <ol>
        <li>
            <p><strong>Purpose of this Personal Information Collection Statement (“PICS”)</strong></p>
            <ol>
                <li>
                    <p>At Imunis Limited (together with “we”, “us”, or “our”), we take your privacy seriously. This PICS is
                        issued pursuant to the PDPO and sets out how we may collect, hold, process, and use the Personal
                        Data of our users who access and use the Platform.</p>
                </li>
                <li>
                    <p>This PICS applies as between us and you (“you”, or “your”) as a user of our Platform. Please read the
                        following to learn how we treat your Personal Data. By accessing or using the Platform in any
                        manner, you accept the practices and policies outlined in this PICS, and you hereby consent that we
                        will collect, hold, process, and use your Personal Data as set out in this PICS. This PICS should be
                        read together with the Imunis App Terms and Conditions (the “Terms and Conditions”), provided that,
                        in the event of any inconsistency between this PICS and the Terms and Conditions concerning matters
                        relating to Personal Data, this PICS prevails.</p>
                </li>
                <li>
                    <p>In this PICS, unless the context otherwise requires, the following words and expressions have the
                        following meanings:</p>
                    <ol className="no-marker">
                        <li>
                            <p>“Database” has the meaning ascribed to it in clause 3.3.</p>
                        </li>
                        <li>
                            <p>“Imunis Partners” includes medical practitioners, hospitals, clinics, healthcare service
                                providers, educational institutions, education providers, education service providers,
                                healthcare insurance providers, healthcare insurance service providers, pharmaceutical
                                companies, biotechnology companies, healthcare technology providers, healthcare technology
                                service providers, non-governmental organisations, and/or our Third Party Processors.</p>
                        </li>
                        <li>
                            <p>“PDPO” means the Personal Data (Privacy) Ordinance (Cap. 486 of the laws of Hong Kong).</p>
                        </li>
                        <li>
                            <p>“Personal Data” means any data relating directly or indirectly to a living individual from
                                which it is practicable for the identity of the individual to be directly or indirectly
                                ascertained, including:</p>
                            <ol className="alpha">
                                <li>name, title, gender, date of birth, age, marital/relationship status, family status,
                                    image/photograph, and copies/details of identity documents, and proof of address;</li>
                                <li>contact details (including personal email address, personal telephone number, personal
                                    facsimile number, personal address, business email address, business telephone number,
                                    business facsimile number, business address/work location) and emergency contact
                                    details;</li>
                                <li>occupation, position/job title, and company name;</li>
                                <li>health-related data and information including vaccination status and records and/or
                                    other medical records or results of medical examinations;</li>
                                <li>other personal/profile information (including personal identifiers; social media links;
                                    personal and professional interests and preferences; favourite activities; any services
                                    purchased from us; experiences of the Platform and how the Platform is accessed and
                                    used; preferred language);</li>
                                <li>any other data or information provided to us in the registration process for, and access
                                    to, or use of, the Platform (including data or information you provide through support
                                    channels, for example, if you report a problem to us or interact with a support team);
                                </li>
                                <li>other data referred to as “personally identifiable information” or “personal
                                    information” under applicable data privacy laws, rules, or regulations.</li>

                            </ol>
                        </li>
                        <li>
                            <p>“Platform” means the imunis app and software platform.</p>
                        </li>
                        <li>
                            <p>“Purposes” has the meaning ascribed to it in clause 3.1.</p>
                        </li>
                        <li>
                            <p>“Representative” means any director, officer, employee, representative, adviser and/or agent.
                            </p>
                        </li>
                        <li>
                            <p>“Subject” means: (A) any family member under the age of 18; (B) any person aged 18 years or
                                over who has given your consent to transfer such person’s Personal Data to Imunis.</p>
                        </li>
                        <li>
                            <p>“Third Party Processors” has the meaning ascribed to it in clause 6.1(A).</p>
                        </li>
                        <li>
                            <p>“Website” means the website at imunis.com (or such other website as we may designate from
                                time to time).</p>
                        </li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>Sources of Personal Data</strong></p>
            <ol>
                <li>
                    <p>We collect and/or receive Personal Data about you from:</p>
                    <ol className="alpha">
                        <li>
                            <p>you:</p>
                            <ol>
                                <li>when you provide such Personal Data directly to us; and</li>
                                <li>when Personal Data about you is automatically collected in connection with your access
                                    to, or use of, the Platform (also when you access or use
                                    our <Link href="https://www.imunis.com" target="_blank">Website</Link>) and
                                </li>

                            </ol>
                        </li>
                        <li>
                            <p>Imunis Partners.</p>
                        </li>

                    </ol>
                </li>
                <li>
                    <p>We are not responsible for the collection, retention, processing, use, disclosure, transfer,
                        security, and/or access of your Personal Data by, or from, such third party sources, and we
                        recommend you examine the privacy policy or personal information collection statement of any third
                        parties to understand their procedures for collecting, holding, processing, and using Personal Data.
                    </p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>What are the purposes of collection of your Personal Data?</strong></p>
            <ol>
                <li>
                    <p>We may collect, hold, process, and use your Personal Data for the Purposes. In this PICS, “Purposes”
                        means:</p>
                    <ol className="alpha">
                        <li>managing our relationship with you, including notifying you about changes to this PICS;</li>
                        <li>providing you with the services as stated in, and in accordance with,
                            the <DrawerLink document="terms_conditions">Terms and Conditions</DrawerLink></li>
                        <li>registering you as a user on the Platform;</li>
                        <li>following registration on the Platform, accessing and managing your account (including, without
                            limitation, updating your Personal Data, changing your password, etc.);</li>
                        <li>identifying you and matching you with your health records and other Personal Data as submitted
                            by you (or on your behalf) and/or Imunis Partners;</li>
                        <li>storing your Personal Data (whether physically, electronically, or any other means whether now
                            known or available in the future);</li>
                        <li>making your health records available to you in digital format should you wish to access them;
                        </li>
                        <li>allowing you to make and manage appointments of medical practitioners for vaccinations;</li>
                        <li>sending you vaccination reminders and information (including, without limitation, schedules and
                            dosage, etc.);</li>
                        <li>allowing you to share Personal Data in a more efficient, digital format (for example, to share
                            your digital COVID-19 vaccination status with a healthcare provider who did not provide that
                            vaccination and may therefore not have access to that information in their own record base) with
                            legitimate third parties;</li>
                        <li>making enquiries, complaints, and/or suggestions to us (including, without limitation, through
                            the Platform and/or by email);</li>
                        <li>seeking your feedback, including through surveys, in relation to the Platform and/or our goods
                            and services (including, without limitation, through the Platform, or by telephone, text, email,
                            mail, and/or or other media whether now known or available in the future);</li>
                        <li>the normal management, operation, maintenance, and support of the Platform;</li>
                        <li>providing you with regular communications from us with details of the Platform and its benefits;
                        </li>
                        <li>delivering relevant content to you through the Platform (and measure and understand the
                            effectiveness of such content), improving the Platform and our goods and services;</li>
                        <li>allowing mobile payment via third party payment gateway;</li>
                        <li>sending you in-app notifications and/or push notifications to your mobile device, and/or emails
                            relating to the foregoing;</li>
                        <li>designing new or improving existing services provided by us to you and other users of the
                            Platform;</li>
                        <li>data analytics, profiling, information management, and database administration;</li>
                        <li>investigation of complaints, suspected suspicious transactions, and research for service
                            improvement;</li>
                        <li>prevention or detection of crime;</li>
                        <li>detection, investigation and/or prevention of activities that may violate, or may be suspected
                            to violate, our policies or may be illegal;</li>
                        <li>disclosure/transfer of your Personal Data as set out in this PICS or as required by law or court
                            orders;</li>
                        <li>aggregated behavioural analysis, including using anonymised Personal Data for data science
                            studies and data mining;</li>
                        <li>other purposes ancillary or related to the above.</li>

                    </ol>
                </li>
                <li>
                    <p>To enable us to perform the Purposes, it is necessary for you to provide us with your Personal Data
                        that is marked as being “required” or “mandatory”. If you do not provide us with complete and
                        correct Personal Data that is stated to be required or mandatory, we may not be able to perform the
                        Purposes or provide goods and services to you. We may also request other Personal Data about you. It
                        is entirely voluntary for you to provide this other Personal Data which is not marked as “required”
                        or “mandatory” at the time of collection (though please note that such other Personal Data may also
                        be used for the Purposes).</p>
                </li>
                <li>
                    <p>While any rights, title, and interests in and to your Personal Data uploaded to the Platform will be
                        your sole and exclusive property of, and owned by, the respective person who provided/uploaded the
                        Personal Data, the database of Personal Data collected and/or stored in the Platform, and any and
                        all rights, title, and interests (including, without limitation, any intellectual property rights)
                        in the aggregate and compilation of the Personal Data (the “Database”) will be the sole and
                        exclusive property of, and owned by, Imunis. You acknowledge that we have the right to collect,
                        hold, process, and use, the Personal Data in the Database in each case for the Purposes as set out
                        in, and in accordance with, this PICS.</p>
                </li>
                <li>
                    <p>When you access or use the Platform, we may also receive technical information including internet
                        protocol address, login data, browser (type and version), time zone setting and geo-location
                        information, browser plug-in types and versions, operating system (type and version), equipment
                        identifier (for example, IMEI), device type/manufacturer, network type, wireless carrier/operator,
                        other technology on the device used to access and use the Platform, and how the Platform is used.
                        This information is anonymous, and cannot be used to identify you (and so is not Personal Data for
                        the purpose of this PICS), but will also help us to improve our understanding of your access and use
                        of the Platform, and to provide better goods and/or services.</p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>Personal Data of children</strong></p>
            <ol>
                <li>We do not knowingly collect or solicit Personal Data directly from children under 18. If you are a child
                    under 18, please do not attempt to register for or otherwise use the Platform or send us any Personal
                    Data. If we learn we have collected Personal Data from a child under 18, we will delete that Personal
                    Data as quickly as possible. If you believe that a child under 18 may have provided Personal Data to us,
                    please contact us at <a href="mailto:privacy@imunis.com" target="_blank" rel="noreferrer">privacy@imunis.com</a>.</li>

            </ol>
        </li>
        <li>
            <p><strong>Subjects’ Personal Data</strong></p>
            <ol>
                <li>
                    <p>Through your use of the Platform, you may, in your discretion, provide us with Subjects’ Personal
                        Data from time to time.</p>
                </li>
                <li>
                    <p>We will use any Subjects’ Personal Data that you submit through the Platform for the Purposes, and
                        this PICS applies to any and all such Personal Data.</p>
                </li>
                <li>
                    <p>By submitting any Subjects’ Personal Data, you warrant to us that:</p>
                    <ol className="alpha">
                        <li>where such Subject is aged 18 years or over, you have obtained any such Subjects’ consent to do
                            so;</li>
                        <li>where such Subject is aged 18 years or over, you have notified them of this PICS and explained
                            to them that we will use their Personal Data as set out in this PICS; and</li>
                        <li>such Personal Data is true, accurate, and complete.</li>

                    </ol>
                </li>
                <li>
                    <p>You agree that we will not be responsible for any false, inaccurate, and/or incomplete family member
                        Personal Data provided to us.</p>
                </li>
                <li>
                    <p>To the fullest extent permissible by applicable law, you agree to indemnify, defend, and hold
                        harmless Imunis and its Representatives fully from and against any and all claims, losses,
                        liabilities, damages, costs and/or expenses (direct and/or indirect, and including legal fees and
                        expenses on a full indemnity basis) arising out of, or resulting from, any breach, alleged breach or
                        non-performance by you of your warranties and/or obligations, and/or any of your acts of omissions,
                        under this PICS.</p>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>Sharing and transfer of Personal Data</strong></p>
            <ol>
                <li>
                    <p>Your Personal Data may be transferred to and/or stored by us in physical locations and/or servers
                        located within or outside Hong Kong and/or shared with third parties (as set out in this PICS)
                        located within or outside Hong Kong. By using the Platform, you agree that any Personal Data about
                        you, regardless of whether provided by you or obtained from a third party, is being provided to
                        Imunis and other third parties (whether in Hong Kong or abroad) as set out in this PICS. We may
                        disclose/transfer (whether in Hong Kong or abroad) your Personal Data:</p>
                    <ol className="alpha">
                        <li>to Imunis Partners in connection with the Purposes;</li>
                        <li>to third parties in connection with the Purposes to process some of your Personal Data on our
                            behalf, for example hosting and other technology and communications providers and analytics
                            providers, and other providers of administrative, data processing, development and maintenance,
                            digital storage, distribution, information technology and telecommunications, marketing and
                            research, payroll, professional, or other similar services to us including, without limitation,
                            in relation to the Platforms and cloud hosting service providers (which provides us with digital
                            storage services on their servers) (“Third Party Processors”). When we allow them access to your
                            Personal Data, we do not permit them to use it for their own purposes. We have in place with
                            each Third Party Processor a contract that requires them only to process the Personal Data on
                            our instructions and to take proper care in using it.</li>
                        <li>to employees of Imunis in connection with the Purposes including, without limitation, in
                            relation to the Platform;</li>
                        <li>to any of our actual or proposed purchasers, assignees, or transferees of our rights with
                            respect to your Personal Data in the event of any actual or proposed transfer of business,
                            transfer of shares, re-structuring, amalgamation, merger, sale, transfer, or purchase of Imunis
                            (in whole or in part), to collect, hold, process, and/or use such Personal Data for the
                            Purposes;</li>
                        <li>for the purpose of restructuring how we provide the Platform, and as part of that, your Personal
                            Data may be transferred to one of our affiliates or to a not-for-profit organisation;</li>
                        <li>to any person to whom Imunis and/or any applicable third parties are under an obligation or
                            otherwise required to make disclosure under the requirement of any applicable law, regulation,
                            or any action of any regulatory authority, court, tribunal, or government agency.</li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>Data security and retention</strong></p>
            <ol>
                <li>We will take all practicable steps to protect your Personal Data from unauthorised or accidental access,
                    processing, erasure, loss, or use using appropriate physical, technical, organisational and
                    administrative security measures based on (among other things) the type of Personal Data and how we are
                    processing that Personal Data. Although we work to protect the security of your account and other
                    Personal Data that we hold in our records, please be aware that no method of transmitting data over the
                    Internet or storing data is completely secure. We cannot guarantee the complete security of any Personal
                    Data you share with us, and except as expressly required by law, we are not responsible for the theft,
                    destruction, loss or inadvertent disclosure of your Personal Data, information, or content.</li>
                <li>We retain your Personal Data in order to provide you with the Platform. We will take all practicable
                    steps to ensure that your Personal Data is not kept longer than is necessary for the fulfilment of the
                    Purposes. The third parties from whom some of your Personal Data is sourced with your consent are
                    subject to separate applicable data privacy laws.</li>
                <li>In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal
                    obligations, resolve disputes or is otherwise required by applicable law, rule, or regulation. We may
                    further retain information in an anonymous or aggregated form where that information would not identify
                    you personally.</li>

            </ol>
        </li>
        <li>
            <p><strong>Your rights</strong></p>
            <ol>
                <li>
                    <p>You have the right to request certain information about our collection and use of your Personal Data.
                        We will provide you with the following information:</p>
                    <ol className="alpha">
                        <li>our policies and practices (from time to time) in relation to personal data (as defined in the
                            PDPO) generally, the types of personal data held by us, and the main purposes for which personal
                            data held by us is, or is to be, used;</li>
                        <li>the categories of personal data that we have collected about you.</li>
                        <li>the categories of sources from which that personal data was collected.</li>
                        <li>the business or commercial purpose for collecting or selling your personal data.</li>
                        <li>the categories of third parties with whom we have shared your personal data.</li>
                        <li>whether we hold any of your personal data;</li>
                        <li>the specific pieces of Personal Data that we have collected about you.</li>

                    </ol>
                </li>
                <li>
                    <p>You have the right to:</p>
                    <ol className="alpha">
                        <li>request access to your personal data held by us (and be given reasons if such a request is
                            refused and to object to such refusal);</li>
                        <li>request us to correct any of your personal data held by us which is inaccurate (and be given
                            reasons if such a request is refused and to object to such refusal).</li>

                    </ol>
                </li>
                <li>
                    <p><strong>Exercising Your Rights</strong></p>
                    <ol className="alpha">
                        <li>
                            <p>To exercise the rights described above, you must send us a request to our Data Protection
                                Officer at <a href="mailto:privacy@imunis.com" target="_blank" rel="noreferrer">privacy@imunis.com</a> that:</p>
                            <ol>
                                <li>provides sufficient information to allow us to verify that you are the person about whom
                                    we have collected Personal Data;</li>
                                <li>describes your request in sufficient detail to allow us to understand, evaluate, and
                                    respond to it (including, without limitation, to locate the personal data to which the
                                    request relates);</li>
                                <li>is in English or Chinese.</li>

                            </ol>
                            <p>Each request that meets these criteria will be considered a “Valid Request”. We may not
                                respond to requests that do not meet these criteria. We will only use Personal Data provided
                                in a Valid Request to verify you and complete your request. You do not need an account to
                                submit a Valid Request.</p>
                        </li>
                        <li>
                            <p>We will work to respond to your Valid Request within 40 days of receipt. In accordance with
                                the PDPO, we have the right to charge you a reasonable fee for the processing of any
                                personal data access request. If we determine that your Valid Request warrants a fee, we
                                will notify you of the fee and explain that decision before completing your request.</p>
                        </li>

                    </ol>
                </li>

            </ol>
        </li>
        <li>
            <p><strong>Personal Data sales</strong></p>
            <ol>
                <li>We may sell your Personal Data for gain, including certain of your Personal Data for direct marketing as
                    set out in our Website personal information collection statement.</li>

            </ol>
        </li>
        <li>
            <p><strong>Cookies</strong></p>
            <ol>
                <li>We may collect your Personal Data through cookies and similar technologies such as pixel tags, web
                    beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognise your
                    web browser and tell us how and when you visit and use our Platform, to analyse trends, learn about our
                    user base and operate and improve our Platform. Cookies are small pieces of data– usually text files –
                    placed on your computer, tablet, phone, or similar device when you use that device to visit access or
                    use our Platform.</li>
                <li>We may also supplement the Personal Data and other information we collect from you with information
                    received from third parties, including third parties that have placed their own Cookies on your device.
                    For example, Google, Inc. (“Google”) uses cookies in connection with its Google Analytics services.
                    Google’s ability to use and share information collected by Google Analytics about your use of the
                    Platform is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the
                    option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page.</li>

            </ol>
        </li>
        <li>
            <p><strong>Third party links</strong></p>
            <ol>
                <li>When you click on links in our Platform, such links may direct you away from our Platform. We are not
                    responsible for the privacy practices of other websites or applications, and encourage you to read their
                    privacy statements.</li>
                <li>Once you leave our Platform, or are redirected to a third party website or application, please note that
                    those websites and/or applications do not operate under this PICS or our Terms and Conditions. We
                    recommend you examine the privacy policy posted on those other web sites and/or applications to
                    understand their procedures for collecting, using, and disclosing personal data.</li>

            </ol>
        </li>
        <li>
            <p><strong>Changes to this PICS</strong></p>
            <ol>
                <li>We are constantly trying to improve our services, so we may need to change this PICS from time to time
                    as well, but we will alert you to changes by placing a notice in the Platform, by sending you an email,
                    and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us
                    (or you have not provided us with your email address), those legal notices will still govern your use of
                    the Platform and our goods and services, and you are still responsible for reading and understanding
                    them. If you use the Platform after any changes to this PICS have been posted, that means you agree to
                    all of the changes. Where there are significant changes which require your acceptance of, or consent to,
                    the changes, we will notify you. If you do not accept the changes and/or provide your consent, then we
                    may not be able to perform the Purposes and/or provide the Platform or other goods or services to you.
                    Use of Personal Data we collect is subject to the PICS in effect at the time such information is
                    collected.</li>

            </ol>
        </li>
        <li>
            <p><strong>About us</strong></p>
            <ol>
                <li>Our address is: Suite 4, 14F CMA Building, 64 Connaught Road, Central, Hong Kong. Data Protection
                    Officer: <a href="mailto:privacy@imunis.com" target="_blank" rel="noreferrer">privacy@imunis.com</a>.</li>

            </ol>
        </li>

    </ol>
</>)
