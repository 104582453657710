import { useEffect, useState, useReducer } from "react"
import { BackButton } from "../../components/buttons/back_btn"
import { BaseContainer } from "../../components/container"
import Card from "../../components/card";
import { HeaderTitle } from "../../components/page_sections"
import { makeStyles } from "@material-ui/core"
import { axiosInstance } from "../../utils/axiosApi"
import ErrorDialog from "../../components/dialogs/error_dialog"
import { useHistory } from "react-router-dom"
import AddIcon from '@material-ui/icons/Add';
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { Button, IconButton, SmallButton } from "../../components/button"
import { TravelCountryDropdown } from "../../components/travel_country_dropdown"
import { DatePicker, toISOString } from "../../components/date_picker"
import { formatDate, TDateISODate } from "../../utils/common";
import TrashIcon from "../../assets/icons/trash";
import { saveFile } from "../../utils/file_download";
import countries from "i18n-iso-countries"
import imunisCountries from "../../components/imunisCountries.json"

countries.registerLocale(imunisCountries)

type Destination = {
    country: string
    from_date: TDateISODate
    to_date: TDateISODate
}

type Itinerary = {
    destinations: Array<Destination>;
};

const itinerary: Itinerary = {
    destinations: []
};

type CertInformation = {
    country: string,
    min_age: string,
    risk_only: boolean,
}

type DiseaseInfo = {
    disease: string,
    certificate: string,
    booster: string,
    emergency: string,
}

type DiseaseStatus = {
    recommended: DiseaseInfo[];
    suggested: DiseaseInfo[];
    risk: DiseaseInfo[];
    risk_countries: string[];
    cert_required: CertInformation[];
}

let diseaseStatus: DiseaseStatus = {
    recommended: [],
    suggested: [],
    risk: [],
    risk_countries: [],
    cert_required: [],
};

type ItineraryResponse = DiseaseStatus


const useStyles = makeStyles((theme) => ({
    text: {
        margin: theme.spacing(1, 0)
    },
    section: {
        margin: theme.spacing(3, 0)
    },
    link: {
        color: "#FF5300",
        cursor: "pointer",
        "font-weight": 600,
        "text-decoration": "underline",
    }
}))

export const TravelItinerary = () => {
    const history = useHistory()

    const [isFormValid, setIsFormValid] = useState(itinerary.destinations.length > 0)
    const [loading, setLoading] = useState(false)
    const [_ignored, forceUpdate] = useReducer((x: number) => x + 1, 0)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    function dispatchData() {
        axiosInstance.post<ItineraryResponse>(`/travel/itinerary/`, itinerary)
            .then((response) => {
                diseaseStatus = response.data
                history.push(`/travel/vaccines`)
            }).catch(error => {
                console.error(error)
                setError('Cannot get vaccination information, please retry');
                setShowError(true)
            }).finally(() => {
                setLoading(false)
            })
    }

    function handleSubmit() {
        setLoading(true)
        dispatchData()
    }

    function removeDestination(index: number) {
        itinerary.destinations.splice(index, 1);
        if (itinerary.destinations.length == 0){
            setIsFormValid(false);
        }
        forceUpdate();
    }

    let cardList = null
    if (itinerary.destinations.length > 0) {
        cardList = itinerary.destinations.map((destination, index) =>
            <Card
                key={index}
                title={countries.getName(destination.country, "en-imunis")}
                subtitle={`${formatDate(destination.from_date) || ''} - ${formatDate(destination.to_date) || ''}`}
                extra={
                    <IconButton
                        className="trash-icon"
                        size="small"
                        aria-label="Remove destination"
                        onClick={() => {
                            removeDestination(index)
                        }}
                    >
                        <TrashIcon />
                    </IconButton>
                }
            />
        );
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                    Continue
                </Button>
            </StickyFooterGroup>
        }>

            <BackButton onClick={() => {
                history.push('/home')
            }} />

            <HeaderTitle>
                Create Travel Itinerary
            </HeaderTitle>

            <p>Create a travel itinerary, and download a PDF with
                a list of the vaccinations you may be recommended
                to take ahead of travel, including guidance on yellow fever
                and other vaccination certificates.</p>

            <p>
                When creating an itinerary, please add departure and final
                destination areas, and any stop-overs, even if you will not pass
                through immigration, as you may be asked to provide vaccination
                proofs in some areas.
            </p>

            {cardList}

            <SmallButton
                loadingProgress={0}
                primary
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push('/travel/add-destination')
                }}>
                Add Destination
            </SmallButton>

            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />

        </BaseContainer>
    )
}

export const TravelAddDestination = () => {

    const history = useHistory()

    const [country, setCountry] = useState('')
    const [fromDate, setFromDate] = useState<string | null>(null)
    const [toDate, setToDate] = useState<string | null>(null)
    const [isFormValid, setIsFormValid] = useState(false)

    // const { state, dispatch } = useRecordContext(RecordContext)

    useEffect(() => {
        if (country && fromDate && toDate) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [country, fromDate, toDate])

    function handleSubmit() {
        const destination: Destination = {
            country: country,
            from_date: fromDate as TDateISODate,
            to_date: toDate as TDateISODate,
        }
        itinerary.destinations.push(destination)
        history.push(`/travel/itinerary`)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} disabled={!isFormValid}>
                    Add Destination
                </Button>
            </StickyFooterGroup>
        }>

            <BackButton onClick={() => {
                history.push('/travel/itinerary')
            }} />

            <HeaderTitle>
                Add Itinerary Destination
            </HeaderTitle>

            <TravelCountryDropdown
                value={country}
                onChange={(countryCode) => {
                    setCountry(countryCode)
                }}
            />

            <DatePicker
                fullWidth
                name="fromDate"
                margin="normal"
                openTo="year"
                label="From Date"
                views={["year", "month", "date"]}
                placeholder={'dd MMM yyyy'}
                value={fromDate}
                helperText=''
                onChange={(fromDate) => setFromDate(fromDate ? toISOString(fromDate) : null)}
                required
            />

            <DatePicker
                fullWidth
                name="toDate"
                margin="normal"
                openTo="year"
                label="To Date"
                views={["year", "month", "date"]}
                placeholder={'dd MMM yyyy'}
                value={toDate}
                helperText=''
                onChange={(toDate) => setToDate(toDate ? toISOString(toDate) : null)}
                required
            />

        </BaseContainer>
    )

}

export const ItineraryVaccines = () => {
    const classes = useStyles()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    let recommendedList = null
    recommendedList = diseaseStatus.recommended.map((disease_info, _index) =>
        <li key={disease_info.disease}>{disease_info.disease}{disease_info.emergency && <> (Emergency)</>}{disease_info.booster && <> (Booster)</>}{disease_info.certificate && <> (including official vaccination record)</>}</li>
    )

    let suggestedList = null
    suggestedList = diseaseStatus.suggested.map((disease_info, _index) =>
        <li key={disease_info.disease}>{disease_info.disease}{disease_info.emergency && <> (Emergency)</>}{disease_info.booster && <> (Booster)</>}</li>
    )

    let riskList = null
    riskList = diseaseStatus.risk.map((disease_info, _index) =>
        <li key={disease_info.disease}>{disease_info.disease}{disease_info.emergency && <> (Emergency)</>}{disease_info.booster && <> (Booster)</>}</li>
    )

    let riskCountries = null
    riskCountries = diseaseStatus.risk_countries.map((country, _index) =>
        <li key={country}>{country}</li>
    )

    let certCountries = null
    certCountries = diseaseStatus.cert_required.map((cert_info, _index) =>
        <li key={cert_info.country}>{cert_info.country} - {cert_info.min_age} {cert_info.risk_only && <><br /><span><i>(if arriving from transmission-risk area)</i></span></>}</li>
    )

    // min_age: string,
    // risk_only: boolean,

    function downloadPDF() {
        setLoading(true)
        axiosInstance.post(`/travel/itinerary/pdf/`, itinerary, {
            responseType: 'arraybuffer',
        })
            .then(({ data }) => {
                const fileName = "travel-vaccines.pdf"
                // const fileName = headers['content-disposition'].match(/filename="(?<filename>.+)"/).groups.filename
                return saveFile(data as string, fileName as string, 'application/pdf')
            }).catch(error => {
                console.error(error)
                setError('Error downloading PDF');
                setShowError(true)
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={downloadPDF} loading={loading}>
                    Download PDF
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton onClick={() => {
                history.push('/travel/itinerary')
            }} />
            <h2>Travel Vaccinations</h2>

            <p>
                Please read <a target="_blank" rel="noreferrer" href="https://imunis.com/vaccination_guide_content.pdf" className={classes.link}>here</a> for important information about travel vaccination and how to use this guide.
            </p>

            <h4>
                Vaccinations recommended for most travellers (excluding yellow fever)
            </h4>

            <ul>
                {recommendedList.length > 0 && recommendedList}
                {recommendedList.length == 0 && <p>No vaccinations listed</p>}
            </ul>

            <h4>
                Vaccinations recommended for some travellers depending on circumstances (excluding yellow fever)
            </h4>

            <ul>
                {suggestedList.length > 0 && suggestedList}
                {suggestedList.length == 0 && <p>No vaccinations listed</p>}
            </ul>

            <h4>
                Yellow fever vaccination
            </h4>
            <h4>
                Itinerary area(s) with a risk of yellow fever transmission & recommendation to vaccinate:
            </h4>
            <ul>
                {riskCountries.length > 0 && riskCountries}
                {riskCountries.length == 0 && <p>No countries</p>}
            </ul>
            <h4>
                Itinerary areas with yellow fever certification requirements in place (including minimum age):
            </h4>
            <ul>
                {certCountries.length > 0 && certCountries}
                {certCountries.length == 0 && <p>No countries</p>}
            </ul>

            <h4>
                Other disease risk in travel itinerary destinations:
            </h4>
            <ul>
                {riskList.length > 0 && riskList}
                {riskList.length == 0 && <p>No confirmed risk of dengue, malaria, middle eastern respiratory syndrome (MERS) or zika virus</p>}
            </ul>

            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />

        </BaseContainer>
    )
}


