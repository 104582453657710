import { forwardRef, useEffect, useState } from "react";
import Grid, {GridSize} from '@material-ui/core/Grid'
import Typography from './typography'
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse, { CollapseProps } from '@material-ui/core/Collapse';
import { TwoLineText } from './two_line_text';
import { cx } from '../utils/common';

const useStyles = makeStyles<Theme, CardProps>((theme) => createStyles({
    card: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '12px',
        margin: theme.spacing(1.5, 0),
        padding: theme.spacing(2),
        cursor: props => { if (props.onClick) return 'pointer' },
    },
    cardInner: {
        cursor: props => { if (props.content) return 'pointer' }
    },
    extra: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        cursor: props => { if (props.content) return 'pointer' }
    },
    center: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    expand: {
        color: theme.palette.grey[600],
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    header: {
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr fit-content(50%)',
        gap: theme.spacing(1),
        color: ({disable}) => {
            if (disable) return theme.palette.grey[600]
            return theme.palette.grey[800]
        }
    },
    highlight: {
        position: 'relative',
        "&::before": {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundColor: ({ highlight }) => {
                switch (highlight) {
                    case 'neutral':
                        return theme.palette.grey[200]
                    case 'success':
                        return theme.palette.success.light
                    case 'error':
                        return theme.palette.error.light
                }
            },
        }
    },
}))

type CardProps = {
    /** Title of card. Required if `children` is not provided */
    title?: string,
    subtitle?: string,

    /** Disable show grey border and text */
    disable?: boolean

    /**
     * Collapsible content to display under the card.
     * Providing this also displays an expand/collapse icon on the card header
     */
    content?: React.ReactNode,

    /**
     * Card contents will be padded to the Card's border by default.
     * In most cases this is desired except when we need highlighting on disease vaccine events.
     * Passing props to the parent <Collapse> allows us to customise this.
     */
    collapseProps?: CollapseProps,

    /** A component displayed on the left of the card */
    icon?: React.ReactNode,

    /**
     * `caption` and `extra` content is displayed on the right of the card.
     * The subtle difference between these is when `content` is also provided;
     * - `caption` content will be displayed next to the expand/collapse chevron icon
     * - `extra` content will replace the icon
     */
    caption?: React.ReactNode,

    /**
     * @deprecated We should simplify the API; use `caption` everywhere with an additional prop
     * ("contentChevron=false") to explicitly set the behaviour for the health page Disease cards.
     */
    extra?: React.ReactNode

    /** Callback fired when the expand/collapse state is changed */
    onChange?: (expanded: boolean) => void

    defaultOpen?: boolean,

    /** Contents of card. Required if none of the above props are used */
    children?: React.ReactNode

    onClick?: React.MouseEventHandler

    className?: string

    /** Sets a background highlight effect on the card */
    highlight?: 'success' | 'neutral' | 'error'
}

const Card = forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
    const [showContent, setShowContent] = useState(props.defaultOpen || false)
    const classes = useStyles(props)
    let mainContainerSize: GridSize = 12;

    // re-assigning because type GridSize can't be modified directly
    if (props.icon) mainContainerSize = 11

    useEffect(() => {
        if (typeof props.defaultOpen === 'boolean') {
            setShowContent(props.defaultOpen)
        }
    }, [props.defaultOpen])

    let containerProps = {};
    if (props.content) {
        containerProps = {
            onClick: () => {
                if (props.onChange) props.onChange(!showContent)
                setShowContent(!showContent)
            },
            "data-action": showContent ? "Collapse card" : "Expand card",
        }
    } else if (props.onClick) {
        containerProps = {
            onClick: props.onClick,
            "data-action": 'Card'
        }
    }

    if (props.children) {
        return (
            <div className={cx('card', classes.card, props.className)} ref={ref} {...containerProps}>
                { props.children }
            </div>
        )
    } else {
        return (
            <div
                className={cx('card', classes.card, props.className, props.highlight && classes.highlight)}
                ref={ref}
                {...(props.highlight && {'aria-current':true })}
            >
                <Grid container spacing={1} className={classes.cardInner} {...containerProps}>
                    {props.icon &&
                        <Grid item xs={1}>
                            <div className={classes.icon}>
                                {props.icon}
                            </div>
                        </Grid>
                    }
                    <Grid item xs={mainContainerSize}>
                        <div className={classes.center}>
                            <Grid container>
                                <div className={classes.header}>
                                    {props.title && <TwoLineText>
                                        <Typography variant="nameLabelMedium" className="card-title">
                                            {props.title}
                                        </Typography>
                                    </TwoLineText>}
                                    <div className={classes.extra}>
                                        {props.caption}
                                        {!props.extra && props.content &&
                                            <ExpandMore className={cx(classes.expand, showContent && classes.expandOpen)}/>
                                        }
                                        {props.extra && props.extra}
                                    </div>
                                </div>
                                {props.subtitle}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Collapse in={showContent} {...props.collapseProps}>
                    {props.content || null}
                </Collapse>
            </div>
        )
    }
})

export default Card
