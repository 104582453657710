import { makeStyles, Theme, createStyles, Box, SvgIconProps } from "@material-ui/core"
import { TwoLineText } from "./two_line_text";
import Typography from "./typography";
import { SvgIconComponent } from "@material-ui/icons";
import { cx } from "../utils/common";

type Props = {
    children: React.ReactNode
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    titleWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    descriptionWrapper: {
        marginBottom: theme.spacing(3),
    },
    sectionWrapper: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
    },
    subsectionWrapper: {
        marginBottom: theme.spacing(2)
    },
    drawerSection: {
        paddingTop: theme.spacing(2),
    },
    headerIcon: {
        fontSize: '42px',
        display: 'block',
        margin: theme.spacing(1, 'auto', 2)

    }
}));

export const ModalTitle = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.titleWrapper}>
            <Typography variant="modalHeader">
                {children}
            </Typography>
        </div>
    )
}

export const PageTitle = ({
    children, icon
}: {
    children: React.ReactNode
    icon?: JSX.Element
}) => {
    return (
        <Box display="flex" alignItems="center" gridGap={8} mb={1.5}>
            {icon}
            <TwoLineText>
                <Typography variant="modalHeader">{children}</Typography>
            </TwoLineText>
        </Box>
    )
}

export const HeaderTitle = ({children}: Props) => {
    const classes = useStyles()
    return (
        <Typography variant="onboardingHeader" className={classes.titleWrapper} component="h1">
            {children}
        </Typography>
    )
}

export const HeaderIcon = ({icon: Icon, ...props}: {icon: SvgIconComponent} & SvgIconProps) => {
    const classes = useStyles()
    return (
        <Icon className={cx(classes.headerIcon, props.className)} />
    )
}

export const PageDescription = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.descriptionWrapper}>
            {children}
        </div>
    )
}

export const PageSection = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.sectionWrapper}>
            {children}
        </div>
    )
}

export const PageSubsection = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.subsectionWrapper}>
            {children}
        </div>
    )
}

export const DrawerSection = ({children}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.drawerSection}>
            {children}
        </div>
    )
}
