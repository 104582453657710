import { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { IconButton } from '../button';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import TickCircleIcon from '../../assets/icons/tick_circle';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    dialog: {
        position: 'relative',
        padding: theme.spacing(4, 2, 2, 2),
    },
    successDialog: {
        position: 'relative',
        padding: theme.spacing(5.5, 2, 2, 2),
    },
    button: {
        margin: theme.spacing(1, 0)
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    }
}))


const Dialogs = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const [openPopup, setOpenPopup] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)

    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Dialog maxWidth="xs" onClose={() => setOpenPopup(false)} open={openPopup}>
                <Paper className={classes.dialog}>
                    <IconButton onClick={() => setOpenPopup(false)} className={classes.closeButton}> <CloseIcon fontSize="small"/></IconButton>
                    <DialogTitle>
                        <Typography style={{fontWeight: 600}} variant="body1" align="center">Perform action?</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom variant="body2" align="center">Are you sure you want to perform this action? Once the action is performed you will no longer be able to perform the same action again</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Box width="100%" display="flex" flexDirection="column">
                            <Button onClick={() => { setOpenPopup(false); setOpenSuccess(true) }} fullWidth className={classes.button} variant="contained" color="primary">Got it</Button>
                            <Button onClick={() => setOpenPopup(false)} fullWidth className={classes.button} variant="outlined">Cancel</Button>
                        </Box>
                    </DialogActions>
                </Paper>
            </Dialog>
            <Dialog maxWidth="xs" onClose={() => setOpenSuccess(false)} open={openSuccess}>
                <Paper className={classes.successDialog}>
                    <IconButton onClick={() => setOpenSuccess(false)} className={classes.closeButton}> <CloseIcon fontSize="small"/></IconButton>
                    <Typography align="center"><TickCircleIcon strokecolor={theme.palette.success.main} fontSize="large"/></Typography>
                    <DialogTitle>
                        <Typography style={{fontWeight: 600}} variant="body1" align="center">Action was successfully executed</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpenSuccess(false)} fullWidth className={classes.button} variant="contained" color="primary">Got it</Button>
                    </DialogActions>
                </Paper>
            </Dialog>
            <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" flex={1} margin={1}>
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            Dialogs
                        </Typography>
                        <Box marginTop={1}>
                            <Button variant='outlined' onClick={() => setOpenPopup(true)}>Popup</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dialogs
