import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Button, IconButton } from '../button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import TickCircleIcon from '../../assets/icons/tick_circle';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    dialog: {
        position: 'relative',
        padding: theme.spacing(4, 2, 2, 2),
    },
    button: {
        margin: theme.spacing(1, 0)
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    },
    iconWrapper: {
        textAlign: 'center'
    },
    successIcon: {
        margin: `${theme.spacing(2)}px auto`,
    }
}))

/**
 * Usage in other components/pages:
 *
 * 1. set up the required state hook: const [success, setSuccess] = useState('')
 * 2. Put the SuccessDialog at the bottom of your component: <SuccessDialog success={success} setSuccess={setSuccess} open={open} setOpen={setOpen} dismiss_label={label} dismiss_callback={() => {...}} />
 * 3. If you want extra callback to be called after dismissing the dialog, pass in the callback via the dismiss_callback prop
 */
const SuccessDialog = (props) => {
    const classes = useStyles()
    const theme = useTheme()

    const dismiss = () => {
        props.setOpen(false)
        if (typeof props.dismiss_callback === 'function') {
            props.dismiss_callback()
        }
    }

    return (
        <Dialog maxWidth="xs" open={props.open}>
            <Paper className={classes.dialog}>
                <IconButton onClick={() => dismiss()} className={classes.closeButton}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
                <Typography align="center" style={{color: theme.palette.success.main}}>
                    <TickCircleIcon fontSize="large" />
                </Typography>
                <DialogContent>
                    <Typography style={{fontWeight: 600}} variant="body1" align="center">
                        {props.success}
                    </Typography>
                    {props.content && <Typography style={{marginTop: '16px', marginBottom: '8px'}} variant='body2' align='center'>{props.content}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Button onClick={dismiss} fullWidth className={classes.button} primary>
                            {props.dismiss_label || 'Got it'}
                        </Button>
                    </Box>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}

export default SuccessDialog
