import { useRef, useState } from "react"
import { Document, Page } from 'react-pdf'
import { DocumentCallback } from "react-pdf/src/shared/types"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import Loading from "./loading"

import 'pdfjs-dist/webpack.mjs'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

export const PdfEmbed = ({document}: {
    document: File
}) => {
    const [loading, setLoading] = useState(true)
    const [pageContents, setPageContents] = useState<JSX.Element[] | null>(null)

    const containerRef = useRef<HTMLDivElement>(null)

    const onDocumentLoadSuccess = ({ numPages }: DocumentCallback) => {
        setPageContents(
            Array.from(
                new Array(numPages),
                (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        devicePixelRatio={2}
                        scale={2}
                        width={containerRef?.current?.offsetWidth || 0}
                        onRenderSuccess={() => {
                            if (index + 1 === numPages) {
                                setLoading(false)
                            }
                        }}
                    />
                )
            )
        )
    }

    return (
        <div ref={containerRef} style={{
            overflow: 'hidden',
        }}>
            <Loading loading={loading} />
            <TransformWrapper
                minScale={0.5}
                initialScale={0.5}
                maxScale={2}
            >
                <TransformComponent
                    wrapperStyle={{
                        width: '100%',
                        height: '100%',
                    }}>
                    <Document file={document} onLoadSuccess={onDocumentLoadSuccess} loading="">
                        {pageContents}
                    </Document>
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
}
