import { useHistory, useLocation } from "react-router-dom"
import { Button } from "../components/button"
import { BaseContainer } from "../components/container"
import { HeaderTitle, PageDescription } from "../components/page_sections"
import { StickyFooterGroup } from "../components/sticky_footer_group"
import { PendingDependant } from "./connections/onboard_adult"
import Action from "../components/action"
import { CenteredIconHeader, LogoHeader } from "../components/header"
import { makeStyles } from "@material-ui/core"
import { useState } from "react"
import { pluralString } from "../utils/common"
import TickCircleIcon from "../assets/icons/tick_circle"
import ActionDialog from "../components/dialogs/action_dialog"
import ClinicHouseIcon from "../assets/icons/clinic_house"

export const isMessageSquelched = () => {
    const squelchedUntil = localStorage.getItem('pending_connections_reminder')

    if (!squelchedUntil) return false

    return new Date() < new Date(Number(squelchedUntil))
}

const squelchMessage = () => {
    const d = new Date()
    d.setDate(d.getDate() + 3)

    localStorage.setItem('pending_connections_reminder', d.getTime().toString())
}

const reminderContent = (dependantsCount: number) => {
    if (dependantsCount === 1) {
        return 'You have an open invitation to connect a dependant in your care. Complete the connection to access their records via Imunis.'
    } else {
        return 'You have open invitations to connect dependants in your care. Complete the connections to access their records via Imunis.'
    }
}


export const InvitedDependantsDashboard = () => {
    const dependants = useLocation<{pendingConnections: PendingDependant[]}>().state.pendingConnections || []
    const history = useHistory()

    const title = pluralString('Pending clinic connection', dependants.length)

    if (dependants.length === 0) {
        return (
            <BaseContainer header={<LogoHeader/>} showCta extraComponent={
                <StickyFooterGroup>
                    <Button primary onClick={() => history.push('/home')}>
                        Continue
                    </Button>
                </StickyFooterGroup>
            }>
                <HeaderTitle>
                    {title}
                </HeaderTitle>

                <PageDescription>
                    You have no pending clinic connections
                </PageDescription>
            </BaseContainer>
        )
    }

    const content = reminderContent(dependants.length)
    const actions = dependants.map(d => (
        <Action
            key={d.uuid}
            type="clinic"
            title={`Connect ${d.full_name} to ${d.organisation}`}
            on_click={() => history.push('/connection/dependant/consent', {
                invite_type: 'clinic',
                ...d
            })} />
    ))

    const confirmSkip = () => {
        squelchMessage()
        history.replace('/home')
    }

    return (
        <BaseContainer header={<LogoHeader/>} showCta extraComponent={
            <StickyFooterGroup>
                <Button onClick={confirmSkip}>
                    Remind me later
                </Button>
            </StickyFooterGroup>
        }>
            <HeaderTitle>
                {title}
            </HeaderTitle>

            <PageDescription>
                {content}
            </PageDescription>

            <PageDescription>
                Connecting only takes a moment and requires the date of birth of the dependant.
            </PageDescription>

            {actions}
        </BaseContainer>
    )
}


const useStyles = makeStyles((theme) => ({
    successIcon: {
        color: theme.palette.success.main,
    },
}))

export const InvitedDependantsPostOnboarding = () => {
    const { organisation, dependants } = useLocation<{
        organisation: string
        dependants: PendingDependant[]
    }>().state || {}

    const [showConfirmation, setShowConfirmation] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    const content = reminderContent(dependants.length)

    const actions = dependants.map(d => (
        <Action
            key={d.uuid}
            type="clinic"
            title={`Connect ${d.full_name} to ${d.organisation}`}
            on_click={() => history.push('/connection/dependant/consent', {
                invite_type: 'clinic',
                ...d
            })} />
    ))

    const confirmSkip = () => {
        squelchMessage()
        history.replace('/home')
    }

    return (
        <BaseContainer header={
            <CenteredIconHeader icon={<TickCircleIcon className={classes.successIcon}/>} />
        } showCta extraComponent={
            <StickyFooterGroup>
                <Button onClick={() => setShowConfirmation(true)}>
                    Remind me later
                </Button>
            </StickyFooterGroup>
        }>

            <HeaderTitle>
                Connection successful
            </HeaderTitle>

            <PageDescription>
                You have successfully connected to {organisation}
            </PageDescription>

            <PageDescription>
                {content}
            </PageDescription>

            {actions}

            <ActionDialog
                icon={<ClinicHouseIcon style={{fontSize: '44px'}}/>}
                title={pluralString("Pending connection", actions.length)}
                content={<>
                    You have {actions.length > 1 ? "pending dependant connections" : "a pending dependant connection"}
                    <br/><br/>
                    These can be viewed and connected at any time by following the link on the Imunis homepage
                </>}
                action_label="Continue"
                openDialog={showConfirmation}
                setOpenDialog={setShowConfirmation}
                action_callback={confirmSkip}
            />
        </BaseContainer>
    )
}
