import { useState, useEffect, useContext, forwardRef, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing, display, flexbox, borders } from '@material-ui/system';
import styled from 'styled-components';
import { BaseContainer } from "../../components/container";
import ErrorDialog from "../../components/dialogs/error_dialog";
import UserTabs from './user_tabs'
import { UserHeader, UserContext } from "./user_header";
import { VaccinationStatus } from "../../components/vaccination_status";
import Typography from '../../components/typography';
import { cx, formatDate, formatDuration, logEvent } from '../../utils/common'
import Loading from '../../components/loading';
import VaccineRecord from "../health/vaccine_record";
import Card from "../../components/card";
import ListItem from "../../components/lists/list_item";
import { Fab, SmallButton } from "../../components/button";
import { Divider } from "../../components/divider";
import GroupIcon from "../../assets/icons/group_icon";
import CalendarIcon from "../../assets/icons/calendar_icon";
import EmptyList from "../../components/empty_list";
import DropdownMenu from "../../components/dropdownmenu";
import ExpandMore from '@material-ui/icons/ExpandMore';
import { VaccineInfo } from "../../components/vaccine_info";
import ShieldIcon from "../../assets/icons/shield_icon";
import PastRecords from "../health/past_records";
import { Drawer, DrawerTrigger } from "../../components/drawer";
import { DiseaseInfo } from "../../components/disease_info";
import { SourceModal } from "../../components/source_modal";
import DropDownMenuIcon from "../../assets/icons/dropdown";
import InformationCircleSolid from "../../assets/icons/information_circle_solid";
import AddIcon from '@material-ui/icons/Add';
import TermsFilledIcon from "../../assets/icons/terms_filled";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import Grid from '@material-ui/core/Grid';
import NewsIcon from "../../assets/icons/news";
import { CardSubtitle } from "../../components/card_components"
import { saveFile } from "../../utils/file_download";
import { AddRecordModal } from "../../components/add_record_modal";
import { diseaseGroupInfo } from "../../components/disease_group_info";
import { DrawerContent } from "../../components/drawer_content";
import { isCancel } from "axios";
import { VaccineEventIcon, conflictLabel, vaccineEventName } from "./utils";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const diseaseStyles = makeStyles((theme) => ({
    shieldIcon: {
        marginLeft: '4px',
        verticalAlign: 'middle',
        fontSize: '24px',
        color: theme.palette.success.main,
    },
    cardDivider: {
        marginLeft: theme.spacing(-2),
        width: `calc(100% + ${theme.spacing(4)}px)`
    },
    expand: {
        fontSize: '24px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    recordsListWrapper: {
        // Undo the padding set on the content by <Card>
        margin: theme.spacing(0, -2),
    },
    recordsListItem: {
        // Re-apply the padding set on the content
        padding: theme.spacing(0, 2)
    },
    recordsList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
    },
    switchText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 400,
        textDecoration: 'underline',
    },
}))

const RecordsList = (({
    disease, vaccinations, onSelect, scanCovidAction, openPastRecords, switchDisease, addedRecords
}) => {
    const classes = diseaseStyles();
    const { vaccineEvents, sources } = useContext(UserContext)

    // We don't have a proper setup for seasonal diseases, hack this for now
    const isSeasonalDisease = disease === '6142004-NORTH' || disease === '6142004-SOUTH'

    const relatedDiseases = {
        '6142004-NORTH': ['6142004-SOUTH', 'Influenza (South)'],
        '6142004-SOUTH': ['6142004-NORTH', 'Influenza (North)']
    }
    const relatedDisease = relatedDiseases[disease]

    let switchDiseaseLink = null;
    if (relatedDisease) {
        const [relatedDiseaseId, relatedDiseaseName] = relatedDisease
        switchDiseaseLink = (
            <Typography align="center" variant="bodyTextSmall" className={classes.switchText}
                onClick={() => switchDisease(relatedDiseaseId)} role="button">
                {`View ${relatedDiseaseName}`}
            </Typography>
        )
    }

    const activeVaccinations = vaccinations.filter(v => {
        if (isSeasonalDisease) {
            return v.in_season
        } else {
            return true
        }
    })

    return (
        <div id={`records-list-${disease}`} className={classes.recordsList}>
            <Divider />
            {vaccinations.length ? activeVaccinations.map((vaccine_info) => {
                // `vaccine_info` may also contain annotations for the vaccine event per disease.
                // Merge these in.
                const vaccine = {
                    ...vaccine_info,
                    ...vaccineEvents[vaccine_info.key],
                }

                const addedRecord = addedRecords.has(vaccine_info.key)
                const isEmergency = vaccine_info.is_emergency

                const title = <>
                    {formatDate(vaccine.vaccine_date)}
                    {isEmergency && <>
                        {' '}
                        <Typography component="span" variant="bodyTextMediumBold">
                            (Emergency)
                        </Typography>
                    </>}
                    {conflictLabel(vaccine)}
                </>

                let highlight
                if (vaccine.unspecified_product) {
                    if (vaccine.unspecified_product_paired) {
                        highlight = 'neutral'
                    } else {
                        highlight = 'error'
                    }
                } else if (addedRecord) {
                    highlight = 'success'
                }

                return (
                    <ListItem
                        key={vaccine_info.key}
                        className={classes.recordsListItem}
                        title={title}
                        subtitle={vaccineEventName(vaccine, sources)}
                        onClick={() => onSelect(vaccine)}
                        highlight={highlight}
                        extra={VaccineEventIcon(vaccine, sources)}
                        divider
                    />
                )
            }) : <>
                {scanCovidAction && <Box mt={1.5} mb={1.5}>
                    <Typography align="center" variant="bodyTextSmall">No available records</Typography>
                </Box>}
            </>
            }
            {scanCovidAction &&
                <Box mt={1.5} mb={1.5}>
                    <SmallButton onClick={scanCovidAction} startIcon={<AddIcon/>}>
                        Add new record
                    </SmallButton>
                </Box>
            }
            {!!switchDiseaseLink &&
                <Box mt={1.5} mb={1.5}>
                    <Box mb={2}>
                        <SmallButton
                            onClick={() => openPastRecords(disease)}
                            startIcon={<TermsFilledIcon/>}
                        >
                            View past records
                        </SmallButton>
                    </Box>
                    {switchDiseaseLink}
                </Box>
            }
        </div>
    )
})

const Disease = forwardRef(function Disease(props, ref) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const classes = diseaseStyles();

    const { disease, canAddCovidRecord } = props

    const openDisease = (e) => {
        const eventData = {
            disease_name: disease.name,

            // To not break current DD notebooks we'll continue sending this. Once we have
            // sufficient data in both formats (30 days), this can be removed.
            disease: {
                name: disease.name,
                status: disease.vaccine_status,
            }
        }

        if (disease.vaccine_status) {
            eventData.action_status = disease.vaccine_status.action_status
            eventData.vaccine_status = disease.vaccine_status.vaccine_status
        }

        logEvent('open_vaccination_status', eventData)
        e.preventDefault()
        e.stopPropagation()
        setOpenDrawer(true)
    }

    const closeDisease = (e) => {
        if (e) e.stopPropagation()
        setOpenDrawer(false)
    }

    const status = disease.vaccine_status

    const canExpand = (
        disease.vaccine_events.length ||
        (disease.target_disease === '840539006' && canAddCovidRecord) ||
        disease.target_disease === '6142004-NORTH' ||
        disease.target_disease === '6142004-SOUTH'
    )

    let title = disease.name
    if (status?.vaccine_status == 'full_plus_delay') {
        title = <>
            <span className={classes.shieldText}>{title}</span>
            <ShieldIcon className={classes.shieldIcon} />
        </>
    }

    return <>
        <Card
            ref={ref}
            title={title}
            subtitle={
                <Box width="100%">
                    <Divider className={classes.cardDivider} />
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <VaccinationStatus
                            actionStatus={status?.action_status}
                            dosesTaken={status?.doses_taken}
                            dosesTotal={status?.doses_total}
                            vaccineEventsCount={disease.vaccine_count} />
                        <Box display="flex" alignItems="center">
                            {canExpand &&
                                <DropDownMenuIcon
                                    className={cx(classes.expand, props.isOpen && classes.expandOpen)}
                                    fill="#E7E7E7"
                                />
                            }
                        </Box>
                    </Box>
                </Box>
            }
            extra={
                <SmallButton onClick={openDisease}
                    startIcon={<InformationCircleSolid color="primary"/>}
                >
                    My status
                </SmallButton>
            }
            content={canExpand && <RecordsList
                disease={disease.target_disease}
                vaccinations={disease.vaccine_events}
                onSelect={props.onSelect} // Clicking a vaccine record
                scanCovidAction={
                    disease.target_disease === '840539006' && canAddCovidRecord && props.onClick
                }
                openPastRecords={props.openPastRecords}
                switchDisease={props.switchDisease}
                addedRecords={props.addedRecords}
            />}
            collapseProps={{
                classes: {
                    root: classes.recordsListWrapper
                }
            }}
            defaultOpen={props.isOpen}
            onChange={() => props.toggleCard(disease.target_disease)}
        />
        <Drawer
            open={openDrawer}
            ModalProps={{
                BackdropProps: {
                    "aria-label": "Close Drawer",
                }
            }}
            onClose={closeDisease}
        >
            <VaccineInfo
                title={disease.name}
                diseaseId={disease.target_disease}
                vaccinationStatus={status}
            >
                <DiseaseInfo
                    disease={disease.target_disease}
                    status={status}
                />
            </VaccineInfo>
        </Drawer>
    </>
})

const SortingType = {
    DISEASE: 'disease',
    TIME: 'time',
}

const sortingLabel = (sort) => {
    switch (sort) {
        case SortingType.DISEASE:
            return 'diseases'

        case SortingType.TIME:
            return 'time'
    }
}

const sortingItems = Object.values(SortingType).map(sort => {
    let icon
    switch (sort) {
        case SortingType.DISEASE:
            icon = GroupIcon
            break
        case SortingType.TIME:
            icon = CalendarIcon
            break
    }
    const label = `View by ${sortingLabel(sort)}`

    return {
        id: sort,
        title: label,
        'data-action': label,
        icon,
    }
})

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.grey[600],
    },
    group: {
        display: 'inline-block',
    },
    groupHeader: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(0, -3),
        position: 'sticky',
        top: -1,
        background: theme.palette.background.default,
        zIndex: 1,
        transition: theme.transitions.create('box-shadow', {
            duration: theme.transitions.duration.short,
        }),

        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),

        clipPath: 'inset(-8px 0px)',
        '&.stuck': {
            boxShadow: theme.shadows[3],
        }
    },
    groupHeaderReadMore: {
        whiteSpace: 'nowrap',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}))

/**
 * Returns a combination of fields from the vaccine event and source record for the vaccine details
 * page. Note vaccineEvent may itself be merged with "disease.vaccine_event"
 */
export const mergeVaccineRecords = (id, vaccineEvent, sourceRecord) => {
    return {
        id,
        // Fields from the sourceRecord are preferred over the vaccineEvent in most cases
        ...vaccineEvent,
        ...sourceRecord,

        // With exceptions;
        // Prefer the vaccine event name (IM-2134), but it may not always be available
        // (e.g. in generic/conflict cases)
        medical_product_friendly:
            vaccineEvent.medical_product_friendly ||
            sourceRecord.medical_product_friendly,
    }
}

export const HealthPage = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const [addedRecords, setAddedRecords] = useState(new Set())

    const [currentDisease, setCurrentDisease] = useState('')
    const [openedDiseases, setOpenedDiseases] = useState(new Set())

    const [vaccineSource, setVaccineSource] = useState({})
    const [showSources, setShowSources] = useState(false)
    const [showAddRecord, setShowAddRecord] = useState(false)
    const [sourceList, setSourceList] = useState([])
    const [pdfLoading, setPdfLoading] = useState(false)
    const diseaseCardRefs = useRef({})
    const hasScrolled = useRef(false)

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const param = new URLSearchParams(location.search).get('view')
    const [sortBy, setSortBy] = useState(param === SortingType.TIME ? SortingType.TIME : SortingType.DISEASE)

    // Create an observer for the sticky disease headers
    const observer = new IntersectionObserver((entries) => {
        entries.map(e => {
            if (e.boundingClientRect.top < 0) {
                e.target.classList.add('stuck')
            } else {
                e.target.classList.remove('stuck')
            }
        })
    }, { threshold: [1] })

    const {
        user, loading,
        error, setError, clearError,
        reloadVaccines, grouping, vaccineEvents, sources, diseases
    } = useContext(UserContext)

    // If viewing a vaccine without vaccine data in state, go to health page.
    useEffect(() => {
        if (props.match.params.tab === 'vaccine' && !props.location.state?.vaccine) {
            console.warn('Vaccine data not available')
            history.push(`/records/${user.uuid}/health`)
        }
    }, [])

    // Restore scroll position after navigating back to the index view.
    // We check `loading` as a network request is made after deleting a vaccine.
    useEffect(() => {
        if (props.match.params.tab === 'health') {
            if (scrollPosition && !loading) {
                window.scrollTo({ top: scrollPosition })
                setScrollPosition(0)
            }
        } else {
            window.scrollTo({ top: 0 })
        }
    }, [props.match.params.tab, scrollPosition, loading])

    useEffect(() => {
        const param = new URLSearchParams(location.search).get('view')
        setSortBy(param === SortingType.TIME ? SortingType.TIME : SortingType.DISEASE)
    }, [location.search])

    useEffect(() => {
        const {openDiseases, addedRecords} = props.location.state || {}

        /**
         *  We persist the open disease card when:
         * - Navigating back from past records or vaccine details
         * - Adding a new Covid or patient-certified record
         * - Link from a digitised paper record submission
         */

        if (addedRecords?.length > 0) {
            // Determine the set of disease IDs for every vaccine event in `addedRecords`.
            const addedRecordsSet = new Set(addedRecords)
            setAddedRecords(addedRecordsSet)

            const openedDiseasesResult = Object.entries(diseases)
                .filter(([_, {vaccine_events}]) => {
                    return vaccine_events.some(({key}) => addedRecordsSet.has(key))
                })
                .map(([diseaseId, _]) => diseaseId)

            setOpenedDiseases(new Set(openedDiseasesResult))

            // Special case: if all records were added in a single previous season
            // (this is applicable when adding Influenza records)
            if (openedDiseasesResult.length === 1) {
                const gotoPast = diseases[openedDiseasesResult[0]].vaccine_events
                    .filter(({key}) => addedRecordsSet.has(key))
                    .every(({in_season}) => in_season === false)

                if (gotoPast) {
                    setCurrentDisease(openedDiseasesResult[0])
                    history.replace(`/records/${user.uuid}/past-records`, {
                        currentDisease: openedDiseasesResult[0]
                    })
                }
            }
        } else if (openDiseases?.length > 0) {
            setOpenedDiseases(new Set(openDiseases))
        }
    }, [props.location.state, diseases, history, user.uuid])

    if (props.location.state?.vaccine) {
        return <VaccineRecord
            vaccine={props.location.state?.vaccine}
            relationship={user.relationship}
            onClose={({ reload }) => {
                if (reload) reloadVaccines()

                if (props.location.state?.referrer) {
                    history.push(props.location.state.referrer)
                } else {
                    history.push(`/records/${user.uuid}/health`)
                }
            }} />
    }

    if (props.match.params.tab === 'past-records') {
        if (currentDisease) {
            return <PastRecords disease={currentDisease} addedRecords={addedRecords} onClose={() => {
                history.push(`/records/${user.uuid}/health`)
            }} />
        } else {
            console.log("No disease")
            history.replace(`/records/${user.uuid}/health`)
        }
    }

    const openScanPage = () => {
        history.push({
            pathname: '/scan',
            state: {
                showIntro: true,
                user: {
                    preferredName: user.preferred_name,
                    uuid: props.match.params.uuid
                }
            }
        })
    }

    /**
     * Event handler when a vaccine event is selected (from either the disease or time view).
     * We either open the vaccine details page, or display the "Select source" prompt
     */
    const selectRecord = (vaccine) => {
        if (vaccine.source_records.length > 1) {
            // Data needed to display the prompt
            setSourceList(vaccine.source_records.map(source_id => {
                const source = sources[source_id]
                return {
                    id: source_id,
                    label: source.issuer,
                    type: source.type,
                    country: source.country,
                    doseCount: source.dose_count,
                    dateAdded: source.date_added,
                }
            }))
            /*
            type VaccineEvent = {
                vaccine_date:       DateString
                source_records:     string[]
                generic_friendly:   string
                is_generic_vaccine: boolean
                conflict_generic:  boolean
                conflict_disease:  boolean
            }
            */
            setVaccineSource(vaccine)
            setShowSources(true)
        } else {
            const id = vaccine.source_records[0]
            openRecord(mergeVaccineRecords(id, vaccine, sources[id]))
        }
    }

    const openRecord = (vaccine) => {
        setScrollPosition(window.scrollY)
        history.push(`/records/${user.uuid}/vaccine`, {
            vaccine,
            // Set referrer. This allows us to navigate back to whichever view by time or disease
            // was active before viewing a vaccine record
            referrer: `${location.pathname}${location.search}`
        })
    }

    const openPastRecords = (diseaseId) => {
        setScrollPosition(window.scrollY)
        setCurrentDisease(diseaseId)
        history.push(`/records/${user.uuid}/past-records`)
    }

    const onPressSort = (id) => {
        setSortBy(id)

        // Update query parameter
        const params = new URLSearchParams({view: id})
        history.replace(`${location.pathname}?${params}`)
    }

    let content

    let sortedVaccines
    if (sortBy === SortingType.DISEASE) {
        sortedVaccines = grouping.map(group => {
            const diseaseList = group.diseases.map(key => {
                if (diseases[key]) {
                    return { target_disease: key, ...diseases[key] }
                }
            })
            return {
                name: group.name,
                diseaseList,
            }
        })
    } else {
        sortedVaccines = Object.keys(vaccineEvents).map((key) => {
            return { key, ...vaccineEvents[key] }
        }).sort((a, b) => {
            const date_a = a['vaccine_date']
            const date_b = b['vaccine_date']
            return date_b.localeCompare(date_a)
        })
    }

    const setDiseaseRef = (diseaseId, el) => {
        // `el` is null when the component unmounts, just before a re-render.
        if (!el) return

        // As soon as a new disease card is created, scroll to it
        // We only scroll to the top-most disease, as multiple diseases may be open
        if (openedDiseases.has(diseaseId) && !diseaseCardRefs.current[diseaseId] && !hasScrolled.current) {
            const offset =
                parseInt(window.getComputedStyle(el).getPropertyValue('margin-top')) +
                el.parentNode.querySelector('.disease-group-header').offsetHeight - 1;
            const scrollPosition = el.offsetTop - offset

            window.scrollTo({
                top: scrollPosition,
                // We want to jump directly to the opened Disease when going back from 'past' screen
                behavior: addedRecords.size ? 'smooth' : 'auto',
            })
            hasScrolled.current = true
        }

        diseaseCardRefs.current[diseaseId] = el
    }

    const switchDisease = (disease) => {
        // const currentElement = diseaseCardRefs.current[openedDisease]
        const targetElement = diseaseCardRefs.current[disease]

        let offset =
            parseInt(window.getComputedStyle(targetElement).getPropertyValue('margin-top')) +
            targetElement.parentNode.querySelector('.disease-group-header').offsetHeight - 1;

        // The offset position of the lower disease card will not be correct as top disease card
        // will close as the bottom one opens, so we have to adjust for this
        // if (targetElement.offsetTop > currentElement.offsetTop) {
        //     offset = offset + currentElement.clientHeight - targetElement.clientHeight - 2
        // }

        const scrollPosition = targetElement.offsetTop - offset

        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        })

        setOpenedDiseases(ds => {
            ds.add(disease)
            // Return new set; don't mutate existing one
            return new Set([...ds])
        })
    }

    const downloadPdfRecords = () => {
        logEvent('save_patient_records_pdf')
        setPdfLoading(true)
        axiosAuthedInstance.get(`/user/${user.uuid}/download-pdf/`, {
            responseType: 'arraybuffer',
        })
            .then(({ data, headers }) => {
                const fileName = headers['content-disposition'].match(/filename="(?<filename>.+)"/).groups.filename

                return saveFile(data, fileName, 'application/pdf')
            })
            .catch((error) => {
                if (isCancel(error)) return

                console.error(error)
                const msg = error.response?.data?.error || 'Downloading PDF records failed.'
                setError(msg)
            })
            .finally(() => {
                setPdfLoading(false)
            })
    }

    if (loading) {
        content = <Loading loading={true} />
    } else if (sortBy === SortingType.DISEASE) {
        content = sortedVaccines.map((group) => {
            const { title, intro, content } = diseaseGroupInfo(group.name)
            const canAddCovidRecord = user.relationship != 'SG'
            return (
                <div key={group.name} className={classes.group}>
                    <div className={cx('disease-group-header', classes.groupHeader)}
                        ref={(el) => { el && observer.observe(el) }}>
                        <Typography variant="bodyTextLargeBold" component="h2">{title}</Typography>
                        <DrawerTrigger label={title} trigger={
                            <Typography variant="bodyTextMediumDark">
                                {intro}
                                {'… '}
                                <span className={classes.groupHeaderReadMore}>read more</span>
                            </Typography>}>
                            <DrawerContent title={title}>
                                {content}
                            </DrawerContent>
                        </DrawerTrigger>
                    </div>
                    {group.diseaseList.map((disease) => disease && (
                        <Disease
                            ref={(el) => (setDiseaseRef(disease.target_disease, el))}
                            key={disease.target_disease}
                            disease={disease}
                            canAddCovidRecord={canAddCovidRecord}
                            onClick={openScanPage}
                            onSelect={selectRecord}
                            toggleCard={diseaseId => setOpenedDiseases(ds => {
                                if (ds.has(diseaseId)) {
                                    ds.delete(diseaseId)
                                } else {
                                    ds.add(diseaseId)
                                }
                                // Return new set; don't mutate existing one
                                return new Set([...ds])
                            })}
                            isOpen={openedDiseases.has(disease.target_disease)}
                            openPastRecords={openPastRecords}
                            switchDisease={switchDisease}
                            addedRecords={addedRecords}
                        />
                    ))}
                </div>
            )
        })
    } else if (sortedVaccines.length) {
        content = sortedVaccines.map((vaccine) => {
            const vaccineDate = new Date(vaccine.vaccine_date)

            let highlight
            if (vaccine.unspecified_product) {
                if (vaccine.unspecified_product_paired) {
                    highlight = 'neutral'
                } else {
                    highlight = 'error'
                }
            } else if (addedRecords.has(vaccine.key)) {
                highlight = 'success'
            }

            return (
                <Card
                    key={vaccine.key}
                    title={vaccineEventName(vaccine, sources)}
                    subtitle={
                        <CardSubtitle>
                            {formatDate(vaccineDate)}
                            {' '}
                            <Typography variant="bodyTextMedium" component="span">
                                ({formatDuration(user.dob, vaccineDate)})
                            </Typography>
                            {conflictLabel(vaccine)}
                        </CardSubtitle>
                    }
                    onClick={() => selectRecord(vaccine)}
                    caption={VaccineEventIcon(vaccine, sources)}
                    highlight={highlight}
                />
            )
        })
    } else {
        content = <>
            <Box pb={3} />
            <EmptyList />
        </>
    }

    return (
        <BaseContainer {...props} noHeader>
            <UserHeader uuid={user.uuid} />
            <UserTabs active="health" uuid={user.uuid} />
            <Box width="100%" mb={2} id={'immunisation-records'} aria-labelledby={'immunisation-records'}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <SmallButton onClick={downloadPdfRecords}
                            primary
                            loading={pdfLoading}
                            startIcon={<NewsIcon/>}
                        >
                            Save PDF
                        </SmallButton>
                    </Grid>
                    <Grid item>
                        <DropdownMenu
                            actionName="View by ..."
                            title={
                                <Typography variant="bodyTextMediumBold" className={classes.title} noWrap>
                                    {`View by ${sortingLabel(sortBy)}`}
                                </Typography>
                            }
                            icon={ExpandMore}
                            items={sortingItems}
                            onClick={onPressSort}
                        />
                    </Grid>
                </Grid>

                {content}

            </Box>
            {user.relationship != 'SG' &&
                <>
                    <Fab aria-label="add" onClick={() => setShowAddRecord(true)}>
                        <AddIcon />
                    </Fab>
                    <AddRecordModal
                        open={showAddRecord}
                        user={user}
                        close={() => setShowAddRecord(false)}
                        showCovidIntro={true}
                    />
                </>
            }
            <SourceModal
                open={showSources}
                sources={sourceList}
                onClick={(id) => {
                    setShowSources(false)
                    openRecord(mergeVaccineRecords(id, vaccineSource, sources[id]))
                }}
                close={() => setShowSources(false)}
            />
            <ErrorDialog
                error={error}
                open={Boolean(error)}
                setOpen={clearError} />
        </BaseContainer>
    )
}
