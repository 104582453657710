import SvgIcon from '@material-ui/core/SvgIcon';

function ScanQRActionIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <circle opacity="0.6" cx="18.3686" cy="8.5" r="5.5" fill="#FBD202"/>
            <path fill="none" d="M19.8962 18.4559C19.8962 18.8906 19.7242 19.3075 19.418 19.6149C19.1118 19.9223 18.6965 20.095 18.2635 20.095H3.56933C3.13632 20.095 2.72104 19.9223 2.41485 19.6149C2.10866 19.3075 1.93665 18.8906 1.93665 18.4559V9.44044C1.93665 9.00571 2.10866 8.58878 2.41485 8.28138C2.72104 7.97397 3.13632 7.80128 3.56933 7.80128H6.8347L8.46739 5.34253H13.3655L14.9981 7.80128H18.2635C18.6965 7.80128 19.1118 7.97397 19.418 8.28138C19.7242 8.58878 19.8962 9.00571 19.8962 9.44044V18.4559Z" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M10.9164 16.8879C12.6876 16.8879 14.1235 15.4521 14.1235 13.6809C14.1235 11.9097 12.6876 10.4738 10.9164 10.4738C9.1452 10.4738 7.70935 11.9097 7.70935 13.6809C7.70935 15.4521 9.1452 16.8879 10.9164 16.8879Z" stroke={props.strokecolor || "#333"} strokeWidth="1.40553" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default ScanQRActionIcon