import { Product, Record, User } from "./recordState";

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
}

export enum RecordSteps {
    SET_USER = "SET_USER",
    SET_LOCATION = "SET_LOCATION",
    SET_HEMISPHERE = "SET_HEMISPHERE",
    SET_DATE = "SET_DATE",
    SET_PRODUCT = "SET_PRODUCT",
}


type RecordPayload = {
    [RecordSteps.SET_USER]: {
        user: User
    }
    [RecordSteps.SET_LOCATION]: {
        country: string;
        provider: string;
        otherProvider?: string;
    };
    [RecordSteps.SET_HEMISPHERE]: {
        hemisphere: 'NORTH' | 'SOUTH'
    };
    [RecordSteps.SET_DATE]: {
        date: string;
    };
    [RecordSteps.SET_PRODUCT]: {
        product: Product
        otherProduct?: string;
    }
};

export type RecordActions = ActionMap<RecordPayload>[keyof ActionMap<RecordPayload>];

export const recordReducer = (
    state: Record,
    action: RecordActions
) => {
    switch (action.type) {
        case RecordSteps.SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case RecordSteps.SET_LOCATION:
            return {
                ...state,
                country: action.payload.country,
                provider: action.payload.provider,
                otherProvider: action.payload.otherProvider
            }
        case RecordSteps.SET_HEMISPHERE:
            return {
                ...state,
                hemisphere: action.payload.hemisphere
            }
        case RecordSteps.SET_DATE:
            return {
                ...state,
                date: action.payload.date,
            }
        case RecordSteps.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
                otherProduct: action.payload.otherProduct,
            }
        default:
            return state;
    }
};
