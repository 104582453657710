import { makeStyles } from "@material-ui/core"
import { Button } from "../../components/button";
import React, { useEffect, useState } from "react"
import { axiosAuthedInstance } from "../../utils/axiosApi"
import { BaseContainer } from "../../components/container"
import ErrorDialog from "../../components/dialogs/error_dialog"
import SuccessDialog from "../../components/dialogs/success_dialog";
import { BackButton } from "../../components/buttons/back_btn";
import { HeaderTitle } from "../../components/page_sections";
import Checkbox from "../../components/checkbox";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import Loading from "../../components/loading";
import Typography from "../../components/typography";
import { isCancel } from "axios";

const AccountSettingStyles = makeStyles(() => ({
    subtitle: {
        marginBottom: '10px'
    }
}))

type AccountSettingsResponse = {
    account_settings: {
        eligibility_notifications_enabled: boolean
    }
}

export const AccountSettings = () => {
    const classes = AccountSettingStyles()
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [emailNotification, setEmailNotification] = useState<boolean|null>(null)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    function fetchAccountSettings(){
        setFetchLoading(true)
        axiosAuthedInstance.get<AccountSettingsResponse>('user/account-settings/').then(response => {
            setEmailNotification(response.data.account_settings.eligibility_notifications_enabled)
        }).catch(err => {
            if (isCancel(err)) return

            handleError(err)
        }).finally(() => {
            setFetchLoading(false)
        })
    }

    useEffect (fetchAccountSettings, [])

    const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }

        setSubmitLoading(true)

        axiosAuthedInstance.post('/user/account-settings/', {
            eligibility_notifications_enabled: emailNotification
        }).then(() => {
            return setShowSuccessDialog(true)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            setSubmitLoading(false)
        })
    }

    const handleError = (error: unknown) => {
        const msg = 'Internal Server Error. Please try again later.'
        console.error(error)
        setError(msg)
        setShowError(true)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth onClick={handleSubmit} loading={submitLoading}>
                Save
            </Button>
        </StickyFooterGroup>}>
            <BackButton />
            <HeaderTitle>
                Account Settings
            </HeaderTitle>
            {fetchLoading && <Loading loading={fetchLoading} />}
            {!fetchLoading &&
                <>
                    <Typography variant="bodyTextLargeBold" className={classes.subtitle}>
                        Email Reminders
                    </Typography>
                    {emailNotification != null &&
                        <Checkbox
                            checked={emailNotification}
                            onChange={() => setEmailNotification(!emailNotification)}
                            label={
                                <Typography variant="bodyTextMedium">Send eligibility reminders by email for account holder and any dependants</Typography>
                            } />
                    }
                    <SuccessDialog
                        success='Account settings updated successfully'
                        open={showSuccessDialog}
                        setOpen={setShowSuccessDialog}
                        dismiss_label={'Got it'}
                    />
                    <ErrorDialog
                        open={showError}
                        setOpen={setShowError}
                        error={error}
                    />
                </>
            }
        </BaseContainer>
    )
}
