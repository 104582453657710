import { Drawer } from "./drawer"
import { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import Typography from "./typography"
import InfoOutlined from "@material-ui/icons/InfoOutlined"
import { IconButton } from "./button"

const useStyles = makeStyles<Theme>((theme) => createStyles({
    container: {
        display: "flex",
        alignItems: 'center',
    },
    miniIcon: {
        fontSize: '16px',
        marginLeft: theme.spacing(0.5)
    }
}))

type TooltipProps = {
    /** title that's described in tooltip */
    title: string,

    /** what's displayed when the tooltip is opened on a drawer */
    children?: React.ReactElement
}

export const TooltipDrawer = ({title, children}: TooltipProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const openTooltip = () => {
        setOpen(true)
    }

    return (
        <>
            <div className={classes.container}>
                <Typography variant="bodyTextMediumDark">{title}</Typography>
                <IconButton size="small" onClick={openTooltip}
                    data-action={`Open Tooltip ${title}`}
                    aria-label={`More info`}>
                    <InfoOutlined fontSize="small"/>
                </IconButton>
            </div>
            <Drawer
                open={open}
                ModalProps={{
                    BackdropProps: {
                        "aria-label": "Close tooltip",
                    }
                }}
                onClose={() => setOpen(false)}
            >
                {children}
            </Drawer>
        </>
    )
}


export const MiniTooltipDrawer = ({title, children}: TooltipProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const openTooltip = () => {
        setOpen(true)
    }

    return (
        <>
            <span className={classes.container} onClick={openTooltip}>
                {title}
                <InfoOutlined className={classes.miniIcon}/>
            </span>
            <Drawer
                open={open}
                ModalProps={{
                    BackdropProps: {
                        "aria-label": "Close tooltip",
                    }
                }}
                onClose={() => setOpen(false)}
            >
                {children}
            </Drawer>
        </>
    )
}
