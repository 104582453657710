import { Button } from "../../components/button"
import { BaseContainer } from "../../components/container"
import { HeaderTitle, PageDescription, PageSection } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import Typography from "../../components/typography"
import styled from "styled-components"
import { spacing, display, flexbox } from "@material-ui/system"
import QRCodeIcon from "../../assets/icons/qr_icon"
import MailIcon from "../../assets/icons/mail"
import { BackButton } from "../../components/buttons/back_btn"
import { useHistory } from "react-router-dom"
import { reduceUserPrefNames } from "../../utils/common"

const Box = styled.div`${spacing}${display}${flexbox}`;

const SelectSharingMethod = (props) => {
    const dependants = props.location?.state?.dependants || []
    const history = useHistory()

    const depString = reduceUserPrefNames(dependants)

    return (
        <BaseContainer showCta noHeader extraComponent={<StickyFooterGroup>
            <Button fullWidth primary onClick={() => {history.push('/sharing/send/qr', {dependants} )}}>
                Show QR code
            </Button>
            <Button fullWidth onClick={() => {history.push('/sharing/send/email', {dependants} )}}>
                Enter email address
            </Button>
        </StickyFooterGroup>
        }>

            <BackButton />

            <HeaderTitle>
                Share Records Method
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    You've chosen to share records for {depString} You can share via 2 methods:
                </Typography>
            </PageDescription>

            <PageSection>
                <Box display="flex" alignItems="flex-end" pb={2}>
                    <QRCodeIcon/>
                    <Box pl={1}>
                        <Typography variant="bodyTextLargeBold">
                            QR Code
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="bodyTextLarge">
                    {'Show your recipient the QR code and they can scan via their Imunis App \n\n'}
                </Typography>
                <br/>
                <Typography variant="bodyTextLarge">
                    {'Home or Records Page > Add Dependent > Scan QR code'}
                </Typography>
            </PageSection>

            <PageSection>
                <Box display="flex" alignItems="flex-end" pb={2}>
                    <MailIcon/>
                    <Box pl={1}>
                        <Typography variant="bodyTextLargeBold">
                            Email
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="bodyTextLarge">
                Send an invitation to the email address you entered
                </Typography>
            </PageSection>

        </BaseContainer>
    )
}

export default SelectSharingMethod
