import { useContext, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormHelperText } from "@material-ui/core"
import { DatePicker, toISOString } from "../../components/date_picker"
import { axiosAuthedInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container"
import Typography from "../../components/typography"
import { Button } from "../../components/button"
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import styled from 'styled-components';
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { BackButton } from "../../components/buttons/back_btn";
import { default as SelectInput, validateRequired } from "../../components/select_input"
import MenuItem from '@material-ui/core/MenuItem'
import ErrorDialog from "../../components/dialogs/error_dialog"
import { validateName } from '../../components/text_input'
import { IdentityNumberField } from "../../components/identity_number_field";
import Link from '@material-ui/core/Link'
import { GlobalContext } from "../../components/context/globalState";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const useStyles = makeStyles((theme) => ({
    contact: {
        color: theme.palette.text.primary
    }
}))

export const EditProfile = (props) => {
    const classes = useStyles()
    const { dispatch: dispatchGlobal } = useContext(GlobalContext)
    const {user, onSaveProfile, setEditMode, isDependant} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)

    const [errorMsgs, setErrorMsgs] = useState({ name: '', preferredName: '', idNumber: '', gender: ''})
    const [state, setState] = useState({
        ...user,
        idType: user.idType === 'unknown' ? 'HKID' : user.idType,
    })

    const {name, preferredName, idType, idNumber, gender, dob } = state

    const validated = !(errorMsgs.name || errorMsgs.preferredName || errorMsgs.gender || errorMsgs.idNumber)
    const enabled = name && preferredName && gender && validated

    useEffect(() => {
        return () => {
            setLoading(false)
        };
    }, [])

    async function saveProfile(e) {
        e.preventDefault()

        setLoading(true)

        let data = {
            full_name: name,
            preferred_name: preferredName,
            gender: gender,
            id_type: idType.toLowerCase(),
            id_number: idNumber,
            dob: toISOString(dob),
        }

        try {
            await axiosAuthedInstance.post(`user/${user.uuid}/profile/`, data);
            onSaveProfile({...data, dob})
            setEditMode(false);
            if (!isDependant) {
                dispatchGlobal({
                    type: "SET_USER",
                    payload: data,
                })
            }
        } catch (error) {
            let msg = 'Cannot update profile.'
            if (error.response?.data?.error) {
                msg = error.response.data.error
                console.error(error)
            }
            setError(msg)
            setErrorOpen(true)
        } finally {
            setLoading(false)
        }
    }

    const validateInput = (text, type) => {
        let error
        switch (type) {
            case 'name':
            case 'preferredName':
                error = validateName(text)
                break
            case 'gender':
                error = validateRequired(text, 'gender')
                break
            default:
                break;
        }
        setErrorMsgs(prevState => ({...prevState, [type]: error}))
        setState(prevState => ({...prevState, [type]: text}))
    }

    function handleFormChange(event, type) {
        let text = event.target.value
        validateInput(text, type)
    }

    const onIdNumberChange = (idType, value, error) => {
        setState(prevState => ({
            ...prevState,
            idType,
            idNumber: value
        }))
        setErrorMsgs(prevState => ({
            ...prevState,
            idNumber: error
        }))
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth
                    type="submit"
                    form="edit-profile"
                    disabled={!enabled}
                    loading={loading}
                >
                    Save
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton onClick={() => setEditMode(false)}/>
            <Box marginTop={1} marginBottom={2}>
                <Typography variant="onboardingHeader">
                    Edit profile
                </Typography>
            </Box>
            <form id="edit-profile" onSubmit={saveProfile}>
                <TextField
                    id="name"
                    name="name"
                    label="Full Name"
                    placeholder="Full Name"
                    disable="true"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(event) => handleFormChange(event, 'name')}
                    error={!!errorMsgs.name}
                    helperText={errorMsgs.name || `Use name and format on${isDependant ? " dependant's" : ""} HKID/ID document.`}
                    required
                />
                <TextField
                    id="preferredName"
                    name="preferredName"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    margin="normal"
                    value={preferredName}
                    onChange={(event) => handleFormChange(event, 'preferredName')}
                    error={!!errorMsgs.preferredName}
                    helperText={errorMsgs.preferredName || `How we refer to ${isDependant ? "your dependant" : "you"} - only in the Imunis App.`}
                    required
                />
                <IdentityNumberField
                    fullWidth
                    margin="normal"
                    defaultValue={idNumber}
                    idType={idType}
                    onChange={onIdNumberChange}
                    error={errorMsgs.idNumber}
                />
                <SelectInput
                    name="gender"
                    label="Gender"
                    fullWidth
                    margin="normal"
                    value={gender}
                    onChange={(event) => handleFormChange(event, 'gender')}
                    error={!!errorMsgs.gender}
                    helperText={errorMsgs.gender}
                    required
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                </SelectInput>
                <DatePicker
                    disableFuture
                    fullWidth
                    margin="normal"
                    id="dob"
                    name="dob"
                    openTo="year"
                    label="Date of Birth"
                    views={["year", "month", "date"]}
                    placeholder={'dd MMM yyyy'}
                    value={dob}
                    helperText={
                        <>
                            Information is used for records matching and vaccination status.&nbsp;
                            <span className={classes.contact}>
                                If this is incorrect, please contact Imunis at&nbsp;
                                <Link data-action="DOB contact support" href="mailto:support@imunis.com">
                                    support@imunis.com
                                </Link>
                                .
                            </span>
                        </>
                    }
                    disabled />
            </form>
            <ErrorDialog
                error={error}
                setError={setError}
                open={errorOpen}
                setOpen={setErrorOpen} />
        </BaseContainer>
    )
}
