import { IconButton } from "./button"
import { makeStyles } from "@material-ui/core/styles"
import ChevronLeftIcon from "../assets/icons/chevron_left"
import Logo from "../assets/img/imunis-logo.svg"

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2, 2, 0),
    },
    logo: {
        marginRight: 'auto',
    },
    centeredIconHeader: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3, 2, 0),
        '& > svg': {
            fontSize: '42px'
        },
    },
}))

export const AppHeader = ({ onBack, rightSlot }: {
    onBack: (e: React.MouseEvent<HTMLButtonElement>) => void
    rightSlot?: React.ReactElement
}) => {

    const classes = useStyles()

    return (
        <div className={classes.header}>
            <IconButton onClick={onBack} aria-label="Back" edge="start">
                <ChevronLeftIcon />
            </IconButton>

            {rightSlot}
        </div>
    )
}

export const LogoHeader = () => {
    const classes = useStyles()

    return (
        <div className={classes.header}>
            {/*
            Intentionally not wrapped in <Link to="/">.
            All existing uses (adult invite consent, pending dependant reminders) don't require it
            */}
            <img src={Logo} width="128px" alt="Imunis Logo" />
        </div>
    )
}

export const CenteredIconHeader = ({ icon }: {
    icon: React.ReactElement
}) => {
    const classes = useStyles()

    return (
        <div className={classes.centeredIconHeader}>
            {icon}
        </div>
    )
}

