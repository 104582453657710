import { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button'
import { spacing, sizing, flexbox, display } from '@material-ui/system';

import LongPollDemo from '../../components/templates/long_poll_demo'
import Brand from '../../components/templates/brand';
import Layout from '../../components/templates/layout';
import Colors from '../../components/templates/colors';
import Typos from '../../components/templates/typography';
import Buttons from '../../components/templates/buttons';
import Inputs from '../../components/templates/inputs';
import Dialogs from '../../components/templates/dialogs';
import Tooltips from '../../components/templates/tooltip';
import Lists from '../../components/templates/lists';
import Components from '../../components/templates/components';
import TabsTemplate from '../../components/templates/tabs';
import FloatingCTAPage from '../../components/templates/floating_cta'

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const Templates = (props) => {
    const [scene, setScene] = useState('')
    const [color, setColor] = useState('#333333')

    const SceneButton = ({name}) => {
        return (
            <Box mb={2} mt={1}>
                <Button fullWidth variant="contained" onClick={() => setScene(name)}>
                    {name}
                </Button>
            </Box>
        )
    }

    const DefaultScene = () => (<div> {scenes.map(s => <SceneButton name={s.name} key={s.name} />)} </div>)

    const scenes = [
        {name: 'Brand'},
        {name: 'Layout'},
        {name: 'Colors'},
        {name: 'Typography'},
        {name: 'Buttons'},
        {name: 'Input'},
        {name: 'LongPollDemo'},
        {name: 'Dialogs'},
        {name: 'Tooltips'},
        {name: 'Lists'},
        {name: 'Components'},
        {name: 'Tabs'},
        {name: 'Floating CTA'},
    ]

    let currScene

    switch (scene) {
        case 'Brand': currScene = <Brand />; break;
        case 'Layout': currScene = <Layout />; break;
        case 'Colors': currScene = <Colors setColor={(c) => setColor(c)} />; break;
        case 'Typography': currScene = <Typos />; break;
        case 'Buttons': currScene = <Buttons />; break;
        case 'Input': currScene = <Inputs />; break;
        case 'LongPollDemo': currScene =  <LongPollDemo />; break;
        case 'Dialogs': currScene = <Dialogs />; break;
        case 'Tooltips': currScene = <Tooltips />; break;
        case 'Lists': currScene = <Lists />; break;
        case 'Components': currScene = <Components />; break;
        case 'Tabs': currScene = <TabsTemplate />; break;
        case 'Floating CTA': currScene = <FloatingCTAPage />; break;
        default: currScene = <DefaultScene />; break;
    }
    return (
        <Box>
            <Box display="flex">
                <Box mr={1}>Scene: {scene}</Box>
                <div>Selected Color: {color} </div>
            </Box>

            {scene && <Button onClick={() => setScene('')}> Return</Button>}
            {currScene}
        </Box>
    )
}

export default Templates
