import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Button, IconButton } from '../button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    dialog: {
        position: 'relative',
        padding: theme.spacing(4, 2, 2, 2),
    },
    successDialog: {
        position: 'relative',
        padding: theme.spacing(5.5, 2, 2, 2),
    },
    button: {
        margin: theme.spacing(1, 0)
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    }
}))

/**
 * Usage in other components/pages:
 *
 * 1. set up the required error state hook: const [error, setError] = useState('')
 * 2. set up the required open state hook: const [showError, setShowError] = useState(false)
 * 3. Put the ErrorDialog at the bottom of your component: <ErrorDialog error={error} open={showError} setOpen={setShowError} />
 * 4. If you want extra callback to be called after dismissing the dialog, pass in the callback via the dismiss_callback prop
 */
const ErrorDialog = (props) => {
    const classes = useStyles()

    const dismiss = () => {
        props.setOpen(false)
        if (typeof props.dismiss_callback === 'function') {
            props.dismiss_callback()
        }
    }

    return (
        <Dialog maxWidth="xs" open={props.open} onClose={() => dismiss()}>
            <Paper className={classes.dialog}>
                <IconButton onClick={() => dismiss()} className={classes.closeButton} aria-label="Close">
                    <CloseIcon fontSize="small"/>
                </IconButton>
                <DialogTitle>
                    <Typography style={{fontWeight: 600}} variant="body1" align="center">{props.title || 'Error'}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom variant="body2" align="center">{props.error}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Button onClick={dismiss} fullWidth className={classes.button} primary>
                            Dismiss
                        </Button>
                    </Box>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}

export default ErrorDialog
