import { Capacitor } from '@capacitor/core'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'

/**
 * Saves a file (web) or opens the file in an external view (native)
 */
export const saveFile = (fileData: string, fileName: string, mimeType: string) => {
    if (Capacitor.getPlatform() === 'web') {
        const file = new Blob([fileData], {type: mimeType});
        const el = document.createElement('a');
        el.href = window.URL.createObjectURL(file);
        el.download = fileName
        el.click()
        el.remove()
    } else {
        return Filesystem.writeFile({
            path: fileName,
            data: fileData,
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
        })
            .then(({uri}) => {
                return FileOpener.open({
                    filePath: uri
                })
            })
    }
}
