import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function EmptyTaskIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 36 35" {...props}>
            <circle cx="29.5" cy="9.5" r="6.5" fill="#FBD202" opacity=".6"/>
            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M26 11 14.84 25 9 19.03"/>
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" stroke="currentColor" strokeWidth="2"/>
        </SvgIcon>
    )
}
