import SvgIcon from '@material-ui/core/SvgIcon';

function HealthIcon(props) {
    const opacityVal = props.active ? 1 : 0.25
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <circle opacity={opacityVal} cx="14.7122" cy="6.18178" r="5.20833" fill="#FBD202"/>
            <path fill="none" d="M4.05014 6.04054L5.71183 4.46757M7.37351 2.8946L5.71183 4.46757M4.28551 11.2817L8.43972 7.34932M12.5939 3.4169L8.43972 7.34932M6.77804 8.92229L11.915 14.349M17.4028 16.8179L14.2932 16.7467C14.2223 16.7451 14.1549 16.7152 14.1061 16.6636L11.915 14.349M17.4028 16.8179L17.5022 13.7091C17.5044 13.6382 17.4782 13.5692 17.4294 13.5177L10.1014 5.77635M17.4028 16.8179L20.1307 19.6997M5.71183 4.46757L8.43972 7.34932M11.915 14.349L13.5767 12.776" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round"/>
        </SvgIcon>
    );
}

export default HealthIcon