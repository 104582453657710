import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PatientRecordsIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <g stroke="currentColor" fill="none">
            <path strokeWidth="1.7" d="M15.5 4.5h1.75A1.75 1.75 0 0 1 19 6.25V18.5a1.75 1.75 0 0 1-1.75 1.75H6.75A1.75 1.75 0 0 1 5 18.5V6.25A1.75 1.75 0 0 1 6.75 4.5H8.5"/>
            <path strokeWidth="1.8" d="M14.63 2.75H9.38c-.48 0-.88.4-.88.87v1.75c0 .49.4.88.88.88h5.25c.48 0 .88-.4.88-.88V3.62c0-.48-.4-.87-.88-.87Z"/>
            <path strokeWidth="1.7" d="M15 16.62c0 .23-.16.38-.4.38H9.4c-.24 0-.4-.15-.4-.38v-.76c0-1 .64-1.9 1.68-1.9h2.64c1.04 0 1.68.98 1.68 1.9v.76Zm-1.44-6.1c0 .84-.72 1.53-1.6 1.53-.88 0-1.6-.69-1.6-1.53 0-.83.72-1.52 1.6-1.52.88 0 1.6.69 1.6 1.52Z"/>
        </g>
    </SvgIcon>
)

export default PatientRecordsIcon
