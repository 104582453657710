import { Capacitor } from '@capacitor/core'
import { datadogRum } from '@datadog/browser-rum'
import { intervalToDuration, formatDuration as formatDurationUtil } from 'date-fns'
import mixpanel from 'mixpanel-browser'

export const getNotificationCount = () => {
    return localStorage.getItem('notification_count') || 0
}

// @ts-expect-error: add types
export const setNotificationCount = (count) => {
    localStorage.setItem('notification_count', count)
}

export function isValidHKID(value: string) {
    const strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    // basic check length
    if (!(value.length == 8 || value.length == 9)) return false;

    // convert to upper case
    value = value.toUpperCase();

    // regular expression to check pattern and split
    const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    const matchArray = value.match(hkidPat);

    // not match, return false
    if (matchArray == null) return false;


    // the character part, numeric part and check digit part
    const charPart = matchArray[1];
    const numPart = matchArray[2];
    const checkDigit = matchArray[3];

    // calculate the checksum for character part
    let checkSum = 0;
    if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (let i = 0, j = 7; i < numPart.length; i++, j--) {
        checkSum += j * Number(numPart.charAt(i))
    }

    // verify the check digit
    const remaining = checkSum % 11;
    const verify = remaining == 0 ? 0 : 11 - remaining;

    if (verify == 10) {
        return checkDigit === 'A'
    } else {
        return verify == Number(checkDigit)
    }
}


const PASSPORT_REGEX = /^(?=(?:[A-Z]*[0-9]){4,})[A-Z0-9]{7,9}$/i

export function isValidPassport(value: string) {
    return PASSPORT_REGEX.test(value)
}

/**
 * Utility for conditionally joining arguments together to return a className string.
 *
 * Each input parameter, if truthy, is included in the output string
 *
 * @example // returns 'foo bar'
 * cx('foo', 'bar')
 *
 * @example // returns 'foo'
 * cx('foo', (1 == 2 ? 'bar'))
 */
export const cx = (...arr: any[]): string => (
    arr.filter(Boolean).join(' ')
)


const dateFormatUTC = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeZone: 'UTC'
})

const dateFormatLocal = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
})

// https://gist.github.com/MrChocolatine/367fb2a35d02f6175cc8ccb3d3a20054
type TYear    = `${number}${number}${number}${number}`
type TMonth   = `${number}${number}`
type TDay     = `${number}${number}`
type THours   = `${number}${number}`
type TMinutes = `${number}${number}`
type TSeconds = `${number}${number}`

/**
 * Represents a date: e.g. `2021-01-08`
 */
export type TDateISODate = `${TYear}-${TMonth}-${TDay}`

/**
 * Represents a point in time: e.g. `2021-01-08T12:34:56Z`
 * This is typically converted to a specific timezone before display
 */
export type TDateISODateTime = `${TDateISODate}T${THours}:${TMinutes}:${TSeconds}Z`

/**
 * Formats a date (ISO string or native Date object) into a human readable string like:
 * 4 Apr 2020
 * Does not perform any timezone logic; a point in time like 2020-01-01T00:00Z will be displayed as
 * 1 Jan 2020 in all client timezones.
 *
 * Typical use case: displaying dates of birth, vaccination events
 */
export const formatDate = (d: Date | TDateISODate) => {
    if (!d) return null

    if (d instanceof Date) return dateFormatUTC.format(d)

    return dateFormatUTC.format(new Date(d))
}

/**
 * Same as `formatDate` but performs timezone conversion; a point in time like 2020-01-01T00:00Z
 * will be displayed as either 31 Dec 2019 or 1 Jan 2020 depending on the client timezone.
 *
 * Typical use case: displaying event time stamps -- "shared at", etc.
 */
export const formatDateLocal = (d: Date | TDateISODateTime) => {
    if (!d) return null

    if (d instanceof Date) return dateFormatLocal.format(d)

    return dateFormatLocal.format(new Date(d))
}

// @ts-expect-error: add types
export const reduceUserPrefNames = (userList, noPeriod=false) => {
    // @ts-expect-error: add types
    return userList.reduce((prev, curr, idx) => {
        let str = prev + curr.preferred_name
        if (idx != userList.length - 1) {
            str += ', '
        } else if (!noPeriod) {
            str += '.'
        }
        return str
    }, '')
}

// @ts-expect-error: add types
export const getGenderLabel = (gender) => {
    if (gender == 'M') {
        return 'Male'
    } else if (gender == 'F') {
        return 'Female'
    } else {
        return 'N/A'
    }
}

/**
 * Pluralises an English word, depending on the value of `amount`;
 *
 * - pluralString('dose', 0) // 'doses'
 * - pluralString('dose', 1) // 'dose'
 * - pluralString('dose', 2) // 'doses'
 *
 * This is very rudimentary and should be enough for our uses cases but is not general purpose.
 * For instance this doesn't work with words like 'fish'.
 */
export const pluralString = (string: string, amount: number) => {
    return `${string}${amount === 1 ? '' : 's'}`
}

/**
 * Returns the possessive form of a proper noun (i.e. a person's name);
 *
 * - possessiveString('James') // James’
 * - possessiveString('Bort')  // Bort's
 */
export const possessiveCheck = (string: string) => {
    if (string[string.length - 1] === 's') {
        return string + "'"
    } else {
        return string + "'s"
    }
}

export const inviteTypeLabel = (inviteType: "school" | "clinic") => {
    switch (inviteType) {
        case "clinic":
            return "Clinic"
        case "school":
            return "School"
    }
}

/**
 * Returns a string describing the duration between dateFrom and dateTo
 */
export const formatDuration = (dateFrom: Date, dateTo: Date): string => {
    // use custom locale for customized date units
    const formatDistanceLocale = {
        xDays: '{{count}}d',
        xMonths: '{{count}}m',
        xYears: '{{count}}y'
    }

    const shortEnLocale = {
        formatDistance: (token: keyof typeof formatDistanceLocale, count: number) => {
            return formatDistanceLocale[token].replace('{{count}}', count.toString())
        }
    }

    const duration = intervalToDuration({
        start: dateFrom,
        end: dateTo
    })
    const ageFormat = duration.years ? ['years', 'months'] : ['months', 'days']
    const result = formatDurationUtil(duration, {format: ageFormat, locale: shortEnLocale})
    if (result == '') {
        // if duration is within a day, empty string is returned
        return '0d'
    }
    // returned age could be negative although this should rarely happen,
    // but it makes sense to reflect age with +/- sign so users can spot any potential discrepancies
    return (dateFrom > dateTo ? '-' : '') + result
}

const uuidRegex = new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, "gi")

// This should match the regex in `nginx.conf.template` as we scrub tokens in the nginx logs as well
const tokenRegex = new RegExp(/(?:[0-9a-z]{6}-)?[0-9a-f]{32,}/, "gi")

const integerRegex = new RegExp(/(?:\/)\d+(\/|$)/, "g")

/**
 * Masks variable tokens in URLs that are sent to GA and Mixpanel in order to:
 * - Remove PII
 * - Allow the same page (e.g. connection invite) which has variable URLs to be treated as a single
 * page for the purposes of user exploration and other analytics
 *
 * A single "~" is used as the substituted string. It has no particular significance other than it's
 * URL-safe and not used by the application itself
 *
 * Examples:
 *
 * Invites: /connection/invite/ced71f4051ae51609694f552c97cd0ef
 * => /connection/invite/~
 *
 * User IDs: /records/634c5934-9852-4c2f-9d0e-ced1aba1d0db/health
 * => /records/~/health
 *
 * Reset password: /reset-password/634c5934-9852-4c2f-9d0e-ced1aba1d0db/bnc6jh-044c5b0603eb28e6101e138a90734dab
 * => /reset-password/~/~
 *
 * Paper submissions: /paper-records/submission/2/page/1
 * => /paper-records/submission/~/page/~
 */
export const scrubbedURL = (href: string) => {
    return href.replaceAll(uuidRegex, '~').replaceAll(tokenRegex, '~').replaceAll(integerRegex, '/~$1')
}

/**
 * Serves the same intent as `scrubbedURL` above but for Datadog. Since it handles UUID masking
 * automatically, we only need to mask tokens.
 */
export const scrubbedToken = (href: string) => {
    return href.replaceAll(tokenRegex, '~')
}

/**
 * Logs a custom user event or action to Datadog / Google Analytics / Mixpanel.
 * For consistency across tracking platforms, use snake_cased action names
 */
export const logEvent = (...args: [
    actionName: string,
    attributes?: { [key: string]: string }
]) => {
    if (args[0].length > 40) {
        console.warn("GA has a 40 character limit on event names: https://support.google.com/analytics/answer/9267744")
    }
    console.log(`[event]`, ...args)

    datadogRum.addAction(...args)
    mixpanel.track(...args)

    // Set page_location explicitly for GA, it appears to not be reliable for custom events in SPAs
    args[1] = {
        ...args[1],
        page_location: scrubbedURL(window.location.href),
    }
    window.gtag('event', ...args)
}

type LinkType = 'link' | 'button' | 'fab' | 'small_button'
/**
 * Manually track a link or button click to GA (Datadog RUM does this automatically)
 *
 * @param linkText The link text / button label. Typically the text contents of the link/button, e.g.
 * "Connect dependant", but a snake_cased identifier is preferred if available, e.g. "scan_qr".
 * @param linkType Provide a value for `linkType` to indicate the component being interacted with
 * @deprecated
 */
export const trackLink = (linkText: string, linkType: LinkType) => {
    const eventName = 'link_click'
    const eventParams = {
        link_text: linkText,
        link_type: linkType,
    }

    console.log(`[GA:${eventName}]`, eventParams)
    window.gtag('event', eventName, eventParams)
}

type Action = 'Click'

/**
 * Track an interaction on a component, with an optional identifier of the component.
 *
 * Example:
 * ```
 * trackAction('Click', 'Button', 'Sign in')
 * trackAction('Expand', 'Card', '840539006') // Note the disease ID rather than the english text
 * trackAction('Dismiss', 'Alert') // An identifier may not be applicable or necessary
 * ```
 *
 * Extra data may be provided, depending on context. Example:
 *
 * ```
 * trackAction('Toggle', 'Checkbox', 'Marketing consent', { checked: false })
 * trackAction('Rotate', 'Image', { orientation: "portrait" })
 * ```
 */
export const trackAction = (
    action: Action,
    component: string,
    id_or_props?: string | Record<string, unknown>,
    props?: Record<string, unknown>
) => {
    console.log(`[${action}: ${component}]`, ...[id_or_props, props].filter(Boolean))

    const properties = {
        ...(typeof id_or_props === 'string' ? { name: id_or_props } : id_or_props),
        ...props
    }

    // Mixpanel uses an (Object) (Verb) format for event names, we follow that.
    mixpanel.track(`${component} ${action}`, properties)
}

/**
 * Track a page view explicity with additional property key/value pairs.
 * Page views are tracked automatically via the `PageTracker` hook, but in some cases we want to
 * attach additional data relevant to a Page View event that's not available via the standard URL
 * parameters.
 *
 * For consistency, use snaked_cased property names
 */
export const trackPageView = (properties?: Record<string, unknown>) => {
    mixpanel.track_pageview({
        current_url_path: scrubbedURL(location.pathname),
        current_url_search: scrubbedURL(location.search),
        ...properties,
    })
}

/**
 * Returns true iff all the provided arguments are "not blank".
 * Used when checking state variables exist for multi-step workflows (e.g. clinic onboarding).
 *
 * "Blank" values include empty objects `{}`, empty arrays `[]`, empty strings, and null/undefined.
 * Note all numbers including 0 are considered as not blank.
 *
 * checkAll('SOME_VALUE')     => true
 * checkAll('SOME_VALUE', {}) => false
 */
export const checkAll = (...vals: unknown[]) => vals.every((val) => {
    if (val?.constructor === Object) return Object.keys(val).length > 0
    if (Array.isArray(val)) return val.length > 0
    if (typeof val === 'number') return true
    return Boolean(val)
})

// Platform / capabilities

/** Cheap test for mobile devices (as opposed to laptops / desktops) */
export const isPhone = () => /Android|iPhone|iPad/i.test(navigator.userAgent)

/**
 * Returns one of:
 * - 'native' if running as a native iOS or Android app
 *
 * Otherwise we're in a web environment, which can be either:
 * - 'browser' if running in a browser, as opposed to:
 * - 'pwa' if launched as a standalone app (PWA / home screen app)
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/display-mode
 */
export const getDisplayMode = () => {
    if (Capacitor.isNativePlatform()) return 'native'

    if (matchMedia('(display-mode: browser)').matches) {
        return 'browser'
    } else {
        return 'pwa'
    }
}
