import { makeStyles, Tab as MuiTab } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import theme from "../../styles/theme";

const useStyles = makeStyles({
    baseTab: {
        display: 'flex',
        alignItems: 'center',
    },
    iconTab: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '12px',
        lineHeight: '19px',
    },
})

const styles = {
    root: {
        fontSize: '14px',
        fontWeight: 400,
        minWidth: '44px',
        maxWidth: '240px',
        minHeight: '30px',
        color: '#767676',
        padding: '3px 12px',
        opacity: 1,
        '&:hover': {
            color: '#767676',
            opacity: 1,
        },
        '&$selected': {
            color: '#FF5300',
        },
        '&:focus': {
            color: '#FF5300',
        },
    },
    selected: {
        fontWeight: 600,
    }
}

const Tab = ({ label, icon, ...props }) => {
    const classes = useStyles()

    return (
        <MuiTab
            disableRipple
            label={<div className={(icon ? classes.iconTab : classes.baseTab)}>{icon}{label}</div>}
            {...props} />
    )
}
export default withStyles(styles)(Tab)

