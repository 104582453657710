import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

function ChevronLeftIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path d="M7.5 11.97a1.49 1.49 0 0 1 .43-1.06l6-5.97a1.5 1.5 0 0 1 2.13 2.12l-4.97 4.91 4.77 4.93a1.5 1.5 0 1 1-2.16 2.06l-5.79-5.97a1.49 1.49 0 0 1-.42-1.02Z" />
        </SvgIcon>
    )
}

export default ChevronLeftIcon
