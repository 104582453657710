import { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import { spacing, sizing, display, flexbox } from '@material-ui/system';
import styled from 'styled-components';
import { IconButton } from "../../components/button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DropdownMenu from "../../components/dropdownmenu";
import Typography from "../../components/typography";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import { getPreferredName, getUUID } from "../../utils/auth";
import DropDownMenuIcon from "../../assets/icons/dropdown";
import { isCancel } from "axios";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

// This context could be moved further up the component tree...
// but how do we keep it in sync?
export const UserContext = createContext({});

export const UserContextProvider = ({userId, children}) => {
    let isMounted = true;
    const [users, setUsers] = useState([]);
    const [primaryUsers, setPrimaryUsers] = useState([])
    const [user, setUser] = useState({uuid: userId})
    const [grouping, setGrouping] = useState([])
    const [loading, setLoading] = useState(0)
    const [diseases, setDiseases] = useState({})
    const [sources, setSources] = useState({})
    const [vaccineEvents, setVaccineEvents] = useState({})
    const [error, setError] = useState(null)

    // Hacky way to refresh data from API
    const [_reloadVaccines, reloadVaccines] = useState(0)

    async function getUserData() {
        try {
            setLoading(l => l + 1)
            const userResult = await axiosAuthedInstance.get(`/user-profile/${userId}/`)
            if (isMounted) {
                const user = userResult.data.data;
                setUser({
                    ...user, // FIXME we mix accessing user data with camelCase / snake_case keys
                    uuid: user.uuid,
                    name: user.full_name,
                    preferredName: user.preferred_name,
                    idType: user.idType.toUpperCase(),
                    idNumber: user.idNumber,
                    gender: user.gender,
                    dob: user.dob && new Date(user.dob),
                })
            }
            setLoading(l => l - 1)
        } catch (error) {
            if (isCancel(error)) return

            console.error(error)
        }
    }

    async function getDependants() {
        try {
            setLoading(l => l + 1)
            const usersResult = await axiosAuthedInstance.get('/user/dependants/')
            if (isMounted) {
                setUsers([
                    {
                        uuid: getUUID(),
                        preferred_name: getPreferredName()
                    },
                    ...usersResult.data.data
                ])
                setPrimaryUsers([
                    {
                        uuid: getUUID(),
                        preferred_name: getPreferredName()
                    },
                    ...usersResult.data.data.filter(dep => dep.relationship === 'PG')
                ])
            }
            setLoading(l => l - 1)
        } catch (error) {
            if (isCancel(error)) return

            console.error(error)
        }
    }

    async function getVaccineRecords() {
        try {
            setLoading(l => l + 1)
            const response = await axiosAuthedInstance.get(`/user/${userId}/v2/vaccine-records/`)
            if (isMounted) {
                setGrouping(response.data.disease_groups)
                setVaccineEvents(response.data.vaccine_events)
                setDiseases(response.data.diseases)
                setSources(response.data.source_records)
                setUser(user => ({
                    relationship: response.data.relationship,
                    ...user
                }))
            }
            setLoading(l => l - 1)
        } catch (error) {
            if (isCancel(error)) return

            console.error(error)
            setLoading(false)
            setError(error.response.data.error || 'Cannot get Vaccine Records')
        }
    }

    useEffect(() => {
        getUserData()
        getVaccineRecords()
        return () => { isMounted = false }
    }, [userId]);

    useEffect(() => {
        getDependants()
        return () => { isMounted = false }
    }, []);

    useEffect(() => {
        if (_reloadVaccines) {
            getVaccineRecords()
            reloadVaccines(0)
        }
    }, [_reloadVaccines]);

    const context = {
        user, setUser, users, primaryUsers, grouping,
        diseases, sources, vaccineEvents,
        loading,
        error, setError,
        clearError: () => setError(null),
        reloadVaccines: () => reloadVaccines(true)}
    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    )
}


export const UserHeader = ({uuid}) => {
    const history = useHistory();

    const {user, users} = useContext(UserContext);

    const menuItems = users.filter(user=> user.uuid != uuid).map(user => ({
        id: user.uuid,
        title: user.preferred_name
    }))

    return (
        <Box display="flex" justifyContent="space-between"
            style={{
                width: "calc(100% +  24px)",
                margin: '0 -16px'
            }}
        >
            <IconButton onClick={() => history.push('/records')} aria-label="Back">
                <ChevronLeftIcon/>
            </IconButton>
            <DropdownMenu
                title={
                    <Typography variant="nameDropdown" style={{marginRight: '25px'}} noWrap>
                        {user.preferred_name}
                    </Typography>
                }
                icon={DropDownMenuIcon}
                items={menuItems}
                onClick={(id) => history.replace(`/records/${id}/health/?view=disease`)} />
        </Box>
    )
}
