import { useHistory } from "react-router-dom"
import { BaseContainer } from "../../components/container"
import { makeStyles } from "@material-ui/core"
import { BackButton } from "../../components/buttons/back_btn"
import TestPdfDocument from "../../assets/pdf/sample_childhood_schedule.pdf";
import { PdfEmbed } from "../../components/pdf_embed"
import { PageTitle } from "../../components/page_sections"

const useStyles = makeStyles(() => ({
    pdfViewContainer: {
        height: '100%',
        overflow: 'hidden',
    },
    pdfViewContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflow: 'hidden',
    }
}))

const VaccinationSchedule = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <BaseContainer noHeader className={classes.pdfViewContainer} contentWrapperClass={classes.pdfViewContent}>

            <PageTitle icon={
                <BackButton onClick={() => {
                    history.push('/discover')
                }}/>
            }>
                Hong Kong Vaccine Schedule
            </PageTitle>

            <PdfEmbed document={TestPdfDocument} />
        </BaseContainer>
    )
}

export default VaccinationSchedule
