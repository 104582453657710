import { useLocation } from "react-router-dom"
import ErrorPage from "../components/error_page"

const InviteError = (props) => {
    const location = useLocation()

    let { error, type, title } = props?.location?.state || location.state

    if (!title) title = `Could not open ${type || ''} invite`
    const content = error || 'Something went wrong'

    return (
        <ErrorPage
            expired
            title={title}
            content={content}
            location={'/'}
            cta_label={"Go to homepage"}
        />
    )
}

export default InviteError
