import { makeStyles, Radio, RadioProps } from "@material-ui/core"
import TickCircleSolidIcon from '../assets/icons/tick_circle_solid'

const useStyles = makeStyles(() => ({
    root: {
        width: '24px',
        height: '24px',
    }
}))

const TickRadio = (props: RadioProps) => {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<TickCircleSolidIcon/>}
            icon={false}
            color="primary"
            {...props}
        />
    );
}

export default TickRadio
