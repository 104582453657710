import { useHistory } from "react-router-dom";
import { spacing, sizing, display, flexbox } from '@material-ui/system';
import styled from 'styled-components';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import TabButton from "../../components/tab_button";
import HealthIcon from "../../assets/icons/health";
import UserIcon from "../../assets/icons/user";

// FIXME: use .tsx
// type Props = {
//     active: 'health' | 'profile'
// }

const UserTabs = ({active, uuid}/*: Props*/) => {
    const history = useHistory();

    return (
        <Box marginTop={3} width="100%">
            <Box width="100%" display="flex" justifyContent="space-around" marginBottom={2}>
                <TabButton
                    title="Health"
                    icon={HealthIcon}
                    active={active === 'health'}
                    onClick={() => history.replace(`/records/${uuid}/health`)} />

                <TabButton
                    title="Profile"
                    icon={UserIcon}
                    active={active === 'profile'}
                    onClick={() => history.replace(`/records/${uuid}/profile`)} />
            </Box>
        </Box>
    )
}

export default UserTabs
