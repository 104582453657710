import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { backgroundStyle } from '../../pages/splash'
import { Button } from '../../components/button'
import Typography from '../../components/typography'
import Logo from "../../assets/img/imunis-logo.svg";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../components/context/globalState';

const useStyles = makeStyles(() => ({
    wrapper: {
        maxWidth: '600px',
        margin: '0 auto',
        ...backgroundStyle
    },
    textCenter: {
        textAlign:"center"
    }
}));

const Logout = () => {
    const classes = useStyles()
    const history = useHistory()
    const { dispatch } = useContext(GlobalContext)

    // Run on initial mount only (the dispatch function doesn't change between renders)
    useEffect(() => {
        dispatch({
            type: "LOGOUT"
        })
    }, [dispatch])

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.wrapper}>
            <Grid container item justifyContent="center">
                <Box pt={10}>
                    <img src={Logo} width="134px" alt="Imunis Logo" />
                </Box>
            </Grid>
            <Grid container item justifyContent="center">
                <Box pt={5}>
                    <Typography variant="modalHeader" className={classes.textCenter}>You are now signed out</Typography>
                </Box>
            </Grid>
            <Grid container item justifyContent="center">
                <Box pt={2}>
                    <Typography variant="bodyTextLarge" className={classes.textCenter}>Thanks for using Imunis.</Typography>
                    <Typography variant="bodyTextLarge" className={classes.textCenter}>We hope to see you again soon.</Typography>
                </Box>
            </Grid>
            <Grid container item justifyContent="center">
                <Box pt={10} pb={5} pl={5} pr={5} style={{width:"100%"}}>
                    <Button fullWidth primary onClick={() => history.push('/login')}>
                        Sign in
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Logout;
