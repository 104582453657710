import Scanner from '../../components/scanner'
import { useHistory } from "react-router-dom";
import { axiosAuthedInstance } from "../../utils/axiosApi";

const DependantScanner = (props) => {
    const history = useHistory()
    const api_call = (data) => {
        const splitUrl = data.split('/sharing/invite/')
        if (splitUrl[1]) {
            return axiosAuthedInstance.post(`user/scan-sharing-invite/${splitUrl[1]}`)
        }
        return Promise.reject('No dependant information found on QR code')
    }
    const api_callback = (result) => {
        history.push('/sharing/confirm', {
            token: result.data.token,
        })
    }

    const title = 'Scan to add dependant'
    const content = "Scan the QR code from another account holder"
    return (
        <Scanner title={title} content={content} api_call={api_call} api_callback={api_callback} />
    )
}

export default DependantScanner
