import { createTheme } from '@material-ui/core/styles';
// Re-export ThemeProvider for consumers (Django-app's vaccination status) so it doesn't need to
// directly require @material-ui/core@4
export { ThemeProvider } from '@material-ui/core/styles';

export default createTheme({
    spacing: 8,
    typography: {
        fontSize: 16,
        fontFamily: '"Open Sans", sans-serif',
        button: {
            textTransform: 'none'
        },
        caption: {
            fontSize: 12,
        }
    },
    palette: {
        primary: {main: "#FF5300", light: '#FFDDCC'},
        secondary: {
            dark: "#FD9801",  // brand 1
            main: "#FBD202",  // brand 2
            light: "#FFD8C7", // brand 3
        },

        error: {main: '#D82C0D', light: '#FBEAE7'},
        warning: {main: '#FFC453', light: '#FFF4DD'},
        info: {main: '#004597', light: '#EBF9FC'},
        success: {main: '#008040', light: '#E5F2EE'},

        grey: {
            100: '#F6F6F6', // general / surface
            200: '#E7E7E7', // grey 3
            400: '#C4C4C4', // grey 2
            600: '#767676', // grey 1
            800: '#333333', // dark
        },
        background: {default: '#FFFFFF'}
    },
    overrides: {
        MuiAppBar: {
            root: {
                zIndex: 'auto',
            }
        },
        MuiCssBaseline: {
            '@global': {
                a: {
                    color: 'inherit',
                    textDecoration: 'inherit',
                },
                b: {
                    fontWeight: 600,
                }
            },
        },
        MuiButton: {
            contained: {
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover': {
                    '&$disabled': {
                        backgroundColor: 'rgba(255, 83, 0, 0.5)',
                        boxShadow: 'none',
                    },
                    boxShadow: 'none'
                },
                '&$disabled': {
                    color: 'white',
                    backgroundColor: 'rgba(255, 83, 0, 0.5)'
                }
            },
            outlined: {
                fontWeight: 600,
                border: '1px solid #767676',
            },
            outlinedPrimary: {
                color: '#333333'
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: '12px'
            }
        },
        MuiInput: {
            root: {
                '&$disabled': {
                    opacity: '50%',
                    color: '#767676',
                }
            },
            underline: {
                '&$disabled': {
                    "&:before": {
                        borderBottomStyle: 'solid',
                    }
                },
                "&:after": {
                    // workaround to fix css issue for IM-919
                    transform: 'scale3d(0,1,1)',
                }
            },
        },
        MuiTooltip: {
            arrow: {
                fontSize: '32px',
                "&:before": {
                    backgroundColor: 'white',
                    boxShadow: '1px 1px 1px 0px rgb(0 0 0 / 40%), 0px -1px 3px 0px rgb(0 0 0 / 12%)',
                },
            }
        },
        MuiLink: {
            root: {
                fontWeight: 600,
                cursor: 'pointer',
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: 'transparent',
            }
        },
    },
    props: {
        MuiLink: {
            underline: 'always',
        }
    }
});
