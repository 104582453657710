import React, { createContext, useReducer, Dispatch, useEffect } from "react";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import { TDateISODate } from "../../utils/common";
import { RecordActions, recordReducer, RecordSteps } from "./recordReducer";
import { isCancel } from "axios";

export type User = {
    uuid: string
    full_name: string
    dob: TDateISODate
    // There are other properties here but we're only interested in the full name
}

export type Product = {
    id: string
    /**
     * Text for the product. May resemble name_friendly but can be different.
     * Generic products will not have a label but instead have isGeneric = true
     * */
    label?: string

    isGeneric?: boolean

    priority?: boolean
}

// TODO Rename to AddRecordContext?
export type Record = {
    user: User
    country: string | null;
    provider: string | null;
    otherProvider?: string | null;
    hemisphere: 'NORTH' | 'SOUTH' | null;
    date: string | null;
    product: Product | null;
    otherProduct?: string
};

const initialState = {
    user: null,
    country: null,
    provider: null,
    otherProvider: null,
    hemisphere: null,
    date: null,
    product: null
};

const RecordContext = createContext<{
    state: Record
    dispatch: Dispatch<RecordActions>
} | null >(null)

/**
 * Use this instead of React.useContext when consuming RecordProvider.
 * We've initialised the context to null, but we never expect the context to actually be null.
 * See https://stackoverflow.com/a/64266985
 */
export const useRecordContext = (context: typeof RecordContext) => {
    const contextValue = React.useContext(context)
    if (contextValue === null) {
        throw Error("Context has not been Provided!")
    }

    return contextValue;
}

type UserProfileResponse = {
    data: User
}

type RecordProviderProps = {
    /** The UUID of the user we're adding the record to  */
    user: string
}

const RecordProvider: React.FC<RecordProviderProps> = ({ user, children }) => {
    const [state, dispatch] = useReducer(recordReducer, initialState as unknown as Record)

    // Get the user's details (we only need the full name)
    // We should consolidate this at some point (see UserContext)
    useEffect(() => {
        if (!state.user) {
            axiosAuthedInstance.get<UserProfileResponse>(`/user-profile/${user}/`).then(response => {
                dispatch({
                    type: RecordSteps.SET_USER,
                    payload: {
                        user: response.data.data
                    }
                })
            }).catch((error) => {
                if (isCancel(error)) return

                console.error(error)
            })
        }
    }, [user, state.user])

    return (
        <RecordContext.Provider value={{ state, dispatch }}>
            {state.user && children}
        </RecordContext.Provider>
    );
};

export { RecordProvider, RecordContext };
