import { makeStyles, Theme, createStyles, Link, Button, CircularProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import VerifyEmailIcon from "../assets/icons/verify_email"
import { axiosInstance } from "../utils/axiosApi"
import { BaseContainer } from "./container"
import { StickyFooterGroup } from "./sticky_footer_group"
import Typography from "./typography"
import Alert from "./alert"
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ErrorDialog from "./dialogs/error_dialog"
import { IconButton } from "./button"

const verifyEmailStyles = makeStyles<Theme>((theme) => createStyles({
    pageIcon: {
        width: '272px',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
    },
    sentIcon: {
        height: '0.5em',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    smallSection: {
        marginBottom: theme.spacing(1)
    },
    section: {
        marginBottom: theme.spacing(4.5)
    },
    snackbarAction: {
        alignSelf: 'flex-start',
    },
    backdrop: {
        zIndex: 10,
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
}))

type ResendProps = {
    /** Email the verification email got sent to */
    email: string
}

export const ResendEmail = () => {
    const INTERVAL_TIME = 59
    const [resent, setResent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(INTERVAL_TIME)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const [redirect, setRedirect] = useState(false)
    const history = useHistory()
    const location = useLocation<ResendProps>()
    const state = location.state
    const email = state.email
    const classes = verifyEmailStyles()
    let timerInterval: ReturnType<typeof setInterval>

    const handleSubmit = () => {
        setLoading(true)
        axiosInstance.post('/user/signup/resend/', { email })
            .then((res) => {
                let time = INTERVAL_TIME
                if (res.data?.time_left) {
                    time = 60 - parseInt(res.data.time_left, 10)
                } else {
                    setSuccess(true)
                }
                setTimer(time)
                setResent(true)
            }).catch(err => {
                if (err.response?.status === 400) {
                    setError('Email already sent. Please wait until you can retry')
                    setTimer(INTERVAL_TIME)
                    setResent(true)
                } else if (err.response?.status === 404) {
                    setRedirect(true)
                    setError('No email found')
                } else {
                    console.error(err)
                    setError('Server error')
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    const countdown = () => {
        if (timer > 0) {
            setTimer(old => {
                if (old <= 0) {
                    setResent(false)
                    return 0
                }
                return old - 1
            })
        } else {
            setResent(false)
        }
    }

    useEffect(() => {
        if (!email) {
            setRedirect(true)
            setError('No email found')
        }
        if (resent) {
            timerInterval = setInterval(countdown, 1000)
        } else {
            clearInterval(timerInterval)
        }
        return () => {
            clearInterval(timerInterval)
        }
    }, [resent])

    const handleClose = () => {
        setSuccess(false)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button fullWidth variant="outlined" onClick={() => history.push('/login')}>
                    Go to sign in
                </Button>
            </StickyFooterGroup>
        }>
            {success &&
                // Aria label hack to not send user email to datadog
                <div className={classes.backdrop} aria-label="Close snackbar"/>
            }
            <div className={classes.container}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    action={
                        <IconButton
                            size="small"
                            aria-label="Close snackbar"
                            className={classes.snackbarAction}
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    <Alert onClose={handleClose} severity="success">
                        <b>Email Sent</b>
                        <br/>
                        Account activation email has been sent to {email}
                    </Alert>
                </Snackbar>
                <VerifyEmailIcon className={classes.pageIcon} />

                <div className={classes.title}>
                    <Typography align="center" variant="modalHeader">
                        Verify your email address to get started
                    </Typography>
                </div>

                <div className={classes.section}>
                    <div className={classes.smallSection}>
                        <Typography align="center" variant="bodyTextLarge">
                            An account activation email has been sent to <b>{email}</b>
                        </Typography>
                    </div>
                    <Typography align="center" variant="bodyTextLarge">
                        Please click the button in the email to confirm your email address and activate your account.
                    </Typography>
                </div>

                <div>
                    {loading ?
                        <CircularProgress size={54}/>
                        :
                        <>
                            <div className={classes.smallSection}>
                                <Typography align="center" variant="bodyTextLarge">
                                    Didn’t get the email?
                                </Typography>
                            </div>
                            {resent ?
                                <Typography align="center" variant="bodyTextLarge">
                                    Re-send email if it doesn’t arrive in 00:{timer < 10 ? `0${timer}` : timer}
                                </Typography>
                                :
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Link align="center" onClick={handleSubmit}>Re-send email</Link>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <ErrorDialog
                dismiss_callback={() => {
                    if (redirect) {
                        history.replace('/login')
                    }
                }}
                setOpen={setError}
                open={!!error}
                error={error}
            />
        </BaseContainer>
    )
}
