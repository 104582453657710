import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { pluralString, formatDate } from "../../utils/common";
import { InfoPanel } from "../info_panel";
import { JsonContent } from "../static_content";
import covidJson from "../../content/covid.json";
import { VaccinationStatus } from "../vaccination_progress";

type CovidContentProps = {
    status: VaccinationStatus
}

export const covidStatusContent = ({ status }: CovidContentProps) => {
    let p1 = null, fifth_dose_note = null
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'ineligible') {
        p1 = 'You are ineligible for vaccination as your current age falls below the minimum recommended age for Covid-19 vaccination in Hong Kong.'
    }

    if (status.action_status === 'ELIGIBLE' && status.vaccine_status === 'unvaxed') {
        p1 = 'You are eligible for vaccination with no qualifying records registered with Imunis.'
    }

    if (status.action_status === 'ELIGIBLE' && status.doses_taken) {
        if (status.covid_age_bracket === 'adult' && status.doses_taken == 4) {
            fifth_dose_note = `For healthy adults, the 5th dose is optional and can be taken 180 days after the 4th dose, to boost protection. It will only be available privately in Hong Kong for most people.`
        } else {
            fifth_dose_note = ``
        }

        if (status.doses_taken > 0) {
            p1 = `You are eligible for vaccination with ${status.doses_taken} qualifying ${pluralString('record', status.doses_taken)} registered with Imunis and you have now passed the minimum interval between doses. ${fifth_dose_note}`
        } else {
            p1 = `You are eligible for vaccination with ${status.doses_taken} qualifying ${pluralString('record', status.doses_taken)} registered with Imunis.`
        }
    }

    if (status.action_status === 'UP_TO_DATE' && status.doses_taken) {
        p1 = `You are up to date, with ${status.doses_taken} qualifying ${pluralString('record', status.doses_taken)} registered with Imunis.`
    }

    if (status.action_status === 'UNAVAILABLE') {
        switch (status.unavailable_reason) {
            case "UNSPECIFIED_PRODUCT":
                p1 = 'Vaccination status is currently unavailable due to a lack of product-/vaccine-specific information about one or more administered vaccines. If product-specific information is available, please share with health provider to update records.'
                break
            case "INVALID_AGE_DOSE":
                p1 = 'Vaccination status is currently unavailable as one or more doses has been administered outside age guidelines.'
                break
            case "BELOW_INTERVAL":
                p1 = 'Vaccination status is currently unavailable as one or more doses has been administered outside interval guidelines.'
                break
            case "CONFLICT":
                p1 = 'Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing.'
                break
            case "GENERIC_PRODUCT":
                p1 = 'Vaccination status is currently unavailable due to a lack of product/vaccine-specific information about one or more administered vaccines. If product-specific information is available, please share with health provider to update records.'
                break
            case "UNSUPPORTED_PRODUCT":
                p1 = 'Vaccination status is currently unavailable as in-country guidelines do not cover one or more administered product(s).'
                break
            case "DOB_UNAVAILABLE":
                p1 = 'Status is unavailable due to missing DOB'
                break
            case "PARTIAL_RECORD": {
                const covid_dose_count: number = status.covid_dose_count || 0
                p1 = `The uploaded Covid-19 QR certificate states that you have received ${covid_dose_count} ${pluralString('dose', covid_dose_count)}, but does not contain information about all doses. Vaccination status is currently unavailable as it may be affected by the missing information. Scanning earlier Covid-19 QR codes in addition may enable status calculation.`
                break;
            }
        }
    }

    return <>
        <p>{p1} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]</p>
    </>
}

export const PartialDoseWarning = ({ partial_vaccine_record, covid_dose_count }: VaccinationStatus) => {
    if (partial_vaccine_record && covid_dose_count) {
        return (
            <PageSubsection>
                <InfoPanel
                    severity="warning"
                    title="Partial HK Covid-19 record"
                >
                    <Typography variant="bodyTextLarge">
                        Uploaded QR code is missing information about one or more doses. See records summary for dose information recorded. Scanning older QR codes or uploading via Imunis scanning may allow earlier dose records to be added. Vaccination status is calculated based on dose information provided. This may apply to multiple QR codes.
                    </Typography>
                </InfoPanel>
            </PageSubsection>
        );
    }

    return null
};

export const InfectionHistory = ({ infection_history }: VaccinationStatus) => {
    switch (infection_history) {
        case '≥6m <5y':
            return (
                <PageSubsection>
                    <InfoPanel
                        severity="warning"
                        title="Infection History - children"
                    >
                        <Typography variant="bodyTextLarge">
                            Guidance is intended for children without a confirmed Covid-19 infection history. Schedule recommendations differ for those with infection history - see <a target="_blank" rel="noreferrer" href="https://www.chp.gov.hk/files/pdf/poster_recommend_dose.pdf">here</a> for details.
                        </Typography>
                    </InfoPanel>
                </PageSubsection>
            );
        case '≥5y':
            return (
                <PageSubsection>
                    <InfoPanel
                        severity="warning"
                        title="Infection History"
                    >
                        <Typography variant="bodyTextLarge">
                            Guidelines apply to individuals regardless of their Covid-19 infection history.
                        </Typography>
                    </InfoPanel>
                </PageSubsection>
            );
        default:
            return null
    }
};

export const BoosterDosing = ({ booster_dosing }: VaccinationStatus) => {
    switch (booster_dosing) {
        case '≥6m <18y':
            return (
                <PageSubsection>
                    <InfoPanel
                        severity="warning"
                        title="Child booster dosing 2024"
                    >
                        <Typography variant="bodyTextLarge">
                            A booster dose is recommended rather than optional for immuno-compromised children, 180 days after last dose or infection. Children outside this group are eligible for an optional, self-paid 2024 booster. See <a target="_blank" rel="noreferrer" href="https://www.chp.gov.hk/files/pdf/poster_recommend_dose.pdf">here</a> and schedule information below for details.
                        </Typography>
                    </InfoPanel>
                </PageSubsection>
            );
        case '≥18y':
            return (
                <PageSubsection>
                    <InfoPanel
                        severity="warning"
                        title="Adult booster dosing 2024"
                    >
                        <Typography variant="bodyTextLarge">
                            2024 booster dosing for the following adult groups is recommended rather than optional: healthcare workers, patients who are pregnant, those with co-morbidities or who are immuno-compromised and those over 50 years. Adults outside these groups are eligible for an optional booster vaccine, at their own cost. See <a target="_blank" rel="noreferrer" href="https://www.chp.gov.hk/files/pdf/poster_recommend_dose.pdf">here</a> & schedule information below for more information.
                        </Typography>
                    </InfoPanel>
                </PageSubsection>
            );
        default:
            return null;
    }
};

export const nextDoseDue = ({ next_dose_due }: VaccinationStatus) => {

    if (next_dose_due){

        const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);
        const match = dateRegex.test(next_dose_due);

        if (match){
            const dateStr = formatDate(new Date(next_dose_due))
            return (
                <Typography variant="listTitle">Next dose due from: {dateStr}</Typography>
            )
        } else {
            return (
                <Typography variant="listTitle">Next dose due: {next_dose_due}</Typography>
            )
        }
    }
}

export const CovidContent = ({ status }: CovidContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark" component="div">
            {covidStatusContent({ status: status })}
        </Typography>
    </PageSubsection>

    <PageSubsection>
        {nextDoseDue({...status})}
    </PageSubsection>

    <InfectionHistory {...status} />

    <BoosterDosing {...status} />

    <PartialDoseWarning {...status} />

    <JsonContent>
        {covidJson}
    </JsonContent>
</>)
