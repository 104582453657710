import {
    createStyles, FormControl, FormHelperText, InputBase, InputBaseComponentProps, InputLabel,
    makeStyles, StyledComponentProps, Theme, withStyles
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField'
import { useEffect, useRef, useState } from 'react'
import { cx } from '../utils/common'

// @ts-expect-error Styles are MUI internals, but we want to use them
// See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/Input/Input.js#L8
import { styles } from '@material-ui/core/Input/Input'
import * as React from 'react';

const HKID_PATTERN = /^(?<number>[A-Z]{1,2}[0-9]{6})\(?(?<checkDigit>.)\)?$/
const NUMBER_PATTERN = /^[A-Z]{1,2}[0-9]{6}$/

const useStyles = makeStyles<Theme, {
    showCheckDigit: boolean
}>(() => createStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    checkDigitWrapper: {
        width: ({ showCheckDigit }) => showCheckDigit ? '' : '0',
        opacity: ({ showCheckDigit }) => showCheckDigit ? '' : '0',
        display: 'flex',
        alignItems: 'center',
    },
    parens: {
        width: '0.5em',
        textAlign: 'center',
    },
    numberInput: {

    },
    checkDigitInput: {
        width: '2em',
        textAlign: 'center',
    }
}))

const HKIDInner = (props: InputBaseComponentProps) => {
    const {
        id,
        inputRef,
        ...rest
    } = props

    const [showCheckDigit, setShowCheckDigit] = useState(false)
    const numberRef = useRef<HTMLInputElement>(null)
    const checkDigitRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const [number, setNumber] = useState("")
    const [checkDigit, setCheckDigit] = useState("")
    const [value, setValue] = useState("")

    const classes = useStyles({ showCheckDigit })

    // When the value is set or updated from the parent component (e.g. after a async data fetch),
    // format the number into its number and check digit parts.
    useEffect(() => {
        let initialValue = String(props.value || "")

        if (!initialValue) return
        if (props.value === value) return

        initialValue = initialValue.toUpperCase()

        const { number, checkDigit } = (initialValue.match(HKID_PATTERN)?.groups || {})

        if (number && checkDigit) {
            setNumber(number)
            setCheckDigit(checkDigit)
            setShowCheckDigit(true)
        } else if (initialValue) {
            setNumber(initialValue)
            setCheckDigit('')
        }
        setValue(initialValue)
    }, [props.value])

    useEffect(() => {
        // @ts-expect-error: use a mock event object (an empty object: `{}`) to make MUI
        // think the change event came from the hidden input field
        // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/InputBase/InputBase.js#L320
        props.onChange && props.onChange({}, value)
    }, [value])

    // Handle backspace and focusing to act like a single input
    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Backspace' && checkDigitRef.current?.value === "") {
            e.stopPropagation()
            numberRef.current?.focus()
        }
    }

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return

        container.addEventListener("keydown", onKeyDown);

        return () => {
            container.removeEventListener("keydown", onKeyDown);
        }
    }, [containerRef])

    const onContainerFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        // Handle backspace event from the check digit to number field.
        if (e.relatedTarget === checkDigitRef.current && !checkDigit) {
            return
        }

        e.stopPropagation()

        if (e.target) {
            if (showCheckDigit) {
                checkDigitRef.current?.focus()
            }
        }

        props.onFocus && props.onFocus(e)
    }

    const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toUpperCase()

        setNumber(value)

        if (NUMBER_PATTERN.test(value)) {
            setShowCheckDigit(true);
            checkDigitRef.current?.focus()
        } else if (checkDigitRef.current?.value) {
            setShowCheckDigit(true);
        } else {
            setShowCheckDigit(false);
        }

        e.stopPropagation()
        setValue(value)
    }

    const onCheckDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toUpperCase()

        e.stopPropagation()

        if (value === '' || value.length === 1) {
            setCheckDigit(value)
            setValue(`${number}${value}`)
        }
    }

    return (
        // @ts-expect-error InputBase doesn't like to see <div>s as a child element,
        // but since we're handling focus events ourselves we can ignore this
        <div
            {...rest}
            ref={containerRef}
            className={cx(props.className, classes.container)}
        >
            {/* hidden input is required to get expected MUI behaviour (e.g. label shrinking) */}
            <input hidden readOnly ref={inputRef} value={value} id={id} />

            <input
                tabIndex={showCheckDigit ? -1 : 0}
                className={cx(props.className, classes.numberInput)}
                ref={numberRef}
                value={number}
                onChange={onNumberChange}
                onFocus={onContainerFocus}
            />

            <div className={classes.checkDigitWrapper}>
                <span className={classes.parens}>(</span>
                <input
                    tabIndex={showCheckDigit ? 0 : -1}
                    className={cx(props.className, classes.checkDigitInput)}
                    ref={checkDigitRef}
                    value={checkDigit}
                    onChange={onCheckDigitChange}
                    onFocus={onContainerFocus}
                />
                <span className={classes.parens}>)</span>
            </div>
        </div>
    )
}

export const HKIDField = withStyles(styles)(function HKIDField(
    props: {
        onChange: (value: string) => void
    } & Omit<TextFieldProps, 'onChange'> & StyledComponentProps<typeof styles>
) {
    const {
        classes = {},
        error = false,
        fullWidth = false,
        helperText,
        inputRef,
        label = "HKID",
        id,

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        defaultValue,

        onBlur,
        onChange,
        onFocus,
        value,
        ...rest
    } = props;

    const inputBaseClasses = {
        ...classes,
        root: cx(classes.root, classes.underline),
        underline: null
    }

    const inputLabelId = id ? `${id}-label` : undefined;

    return (
        <FormControl
            error={error}
            fullWidth={fullWidth}
            {...rest}
        >
            {label && (
                <InputLabel htmlFor={id} id={inputLabelId}>
                    {label}
                </InputLabel>
            )}

            <InputBase
                classes={inputBaseClasses}
                fullWidth={fullWidth}
                value={value}
                inputRef={inputRef}
                onBlur={onBlur}
                onChange={(_, v?: string) => {onChange && onChange(v || "")}}
                onFocus={onFocus}
                id={id}
                inputComponent={HKIDInner}
            />

            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
})
