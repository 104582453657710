import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import theme from '../../styles/theme';

type IconProps = {
    variant?: 'active' | 'inactive';
}

export default function PrivacyPolicyIcon({variant}: IconProps) {
    let stroke;

    switch (variant) {
        case 'active':
            stroke = theme.palette.primary.main
            break;
        case 'inactive':
            stroke = theme.palette.grey[600]
            break;
        default:
            stroke = 'currentColor'
    }
    
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" stroke={stroke} strokeWidth="1.5" viewBox="0 0 24 24">
            <path id="PrivacyPolicyIcon-s" strokeLinecap="round" strokeLinejoin="round" fill="none" d="M4.05 6.5 12 3l7.95 3.5c.33 3.5-.8 11.3-7.95 14.5-7.15-3.2-8.28-11-7.95-14.5Z" />
            <clipPath id="PrivacyPolicyIcon-b">
                <use href="#PrivacyPolicyIcon-s" />
            </clipPath>
            <g clipPath="url(#PrivacyPolicyIcon-b)">
                <path fill={stroke} stroke='none' d="M16.75 18v.75h1.5V18h-1.5Zm-11 0v.75h1.5V18h-1.5Zm4.05-2.25h4.4v-1.5H9.8v1.5Zm4.4 0c1.48 0 2.55 1.07 2.55 2.25h1.5c0-2.14-1.88-3.75-4.05-3.75v1.5ZM7.25 18c0-1.18 1.07-2.25 2.55-2.25v-1.5c-2.17 0-4.05 1.61-4.05 3.75h1.5Z" />
                <path fill="none" d="M14.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
            </g>
        </SvgIcon>
    )
}

