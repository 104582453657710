import { useState, useContext, useEffect } from "react"
import { DatePicker, toISOString } from "../../components/date_picker"
import { Button } from "../../components/button"
import { BaseContainer } from "../../components/container"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import Typography from "../../components/typography"
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { spacing, sizing, display, flexbox, } from '@material-ui/system'
import { useTheme } from '@material-ui/core'
import { ConnectionInviteContext } from "../../components/context/connection_invite"
import { BackButton } from "../../components/buttons/back_btn"
import ErrorDialog from "../../components/dialogs/error_dialog"
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { possessiveCheck } from "../../utils/common"
import CancelCircleIcon from "../../assets/icons/cancel_circle"
import { useHistory } from "react-router-dom"

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles(() => ({
    fontSizeLarge: {
        width:'44px',
        height:'44px',
    },
}))

const SecurityCheck = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const {state} = useContext(ConnectionInviteContext)
    const [dob, setDob] = useState(null)
    const [dobError, setDobError] = useState('')
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [showMaxAttemptsReached, setShowMaxAttemptsReached] = useState(props.noRetries || false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        return () => {
            setLoading(false)
        };
    }, [])

    const confirmDob = (e) => {
        e.preventDefault()

        setLoading(true)

        props.confirmSecurity(dob)
            .catch(err => {
                if (err.cause == 'SECURITY_FAIL_RETRY') {
                    setDobError(err.msg)
                } else if (err.cause == 'SECURITY_FAIL_NO_RETRY') {
                    setShowMaxAttemptsReached(true)
                } else {
                    console.error(err)
                    let msg = 'Server error. Try again later'
                    if (err.response?.data?.error) {
                        msg = err.response.data.error
                    }
                    setError(msg)
                    setShowError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const organisationName = state.organisation
    const dependantName = state.full_name

    if (showMaxAttemptsReached) {
        // The School model doesn't support security failures; this is always the clinic phone
        const clinicPhone = state.organisation_phone
        return (
            <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
                <Button primary fullWidth href={`tel:${clinicPhone}`}>
                    Contact {organisationName}
                </Button>
                <Button fullWidth onClick={() => history.push('/')}>
                    Go to homepage
                </Button>
            </StickyFooterGroup>}>
                <Box marginBottom={10} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box marginBottom={3}>
                        <CancelCircleIcon classes={{fontSizeLarge: classes.fontSizeLarge}} fontSize="large" strokecolor={theme.palette.error.main} />
                    </Box>
                    <Box marginBottom={4}>
                        <Typography align="center" variant="modalHeader" data-selenium="max-attempts-reached-title" style={{marginBottom: '1rem'}}>
                            Maximum attempts reached
                        </Typography>
                        <Typography align="center" variant="bodyTextLarge" gutterBottom>
                            The date of birth you have entered does not match the clinic record and you have reached the maximum attempts. Please contact {organisationName} for a new invitation.
                        </Typography>
                    </Box>
                </Box>
            </BaseContainer>
        )
    }

    const isFormValid = dob && !dobError

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth
                type="submit"
                form="security-check"
                disabled={!isFormValid}
                loading={loading}>
                Continue
            </Button>
        </StickyFooterGroup>}>
            <BackButton />

            <HeaderTitle>
                Secure matching
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    Please confirm {dependantName ? possessiveCheck(dependantName) : 'your'} date of birth as held on file with {organisationName}
                </Typography>
            </PageDescription>

            <form id="security-check" onSubmit={confirmDob}>
                <DatePicker
                    disableFuture
                    fullWidth
                    id="dob"
                    name="dob"
                    openTo="year"
                    label="Date of birth"
                    views={["year", "month", "date"]}
                    value={dob}
                    helperText={dobError}
                    error={!!dobError}
                    onChange={dob => {
                        setDob(toISOString(dob))
                        setDobError('')
                    }}
                />
            </form>
            <ErrorDialog open={showError} setOpen={setShowError} error={error}/>
        </BaseContainer>
    )
}

export default SecurityCheck
