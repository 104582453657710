import { makeStyles } from "@material-ui/core/styles"
import { TDateISODateTime, formatDateLocal } from "../utils/common"
import { Chip } from "./chip"
import Typography from "./typography"

const useSubmissionTimelineStyles = makeStyles((theme) => ({
    submissionTimeline: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    timestamps: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(0.25),
    },
    status: {
        display: 'flex',
        flexDirection: 'column',
    },
    separator: {
        background: theme.palette.grey[400],
        height: 1,
        width: '100%',
        alignSelf: 'center',
        margin: theme.spacing(0, 1),
    }
}))

export type SubmissionTimelineProps = {
    submittedAt?: TDateISODateTime
    resubmittedAt?: TDateISODateTime

    processedAt?: TDateISODateTime
    digitisedAt?: TDateISODateTime
    rejectedAt?: TDateISODateTime
}

export const SubmissionTimeline = (props: SubmissionTimelineProps) => {
    const classes = useSubmissionTimelineStyles()

    let startChip
    let startTimestamp
    if (props.resubmittedAt) {
        startChip = <Chip label="Resubmitted" color="warn" />
        startTimestamp = props.resubmittedAt
    } else if (props.submittedAt) {
        startChip = <Chip label="Submitted" color="neutral"/>
        startTimestamp = props.submittedAt
    }

    let endChip
    let endTimestamp
    if (props.processedAt) {
        endChip = <Chip label="Processing" color="success"/>
        endTimestamp = props.processedAt
    } else if (props.digitisedAt) {
        endChip = <Chip label="Digitised" color="info"/>
        endTimestamp = props.digitisedAt
    } else if (props.rejectedAt) {
        endChip = <Chip label="Rejected" color="error"/>
        endTimestamp = props.rejectedAt
    }

    if (endChip) {
        endChip = <>
            <span className={classes.separator}/>
            {endChip}
        </>
    }

    return <div>
        <div className={classes.submissionTimeline}>
            {startChip}
            {endChip}
        </div>

        <div className={classes.timestamps}>
            <Typography variant="bodyTextSmall" component="span">
                {startTimestamp ? formatDateLocal(startTimestamp) : null}
            </Typography>
            {endTimestamp ?
                <Typography variant="bodyTextSmall" component="span">
                    {formatDateLocal(endTimestamp)}
                </Typography>
                : null }
        </div>
    </div>
}

export default SubmissionTimeline
