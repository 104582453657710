import { makeStyles } from "@material-ui/core"
import styled from 'styled-components';
import { spacing, sizing, flexbox, display } from '@material-ui/system';
import Grid from '@material-ui/core/Grid'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    fontSizeLarge: {
        width:'44px',
        height:'44px',
    },
    Body2_14: {
        fontSize: theme.typography.fontSize * 0.875,
        fontWeight: 'normal',
        color: theme.palette.grey[800]
    },
    Caption_12: {
        color: theme.palette.grey[600],
    },
    iconGrid: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
}))

const HealthListItem = (props) => {
    const classes = useStyles()

    return (
        <Box paddingTop={1.5} paddingBottom={2} width="100%">
            <Grid container spacing={1} style={{cursor: 'pointer'}} onClick={props.on_click}>
                <Grid item xs={11}>
                    <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                        <Typography variant="body2" className={classes.Body2_14}>
                            {props.title}
                        </Typography>
                        <Typography variant="caption" className={classes.Caption_12}>
                            {props.caption}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1} className={classes.iconGrid}><ChevronRightIcon /></Grid>
            </Grid>
        </Box>
    )}

export default HealthListItem
