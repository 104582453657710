import { MenuItem } from "@material-ui/core";
import SelectInput from "./select_input";
import countries from "i18n-iso-countries"
import en from "i18n-iso-countries/langs/en.json"
import imunisCountries from "./imunisCountries.json"
countries.registerLocale(imunisCountries)

type CountryDropdownType = {
    value: string,
    onChange(country: string): void
}

// Subset of countries for the travel lookup
const {
    // Prioritised
    HK,
    // Excluded
    AX, // Åland Islands
    BT, // Bhutan
    BQ, // Bonaire, Sint Eustatius and Saba
    BV, // Bouvet Island
    CA, // Canada
    KY, // Cayman Islands
    CC, // Cocos (Keeling) Islands
    TF, // French Southern Territories
    GG, // Guernsey
    HM, // Heard Island and McDonald Islands
    VA, // Holy See
    IM, // Isle of Man
    IL, // Israel
    JE, // Jersey
    MO, // Macao
    NF, // Norfolk Island
    PS, // Palestine, State of
    SM, // San Marino
    GS, // South Georgia and the South Sandwich Islands
    SJ, // Svalbard and Jan Mayen
    UM, // United States Minor Outlying Islands
    VG, // Virgin Islands (British)
    EH, // Western Sahara
    ...rest
} = countries.getNames("en-imunis", {select: "official"})

const prioritisedCountries = Object.entries({HK})
const remainingSortedCountries = Object.entries(rest).sort(([, c1], [, c2]) => c1.localeCompare(c2))

export const countryList = new Map(prioritisedCountries.concat(remainingSortedCountries))

export const TravelCountryDropdown = (props: CountryDropdownType) => {
    return (
        <SelectInput
            value={props.value}
            name="country"
            label="Country / region"
            fullWidth
            onChange={(event) => {
                props.onChange(event.target.value as string)
            }}
            margin="none"
            error={false}
            helperText=""
            required
        >
            {
                Array.from(countryList).map(([code, label]) => (
                    <MenuItem key={code} value={code}>
                        {label}
                    </MenuItem>
                ))
            }
        </SelectInput>
    )
}
