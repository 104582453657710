import { ReactNode } from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    twoLineText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
    },
}))

/**
 * Wraps its children (typically <Typography> elements) to ensure text does not span more than 2
 * lines. Overflowing text will be clipped.
 */
export const TwoLineText = ({children}: {children: ReactNode}) => {
    const classes = useStyles()

    return <span className={classes.twoLineText}>
        {children}
    </span>
}
