import { SvgIcon, SvgIconProps } from "@material-ui/core";

const QRCodeIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <g fill='none'>
                <rect x="4" y="5" width="6" height="6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="6.5" y="7.5" width="1" height="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="6.5" y="16.5" width="1" height="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="16.5" y="7.5" width="1" height="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="16" y="19" width="1" height="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="19" y="19" width="1" height="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="4" y="14" width="6" height="6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="14" y="5" width="6" height="6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14 20V14H17V16.5H19.5V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </SvgIcon>
    )
}

export default QRCodeIcon
