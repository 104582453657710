import {SvgIcon, SvgIconProps} from '@material-ui/core';

export default function ExclamationTriangleIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path stroke="currentColor" fill="none" strokeWidth="2" d="m3.5 17.09 6.72-13.12a2 2 0 0 1 3.56 0l6.73 13.12A2 2 0 0 1 18.73 20H5.27a2 2 0 0 1-1.78-2.91Z"/>
            <path fill="currentColor" d="M11 16h2v2h-2zm0-8h2v6h-2z"/>
        </SvgIcon>
    )
}
