import { SvgIcon, SvgIconProps } from "@material-ui/core";

function ShieldIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 26" {...props}>
            <path stroke="currentColor" fill="none" d="M1.07024 5.66667L12 1L22.9298 5.66667C23.3852 10.3333 21.8368 20.7333 12 25C2.16322 20.7333 0.614836 10.3333 1.07024 5.66667Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel"/>
            <path fill="currentColor" d="M7.58398 12.051H9.19019V12.96H7.58398V14.5579H6.67505V12.96H5.06885V12.051H6.67505V10.4324H7.58398V12.051ZM13.0791 15.5H11.7966V11.9888C11.7966 11.8919 11.798 11.7729 11.8008 11.6318C11.8035 11.488 11.8077 11.3413 11.8132 11.1919C11.8188 11.0397 11.8243 10.9028 11.8298 10.781C11.7994 10.817 11.7371 10.8792 11.6431 10.9678C11.5518 11.0535 11.466 11.131 11.3857 11.2002L10.6885 11.7605L10.0701 10.9885L12.0249 9.43213H13.0791V15.5ZM19.1511 14.2424H18.4207V15.5H17.1672V14.2424H14.5774V13.3501L17.2378 9.43213H18.4207V13.2463H19.1511V14.2424ZM17.1672 13.2463V12.217C17.1672 12.1202 17.1686 12.0054 17.1714 11.8726C17.1769 11.7397 17.1825 11.6069 17.188 11.4741C17.1935 11.3413 17.1991 11.2237 17.2046 11.1213C17.2129 11.0162 17.2184 10.9429 17.2212 10.9014H17.188C17.1354 11.0148 17.0801 11.1255 17.022 11.2334C16.9639 11.3385 16.8961 11.4492 16.8186 11.5654L15.7063 13.2463H17.1672Z"/>
        </SvgIcon>
    )
}

export default ShieldIcon
