const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <g filter="url(#filter1_dd_4_4850)">
            <rect x="24" y="174" width="327" height="90" rx="12" fill="white"/>
        </g>
        <text xmlSpace="preserve" fill="#767676" fontFamily="Open Sans" fontSize="12" fontWeight="400">
            <tspan x="262" y="211">2/2 doses</tspan>
        </text>
        <path d="M326.98 209.004C326.849 209.005 326.72 208.98 326.598 208.93C326.477 208.88 326.367 208.807 326.274 208.714L322.293 204.714C322.2 204.621 322.126 204.51 322.076 204.388C322.026 204.267 322 204.136 322 204.004C322 203.872 322.026 203.742 322.076 203.62C322.126 203.498 322.2 203.387 322.293 203.294C322.385 203.201 322.496 203.127 322.617 203.076C322.738 203.026 322.868 203 322.999 203C323.131 203 323.261 203.026 323.382 203.076C323.503 203.127 323.613 203.201 323.706 203.294L326.98 206.604L330.265 203.424C330.356 203.322 330.468 203.24 330.592 203.183C330.717 203.126 330.852 203.095 330.989 203.093C331.126 203.091 331.262 203.117 331.388 203.17C331.514 203.223 331.629 203.302 331.723 203.401C331.818 203.5 331.891 203.618 331.939 203.747C331.986 203.876 332.006 204.014 331.998 204.151C331.99 204.288 331.954 204.423 331.892 204.545C331.83 204.668 331.744 204.777 331.638 204.864L327.657 208.724C327.475 208.9 327.233 209.001 326.98 209.004Z" fill="#767676"/>
        <circle cx="52" cy="232" r="10" fill="#E5F2EE"/>
        <path d="M48.2917 232.428C48.109 232.278 47.8709 232.197 47.6251 232.201C47.3794 232.204 47.1442 232.292 46.9667 232.447C46.7893 232.603 46.6827 232.813 46.6683 233.037C46.654 233.261 46.7329 233.481 46.8894 233.654L49.0514 235.725C49.1419 235.811 49.2506 235.88 49.3711 235.928C49.4916 235.975 49.6214 236 49.7526 236C49.883 236.001 50.0123 235.977 50.1328 235.932C50.2533 235.886 50.3624 235.818 50.4537 235.733L57.0566 229.514C57.1461 229.43 57.2166 229.331 57.2641 229.222C57.3115 229.113 57.335 228.997 57.3332 228.88C57.3314 228.762 57.3043 228.647 57.2535 228.539C57.2027 228.432 57.1292 228.334 57.0371 228.252C56.945 228.171 56.8362 228.106 56.7169 228.063C56.5975 228.02 56.47 227.998 56.3416 228C56.2132 228.002 56.0864 228.026 55.9684 228.073C55.8505 228.119 55.7437 228.186 55.6542 228.27L49.7623 233.832L48.2917 232.428Z" fill="#008040"/>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="14" fontWeight="400">
            <tspan x="68" y="238">Up-to-date</tspan>
        </text>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="40" y="211">Influenza</tspan>
        </text>
        <defs>
            <filter id="filter1_dd_4_4850" x="20" y="173" width="335" height="99" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_4850"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_4_4850" result="effect2_dropShadow_4_4850"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_4850" result="shape"/>
            </filter>
        </defs>
    </svg>
)

export default Svg
