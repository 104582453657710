import { makeStyles } from "@material-ui/core"
import TickCircleIcon from "../../../assets/icons/tick_circle"
import { BaseContainer } from "../../../components/container"
import { CenteredIconHeader } from "../../../components/header"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { SubmissionTimeline } from "../../../components/submission_timeline"
import Typography from "../../../components/typography"
import { ImageCarousel, Profile } from "./utils"
import { SubmissionResponse } from "./new"
import { Button } from "../../../components/button"
import { useHistory } from "react-router-dom"

const useSubmissionConfirmationStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.success.main
    },
}))

export const SubmissionConfirmation = ({
    userName, submissionResponse
}: {
    userName: string
    submissionResponse: SubmissionResponse
}) => {
    const classes = useSubmissionConfirmationStyles()
    const history = useHistory()

    return (
        <BaseContainer
            header={
                <CenteredIconHeader icon={<TickCircleIcon className={classes.icon}/>} />
            }
            showCta extraComponent={
                <StickyFooterGroup>
                    <Button primary onClick={() => history.push('/home')}>
                        Continue to home
                    </Button>
                    <Button onClick={() => history.push('/paper-records/new')}>
                        Submit another document
                    </Button>
                </StickyFooterGroup>
            }>

            <HeaderTitle>Submission confirmation</HeaderTitle>

            <p>
                Thank you - submission successful.
                You will receive email confirmation once your submission has been processed, which is typically within 5 working days.
                The submission status is also visible within the ‘Submission History’ tab.
            </p>

            <p>
                <Button fullWidth onClick={() => history.push('/paper-records/history', {
                    backTo: '/paper-records/new'
                })}>
                    Submission history
                </Button>
            </p>

            <Typography variant="bodyTextSmallDark" style={{marginBottom: 4}}>
                Submission #{submissionResponse.submissionNumber}
            </Typography>

            <SubmissionTimeline
                submittedAt={submissionResponse.submittedAt}
                resubmittedAt={submissionResponse.resubmittedAt}
            />

            <Profile name={userName} />

            <ImageCarousel imageUrls={submissionResponse.images} />
        </BaseContainer>
    )
}
