import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { RecordSteps } from "../../../components/context/recordReducer"
import { RecordContext, useRecordContext } from "../../../components/context/recordState"
import { DatePicker, toISOString } from "../../../components/date_picker"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"

const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    }
}))

export const SetDate = () => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [date, setDate] = useState<string | null>(null)
    const { state, dispatch } = useRecordContext(RecordContext)
    const maxDate = new Date()

    useEffect(() => {
        if (state.date) {
            setDate(state.date)
        }
    }, [state.date])

    useEffect(() => {
        if (date) {
            setIsFormValid(true)
        }
    }, [date])

    function dispatchData() {
        if (!date) {
            throw "Date is not provided"
        }

        dispatch({
            type: RecordSteps.SET_DATE,
            payload: {
                date
            }
        })
    }

    function handleSubmit() {
        setLoading(true)
        dispatchData()
        history.push(`/add-record/${state.user.uuid}/product`)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                    Next
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>
            <HeaderTitle>
                When did you take the vaccine?
            </HeaderTitle>
            <DatePicker
                fullWidth
                name="vaccinationDate"
                maxDate={maxDate}
                minDate={state.user.dob}
                margin="normal"
                openTo="year"
                label="Date"
                views={["year", "month", "date"]}
                placeholder={'dd MMM yyyy'}
                value={date}
                helperText=''
                onChange={(date) => setDate(date ? toISOString(date) : null)}
                required
            />
            <TextButton
                className={classes.textButton}
                onClick={() => {
                    if (date) {
                        dispatch({
                            type: RecordSteps.SET_DATE,
                            payload: {
                                date
                            }
                        })
                    }
                    history.push(`/add-record/${state.user.uuid}/date-cannot-proceed`)
                }}
            >
                I don't know
            </TextButton>
        </BaseContainer>
    )
}
