import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { axiosAuthedInstance, axiosInstance } from "../../utils/axiosApi";
import { isLoggedIn } from "../../utils/auth";
import { OnboardingJourney } from "../../components/context/connection_invite";
import axios from "axios";

const AcceptConnectionInvite = (props) => {
    const history = useHistory();

    const token = props.match.params.token
    const source = axios.CancelToken.source()

    useEffect(() => {
        const checkInvite = () => {
            axiosInstance.post('user/invite/check-invite/', { token }, {
                cancelToken: source.token
            }).then(result => {
                if (result.data?.data?.valid) {
                    if (result.data.data.source === 'ClinicInvite') {
                        history.push('/connection/adult/consent', {
                            token,
                            journey: OnboardingJourney.CLINIC_ACCOUNT_HOLDER,
                            account_holder: result.data.data.clinic_invite.account_holder,
                            organisation: result.data.data.clinic_invite.organisation,
                            organisation_phone: result.data.data.clinic_invite.organisation_phone,
                            email: result.data.data.clinic_invite.email,
                        })
                    } else {
                        if (isLoggedIn()) {
                            // Open the SchoolInvite and redirect to home as user is already logged-in
                            axiosAuthedInstance.post(
                                'user/invite/post-signup-login/',
                                { token }
                            ).then(() => {
                                history.push('/home')
                            }).catch(err => {
                                console.error(err)
                                const errorMessage = err.response?.data?.error || 'There was an error with the request. Please try again later and make sure the url is correct'
                                history.replace('/invite/error', {
                                    error: errorMessage
                                })

                            })
                        } else {
                            // Set token in (browser location) state for login / sign up
                            history.push('/', { token, type: 'connection'})
                        }
                    }
                } else {
                    const errorMessage = result.data?.data?.error_reason || 'Token is either invalid or has expired'
                    history.replace('/invite/error', {
                        title: 'Your invitation link is no longer valid',
                        error: errorMessage
                    })
                }
            }).catch(error => {
                if (axios.isCancel(error)) return
                console.error(error)
                history.replace('/invite/error', {
                    error: 'There was an error with the request. Please try again later and make sure the url is correct.'
                })
            })
        }
        checkInvite()

        return () => {
            source.cancel('Component is unmounting')
        }
    }, [history, token, source]);

    return (
        <Loading loading={true}/>
    )
}
export default AcceptConnectionInvite;
