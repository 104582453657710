import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import styled from 'styled-components';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DatePicker, toISOString } from "../../components/date_picker";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import { axiosAuthedInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";
import SuccessDialog from "../../components/dialogs/success_dialog";
import ErrorDialog from "../../components/dialogs/error_dialog";
import {validateName, validateEmail} from '../../components/text_input'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 1.5,
        marginBottom: theme.spacing(2)
    },
    share_text: {
        marginBottom: theme.spacing(2)
    },
    secondary_text: {
        fontSize: '0.8rem',
        lineHeight: '0.9rem',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    },
    selector: {
        minWidth: 300,
    },
    select: {
        '&:focus': {
            backgroundColor: theme.palette.common.white
        }
    }
}))

const TestClinicInvite = (props) => {
    const [loading, setLoading] = useState(true);
    const [clinics, setClinics] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [clinic_id, setClinicId] = useState('')
    const [state, setState] = useState({full_name: '', patient_id: ''})
    const [formErrors, setFormErrors] = useState({full_name: '', patient_id: ''})
    const [dob, handleDateChange] = useState(null)
    const [dobError, setDobError] = useState('')
    const [accountHolder, setAccountHolder] = useState(false)
    const [success, setSuccess] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const classes = useStyles();

    function handleError (error, defaultMsg) {
        let msg = defaultMsg
        if (error.response?.data?.error) {
            console.error(error)
            msg = error.response.data.error
        }
        setError(msg)
        setShowError(true)
        setLoading(false)
    }

    async function getClinics() {
        try {
            setLoading(true)
            const response = await axiosAuthedInstance.get('/test/get-clinics/')
            setClinics(response.data.data)
            setClinicId(response.data.data[0].uuid)
            setLoading(false)
        } catch (error) {
            handleError(error, 'Error getting clinics')
        }
    }

    const sendEmail = () => {
        setLoading(true)
        axiosAuthedInstance.post('/test/send-clinic-invite/', {
            email,
            clinic_id,
            patient_info: {...state, dob: toISOString(dob)},
            account_holder: accountHolder,
        }).then(() => {
            setLoading(false)
            setSuccess('Email sent successfully')
            setShowSuccess(true)
        }).catch(err => {
            handleError(err, 'Error sending email.')
        })
    }

    function handleChange(event) {
        let error
        let text = event.target.value
        switch (event.target.name) {
            case 'full_name':
                error = validateName(text)
                break;
            default:
                break;
        }
        setFormErrors(prevState => ({...prevState, [event.target.name]: error}))
        setState(prevState => ({...prevState, [event.target.name]: text}))
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setEmailError(validateEmail(event.target.value))
    }

    useEffect(() => {
        getClinics();
    }, []);

    return (
        <BaseContainer {...props}>
            {loading && <Loading loading={loading} />}
            <Typography variant="h5" className={classes.heading}>
                Create new clinic invite
            </Typography>
            <Typography className={classes.share_text}>Select Clinic</Typography>
            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                <FormControl className={classes.selector} variant="outlined" >
                    <InputLabel>Clinic</InputLabel>
                    <Select
                        classes={{ select: classes.select }}
                        open={openSelect}
                        onOpen={() => setOpenSelect(true)}
                        onClose={() => setOpenSelect(false)}
                        native
                        value={clinic_id}
                        onChange={(event) => {
                            setClinicId(event.target.value)
                        }}
                        label="Clinic">
                        {clinics.map((clinic, i) => <option key={i} value={clinic.uuid}>{clinic.full_name}</option>)}
                    </Select>
                </FormControl>
            </ClickAwayListener>
            <TextField
                id="full_name"
                name="full_name"
                label="Full Name"
                placeholder="Full Name"
                fullWidth
                error={!!formErrors.full_name}
                helperText={formErrors.full_name}
                margin="normal"
                value={state.full_name}
                onChange={handleChange}
            />
            <TextField
                id="patient_id"
                name="patient_id"
                label="Patient ID"
                placeholder="Patient ID"
                fullWidth
                error={!!formErrors.patient_id}
                helperText={formErrors.patient_id}
                margin="normal"
                value={state.patient_id}
                onChange={handleChange}
            />
            <DatePicker
                disableFuture
                fullWidth
                id="dob"
                name="dob"
                openTo="year"
                label="Date of birth"
                views={["year", "month", "date"]}
                placeholder={'02/10/2010'}
                margin="normal"
                value={dob}
                onError={(err) => setDobError(err)}
                onChange={handleDateChange}
            />
            <TextField
                id="email"
                name="email"
                label="Email"
                placeholder="Enter recipient's email address"
                fullWidth
                margin="normal"
                value={email}
                error={!!emailError}
                helperText={emailError}
                onChange={handleEmailChange}
            />
            <FormControlLabel
                className={classes.margin}
                control={<Checkbox name='accountHolder' checked={accountHolder} onChange={() => setAccountHolder(!accountHolder)} color="primary" />}
                label={'Account Holder?'}
            />
            <Box width="100%" mt={2} mb={2}>
                <Button fullWidth variant='contained' color='primary' onClick={() => sendEmail()}>
                    Send Email
                </Button>
            </Box>
            <SuccessDialog open={showSuccess} setOpen={setShowSuccess} success={success}/>
            <ErrorDialog open={showError} setOpen={setShowError} error={error}/>
        </BaseContainer>
    )
}
export default TestClinicInvite;
