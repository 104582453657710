import { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders, positions } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}${positions}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    menu: {
        padding: theme.spacing(1, 0, 1),
    },
    menuItem: {
        padding: theme.spacing(1, 7, 1, 2),
        color: theme.palette.grey[600],
        zIndex: 3
    },
    leftIcon: {
        margin: theme.spacing(0, 2.5, 0, 0)
    },
    divider: {
        margin: theme.spacing(1, 0)
    },
    selector: {
        minWidth: 300,
    },
    select: {
        '&:focus': {
            backgroundColor: theme.palette.common.white
        }
    },
}))


const Input = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const [text, setText] = useState('')
    const [wrongText, setWrongText] = useState('')
    const [name, setName] = useState('Name 1 (Me)')
    const [openSelect, setOpenSelect] = useState(false)

    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" flex={1} margin={1}>
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            TextFields
                        </Typography>
                        <TextField
                            id="test"
                            name="test"
                            label="Label"
                            fullWidth
                            placeholder="Placeholder"
                            margin="normal"
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                        />
                        <TextField
                            id="test2"
                            name="test2"
                            label="Label"
                            fullWidth
                            placeholder="Placeholder"
                            margin="normal"
                            error={true}
                            value={wrongText}
                            helperText={'error message'}
                            onChange={(event) => setWrongText(event.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" flex={1} margin={1}>
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            Menus
                        </Typography>
                        <Box marginTop={1} marginBottom={2}>
                            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                                <FormControl className={classes.selector} variant="outlined" >
                                    <InputLabel>Dropdown Label</InputLabel>
                                    <Select
                                        classes={{select: classes.select}}
                                        open={openSelect}
                                        onOpen={() => setOpenSelect(true)}
                                        onClose={() => setOpenSelect(false)}
                                        native
                                        value={name}
                                        onChange={(event) => {
                                            setName(event.target.value)
                                        }}
                                        label="Dropdown Label"
                                    >
                                        <option value={'Name 1 (Me)'}>Name 1 (Me)</option>
                                        <option value={'Name 2'}>Name 2</option>
                                        <option value={'Name 3'}>Name 3</option>
                                    </Select>
                                </FormControl>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}

export default Input
