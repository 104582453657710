import { makeStyles } from "@material-ui/core"
import { Button } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import Typography from "../../../components/typography"
import { cx } from "../../../utils/common"

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.grey[600]
    },
    paragraphGap: {
        marginTop: '20px'
    }
}))

type CannotProceedProps = {
    exitWizard: () => void
}

export const LocationCannotProceed: React.FC<CannotProceedProps> = ({exitWizard}) => {
    const classes = useStyles()

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={() => exitWizard()}>
                    OK
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>
            <HeaderTitle>
                Cannot proceed
            </HeaderTitle>
            <Typography variant="bodyTextLargeBold" className={classes.text}>
                We need to know the country in which you were vaccinated, to allow us to guide you through product selection.
                Without this information, we cannot use your record.
            </Typography>
            <Typography variant="bodyTextLargeBold" className={cx(classes.text, classes.paragraphGap)}>
                We also need to know the setting where the vaccine was administered.
            </Typography>
        </BaseContainer>
    )
}

export const HemisphereCannotProceed: React.FC<CannotProceedProps> = ({exitWizard}) => {
    const classes = useStyles()

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={() => exitWizard()}>
                    OK
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>
            <HeaderTitle>
                Cannot proceed
            </HeaderTitle>
            <Typography variant="bodyTextLargeBold" className={classes.text}>
                We need to know the vaccine formulation/season, in order to provide you with a vaccination status.
                Without this information, we’re unable to record the vaccination event.
            </Typography>
        </BaseContainer>
    )
}

export const DateCannotProceed: React.FC<CannotProceedProps> = ({exitWizard}) => {
    const classes = useStyles()

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={() => exitWizard()}>
                    OK
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>
            <HeaderTitle>
                Cannot proceed
            </HeaderTitle>
            <Typography variant="bodyTextLargeBold" className={classes.text}>
                We need to know the vaccine administration date, in order to provide a vaccination status.
                Without this information, we’re unable to record the vaccination event.
            </Typography>
        </BaseContainer>
    )
}
