import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory, useLocation } from "react-router-dom"
import { Button } from "../../components/button"
import { BaseContainer } from "../../components/container"
import { HeaderTitle } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import Typography from "../../components/typography"
import { formatDate, TDateISODate } from "../../utils/common"

type DobMismatchType = {
    /** Fields from the clinic object */
    organisation: {
        name: string
        phone: string
        email: string
    }

    /** DOB as held on file with the clinic (ClinicProfile.dob) */
    clinicDob: TDateISODate

    /** DOB of the signed in Imunis user (ImunisUser.dob) */
    userDob: TDateISODate
}

const useStyles = makeStyles(() => ({
    contentDivider: {
        marginTop: '15px'
    },
    sectionDivider: {
        marginTop: '30px'
    }
}))

export const DobMismatch = () => {
    const props = useLocation<DobMismatchType>()
    const classes = useStyles()
    const history = useHistory()

    function handleContinue() {
        history.replace('/home')
    }

    return (
        <BaseContainer showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleContinue}>
                    Continue
                </Button>
            </StickyFooterGroup>
        }>
            <HeaderTitle>
                Date of birth mismatch
            </HeaderTitle>
            <Typography variant="bodyTextMediumDark">
                Date of birth registered with {props.state.organisation.name}:
            </Typography>
            <Typography variant="bodyTextMediumBold" className={classes.contentDivider}>
                {formatDate(props.state.clinicDob)}
            </Typography>
            <Typography variant="bodyTextMediumDark" className={classes.contentDivider}>
                Date of birth held with Imunis:
            </Typography>
            <Typography variant="bodyTextMediumBold" className={classes.contentDivider}>
                {formatDate(props.state.userDob)}
            </Typography>
            <Typography variant="bodyTextMediumDark" className={classes.sectionDivider}>
                Imunis uses date of birth for records matching and vaccination status.
                In order to connect with {props.state.organisation.name} and receive digital vaccination records,
                the dates of birth must match and a new connection invitation must be issued by the clinic.
            </Typography>
            <Typography variant="bodyTextMediumDark" className={classes.contentDivider}>
                To edit date of birth with {props.state.organisation.name}:
            </Typography>
            <ul>
                <li>
                    <Typography variant="bodyTextMediumDark">
                        Email <Link data-action="DOB conflict: contact clinic email"
                            href={`mailto:${props.state.organisation.email}`}>
                            {props.state.organisation.email}
                        </Link>
                    </Typography>
                </li>
                <li>
                    <Typography variant="bodyTextMediumDark">
                        Call <Link data-action="DOB conflict: contact clinic phone"
                            href={`tel:${props.state.organisation.phone}`}>
                            {props.state.organisation.phone}
                        </Link>
                    </Typography>
                </li>
            </ul>
            <Typography variant="bodyTextMediumDark">
                To edit date of birth with Imunis:
            </Typography>
            <ul>
                <li>
                    <Typography variant="bodyTextMediumDark">
                        Email <Link data-action="DOB conflict: contact Imunis"
                            href="mailto:support@imunis.com">
                            support@imunis.com
                        </Link>
                    </Typography>
                </li>
            </ul>
        </BaseContainer>
    )
}
