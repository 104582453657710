// key for localStorage
export const LOCAL_STORAGE_STAY_SIGNED_IN = 'remember_me'

export const login_expiration_time = 1800000; // 30 minutes

export const notifications_polling_interval = 30000; // 30 seconds

//HTTP CODE
export const HTTP_STATUS = {
    HTTP_UNAUTH: 401,
    HTTP_NOT_FOUND: 404,
    CLIENT_CLOSED_REQUEST: 499,
    HTTP_INTERNAL_SERVER_ERROR: 500,
    HTTP_SERVICE_UNAVAILABLE: 503
}
