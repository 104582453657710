import SvgIcon from '@material-ui/core/SvgIcon';

function ImageOutlined() {
    return (
        <SvgIcon viewBox="0 0 24 24" >
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M18.86 5H5.14C4.51 5 4 5.52 4 6.17v11.66c0 .65.51 1.17 1.14 1.17h13.72c.63 0 1.14-.52 1.14-1.17V6.17C20 5.52 19.49 5 18.86 5Z"/>
                <path d="m4 14.89 3.4-3.3a1 1 0 0 1 1.28-.08l3.3 2.44a1 1 0 0 0 1.08.07l1.88-1.04a1 1 0 0 1 1.08.07L20 16"/>
            </g>
            <circle cx="16.25" cy="9.25" r="1.25"/>
        </SvgIcon>
    )
}

export default ImageOutlined
