import { FormGroup } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "../../components/button"
import { BackButton } from "../../components/buttons/back_btn"
import CheckboxCard from "../../components/checkbox_card"
import { BaseContainer } from "../../components/container"
import { InfoBox } from "../../components/info_box"
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { TwoLineText } from "../../components/two_line_text"
import Typography from "../../components/typography"

const SelectSharingUsers = (props) => {
    const [selected, setSelected] = useState([])
    const history = useHistory()

    const addToSharing = (event, dependant) => {
        const checked = event.target.checked
        if (checked) {
            setSelected(old => [...old, dependant])
        } else {
            setSelected(old => {
                const newArr = old.filter(dep => dep.uuid !== dependant.uuid)
                return newArr
            })
        }
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button fullWidth primary disabled={!selected.length}
                    onClick={() => {history.push('/sharing/send', {dependants: selected} )}}>
                    Share
                </Button>
                <Button fullWidth onClick={props.stopSharing}>
                    Cancel
                </Button>
            </StickyFooterGroup>
        }>

            <BackButton onClick={props.stopSharing}/>

            <HeaderTitle>
                Select profile to share
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    You can share view access to the records you manage with other trusted parties.
                </Typography>
            </PageDescription>

            <PageDescription>
                <InfoBox>
                    <Typography variant="bodyTextMediumDark">
                        Vaccination records contain sensitive personal healthcare information - please only share with parties you trust.
                    </Typography>
                </InfoBox>
            </PageDescription>

            <FormGroup>
                {props.dependants.map(dep => (
                    <CheckboxCard
                        key={dep.uuid}
                        label={
                            <TwoLineText>
                                <Typography variant="bodyTextLargeBold">
                                    {dep.preferred_name}
                                </Typography>
                            </TwoLineText>
                        }
                        value={dep.uuid}
                        onChange={(e) => addToSharing(e, dep)}
                    />
                ))}
            </FormGroup>

        </BaseContainer>
    )
}

export default SelectSharingUsers
