import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import RecordsWarningIcon from "../../assets/icons/records_warning"
import ActionDialog from "../../components/dialogs/action_dialog"
import Scanner from "../../components/scanner"
import { axiosAuthedInstance } from "../../utils/axiosApi"
import { possessiveCheck } from "../../utils/common"
import { getUUID } from "../../utils/auth"
import { UserContextProvider } from "../records/user_header"
import ConfirmVaccine from "./confirm_vaccine"
import { Success } from "./add_covid_record"

const Step = {
    SCAN: 1,
    DISCREPANCY: 2,
    CONFIRMATION: 3,
}

const AddCovidVaccine = (props) => {
    const [step, setStep] = useState(Step.SCAN)
    const [user, setUser] = useState(props.location.state?.user)
    const [QRData, setQRData] = useState(null)
    const [discrepancies, setDiscrepancies] = useState(null)
    const [duplicate, setDuplicate] = useState(false)
    const [successData, setSuccessData] = useState(null)
    const [notice, setNotice] = useState(false)
    const [noticeMsg, setNoticeMsg] = useState('')
    const [suggested, setSuggested] = useState(null)
    const [showInfo] = useState(props.location.state?.showIntro)
    const history = useHistory()

    // Require a user to be provided, otherwise we can't do anything
    // Reproducible by visiting /scan directly
    useEffect(() => {
        if (!user) {
            console.warn("User not provided")
            history.replace('/')
        }
    }, [history, user])

    if (!user) {
        return null
    }

    const onFinishScanning = (addedRecordKeys) => {
        history.push(`/records/${user.uuid}/health`, {
            addedRecords: addedRecordKeys,
            // Expand the covid disease widget, even when no records were added
            openDiseases: ['840539006'],
        })
    }

    const scanVaccine = (qrData, uuid = user.uuid, confirmNotice = false, confirm = false) => {
        return axiosAuthedInstance.post('/scan-vaccine-cert/', {
            data: qrData,
            uuid,
            confirm_notice: confirmNotice,
            confirm_discrepancies: confirm,
        })
    }

    const onScanSuccess = (result, qrData = QRData) => {
        if (result.data.notice_msg) {
            setQRData(qrData)
            setNoticeMsg(result.data.notice_msg)
            setNotice(true)
        } else if (result.data.discrepancies) {
            setQRData(qrData)
            setDiscrepancies(result.data.discrepancies)
            setSuggested(result.data.discrepancies.suggested.uuid)
            setStep(Step.DISCREPANCY)
        } else if (result.data.duplicate) {
            setDuplicate(true)
        } else {
            setSuccessData(result.data)
            setStep(Step.CONFIRMATION)
        }
    }

    const confirmNoticeAction = () => {
        return scanVaccine(QRData, user.uuid, true)
            .then((result) => onScanSuccess(result))
    }

    const confirmUser = (usr) => {
        setUser(usr)
        return scanVaccine(QRData, usr.uuid, true, true)
            .then((result) => onScanSuccess(result))
    }

    const onCancel = () => {
        if (step == Step.DISCREPANCY) {
            setStep(Step.SCAN)
        }
        else {
            history.goBack()
        }
    }

    const duplicateError =
        <ActionDialog
            title='Record already exists'
            content='This record hasn’t been updated.'
            action_label='OK'
            action_callback={() => onFinishScanning([])}
            openDialog={duplicate}
            setOpenDialog={setDuplicate}
            icon={<RecordsWarningIcon />}
        />

    const noticeDialog =
        <ActionDialog
            cancel
            title='Notice'
            content={noticeMsg}
            action_label='Confirm add record'
            action_callback={confirmNoticeAction}
            cancel_label='Cancel'
            openDialog={notice}
            setOpenDialog={setNotice}
        />

    let page = null

    switch (step) {
        case Step.SCAN: {
            const title = 'Scan to add Covid-19 record'
            const content = `Scan the QR code on ${possessiveCheck(user.preferredName)} Covid-19 record`
            const infoText = 'Scan a paper QR code or import a photo of a paper or digital QR code. Imunis supports Hong Kong, EU and NHS-issued QR codes.'
            page = <Scanner
                title={title}
                content={!showInfo && content}
                api_call={scanVaccine}
                api_callback={onScanSuccess}
                infoText={showInfo && infoText}
                onCancel={onCancel}
            >
                {noticeDialog}
                {duplicateError}
            </Scanner>
            break;
        }
        case Step.DISCREPANCY: {
            page = <ConfirmVaccine
                suggested={suggested}
                discrepancies={discrepancies}
                confirmUser={confirmUser}
                onCancel={onCancel}
            >
                {duplicateError}
            </ConfirmVaccine>
            break;
        }
        case Step.CONFIRMATION: {
            const addedRecordKeys = successData.added_records.map(rec => (
                `${rec.resolved_target},${rec.vaccine_date}`
            ))
            page = <Success
                addedRecords={successData.added_records}
                doseCount={successData.dose_count}
                newRecordKeys={successData.new_record_keys}
                isDependant={user.uuid !== getUUID()}
                exitWizard={() => {onFinishScanning(addedRecordKeys)}}
            />
            break
        }
        default:
            break;
    }

    return (
        <UserContextProvider userId={getUUID()}>
            {page}
        </UserContextProvider>
    )
}

export default AddCovidVaccine
