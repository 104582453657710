import {
    DatePicker as MuiDatePicker,
    DatePickerProps
} from "@material-ui/pickers"

const getTimezoneOffset = (d: Date) => d.getTimezoneOffset() * 60000;

const shim = (d: DatePickerProps['value']): DatePickerProps['value'] => {
    if (d instanceof Date) return new Date(d.getTime() + getTimezoneOffset(d))

    if (typeof(d) === 'string') {
        // A component is storing dates as strings - we should align to use Date objects internally
        return shim(new Date(d))
    }

    return d
}

const unshim = (d: Date | null) => {
    if (d) {
        return new Date(d.getTime() - getTimezoneOffset(d))
    } else {
        return d
    }
}

export const toISOString = (date: Date): string => {
    return date.toISOString().substring(0, 10)
}

/**
 * Wrap MUI's DatePicker component to handle local dates correctly.
 */
export const DatePicker = (props: DatePickerProps) => (
    <MuiDatePicker
        format="dd MMM yyyy"
        {...props}
        value={shim(props.value)}
        onChange={value => props.onChange(unshim(value))}
    />
)
