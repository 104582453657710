import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const Buttons = (props) => {
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection="row" border={2} borderRadius={16} m={2} p={2}>
            <Box display="flex" flexDirection="column" mr={2} flexGrow={0.5} >
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Primary
                </Typography>
                <Box mb={3}>
                    <Button variant='contained' color='primary'> Button </Button>
                </Box>
                <Box mb={3}>
                    <Button variant='contained' color='primary' fullWidth> Button </Button>
                </Box>
                <Box mb={3}>
                    <Button variant='contained' color='primary' fullWidth disabled> Button </Button>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={0.5} >
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Outlined
                </Typography>
                <Box mb={3}>
                    <Button variant='outlined'> Button </Button>
                </Box>
                <Box mb={3}>
                    <Button variant='outlined' fullWidth> Button </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Buttons