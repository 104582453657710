import SvgIcon from '@material-ui/core/SvgIcon';

function ProfileIcon(props) {
    return (
        <SvgIcon viewBox="0 0 26 26" {...props}>
            <path fill="none" d="M18.4762 20.2857C18.4762 18.7363 17.6644 16.9348 15.4905 16.9348H11.5095C9.36042 16.9348 8.5238 18.7363 8.5238 20.2857M16.4857 11.3275C16.4857 12.876 15.1491 14.1312 13.5 14.1312C11.8509 14.1312 10.5143 12.876 10.5143 11.3275C10.5143 9.77892 11.8509 8.5238 13.5 8.5238C15.1491 8.5238 16.4857 9.77892 16.4857 11.3275Z" stroke={props.strokecolor || "#333"} strokeWidth="1.5" strokeLinecap="square"/>
            <circle fill="none" cx="13.5" cy="13.5" r="8.6" stroke={props.strokecolor || "#333"} strokeWidth="1.8"/>
        </SvgIcon>
    );
}

export default ProfileIcon