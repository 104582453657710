import { useContext, useState } from "react";
import { Button } from "../../components/button";
import { BaseContainer } from "../../components/container";
import Typography from "../../components/typography";
import { spacing, sizing, flexbox, display, borders, } from '@material-ui/system';
import styled from 'styled-components';
import { makeStyles } from "@material-ui/styles";
import { UserContext } from "../records/user_header";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { ModalTitle, PageDescription } from "../../components/page_sections";
import RadioCard from "../../components/radio_card";
import RadioCardGroup from "../../components/radio_card_group";
import ErrorDialog from "../../components/dialogs/error_dialog";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const useStyles = makeStyles((theme) => ({
    box: {
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        borderRadius: '10px',
        width: '100%',
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 1.25,
    },
    Caption_12: {
        fontWeight: 400,
        fontSize: theme.typography.fontSize * 0.75,
        color: theme.palette.grey[600],
    },
    Body1_16_Regular: {
        fontWeight: 400,
        fontSize: theme.typography.fontSize,
        marginBottom: theme.spacing(3),
    },
    Body2_14: {
        fontWeight: 400,
        fontSize: theme.typography.fontSize * 0.875,
    },
    selector: {
        minWidth: 300,
    },
    select: {
        '&:focus': {
            backgroundColor: theme.palette.common.white
        }
    },
}))

const ConfirmVaccine = (props) => {
    const [selected, setSelected] = useState(props.suggested)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const {primaryUsers} = useContext(UserContext)
    const classes = useStyles()


    const confirmUser = () => {
        const selected_user = primaryUsers.filter(d => d.uuid === selected)[0]
        setLoading(true)
        props.confirmUser(selected_user)
            .catch(err => {
                console.error(err)
                setError(err.response?.data?.error || 'Error adding vaccine')
                setShowError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth onClick={confirmUser} loading={loading}>
                Confirm
            </Button>
            <Button fullWidth component="label" onClick={props.onCancel}>
                Cancel
            </Button>
        </StickyFooterGroup>}>

            <ModalTitle>
                Confirmation
            </ModalTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    Please confirm the information matches
                </Typography>
            </PageDescription>

            <Box className={classes.box}>
                <Typography variant="bodyTextLargeBold">
                    Record information
                </Typography>
                <Box paddingBottom={0.5}/>
                {props.discrepancies.name &&
                    <Typography variant="bodyTextMediumDark">
                        {props.discrepancies.name[0]}
                    </Typography>
                }
            </Box>
            <Box marginTop={2} marginBottom={4} className={classes.box}>
                <Typography variant="bodyTextLargeBold">
                    Imunis information
                </Typography>
                <Box paddingBottom={0.5}/>
                {props.discrepancies.name &&
                    <Typography variant="bodyTextMediumDark" color={"textPrimary"}>
                        {props.discrepancies.name[1]}
                    </Typography>
                }
            </Box>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    Please confirm you are uploading records for the correct patient.
                </Typography>
            </PageDescription>


            <RadioCardGroup value={selected} name="users" onChange={(e) => { setSelected(e.target.value) }}>
                {primaryUsers?.map(dependant => (
                    <RadioCard
                        compact
                        key={dependant.uuid}
                        label={
                            <Typography variant="bodyTextLargeBold">
                                {dependant.preferred_name}
                            </Typography>}
                        value={dependant.uuid}
                    />
                ))}
            </RadioCardGroup>

            <ErrorDialog setOpen={setShowError} open={showError} error={error} />

            {props.children}
        </BaseContainer>
    )
}

export default ConfirmVaccine
