import { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RecordContext, RecordProvider, useRecordContext } from "../../../components/context/recordState";
import { LocationCannotProceed, HemisphereCannotProceed, DateCannotProceed } from "./cannot_proceed";
import { RecordDuplicate } from "./record_duplicate";
import { SetDate } from "./set_date";
import { SetHemisphere } from "./set_hemisphere";
import { SetLocation } from "./set_location";
import { SetProduct } from "./set_product";
import { AddRecordSummary } from "./summary";
import { checkAll } from "../../../utils/common";
import { SubroutineContext, SubroutineProvider } from "../../../components/context/subroutine";

type AddRecordStep = 'location' | 'hemisphere' | 'date' | 'product' | 'summary' |
'location-cannot-proceed' | 'hemisphere-cannot-proceed' | 'date-cannot-proceed' |
'record-duplicate'

type AddRecordProps = RouteComponentProps<{
    uuid: string
    step: AddRecordStep
}>
const AddRecordStep: React.FC<{
    step: AddRecordStep
}> = ({step}) => {
    const { state } = useRecordContext(RecordContext)
    const { exitWizard } = useContext(SubroutineContext)
    const { country, provider, otherProvider, hemisphere, date, product, otherProduct } = state

    // Ensure our state contains the required values for a step
    switch (step) {
        case 'location':
            return <SetLocation />
        case 'hemisphere':
            if (checkAll(country, provider || otherProvider)) {
                return <SetHemisphere />
            }
            break
        case 'date':
            if (checkAll(country, provider || otherProvider, hemisphere)) {
                return <SetDate />
            }
            break
        case 'product':
            if (checkAll(country, provider || otherProvider, hemisphere, date)) {
                return <SetProduct />
            }
            break
        case 'summary':
            if (checkAll(country, provider || otherProvider, hemisphere, date, product || otherProduct)) {
                return <AddRecordSummary exitWizard={exitWizard} />
            }
            break
        case 'location-cannot-proceed':
            return <LocationCannotProceed exitWizard={exitWizard} />
        case 'hemisphere-cannot-proceed':
            return <HemisphereCannotProceed exitWizard={exitWizard} />
        case 'date-cannot-proceed':
            return <DateCannotProceed exitWizard={exitWizard} />
        case 'record-duplicate':
            return <RecordDuplicate exitWizard={exitWizard} />
    }

    // If we're here, we have some data missing in state. Likely from a page reload or direct URL visit
    return <SetLocation />
}

/**
 * Add patient-certified record workflow (wizard).
 */
export const AddRecord = ({ match }: AddRecordProps) => {

    return (
        <RecordProvider user={match.params.uuid}>
            <SubroutineProvider>
                <AddRecordStep step={match.params.step}/>
            </SubroutineProvider>
        </RecordProvider>
    )
}
