import { HealthPage } from "./health";
import { ProfilePage } from "./profile";
import { UserContextProvider } from "./user_header";

const UserPage = (props) => {
    let child;
    switch (props.match.params.tab) {
        case 'health':
        case 'vaccine':
        case 'certificate':
        case 'past-records':
            child = <HealthPage {...props} />;
            break;
        case 'profile':
            child =  <ProfilePage {...props} />
            break;
        default:
             // FIXME set default: page not found?
    }

    return (
        <UserContextProvider userId={props.match.params.uuid}>
            {child}
        </UserContextProvider>
    )
}

export default UserPage;
