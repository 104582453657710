import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { JsonContent } from "../static_content";
import { InfoPanel } from "../info_panel";
import measlesJson from "../../content/measles.json";
import { VaccinationStatus } from "../vaccination_progress";

type MeaslesContentProps = {
    status: VaccinationStatus
}

export const measlesStatusContent = ({ status }: MeaslesContentProps) => {
    const emergencyText = " Doses denoted with an 'E' are considered 'emergency doses', i.e. taken below 12 months of age and are not considered part of an official course. This is because the vaccine is less effective when given under 12 months of age. Some organisations/jurisdictions may not accept this dose if proof of vaccination is required."
    const emergencyText2 = "Dose is recognised as an 'emergency dose' as it was taken before 12 months of age (the minimum age for recognition as part of the official course). Note that the 'emergency dose' will not count towards full vaccination status with Imunis as many organisations and public health institutions do not recognise it because the vaccine is less effective when given under 12 months of age. You will become eligible for vaccination once the minimum interval between doses has passed and you reach the target age for vaccination."
    if (status.action_status === 'ELIGIBLE') {
        let dosesText;
        switch (status.doses_taken) {
            case 0:
                dosesText = 'You are eligible for vaccination with no qualifying records held with Imunis.'
                break
            case 1:
                dosesText = 'You are partially-vaccinated with one qualifying record held with Imunis, and eligible for vaccination.'
                break
        }
        if (status.emergency > 0) {
            dosesText += emergencyText
        }
        return dosesText
    }
    if (status.action_status === 'UNAVAILABLE') {
        switch (status.unavailable_reason) {
            case 'CONFLICT':
                return "Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing."
            case "UNSPECIFIED_PRODUCT":
                return "Vaccination status is currently unavailable due to a lack of necessary information about the vaccine products/type of products taken."
            case 'DOB_UNAVAILABLE':
                return 'Status is unavailable due to missing DOB'
            case 'BELOW_AGE':
                return 'Your records indicate vaccination below the minimum recommended age. As a result, Imunis cannot at this time calculate your vaccination status.'
        }
    }
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'ineligible') {
        if (status.emergency > 0) {
            return emergencyText2
        }
        return 'You are ineligible for vaccination as your current age falls below the recommended age for measles vaccination.'
    }
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'unvaxed' && status.emergency > 0) {
        return emergencyText2
    }
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'partial') {
        const dosesText = "You are partially-vaccinated and up to date and with one qualifying record held with Imunis."
        if (status.emergency > 0) {
            return dosesText + emergencyText
        }
        return dosesText
    }
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'full') {
        if (status.emergency > 0) {
            return <>
                <span>
                    You are fully-vaccinated and up to date  - optimal protection occurs 14 days post-vaccination.
                </span>
                <br />
                <br />
                <span>
                    Note: {emergencyText}
                </span>
            </>
        }
        return "You are fully-vaccinated and up to date  - optimal protection occurs 14 days post-vaccination"
    }
    if (status.action_status === 'UP_TO_DATE' && status.vaccine_status === 'full_plus_delay') {
        if (status.emergency > 0) {
            return <>
                <span>
                    You are up to date and fully-vaccinated, and should have developed optimal protection at more than 14 days post-vaccination.
                </span>
                <br />
                <br />
                <span>
                    Note: {emergencyText}
                </span>
            </>
        }
        return "You are up to date and fully-vaccinated, and should have developed optimal protection at more than 14 days post-vaccination."
    }
}

export const MeaslesContent = ({ status }: MeaslesContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {measlesStatusContent({ status: status })} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    <Divider />

    <JsonContent>
        {measlesJson}
    </JsonContent>
</>)
