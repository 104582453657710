import * as React from 'react'
import { IconButton } from "../button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import { cx } from '../../utils/common';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles<Theme, BackButtonProps>(() => createStyles({
    backBtn: {
        marginLeft: '-16px',
    },
}))

type BackButtonProps = {
    onClick?: React.MouseEventHandler
    className?: string,
}

export const BackButton = (props: BackButtonProps) => {
    const classes = useStyles(props)
    const history = useHistory()

    return (
        <IconButton
            id="back-btn"
            aria-label="Back"
            className={cx(classes.backBtn, props.className)}
            onClick={(e) => {
                if (props.onClick) props.onClick(e)
                else history.goBack()
            }}>
            <ChevronLeftIcon/>
        </IconButton>
    )
}
