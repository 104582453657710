import { useState, useRef } from 'react';
import {makeStyles} from '@material-ui/core/styles'
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Typography from "./typography";
import { SvgIconProps } from '@material-ui/core';
import { cx } from '../utils/common'
import { IconButton } from "./button";

const useStyles = makeStyles((theme) => ({
    menu: {
        minWidth: '196px',
        maxWidth: 'calc(100vw - 10px)',
        borderRadius: '3px',
        marginTop: '10px'
    },
    menuItem: {
        minHeight: '44px',
    },
    divider: {
        margin: theme.spacing(1, 0)
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
    },
    hasItems: {
        cursor: 'pointer',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: theme.spacing(1),
        color: theme.palette.grey[800],
    }
}))

type Item = {
    /** Value to be used on parent's global function */
    id: string

    /** Title of menu item */
    title: string,

    /** Icon preposed to menu item */
    icon?: React.ComponentType<SvgIconProps>,

    /** Menu item content if more than just title and icon is needed to render */
    contents?: React.ReactNode,

    /** divider for extra spacing between items */
    divider?: boolean,
} &
// Can't just intersect with MenuItemProps: https://github.com/mui/material-ui/issues/16245
// this looks resolved in MUI v5
MenuItemProps<'li', { button: false }>

type DropdownMenuProps = {
    /** Current active value of dropdown */
    title: React.ReactNode,

    /** Array of menu items */
    items: Array<Item>,

    /** Dropdown icon */
    icon?: React.ComponentType<SvgIconProps>,

    /** Parent function of what to do given value provided by menu item */
    onClick?: (id: string) => void

    /**
     * The name that Datadog's RUM event tracking will use. By default a generic "click Dropdown" is
     * used in order to avoid flooding sessions with unique events (click Alan, click Jennie, etc.)
     */
    actionName?: string
}

export const DropdownMenu = ({title, items, actionName, icon: Icon, onClick}: DropdownMenuProps) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const backdropRef = useRef<HTMLElement>(null)

    return (
        <div
            data-action={actionName || "Dropdown"}
            className={cx(classes.container, items.length && classes.hasItems)}
            onClick={() => {if (items.length > 0) setOpen(!open)}}
            ref={anchorRef}
        >
            { title }
            { items.length > 0 ? <>
                {Icon &&
                    <Icon className={cx(classes.expand, open && classes.expandOpen)}/>}

                <Popover
                    open={open}
                    anchorEl={anchorRef.current}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    elevation={1}
                    classes={{
                        paper: classes.menu
                    }}
                    data-action="Close Dropdown"

                    // IM-1389: hack to block scrolling on iOS
                    // https://github.com/mui/material-ui/issues/5750
                    BackdropProps={{
                        ref: backdropRef,
                        style: {
                            backgroundColor: 'transparent',
                        }
                    }}
                    TransitionProps={{
                        onEntered: () => {
                            if (backdropRef.current) {
                                backdropRef.current.addEventListener(
                                    'touchmove',
                                    e => e.preventDefault(),
                                    { passive: false })
                            }
                        }
                    }}
                >
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                        {items.map((item: Item) => {
                            if (item.divider) {
                                return <Divider key={item.id} className={classes.divider}/>
                            }

                            const {id, contents, icon: IconComponent, title, ...rest} = item

                            return (
                                <MenuItem key={id} className={classes.menuItem} onClick={() => {
                                    setOpen(false)
                                    if (onClick) onClick(id)
                                }} {...rest}>
                                    {contents ||
                                        <>
                                            {IconComponent &&
                                                <IconComponent
                                                    classes={{fontSizeLarge: classes.icon}}
                                                    fontSize="large"
                                                />
                                            }
                                            <Typography variant="bodyTextLarge" noWrap>
                                                {title}
                                            </Typography>
                                        </>
                                    }
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </Popover>
            </> : null}
        </div>
    )
}

export default DropdownMenu
