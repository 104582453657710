import { SvgIcon, SvgIconProps } from '@material-ui/core';
import theme from '../../styles/theme';

type IconProps = {
    variant?: 'warningSubdued'
} & SvgIconProps

function ExclamationCircleSolidIcon({variant, ...props}: IconProps) {
    let bg, fg

    switch (variant) {
        case 'warningSubdued':
            bg = theme.palette.warning.light
            fg = theme.palette.common.black
            break
        default:
            bg = theme.palette.warning.main
            fg = theme.palette.grey[800]
    }

    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="10" fill={bg}/>
            <rect x="11" y="15" width="2" height="2" fill={fg}/>
            <rect x="11" y="7" width="2" height="6" fill={fg}/>
        </SvgIcon>
    )
}

export default ExclamationCircleSolidIcon
