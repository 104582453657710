import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Typography from '../../components/typography';
import { IconButton } from "../../components/button";
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { axiosInstance } from "../../utils/axiosApi";
import { BaseContainer } from '../../components/container'
import Loading from "../../components/loading";
import ErrorPage from "../../components/error_page";
import Alert from '../../components/alert';
import SuccessDialog from "../../components/dialogs/success_dialog";
import { validatePassword, validateConfirmPassword } from '../../components/text_input'
import CTAButton from "../../components/buttons/cta";

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false)
    const [error, setError] = useState('');
    const [expired, setExpired] = useState(false)
    const [error_open, setErrorOpen] = useState(false)
    const [form_error, setFormError] = useState('')
    const [state, setState] = useState({ password: '', confirmPassword: '' })
    const [errorMsgs, setErrorMsgs] = useState({ password: '', confirmPassword: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showSucess, setShowSuccess] = useState(false)
    const [email, setEmail] = useState('')
    const history = useHistory();

    function handleChange(event, type) {
        let error
        const text = event.target.value
        switch (type) {
            case 'password':
                error = validatePassword(text)
                if (state.confirmPassword) {
                    setErrorMsgs(prevState => ({
                        ...prevState,
                        'confirmPassword': validateConfirmPassword(state.confirmPassword, text)
                    }))
                }
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(text, state.password)
                break;
            default:
                break;
        }
        setErrorMsgs(prevState => ({ ...prevState, [event.target.name]: error }))
        setState(prevState => ({ ...prevState, [event.target.name]: text }))
    }

    useEffect(() => {
        function validateToken() {
            axiosInstance.post('/user/check-reset-token/', {
                uuid: props.match.params.uuid,
                token: props.match.params.token
            }).then(response => {
                setEmail(response.data.user_email)
            }).catch(error => {
                if (error.response && error.response.data) {
                    setError(error.response.data.error)
                    setExpired(error.response.status === 400)
                } else {
                    console.error(error)
                    setError('There was an error with the request. Please try again later and make sure the url is correct.')
                }
            }).finally(() => {
                setLoading(false)
            })
        }

        validateToken();
    }, [props.match.params.token, props.match.params.uuid])

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault()
        }

        // check has input
        if (state.password !== state.confirmPassword) {
            setFormError('Passwords do not match with each other')
            setErrorOpen(true)
            return;
        }
        setFormLoading(true)
        axiosInstance.post('/user/set-new-password/', {
            uuid: props.match.params.uuid,
            token: props.match.params.token,
            password: state.password
        }).then(() => {
            setShowSuccess(true)
        }).catch(err => {
            console.error(err)
            setFormError(err.response.data.error || "Error setting new password")
            setErrorOpen(true)
        }).finally(() => {
            setFormLoading(false)
        })
    }

    if (loading) {
        return (
            <BaseContainer {...props}>
                <Loading loading={loading} />
            </BaseContainer>
        )
    }

    if (error) {
        return (
            <ErrorPage
                expired={expired}
                title={expired ? 'Sorry, this email link has expired' : 'Error verifying link'}
                content={error}
                location={'/'}
                cta_label={"Go to homepage"}
            />
        )
    }

    const { password, confirmPassword } = state
    const formErrors = errorMsgs.password || errorMsgs.confirmPassword
    const isFormValid = password && confirmPassword && !formErrors

    return (
        <BaseContainer {...props} showCta extraComponent={
            <CTAButton
                fullWidth
                primary
                disabled={!isFormValid}
                loading={formLoading}
                form="reset-password"
                type="submit"
            >
                Confirm
            </CTAButton>
        }>
            <Typography component="h1" variant="onboardingHeader" gutterBottom>
                Create a new Password
            </Typography>

            <Collapse in={error_open}>
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {form_error}
                </Alert>
            </Collapse>

            <form id="reset-password" onSubmit={handleSubmit}>
                {/* For browser autocomplete / password managers */}
                <input hidden type="text" autoComplete="username" value={email} readOnly />
                <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    error={!!errorMsgs.password}
                    helperText={errorMsgs.password}
                    value={password}
                    onChange={(event) => handleChange(event, 'password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirm ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    error={!!errorMsgs.confirmPassword}
                    helperText={errorMsgs.confirmPassword}
                    onChange={(event) => handleChange(event, 'confirmPassword')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowConfirm(!showConfirm)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </form>
            <SuccessDialog
                success={'Your password has been reset'}
                dismiss_label={'Go to sign in'}
                open={showSucess}
                setOpen={setShowSuccess}
                dismiss_callback={() => { history.replace('/login') }}
            />
        </BaseContainer>
    )
}
export default ResetPassword;
