import { makeStyles } from "@material-ui/core"
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import SchoolIcon from "../assets/icons/school";
import ClinicHouseIcon from "../assets/icons/clinic_house";
import FamilyActionIcon from "../assets/icons/family_action";
import VaccineClipboardIcon from "../assets/icons/vaccine_clipboard";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 2, 1),
        display: 'block',
        verticalAlign: 'middle',
        background: '#FFFFFF',
        border: '0.5px solid #E7E7E7',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.2)',
        borderRadius: '4px',
    },
    fontSizeLarge: {
        fontSize: '50px',
    },
    caption: {
        marginBottom: '0px',
        color: theme.palette.grey[600],
    },
    iconGrid: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    disabledUsers: {
        opacity: '50%'
    },
    body1_16_Medium: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: '#333333'
    },
    body2_14: {
        fontWeight: 400,
        fontSize: '12px',
        fontFamily: 'Open Sans',
        color: '#767676'
    }
}))

const Action = (props) => {
    const classes = useStyles()
    let icon
    switch (props.type) {
        case "school":
            icon = <SchoolIcon classes={{root: classes.fontSizeLarge}} />
            break;
        case "clinic":
            icon = <ClinicHouseIcon classes={{root: classes.fontSizeLarge}} />
            break;
        case "sharing":
            icon = <FamilyActionIcon classes={{root: classes.fontSizeLarge}} />
            break;
        case "vaccinations":
            icon = <VaccineClipboardIcon classes={{root: classes.fontSizeLarge}} />
            break;
        default: break;
    }
    return (
        <Box className={classes.container} width="100%">
            <Grid container spacing={1} style={{cursor: 'pointer', height:'100%'}} onClick={props.on_click}>
                <Grid item xs={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {icon}
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                        <Typography className={classes.body1_16_Medium}>
                            {props.title}
                        </Typography>
                        <Typography className={classes.body2_14}>
                            {props.content}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )}

export default Action
