import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react'

import Typography from "../../components/typography";
import { PageSubsection } from "../../components/page_sections";


const useStyles = makeStyles((theme) => ({
    QRContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2.5, 4),
        border: '1px solid',
        borderColor: theme.palette.grey[200],
        borderRadius: '15px',
    },
}))


const VaccineCertificate = ({certificate, qrData}) => {
    const classes = useStyles()

    return (
        <div>
            {certificate.personal_information &&
                <div>
                    <div className={classes.QRContainer}>
                        <PageSubsection>
                            {qrData && <QRCode size={300} value={qrData} />}
                        </PageSubsection>
                        <Typography align="center" variant="modalHeader">
                            {certificate.personal_information.full_name && certificate.personal_information.full_name}
                            {certificate.personal_information.given_name && certificate.personal_information.given_name + ' '
                            + certificate.personal_information.family_name}
                        </Typography>
                    </div>
                </div>
            }
        </div>
    )
}
export default VaccineCertificate;
