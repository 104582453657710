import SvgIcon from '@material-ui/core/SvgIcon';

function VaccineClipboardIcon(props) {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}> 
            <circle opacity="0.6" cx="33.9581" cy="17" r="12" fill="#FBD202"/>
            <path fill="none" d="M29.0392 7.93854H32.7124C33.6866 7.93854 34.6209 8.32553 35.3098 9.01439C35.9986 9.70325 36.3856 10.6375 36.3856 11.6117V37.3242C36.3856 38.2984 35.9986 39.2327 35.3098 39.9215C34.6209 40.6104 33.6866 40.9974 32.7124 40.9974H10.6732C9.69901 40.9974 8.76472 40.6104 8.07586 39.9215C7.387 39.2327 7 38.2984 7 37.3242V11.6117C7 10.6375 7.387 9.70325 8.07586 9.01439C8.76472 8.32553 9.69901 7.93854 10.6732 7.93854H14.3464" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinejoin="round"/>
            <path fill="none" d="M26.9681 5.28149H16.4174C15.4462 5.28149 14.6589 5.91132 14.6589 6.68825V9.50177C14.6589 10.2787 15.4462 10.9085 16.4174 10.9085H26.9681C27.9392 10.9085 28.7265 10.2787 28.7265 9.50177V6.68825C28.7265 5.91132 27.9392 5.28149 26.9681 5.28149Z" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinejoin="round"/>
            <path fill="none" d="M11.9091 20.0322L13.9932 18.123M16.0772 16.2137L13.9932 18.123M12.0941 26.5104L17.3043 21.7372M22.5144 16.9641L17.3043 21.7372M15.2202 23.6465L21.4554 30.4525M28.1836 33.6126L24.5135 33.4686C24.3177 33.4609 24.133 33.3753 24.0006 33.2308L21.4554 30.4525M28.1836 33.6126L28.3608 29.944C28.3703 29.7482 28.3011 29.5568 28.1687 29.4123L19.3883 19.828M28.1836 33.6126L31.4947 37.2269M13.9932 18.123L17.3043 21.7372M21.4554 30.4525L23.5395 28.5433" stroke={props.strokecolor || "#333"} strokeWidth="1.99231" strokeLinejoin="round"/>
        </SvgIcon>

    );
}

export default VaccineClipboardIcon