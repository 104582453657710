import ReactDOM from 'react-dom';
import App from './app';
import { datadogRum } from '@datadog/browser-rum'
import { App as CapacitorApp } from '@capacitor/app'
import { getDisplayMode, scrubbedToken } from './utils/common';
import mixpanel from 'mixpanel-browser'

const {MIXPANEL_TOKEN, GOOGLE_ANALYTICS_ID, DATADOG, APP_VERSION} = window._env_
const {APPID, TOKEN, ENV} = DATADOG

const displayMode = getDisplayMode()

/** Initialise Mixpanel */
if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
        track_pageview: false, // We handle this ourselves
        persistence: 'localStorage',
    })
} else {
    mixpanel.init("_")
    mixpanel.disable()
    mixpanel.track_pageview = (props) => {
        // Removes property keys with empty values
        // (`current_url_search` will be empty most of the time)
        const filteredProps = Object.fromEntries(Object.entries(props).filter(([_, v]) => Boolean(v)))

        console.log("[MP:page_view]", filteredProps)
    }
}

mixpanel.register({
    display_mode: displayMode
})

/** Initialise Datadog */
if (APPID && TOKEN) {
    datadogRum.init({
        applicationId: APPID,
        clientToken: TOKEN,
        site: 'datadoghq.eu',
        env: ENV,
        service: 'frontend',
        version: APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        actionNameAttribute: 'data-action',
        beforeSend: (event) => {
            event.view.url = scrubbedToken(event.view.url)
            if (event.resource) {
                event.resource.url = scrubbedToken(event.resource.url)
            }
        },
    })
} else {
    console.groupCollapsed('Datadog actions will be logged to console')
    console.info("Context", { display_mode: displayMode })
    console.groupEnd()

    window.__dd_actions = []
    datadogRum.init({
        clientToken: '_',
        applicationId: '_',
        trackInteractions: true,
        actionNameAttribute: 'data-action',
        beforeSend: (event) => {
            const {type, action, view, context} = event;

            switch (type) {
                case 'action': {
                    // We log custom actions ourselves (see `logEvent`)
                    if (action.type === 'custom') {
                        break
                    }

                    window.__dd_actions.push({
                        event: type,
                        type: action.type,
                        name: action.target.name
                    })
                    const message = `[RUM:${action.type}] ${action.target.name}`

                    if (Object.keys(context).length) {
                        console.log(message, context)
                    } else {
                        console.log(message)
                    }
                    break;
                }
                case 'view': {
                    const {loading_type, loading_time, is_active, url} = view
                    if (is_active && !loading_time) {
                        const path = scrubbedToken(new URL(url).pathname)
                        window.__dd_actions.push({
                            event: type,
                            loading_type,
                            path})
                        console.log(`[RUM:${loading_type}] ${path}`)
                    }
                    break;
                }
            }

            if (type !== 'view') return false;
        },
    })
}

// https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#add-global-context-property
datadogRum.setGlobalContextProperty('display_mode', displayMode)

/** Initialise Google Analytics */
window.dataLayer = window.dataLayer || []
const gtag = window.gtag = function gtag() {
    window.dataLayer.push(arguments)
}
if (GOOGLE_ANALYTICS_ID) {
    fetch(`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`)
        .then(response => response.text())
        .then(response => {
            if (document.location.protocol.startsWith('http')) {
                Function(response)()
            } else {
                Function(response.replaceAll('http:', 'capacitor:'))()
            }
        })
        .catch(_ => {
            // Failure potentially due to an ad-blocker, just ignore it
        })
}
gtag('js', new Date())
gtag('config', GOOGLE_ANALYTICS_ID, {
    // Don't send the initial page view event here (GA4 does this by default), as our
    // `GAPageTracker` component/hook does this as well when it first mounts
    send_page_view: false,

    // A custom dimension set up on every event since currently there is no support for
    // session-scoped custom dimensions in GA4
    display_mode: displayMode,
})

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (canGoBack) {
        window.history.back()
    } else {
        CapacitorApp.exitApp()
    }
})

ReactDOM.hydrate(
    <App splash/>,
    document.getElementById('root')
);
