import SvgIcon from '@material-ui/core/SvgIcon';

function ShareIosIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M10 8H5V21.5H19V8H14M12 2.5V15M12 2.5L9 5.5M12 2.5L15 5.5" stroke="#3778E6" strokeWidth="1.5"/>
        </SvgIcon>
    );
}

export default ShareIosIcon
