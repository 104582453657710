import { makeStyles } from "@material-ui/core"
import UserIcon from "../assets/icons/user";
import Typography from "./typography";

type Props = {
    name: string
    year?: string
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        gap: '10px',
    },
    icon: {
        height: '33px',
        display: 'flex',
        alignItems: 'flex-end',
    },
    text: {
        overflow: 'hidden',
        overflowWrap: 'break-word',
        WebkitHyphens: 'auto',
        hyphens: 'auto',
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
    }
}));

export const ConnectionProfile = ({name, year}: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <div className={classes.icon}>
                <UserIcon active={1}/>
            </div>
            <div className={classes.userInfo}>
                <Typography variant="onboardingHeader" className={classes.text}>
                    {name}
                </Typography>
                {year &&
                    <Typography variant="bodyTextLargeBold">
                        {year}
                    </Typography>
                }
            </div>

        </div>
    )
}
