import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { axiosAuthedInstance, axiosInstance } from "../../utils/axiosApi";
import {isLoggedIn} from "../../utils/auth";
import ErrorPage from "../../components/error_page";

const VerifyShare = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [expired, setExpired] = useState(false)
    const history = useHistory();

    function openSharingInvite() {
        axiosInstance.post(
            `/user/open-sharing-invite/${props.match.params.token}/`
        ).then(() => {
            if (isLoggedIn()) {
                // This sets recipient of SharingInvite to current user
                // redirects to home as user is already logged-in
                axiosAuthedInstance.post(
                    'user/invite/post-signup-login/',
                    { token: props.match.params.token }
                ).then(result => {
                    if (result.data.data.error) {
                        history.push('/invite/error', {type: "sharing", error: result.data.data.error})
                    } else {
                        history.push('/sharing/confirm', {token: props.match.params.token})
                    }
                }).catch(err => {
                    console.error(err)
                    let msg = 'There was an error with the request. Please try again later and make sure the url is correct'
                    if (err.response?.data?.error) {
                        msg = err.response.data.error
                    }
                    setError(msg)
                    setLoading(false)
                })
            } else {
                history.push('/', { token: props.match.params.token, type: 'sharing' })
            }
        }).catch(error => {
            console.warn(error)
            if (error.response?.data) {
                setError(error.response.data.error)
                setExpired(error.response.status === 400)
            } else {
                setError('There was an error with the request. Please try again later and make sure the url is correct.')
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        openSharingInvite();
    }, []);

    if (loading) {
        return (
            <Loading loading={loading}/>
        )
    }

    if (error){
        return (
            <ErrorPage
                expired
                title={expired ? 'Sorry, this email link has expired' : 'Could not open sharing invite'}
                content={error}
                location={'/'}
                cta_label={"Go to homepage"}
            />
        )
    }
    return null
}
export default VerifyShare;
