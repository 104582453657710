import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const SearchIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path fill="none" stroke="currentColor" strokeWidth="2" d="M3 10c0 3.9 3.2 7.1 7.1 7.1 3.9 0 7.1-3.2 7.1-7.1S13.9 3 10 3c-3.8 0-7 3.2-7 7z"/>
        <path d="M20.7 19.3 15.5 14 14 15.5l5.3 5.3c.4.4 1 .4 1.3 0l.1-.1c.4-.4.4-1 0-1.4z"/>
    </SvgIcon>
)

export default SearchIcon
