import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar';
import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { spacing, sizing, palette, positions, display, flexbox } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from "./button";
import Typography from '../components/typography'
import Loading from "./loading";
import jsQR from "jsqr";
import ImageOutlinedIcon from '../assets/icons/image_outlined'
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIosOutlined';
import { FileUpload } from "./file_upload";
import ActionDialog from "./dialogs/action_dialog";
import ExclamationCircleIcon from "../assets/icons/exclamation_circle";
import { StickyFooterGroup } from "./sticky_footer_group";
import { HeaderTitle } from "./page_sections";
import { BackButton } from "./buttons/back_btn";

const Box = styled.div`${spacing}${sizing}${palette}${positions}${display}${flexbox}`;

// for testing
window.__test_canvas = function imagedata_to_image() {
    let canvas = document.getElementsByTagName("canvas")[0]
    let context = canvas.getContext('2d')
    let imageData = context.getImageData(0, 0, canvas.width, canvas.height)

    let c = document.createElement('canvas')
    let ctx = c.getContext('2d')
    c.width = imageData.width
    c.height = imageData.height
    ctx.putImageData(imageData, 0, 0)

    let newTab = window.open();
    newTab.document.body.innerHTML = `<img src="${c.toDataURL()}">`;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.common.black,
        paddingTop: 'env(safe-area-inset-top)'
    },
    frame: {
        position: 'absolute',
        border: '4px solid',
        borderColor: theme.palette.primary.main,
        boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    },
    frameText: {
        position: 'absolute',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    infoText: {
        position: 'absolute',
        color: theme.palette.common.white,
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(0, 2),
    },
    text: {
        zIndex: 2,
        color: theme.palette.common.white,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    videoContainer: {
        flex: 1,
        position: 'relative',
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover', // This looks only looks weird on desktops
    },
    footer: {
        position: 'absolute',
        bottom: 'env(safe-area-inset-bottom)',
        left: 0,
        right: 0,
    }
}))

export const getQRUserMessagingAction = (action) => {
    // action is either retry/contact
    if (action === "retry") {
        return <Typography variant="bodyTextLarge" align="center" style={{ marginTop: '16px' }}>Please try again in 5 business days.</Typography>
    }
    if (action === "contact") {
        return <Typography variant="bodyTextLarge" align="center" style={{ marginTop: '16px' }}>Please contact <a href="mailto:support@imunis.com">support@imunis.com</a> for further information.</Typography>
    }
}

const Scanner = ({ title, content, infoText, ...props }) => {
    const [devices, setDevices] = useState([])
    const [cameraFail, setCameraFail] = useState(false)
    const [faceUser, setFaceUser] = useState(0)
    const [showError, setShowError] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [scanAgain, setScanAgain] = useState(false)
    const scanningFrame = useRef(null)
    const videoElRef = useRef(null)
    const canvasRef = useRef(null)
    const importRef = useRef(null)
    const history = useHistory()
    const classes = useStyles()

    // Set status bar style to white text / dark bg for this page
    useEffect(() => {
        if (Capacitor.getPlatform() !== 'ios') {
            return
        }

        void StatusBar.setStyle({ style: Style.Dark })

        return () => {
            // Revert style
            void StatusBar.setStyle({ style: Style.Light })
        }
    }, [])

    useEffect(() => {
        if (!navigator.mediaDevices) {
            // No devices available at all
            cameraFailure()
            return;
        }
        getDeviceList(true)


        // Make sure we have a reference to the video element, even when the ref is cleared
        const videoEl = videoElRef.current
        return () => {
            stopVideo(videoEl)
        }
    }, [])

    const getDeviceList = (firstTime = false) => {

        if (!videoElRef.current) {
            // Avoid attempting to set state with an unmounted component
            // (we can't 'cancel' a promise)
            return
        }

        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoDevices = devices.filter(d => d.kind === 'videoinput')
            setDevices(videoDevices)
            if (firstTime) {
                setupCamera(videoDevices)
            }
        }).catch((err) => {
            console.warn(err)
            // Catch error from promise
            cameraFailure()
        })
    }

    const stopVideo = (videoEl) => {
        if (scanningFrame.current) {
            window.cancelAnimationFrame(scanningFrame.current)
        }

        videoEl = videoEl || videoElRef.current

        const stream = videoEl.srcObject
        if (stream) {
            const tracks = stream.getTracks()
            tracks.forEach(t => t.stop())
        }
    }

    const setupCamera = (deviceList, deviceIndex) => {
        let constraints

        if (deviceList.length === 0) {
            cameraFailure()
            return
        }

        if (typeof deviceIndex === 'number') {
            constraints = {
                deviceId: { exact: deviceList[deviceIndex].deviceId }
            }
        } else {
            constraints = {
                facingMode: { ideal: 'environment' },
                deviceId: deviceList[deviceList.length - 1].deviceId
            }
        }

        navigator.mediaDevices.getUserMedia({ video: constraints }).then(stream => {
            const videoEl = videoElRef.current
            // In some iOS versions you can't get device list info without calling getUserMedia first
            getDeviceList()

            if (!videoEl) {
                // Avoid attempting to set state with an unmounted component
                // (we can't 'cancel' a promise)
                return
            }

            videoEl.srcObject = stream
            videoEl.setAttribute('playsinline', true)

            videoEl.play().catch(err => {
                // Not an error if the video element fails to play, probably just navigated away
                // from the page
                console.warn("video failed to play", err)
            })

            const track = stream.getVideoTracks()[0]
            let capabilities = {}
            // Firefox does not have this method
            if (track.getCapabilities) {
                capabilities = track.getCapabilities()
            }
            const deviceId = track.getSettings().deviceId
            const deviceIndex = deviceList.findIndex(d => d.deviceId === deviceId)
            setFaceUser(deviceIndex)

            if (capabilities.focusMode && capabilities.focusMode.includes('continuous')) {
                track.applyConstraints({ advanced: [{ focusMode: 'continuous' }] })
            }
            scanningFrame.current = requestAnimationFrame(scan)
        }).catch(err => {
            cameraFailure()
            console.warn(err)
        })
    }

    const changeCamera = () => {
        stopVideo()
        setFaceUser(currentIndex => {
            const newIndex = (currentIndex + 1) % devices.length
            setupCamera(devices, newIndex)
            return newIndex
        })
    }

    const cameraFailure = () => {
        setErrorTitle('Unable to access camera')
        setErrorMsg("Please enable camera permissions or import from file.")
        setShowError(true)
        setCameraFail(true)
    }

    const onScanSuccess = (data) => {
        setLoading(true)
        setShowError(false)
        props.api_call(data)
            .then((result) => {
                if (result.data.status == 'ok') {
                    setLoading(false)
                    props.api_callback(result, data)
                } else {
                    // Expected error seen when scanning an invalid QR code (e.g. not a vaccine cert)
                    setScanAgain(true)
                    setLoading(false)
                    setErrorTitle(result.data.error_header || 'Invalid QR Code')
                    setShowError(true)
                    setErrorMsg((<>
                        <Typography variant="bodyTextLarge" align="center">{result.data.error_content}</Typography>
                        {result.data.error_action && getQRUserMessagingAction(result.data.error_action)}
                    </>))
                }
            }).catch((err) => {
                // Unexpected error
                console.error(err)
                setScanAgain(true)
                setLoading(false)
                setShowError(true)
                setErrorTitle('Invalid QR Code')
                let msg = 'There was an error reading the QR code'
                if (err.response?.data?.error) {
                    msg = err.response.data.error
                } else if (typeof err == 'string') {
                    msg = err
                }
                setErrorMsg(msg)
            })
    }

    const scan = () => {
        const vid = videoElRef.current
        const cv = canvasRef.current
        if (!vid || !cv) { return }
        const frame = document.getElementById("canvas-frame")
        const frameText = document.getElementById("frame-text")
        const canvasCtx = cv.getContext('2d', { willReadFrequently: true })
        if (vid.readyState === vid.HAVE_ENOUGH_DATA) {
            const scale = 0.67
            cv.hidden = false;
            cv.height = vid.videoHeight;
            cv.width = vid.videoWidth;
            const squareLength = cv.height >= cv.width ? cv.width * scale : cv.height * scale
            const clientLength = vid.clientHeight >= vid.clientWidth ? vid.clientWidth * scale : vid.clientHeight * scale

            // FIXME - don't do this on every frame
            frame.style.width = clientLength + 'px'
            frame.style.height = clientLength + 'px'
            frame.style.left = (vid.clientWidth / 2) - (clientLength / 2) + 'px'
            frame.style.top = (vid.clientHeight / 2) - (clientLength / 2) + 'px'
            frame.removeAttribute('hidden')
            frameText.style.width = clientLength + 'px'
            frameText.style.top = (vid.clientHeight / 2 + clientLength / 2) + 4 + 'px'
            frameText.style.left = (vid.clientWidth / 2) - (clientLength / 2) + 'px'
            frameText.removeAttribute('hidden')

            canvasCtx.drawImage(vid,
                (cv.width / 2) - (squareLength / 2), (cv.height / 2) - (squareLength / 2),
                squareLength, squareLength,
                (cv.width / 2) - (squareLength / 2), (cv.height / 2) - (squareLength / 2),
                squareLength, squareLength);
            let imageData = canvasCtx.getImageData(0, 0, cv.width, cv.height);
            let code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });
            if (code && code.data) {
                onScanSuccess(code.data)
                scanningFrame.current = null
                return
            }
        }
        scanningFrame.current = requestAnimationFrame(scan);
    }

    return (
        <div className={classes.container}>
            {loading && <Loading loading={loading} />}

            <Box className={classes.header} width="100%" pl={3} pt={2}>
                <BackButton className={classes.text} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    } else {
                        history.goBack()
                    }
                }} />
                {!cameraFail && devices.length > 1 &&
                    <IconButton className={classes.text} onClick={changeCamera}>
                        <FlipCameraIosIcon />
                    </IconButton>
                }
            </Box>

            <Box zIndex={2} className={classes.text} position="relative" pl={3}>
                <HeaderTitle>{title}</HeaderTitle>
            </Box>

            <div className={classes.videoContainer}>
                <video ref={videoElRef} className={classes.video} />
                <canvas ref={canvasRef} style={{ display: "none" }} hidden />
                <Box id="canvas-frame" hidden className={classes.frame} />
                {infoText &&
                    <Typography id="info-text" className={classes.infoText}>
                        {infoText}
                    </Typography>
                }
                <div style={{ display: content ? 'block' : 'none' }}>
                    <Typography id="frame-text" variant="bodyTextLarge" hidden className={classes.frameText}>
                        {content}
                    </Typography>
                </div>
            </div>

            <div className={classes.footer}>
                <StickyFooterGroup>
                    <Button variant="text" startIcon={<ImageOutlinedIcon />} component="label"
                        className={classes.text} htmlFor={importRef.current?.id}>
                        Import from file
                    </Button>
                </StickyFooterGroup>
            </div>


            <FileUpload
                ref={importRef}
                onSuccess={onScanSuccess}
                beforeLoad={() => {
                    if (scanningFrame.current) {
                        window.cancelAnimationFrame(scanningFrame.current)
                    }
                    setLoading(true)
                }}
                onError={(errorTitle, errorMessage) => {
                    setLoading(false)
                    setErrorTitle(errorTitle)
                    setErrorMsg(errorMessage)
                    setShowError(true)
                }}
            />

            <ActionDialog
                cancel
                title={errorTitle || "Error"}
                content={errorMsg}
                action_label={scanAgain ?
                    "Scan again" :
                    "Import from file"
                }
                action_callback={(e) => {
                    if (scanAgain) {
                        setShowError(false)
                        setErrorTitle('')
                        setErrorMsg('')
                        setScanAgain(false)
                        scanningFrame.current = requestAnimationFrame(scan)
                    } else {
                        e.preventDefault()
                        importRef.current.click()
                    }
                }}
                cancel_callback={() => {
                    if (props.onCancel) { props.onCancel() }
                    else { history.goBack() }
                }}
                close_callback={() => {
                    setShowError(false)
                    setScanAgain(false)
                    setErrorTitle('')
                    setErrorMsg('')
                    scanningFrame.current = requestAnimationFrame(scan)
                }}
                openDialog={showError}
                setOpenDialog={setShowError}
                icon={<ExclamationCircleIcon fontSize="large" />}
            />
            {props.children}
        </div>

    )
}
export default Scanner;
