import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing, display, flexbox } from '@material-ui/system';
import styled from 'styled-components';
import { IconButton } from "../../components/button";
import Typography from '../../components/typography';

import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";
import Card from "../../components/card";
import ListItem from "../../components/lists/list_item";
import DisconnectIcon from "../../assets/icons/disconnect";
import ActionDialog from "../../components/dialogs/action_dialog";
import SuccessDialog from "../../components/dialogs/success_dialog";
import ErrorDialog from "../../components/dialogs/error_dialog";
import { formatDateLocal, possessiveCheck } from "../../utils/common";
import { getPreferredName } from "../../utils/auth";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import ConnectionDotIcon from "../../assets/icons/connection_dot";
import { PageDescription } from "../../components/page_sections";
import { TooltipDrawer } from "../../components/tooltip_drawer";
import { DrawerContent } from "../../components/drawer_content";
import { Divider } from "../../components/divider";
import SimpleUserIcon from "../../assets/icons/simple_user";
import { EmptyUsers } from "../../components/empty_content";
import { CardCaption } from "../../components/card_components";
import { isCancel } from "axios";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles(() => ({
    icon: {
        width: '24px',
        height: '24px',
    },
}))

const Connections = (props) => {
    const [loading, setLoading] = useState(false)
    const [showDisconnectDialog, setShowDisconnectDialog] = useState(false)
    const [selectedConnection, setSelectedConnection] = useState({})
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [guardian, setGuardian] = useState(false)
    const [dependantName, setDependantName] = useState('')
    const [connectionType, setConnectionType] = useState('')
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [connections, setConnections] = useState([])
    const classes = useStyles()

    const disconnectText = guardian ?
        `You will no longer be able to access your health records or those of dependants you manage who are connected to ${selectedConnection.name} via the Imunis platform.`
        : connectionType === 'CLINIC' ?
            `You will no longer be able to access ${possessiveCheck(dependantName)} health records from ${selectedConnection.name} via the Imunis platform.`
            : `${selectedConnection.name} will no longer be able to access ${possessiveCheck(dependantName)} health records via the Imunis platform.`

    useEffect(() => {
        getConnections()
    },[])

    const getConnections = async () => {
        setLoading(true)
        try {
            const response = await axiosAuthedInstance.get('user/connections/')
            setConnections(response.data.data)
        } catch (err) {
            if (isCancel(err)) return

            let msg = 'Error getting connections'
            if (err.response?.data?.error) {
                console.error(err)
                msg = err.response.data.error
            }
            setError(msg)
            setShowError(true)
        }
        setLoading(false)
    }

    const disconnect = async (id) => {
        setLoading(true)
        try {
            await axiosAuthedInstance.delete(`user/connections/${id}/`)
            setShowSuccessDialog(true)
            getConnections()
        } catch (err) {
            let msg = 'Error deleting connection'
            if (err.response?.data?.error) {
                console.error(err)
                msg = err.response.data.error
            }
            setError(msg)
            setShowError(true)
        }
        setLoading(false)
    }

    const renderConnections = (conArray = [], name='', permission=true, guardian=false) => {
        return (
            <div>
                <Divider />
                {conArray.length ?
                    conArray.map((connection, index) => {
                        let connectedAt;
                        if (connection.connected_at) {
                            connectedAt = `Connected on ${formatDateLocal(connection.connected_at)}`
                        }
                        return (
                            <ListItem
                                key={connection.connection_id}
                                divider={index + 1 < conArray.length}
                                title={connection.name}
                                subtitle={connectedAt}
                                extra={
                                    permission &&
                                    <IconButton
                                        className="disconnect-icon"
                                        size="small"
                                        aria-label="Disconnect connection"
                                        onClick={() => {
                                            setGuardian(guardian)
                                            setDependantName(name)
                                            setConnectionType(connection.type)
                                            setSelectedConnection(connection)
                                            setShowDisconnectDialog(true)
                                        }}
                                    >
                                        <DisconnectIcon />
                                    </IconButton>
                                }
                            />
                        )
                    })
                    :
                    <Typography align="center" variant="bodyTextSmall">
                        {guardian ?
                            "You haven’t connected with any healthcare or education providers."
                            :
                            "Dependant hasn’t connected with any healthcare or education providers."
                        }
                    </Typography>
                }
            </div>
        )
    }

    let me = {}
    let primaryList = []
    let secondaryList = []
    connections.map(connection => {
        if (connection.relationship === 'PG') {
            primaryList.push(connection)
        } else if (connection.relationship === 'SG') {
            secondaryList.push(connection)
        } else {
            me = connection
        }
    })

    const primaryTitle = "Dependants you manage"
    const primaryContent = "You can manage the connections of your dependants by connecting or disconnecting with healthcare and education providers."
    const secondaryTitle = "Dependants shared with you"
    const secondaryContent = "You can view the connections of dependants that have been shared with you, but you are not authorised to connect or disconnect them."

    return (
        <BaseContainer showNav connection {...props}>
            {loading && <Loading loading={loading}/>}

            <Box display="flex" mb={1.5}>
                <Box marginRight={1}>
                    <ConnectionDotIcon/>
                </Box>
                <Typography variant="modalHeader">Connections</Typography>
            </Box>

            <PageDescription>
                <Typography variant="bodyTextMedium">
                    Manage connections with healthcare and education providers for digital immune health record sharing
                </Typography>
            </PageDescription>

            <PageDescription>
                <Card
                    icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                    title={me.name || getPreferredName()}
                    caption={<CardCaption amount={me.connections?.length || 0} item='connection'/>}
                    content={renderConnections(me.connections, me.name, true, true)}
                />
            </PageDescription>

            <PageDescription>
                <div id="primary_connections_list">
                    <TooltipDrawer title={primaryTitle}>
                        <DrawerContent title={primaryTitle} content={primaryContent}/>
                    </TooltipDrawer>
                    {primaryList.length > 0 ? primaryList.map(primary => (
                        <Card
                            key={primary.user_id}
                            icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                            title={primary.name}
                            caption={<CardCaption amount={primary.connections?.length || 0} item='connection'/>}
                            content={renderConnections(primary.connections, primary.name)}
                        />
                    ))
                        : <EmptyUsers emptyText="No profile you manage"/>
                    }
                </div>
            </PageDescription>

            <PageDescription>
                <div id="secondary_connections_list">
                    <TooltipDrawer title={secondaryTitle}>
                        <DrawerContent title={secondaryTitle} content={secondaryContent}/>
                    </TooltipDrawer>
                    {secondaryList.length > 0 ? secondaryList.map(secondary => (
                        <Card
                            key={secondary.user_id}
                            icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                            title={secondary.name}
                            caption={<CardCaption amount={secondary.connections?.length || 0} item='connection'/>}
                            content={renderConnections(secondary.connections, secondary.name, false)}
                        />
                    ))
                        : <EmptyUsers emptyText="No profile shared with you"/>
                    }
                </div>
            </PageDescription>

            <ActionDialog
                title="Disconnect connection?"
                content={disconnectText}
                action_label="Disconnect"
                openDialog={showDisconnectDialog}
                setOpenDialog={setShowDisconnectDialog}
                action_callback={() => disconnect(selectedConnection.connection_id)}
                cancel
            />

            <SuccessDialog
                success={'Connection removed successfully!'}
                open={showSuccessDialog}
                setOpen={setShowSuccessDialog}
                dismiss_label={'Got it'}
            />
            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />
        </BaseContainer>
    )
}

export default Connections
