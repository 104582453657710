import SvgIcon from '@material-ui/core/SvgIcon';

function HourglassIcon(props) {
    return (
        <SvgIcon {...props}>
            <circle cx="17" cy="14" r="5" fill="#FBD202"/>
            <path d="M7.07692 2.5V8.41733C7.07692 9.08921 7.33626 9.73357 7.79789 10.2087L8.66818 11.1043C9.14882 11.599 9.14882 12.401 8.66818 12.8957L7.43741 14.1623C7.20659 14.3999 7.07692 14.7221 7.07692 15.058V21.5M16.9231 2.5V8.942C16.9231 9.27794 16.7934 9.60012 16.5626 9.83766L15.3318 11.1043C14.8512 11.599 14.8512 12.401 15.3318 12.8957L16.5626 14.1623C16.7934 14.3999 16.9231 14.7221 16.9231 15.058V21.5M4 3.13333H20M4 20.8667H20" fill="none" stroke={props.strokecolor || '#333'} strokeWidth="2"/>
        </SvgIcon>
    );
}

export default HourglassIcon