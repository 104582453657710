const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <g filter="url(#filter0_dd_4_4850)">
            <rect x="25" y="272" width="327" height="90" rx="12" fill="white"/>
        </g>
        <text xmlSpace="preserve" fill="#767676" fontFamily="Open Sans" fontSize="12" fontWeight="400">
            <tspan x="263" y="309">1/2 doses</tspan>
        </text>
        <path d="M327.98 307.004C327.849 307.005 327.72 306.98 327.598 306.93C327.477 306.88 327.367 306.807 327.274 306.714L323.293 302.714C323.2 302.621 323.126 302.51 323.076 302.388C323.026 302.267 323 302.136 323 302.004C323 301.872 323.026 301.742 323.076 301.62C323.126 301.498 323.2 301.387 323.293 301.294C323.385 301.201 323.496 301.127 323.617 301.076C323.738 301.026 323.868 301 323.999 301C324.131 301 324.261 301.026 324.382 301.076C324.503 301.127 324.613 301.201 324.706 301.294L327.98 304.604L331.265 301.424C331.356 301.322 331.468 301.24 331.592 301.183C331.717 301.126 331.852 301.095 331.989 301.093C332.126 301.091 332.262 301.117 332.388 301.17C332.514 301.223 332.629 301.302 332.723 301.401C332.818 301.5 332.891 301.618 332.939 301.747C332.986 301.876 333.006 302.014 332.998 302.151C332.99 302.288 332.954 302.423 332.892 302.545C332.83 302.668 332.744 302.777 332.638 302.864L328.657 306.724C328.475 306.9 328.233 307.001 327.98 307.004Z" fill="#767676"/>
        <path d="M50.3306 321.514L43.6038 334.631C42.5801 336.627 44.0298 339 46.2733 339H59.7267C61.9702 339 63.4199 336.627 62.3962 334.631L55.6695 321.514C54.5542 319.339 51.4458 319.339 50.3306 321.514Z" fill="#FBEAE7"/>
        <rect x="52" y="334" width="2" height="2" fill="#D82C0D"/>
        <rect x="52" y="326" width="2" height="6" fill="#D82C0D"/>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="14" fontWeight="400">
            <tspan x="69" y="335.5">Eligible for vaccination</tspan>
        </text>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="41" y="309">Measles</tspan>
        </text>
        <defs>
            <filter id="filter0_dd_4_4850" x="21" y="271" width="335" height="99" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_4850"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_4_4850" result="effect2_dropShadow_4_4850"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_4850" result="shape"/>
            </filter>
        </defs>
    </svg>
)

export default Svg
