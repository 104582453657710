import { isCancel } from "axios"
import { useState, useEffect } from "react"
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import { AppHeader } from "../../../components/header"
import { BaseContainer } from "../../../components/container"
import { useHistory, useLocation } from "react-router-dom"
import { HeaderTitle } from "../../../components/page_sections"
import ErrorDialog from "../../../components/dialogs/error_dialog"
import Loading from "../../../components/loading"
import Card from "../../../components/card"
import Typography from "../../../components/typography"
import SubmissionTimeline from "../../../components/submission_timeline"
import { Profile, Submission, SubmissionImage, toTimelineProps } from "./utils"
import { makeStyles } from "@material-ui/core"
import ScanDocumentIcon from "../../../assets/icons/scan_document"
import { EmptyContent } from "../../../components/empty_content"

export const SubmissionHistory = () => {
    const [submissionHistory, setSubmissionHistory] = useState<Submission[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const history = useHistory()
    const location = useLocation<{
        backTo?: string
    }>()

    useEffect(() => {
        setLoading(true)
        axiosAuthedInstance.get<{ submissions: Submission[] }>('/user/paper-record/history/')
            .then((response) => {
                setSubmissionHistory(response.data.submissions)
            }).catch((error) => {
                if (isCancel(error)) return

                console.error(error)
                setError('Server error. Please try again later.')
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    let content
    if (submissionHistory?.length === 0) {
        content = (
            <EmptyContent icon={ScanDocumentIcon}>
                No submissions have been created
            </EmptyContent>
        )
    } else if (submissionHistory) {
        content = submissionHistory.map((submission) => {
            return <SubmissionItem key={submission.id} {...submission} />
        })
    }

    return (
        <BaseContainer header={
            <AppHeader onBack={() => history.push(location.state?.backTo || '/home')} />
        }>
            <HeaderTitle>Submission History</HeaderTitle>

            {content}

            <Loading loading={loading} />

            <ErrorDialog
                error={error}
                open={Boolean(error)}
                setOpen={setError}
            />
        </BaseContainer>
    )
}

const useSubmissionItemStyles = makeStyles((theme) => ({
    submissionItem: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    submissionItemContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        flexGrow: 1,
    },
    submissionImages: {
        position: 'relative',
        height: 96,
        width: 80,
    },
    thumbnailWrapper: {
        position: 'absolute',
        height: 96,
        width: 64,
    },
    thumbnail: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    morePagesCount: {
        background: theme.palette.background.default,
        position: 'absolute',
        right: 0,
        top: '50%',
        translate: '0 -50%',
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.palette.grey[600],
        padding: 2,
    }
}))

const SubmissionItem = (submission: Submission) => {
    const { id, subject, pages } = submission
    const history = useHistory()
    const classes = useSubmissionItemStyles()

    const offsetX = 32 / pages.slice(0, 4).length

    // Draw images backwards to get DOM order correct
    const thumbnails = pages.slice(0, 4).map((url, i) => [url, i] as const).reverse().map(([url, i]) =>  {
        return <div  key={i} className={classes.thumbnailWrapper} style={{
            transform: `scale(${1 - (0.1 * i)})`,
            translate: `${offsetX * i}px`,
        }}>
            <SubmissionImage key={i} src={url} size="small" className={classes.thumbnail}/>
        </div>
    })

    const morePagesCount = pages.length > 3 ? (
        <Typography variant="bodyTextSmallDark" className={classes.morePagesCount}>
            +{pages.length - 3}
        </Typography>
    ) : null

    return (
        <Card key={id} className={classes.submissionItem} onClick={() => {
            history.push(`/paper-records/submission/${id}`, {
                submission,
                continueHome: false
            })
        }}>
            <div className={classes.submissionImages}>
                {thumbnails}
                {morePagesCount}
            </div>
            <div className={classes.submissionItemContent}>
                <Typography variant="bodyTextSmallDark">Submission #{id}</Typography>
                <SubmissionTimeline {...toTimelineProps(submission)} />
                <Profile name={subject.fullName} size="small" />
            </div>
        </Card>
    )
}
