import { SvgIcon, SvgIconProps } from '@material-ui/core';
import theme from '../../styles/theme';

const InformationCircleSolid = ({color, ...props}: SvgIconProps) => {
    let bg = theme.palette.grey[200]
    let fg = theme.palette.grey[800]

    if (color === 'primary') {
        bg = theme.palette.primary.main
        fg = theme.palette.common.white
    }

    return (
        <SvgIcon {...props}>
            <circle cx={12} cy={12} r={10} fill={bg} />
            <path fill={fg} d="M11 7h2v2h-2zM11 11h2v6h-2z" />
        </SvgIcon>
    )
}

export default InformationCircleSolid
