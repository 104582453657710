import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Button, IconButton } from '../button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '../typography';

const useStyles = makeStyles<Theme>((theme) => createStyles({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
    dialog: {
        position: 'relative',
        padding: theme.spacing(4, 2, 2, 2),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            fontWeight: 600
        },
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1, 0),
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    },
    actionsWrapper: {
        flexDirection: 'column'
    }
}))

type ActionDialogProps = {
    title: string,
    content: string | React.ReactNode,
    action_label?: string,
    cancel_label?: string,
    icon?: React.ReactNode,
    cancel?: boolean,
    openDialog: boolean,
    setOpenDialog: (open: boolean) => void,
    action_callback?: React.MouseEventHandler,
    cancel_callback?: () => void,

    /** Callback run when clicking the "x" icon to close the prompt */
    close_callback?: () => void

    /**
     * Content for the action buttons. Use this when the (action|cancel)_(label|callback) props
     * aren't flexible enough, for example, to set loading state on buttons.
     */
    actions?: React.ReactNode
}

/**
 * Usage in other components/pages:
 *
 * 1. set up the required open dialog state hook: const [openDialog, setOpenDialog] = useState(false)
 * 3. Put the ActionDialog at the bottom of your component: <ActionDialog title={title} content={content} action_label={action_text} cancel_label={cancel_text} action_callback={action_callback} />
 */
const ActionDialog = (props: ActionDialogProps) => {
    const classes = useStyles()

    const action = (e: React.MouseEvent<HTMLElement>) => {
        if (typeof props.action_callback === 'function') {
            props.action_callback(e)
        }
        if (!e.defaultPrevented) props.setOpenDialog(false)
    }

    const cancel = () => {
        if (typeof props.cancel_callback === 'function') {
            props.cancel_callback()
        }
        props.setOpenDialog(false)
    }

    const close = () => {
        if (typeof props.close_callback === 'function') {
            props.close_callback()
        }
        props.setOpenDialog(false)
    }

    let content = props.content
    if (typeof props.content === 'string') {
        content = <Typography variant="bodyTextLarge">{content}</Typography>
    }
    if (content) {
        content = <DialogContent>{content}</DialogContent>
    }

    const actions = props.actions || <>
        <Button id="action_button" onClick={action} className={classes.button} fullWidth primary>
            {props.action_label}
        </Button>
        {props.cancel && (
            <Button id="cancel_button" onClick={cancel} className={classes.button} fullWidth>
                {props.cancel_label || 'Cancel'}
            </Button>
        )}
    </>

    return (
        <Dialog maxWidth="xs" open={props.openDialog}>
            <Paper className={classes.dialog}>
                <IconButton onClick={close} className={classes.closeButton} aria-label="Close">
                    <CloseIcon fontSize="small" />
                </IconButton>
                {props.icon && <Typography variant='bodyTextLarge'>
                    {props.icon}
                </Typography>
                }
                <DialogTitle>
                    <Typography variant="modalHeader">{props.title}</Typography>
                </DialogTitle>
                {content}
                <DialogActions className={classes.actionsWrapper}>
                    {actions}
                </DialogActions>
            </Paper>
        </Dialog>
    );
}

export default ActionDialog
