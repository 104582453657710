import SvgIcon from '@material-ui/core/SvgIcon';

function RecordsWarningIcon(props) {
    return (
        <SvgIcon viewBox='0 0 40 41' {...props}>
            <g fill="none">
                <path d="M13.3804 18.4783H26.1585" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.3804 22.7826H26.1585" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.3804 14.174H22.7959" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.3804 27.087H22.7959" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.3804 31.3913H19.4332" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M18.7606 40H9.34507C8.98834 40 8.64621 39.8488 8.39396 39.5798C8.14171 39.3107 8 38.9457 8 38.5652V8.43478C8 8.05425 8.14171 7.68931 8.39396 7.42024C8.64621 7.15116 8.98834 7 9.34507 7H27.2251C27.5818 7.00008 27.9239 7.1513 28.176 7.42039L33.1622 12.7391C33.4145 13.0081 33.5563 13.373 33.5563 13.7535V18.4783" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.76056 34C1.08358 34 2.34507 34 2.34507 34C1.98834 34 1.64621 33.8488 1.39396 33.5798C1.14171 33.3107 1 32.9457 1 32.5652V2.43478C1 2.05425 1.14171 1.68931 1.39396 1.42024C1.64621 1.15116 1.98834 1 2.34507 1H20.2251C20.5818 1.00008 20.9239 1.1513 21.176 1.42039" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M31.9937 24.2174C31.8847 23.9996 31.7222 23.8174 31.5237 23.6905C31.3252 23.5635 31.0981 23.4964 30.8666 23.4964C30.6351 23.4964 30.408 23.5635 30.2094 23.6905C30.0109 23.8174 29.8484 23.9996 29.7394 24.2174L22.9387 38.0415C22.8383 38.2451 22.7893 38.4731 22.7966 38.7031C22.8039 38.9331 22.8673 39.157 22.9804 39.3529C23.095 39.5498 23.2549 39.7122 23.4449 39.8247C23.6348 39.9372 23.8486 39.9961 24.0659 39.9957H37.6672C37.8845 39.9961 38.0983 39.9372 38.2883 39.8247C38.4782 39.7122 38.6381 39.5498 38.7527 39.3529C38.8659 39.157 38.9292 38.9331 38.9365 38.7031C38.9438 38.4731 38.8949 38.2451 38.7944 38.0415L31.9937 24.2174Z" fill="#FBD202"/>
                <path d="M30.8661 35.6957C30.9326 35.6957 30.9976 35.7167 31.0529 35.7561C31.1082 35.7955 31.1513 35.8516 31.1767 35.9171C31.2022 35.9827 31.2088 36.0548 31.1959 36.1244C31.1829 36.1939 31.1509 36.2578 31.1038 36.308C31.0568 36.3582 30.9969 36.3923 30.9317 36.4062C30.8664 36.42 30.7988 36.4129 30.7374 36.3858C30.6759 36.3586 30.6234 36.3126 30.5865 36.2537C30.5495 36.1947 30.5298 36.1253 30.5298 36.0544C30.5298 35.9592 30.5652 35.868 30.6283 35.8007C30.6913 35.7335 30.7769 35.6957 30.8661 35.6957Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M30.8662 32.8261V28.5217" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M31.9937 24.2174C31.8847 23.9996 31.7222 23.8174 31.5237 23.6905C31.3252 23.5635 31.0981 23.4964 30.8666 23.4964C30.6351 23.4964 30.408 23.5635 30.2094 23.6905C30.0109 23.8174 29.8484 23.9996 29.7394 24.2174L22.9387 38.0415C22.8383 38.2451 22.7893 38.4731 22.7966 38.7031C22.8039 38.9331 22.8673 39.157 22.9804 39.3529C23.095 39.5498 23.2549 39.7122 23.4449 39.8247C23.6348 39.9372 23.8486 39.9961 24.0659 39.9957H37.6672C37.8845 39.9961 38.0983 39.9372 38.2883 39.8247C38.4782 39.7122 38.6381 39.5498 38.7527 39.3529C38.8659 39.157 38.9292 38.9331 38.9365 38.7031C38.9438 38.4731 38.8949 38.2451 38.7944 38.0415L31.9937 24.2174Z" stroke="black" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </SvgIcon>
    )
}

export default RecordsWarningIcon
