import { useState, useEffect, useRef, RefObject } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Theme } from "@material-ui/core";
import { backgroundStyle } from '../pages/splash';
import { IconButton } from "./button";
import CloseIcon from '@material-ui/icons/Close';
import Typography from './typography';
import imunisTheme from '../styles/theme';

import { Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/css'
import 'swiper/css/pagination'

import Onboarding1 from '../assets/app_intro/onboarding_1'
import Onboarding2 from '../assets/app_intro/onboarding_2'
import Onboarding3 from '../assets/app_intro/onboarding_3'
import Onboarding4 from '../assets/app_intro/onboarding_4'


const useAppIntroStyles = makeStyles<Theme, AppIntroProps>((theme) => ({
    // styles mostly copied from the mui backdrop behind dialogs
    backDropBelowNavbar: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 110,
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        "-webkit-tap-highlight-color": 'transparent',
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    onboardingWidget: {
        zIndex: 120,
        width: '300px',
        position: 'fixed',
        bottom: ({navRef}) => {
            const el = navRef?.current?.getBoundingClientRect()
            if (el) return `${window.innerHeight - el.top + 18}px`
        },
        left: '16px',
        backgroundColor: 'white',
        borderRadius: '4px',
        transition: 'left 0.2s ease',
    },
    arrowWrapper: {
        position: 'fixed',
        // point at the first navItem, needs changed if total navItem number changes
        // this is needed to prevent the arrow moves to the calculated position when widget first appears
        left: 'calc(10vw - 12px)',
        transition: 'left 0.2s ease',

        // Arrow style
        width: 0,
        border: '10px solid transparent',
        borderTopColor: theme.palette.background.default,
    },
    widgetInnerWrapper: {
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        zIndex: 2,
    },
    bgWrapper: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        '& > svg': {
            width: '300px',
            height: '150px',
        }
    },
    slideContainer: {
        gridArea: 'slides',
        width: '100%',
        maxWidth: '100vw', // iOS 12 needs this

        '&::before': {
            ...backgroundStyle['&::before'],
            WebkitMaskImage: 'linear-gradient(#000 -300%, transparent 100%)',
            maskImage: 'linear-gradient(#000 -300%, transparent 100%)',
        },
    },
    slide: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '250px',
        '& img': {
            userSelect: 'none',
            width: '100%',
            height: '100%',
        },
    },
    slideText: {
        textAlign: 'center',
        background: theme.palette.background.default,
        '& p': {
            margin: theme.spacing(2)
        }
    },
    widgetFooter: {
        margin: theme.spacing(2),
        marginBottom: theme.spacing(3),
        display: 'grid',
        gridTemplateColumns: '1fr max-content'
    },
    bulletActive: {
        opacity: 1,
        background: theme.palette.primary.main,
    },
}))

type AppIntroProps = {
    dismissOnboarding: () => void

    /** Ref to the <nav>> element for determining positions/offsets */
    navRef: RefObject<HTMLDivElement>
}

export const AppIntro = (props: AppIntroProps) => {
    const {dismissOnboarding, navRef} = props
    const [bulletContainer, setBulletContainer] = useState<HTMLDivElement | null>(null);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const arrowRef = useRef<HTMLDivElement>(null)
    const widgetRef = useRef<HTMLDivElement>(null)
    const navItemWidth = useRef<number>(0)
    const classes = useAppIntroStyles(props)

    const updateWidgetPosition = (swiperIndex: number) => {
        const currentNavItem = navRef?.current?.querySelector(`a:nth-child(${swiperIndex + 1})`)
        const navItemLeftPos = currentNavItem?.getBoundingClientRect().left || 0

        // only need to move the widget if viewPort width >= 400px
        if (widgetRef?.current !== null && window.innerWidth >= 400) {
            let widgetLeftPos
            if (navItemLeftPos == 0) {
                widgetLeftPos = 16
            } else if (navItemLeftPos + 300 + 16 > window.innerWidth) {
                widgetLeftPos = window.innerWidth - 300 - 16
            } else {
                widgetLeftPos = navItemLeftPos
            }
            widgetRef.current.style.left = `${widgetLeftPos}px`
        }
        if (arrowRef?.current !== null) {
            // point the dialog arrow above the center of the current navItem
            // minus half the width of the arrow itself
            arrowRef.current.style.left = `${navItemLeftPos + navItemWidth.current / 2 - 10}px`
        }
    }

    useEffect(() => {
        // set first_visit to false as we have shown the guiding steps
        if (localStorage.getItem('first_visit') === "true") {
            localStorage.setItem('first_visit', "false")
        }

        const firstNavItem = navRef?.current?.querySelector('a')
        navItemWidth.current = firstNavItem?.getBoundingClientRect().width || 0
        updateWidgetPosition(0)
    }, [])

    return (
        <>
            <div className={classes.backDropBelowNavbar}></div>
            <div className={classes.onboardingWidget} aria-label="App intro" ref={widgetRef}>
                <div className={classes.widgetInnerWrapper}>
                    <IconButton onClick={() => dismissOnboarding()} className={classes.closeButton} aria-label="Close">
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                    <Swiper
                        onSwiper={(swiper) => setSwiper(swiper)}
                        className={classes.slideContainer}
                        modules={[Pagination, Mousewheel]}
                        mousewheel
                        pagination={{
                            el: bulletContainer,
                            bulletActiveClass: classes.bulletActive
                        }}
                        onRealIndexChange={(swiper) => {
                            updateWidgetPosition(swiper.activeIndex)
                            setIsLastSlide(swiper.slides.length === swiper.activeIndex + 1)
                        }}
                    >
                        <SwiperSlide className={classes.slide}>
                            <Onboarding1 />
                            <div className={classes.slideText}>
                                <Typography variant="modalHeader">See what's in Imunis</Typography>
                                <Typography variant="bodyTextMediumDark">
                                    Imunis helps you stay on track with official vaccination recommendations
                                </Typography>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={classes.slide}>
                            <Onboarding2 />
                            <div className={classes.slideText}>
                                <Typography variant="modalHeader">Track your records</Typography>
                                <Typography variant="bodyTextMediumDark">
                                    Keep your immune health records in one place, accessible when you need them
                                </Typography>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={classes.slide}>
                            <Onboarding3 />
                            <div className={classes.slideText}>
                                <Typography variant="modalHeader">Secure records sharing</Typography>
                                <Typography variant="bodyTextMediumDark">
                                    Keep the health data of those you care for in one digital home
                                </Typography>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={classes.slide}>
                            <Onboarding4 />
                            <div className={classes.slideText}>
                                <Typography variant="modalHeader">Secure connection</Typography>
                                <Typography variant="bodyTextMediumDark">
                                    Safe 3rd Party Connection: Receive digital records from the range of health providers who care for you over the course of your life
                                </Typography>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className={classes.widgetFooter}>
                        <div ref={el => setBulletContainer(el)}/>
                        <Link onClick={() => {
                            if (isLastSlide) {
                                dismissOnboarding()
                            } else {
                                swiper?.slideNext(imunisTheme.transitions.duration.standard)
                            }
                        }}>
                            Next
                        </Link>
                    </div>
                </div>
                <div className={classes.arrowWrapper} ref={arrowRef} />
            </div>
        </>
    )
}
