
import { makeStyles, Theme } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Checkbox as MuiCheckbox } from "@material-ui/core"


type CheckboxProps = {
    /** Checkbox check status */
    checked: boolean

    /** Checkbox label component*/
    label: React.ReactNode,

    /** Name of checkbox */
    name?: string,

    /** Checkbox on change function */
    onChange?: () => void
}

const useStyles = makeStyles<Theme, CheckboxProps>((theme) => ({
    checkbox: {
        padding: theme.spacing(0, '9px' , '9px')
    },
    checkboxContainer: {
        alignItems: "flex-start"
    }
}))

/**
 * Wraps MUI's label+checkbox but ensures the checkbox is top-aligned to the label
 */
const Checkbox = (props: CheckboxProps) => {
    const classes = useStyles(props);
    const {name, checked, label, onChange, ...rest} = props;

    return (
        <FormControlLabel
            {...rest}
            className={classes.checkboxContainer}
            control={<MuiCheckbox
                className={classes.checkbox}
                name={name}
                checked={checked}
                color="primary"
                onChange={onChange} />}
            label={label}
        />
    )
}

export default Checkbox
