import { makeStyles, Theme } from "@material-ui/core"

type Props = {
    /** ISO 3166-1 alpha-2 two-letter country code */
    country: string,
}

const useStyles = makeStyles<Theme>(() => ({
    flag: {
        display: 'block'
    },
    circleFlag: {
        width: '20px',
        height: '20px',
        display: 'block',
        borderRadius: '50%',
        objectFit: 'cover',
    }
}))

export const Flag = ({country}: Props) => {
    const classes = useStyles()
    if (country) {
        return <img className={classes.flag} src={`/flags/${country.toLowerCase()}.svg`}/>
    } else {
        return null
    }
}

export const CircleFlag = ({country}: Props) => {
    const classes = useStyles()
    if (country) {
        return <img className={classes.circleFlag} src={`/flags/${country.toLowerCase()}.svg`}/>
    } else {
        return null
    }
}
