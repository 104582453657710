import { makeStyles, Theme, createStyles } from "@material-ui/core"
import InformationCircleIcon from "../assets/icons/information_circle";
import ExclamationTriangleIcon from "../assets/icons/exclamation_triangle";
import ExclamationCircleIcon from "../assets/icons/exclamation_circle";
import Typography from "./typography";
import { ReactElement } from "react";
import { cx } from "../utils/common";

type InfoPanelProps = {
    /** The severity level determines the icon and colours used */
    severity: 'info' | 'warning' | 'error' | 'neutral'

    /** If a title is not provided, no icon will be displayed (unless `icon` is also provided) */
    title?: string

    /**
     * If `icon` is provided, it will always be displayed (even if no title is provided)
     * and will override the default icon for the severity
     *
     * Example: `<CancelCircleIcon />`
     */
    icon?: ReactElement

    /**
     * Content to display inside info box.
     * For regular text content, pass a string.
     * For multiple paragraphs, pass in `<p>` tags as children:
     * ```
     * <InfoPanel>
     *  <p>...<p>
     *  <p>...<p>
     * </InfoPanel>
     * ```
     *  */
    children: React.ReactNode
}

const useStyles = makeStyles<Theme, InfoPanelProps>((theme) => createStyles({
    boxWrapper: {
        padding: theme.spacing(3),
        background: ({severity}) => {
            switch (severity) {
                case 'neutral':
                    return theme.palette.grey[100]
                default:
                    return theme.palette[severity].light
            }
        },
        borderLeft: '2px solid',
        borderColor: ({severity}) => {
            switch (severity) {
                case 'neutral':
                    return theme.palette.grey[600]
                default:
                    return theme.palette[severity].main
            }
        },
        margin: theme.spacing(0, -3),
        '& > p, ul': {
            margin: theme.spacing(1, 0),
            '&:first-child': {
                marginTop: 0
            },
            '&:last-child': {
                marginBottom: 0
            }
        },
    },
    rowLayout: {
        display: 'flex',
        gap: 8,
    },
    titleWrapper: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    iconWrapper: {
        '& svg': {
            fontSize: 'inherit',
            display: 'block',
        }
    },
    icon: {
        fontSize: '24px',
        color: ({severity}) => {
            switch (severity) {
                case 'neutral':
                    return theme.palette.grey[600]
                default:
                    return theme.palette[severity].main
            }
        },
    }
}));

export const InfoPanel = (props: InfoPanelProps) => {
    const {severity, title, children} = props
    const classes = useStyles(props)

    let icon
    if (props.icon) {
        icon = <span className={cx(classes.iconWrapper, classes.icon)}>{props.icon}</span>
    } else if (title) {
        let Icon
        switch (severity) {
            case 'info':
                Icon = InformationCircleIcon
                break;
            case 'warning':
                Icon = ExclamationTriangleIcon
                break;
            case 'error':
                Icon = ExclamationCircleIcon
                break;
            case 'neutral':
                Icon = InformationCircleIcon
                break;
        }

        icon = <Icon className={classes.icon} />
    }

    return (
        <div className={cx(classes.boxWrapper, !title && icon && classes.rowLayout)}>
            {title ? (
                <div className={classes.titleWrapper}>
                    {icon}
                    <Typography variant="bodyTextLargeBold">
                        {title}
                    </Typography>
                </div>
            ) : icon ? (
                icon
            ) : null}
            {children}
        </div>
    )
}
