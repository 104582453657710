import { useState } from "react";
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, borders, positions } from '@material-ui/system';
import {Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';
import Tab from "../tabs/tab";
import Tabs from "../tabs/tabs";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}${positions}`;

const useStyles = makeStyles((theme) => ({
    backIcon: {
        marginRight: theme.spacing(2)
    }
}))

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{padding: "1rem"}}>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}

const TabsTemplate = (props) => {
    const classes = useStyles()
    const initialTabIndex = 0;
    const [value, setValue] = useState(initialTabIndex);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Tabs value={value} onChange={handleChange} className={classes.tabs}>
                <Tab className={classes.tab} label={`Tab 1`}/>
                <Tab className={classes.tab} label={`Tab 2`}/>
                <Tab className={classes.tab} label={`Tab 3`}/>
            </Tabs>
            <TabPanel value={value} index={0}>
                Item One
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Box>
    );
}

export default TabsTemplate