import { makeStyles, Theme, createStyles } from "@material-ui/core"
import Typography from "./typography";
import { ComponentProps, HTMLAttributes, useState } from "react";
import ChevronDownIcon from "../assets/icons/chevron_down";
import { cx, trackAction } from "../utils/common";

type Props = {
    severity: 'info' | 'warning' | 'error' | 'neutral'

    title?: string

    icon: React.ReactChild
} & ({
    collapsible: false
    children: React.ReactNode
    actions?: React.ReactNode
} | {
    /**
     * Set to true to enable collapsing / expanding on click
     */
    collapsible: true
    defaultCollapsed?: boolean
    children: (isCollapsed: boolean) => React.ReactNode
    actions: (isCollapsed: boolean) => React.ReactNode
})

const useInfoPanelStyles = makeStyles<Theme, Props>((theme) => createStyles({
    container: {
        background: ({severity}) => {
            switch (severity) {
                case 'neutral':
                    return theme.palette.grey[100]
                default:
                    return theme.palette[severity].light
            }
        },
        borderRadius: 8,
        borderBottom: '2px solid',
        borderColor: ({severity}) => {
            switch (severity) {
                case 'neutral':
                    return theme.palette.grey[600]
                default:
                    return theme.palette[severity].main
            }
        },
        padding: ({title}) => title ? theme.spacing(1) : theme.spacing(1.5, 1),
        '& > p': {
            margin: theme.spacing(1, 0),
            '&:last-child': {
                marginBottom: 0
            }
        },
        display: 'grid',
        gridTemplateColumns: 'min-content auto',
        gap: theme.spacing(1),
        '&$collapsible': {
            cursor: 'pointer',
        }
    },
    collapsible: {},

    icon: {
        // color: ({severity}) => theme.palette[severity].main,
        '& svg': {
            fontSize: 24,
            display: 'block',
        }
    },
    titleWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        marginRight: ({title}) => title ? theme.spacing(3) : '',
    },
    actions: {
        marginTop: theme.spacing(1),
    },
    collapsed: {},
    collapseIcon: {
        fontSize: 20,
        marginTop: 4,
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        '&$collapsed': {
            transform: 'rotate(0deg)',
        },
    }
}));

/**
 * An interactive variant of an InfoPanel.
 * Supports collapsing and actions.
 */
export const InfoPanelCollapsible = (props: Props) => {
    const {icon, title, children} = props
    const classes = useInfoPanelStyles(props)

    const isCollapsible = 'collapsible' in props && props.collapsible
    const [isCollapsed, setCollapsed] = useState(isCollapsible && Boolean(props.defaultCollapsed))

    const containerProps: HTMLAttributes<HTMLDivElement> & {
        [key: `data-${string}`]: unknown
    } = {}

    if (isCollapsible) {
        containerProps['data-action'] = `${isCollapsed ? 'Expand' : 'Collapse'} InfoPanel`
        containerProps.onClick = () => {
            trackAction('Click', 'InfoPanel', {
                state: isCollapsed ? 'collapsed' : 'expanded'
            })
            setCollapsed(c => !c)
        }
    }

    let actions
    if (typeof props.actions === 'function') {
        actions = props.actions(isCollapsed) as React.ReactNode
    } else {
        actions = props.actions
    }

    return (
        <div className={cx(classes.container, isCollapsible && classes.collapsible)} {...containerProps}>
            <div className={classes.icon}>
                {icon}
            </div>

            <div>
                {title &&
                    <div className={classes.titleWrapper}>
                        <Typography variant="bodyTextLargeBold">
                            {title}
                        </Typography>

                        {isCollapsible ? (
                            <ChevronDownIcon
                                className={cx(classes.collapseIcon, isCollapsed && classes.collapsed)}
                            />
                        ) : null }
                    </div>
                }

                <div className={classes.content}>
                    {typeof children === 'function' ? (
                        children(isCollapsed)
                    ) : children}
                </div>

                {actions && (
                    <div className={cx(classes.content, classes.actions)}>
                        {actions}
                    </div>
                )}
            </div>
        </div>
    )
}

export const InfoPanelAction = ({
    children, ...props
}: Partial<ComponentProps<typeof Typography>>) => {
    return (
        <Typography variant="bodyTextSmallBoldDark" {...props}>
            {children}
        </Typography>
    )
}
