import { makeStyles } from "@material-ui/core"
import { BaseContainer } from "../../../components/container"
import { AppHeader } from "../../../components/header"
import { HeaderTitle, PageSection } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import Typography from "../../../components/typography"
import { FileInputButton, Profile } from "./utils"
import { cx } from "../../../utils/common"
import TickCircleIcon from "../../../assets/icons/tick_circle"
import ExclamationCircleIcon from "../../../assets/icons/exclamation_circle"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Mousewheel, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'

/**
 * Loads all PNG files in the subdirectory and returns a map like { 'blank': /<contenthash>.png }
 */
const images = (r => {
    const keys = r.keys()
    return keys.reduce((o: { [k: string]: string }, k) => {
        o[k.slice(2, -4)] = r(k) as string
        return o
    }, {})
})(require.context('../../../assets/paper_records', false, /\.png$/))

const useSubmissionExamplesStyles = makeStyles((theme) => ({
    submissionExamples: {
        position: 'relative',
        height: 280,
    },
    submission: {
        position: 'absolute',
        border: `1px solid ${theme.palette.grey[400]}`,
        boxShadow: theme.shadows[2],
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        fontSize: 12,
        padding: '12px 8px',
        borderRadius: 12,
        background: theme.palette.common.white,

        '& > img': {
            width: '100%',
        }
    },
}))
const SubmissionExamples = () => {
    const classes = useSubmissionExamplesStyles()

    return (
        <div className={classes.submissionExamples}>
            <div className={classes.submission} style={{ top: 0, left: 0 }}>
                Submission 1
                <img src={images.example1} />
            </div>
            <div className={classes.submission} style={{ top: 24, right: 0 }}>
                Submission 2
                <img src={images.example2} />
            </div>
        </div>
    )
}

const usePageExamplesStyles = makeStyles((theme) => ({
    swiper: {
        paddingBottom: theme.spacing(2.5),
    },
    page: {
        border: `1px solid ${theme.palette.grey[400]}`,
        boxShadow: theme.shadows[2],
        display: 'inline-flex',
        width: 'min-content',
        flexDirection: 'column',
        gap: 12,
        fontSize: 12,
        padding: '12px 8px',
        borderRadius: 12,
        background: theme.palette.common.white,
        '& > img': {
            width: '240px',
        },
        position: 'relative',
        marginTop: 8,
        marginLeft: 8,
    },
    statusWrapper: {
        position: 'absolute',
        top: -8,
        left: -8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    status: {
        borderRadius: 8,
        fontSize: 14,
        display: 'inline-flex',
        alignItems: 'center',
        width: 'max-content',
        padding: 4,
        gap: 8,
        boxShadow: theme.shadows[2],
        '& > svg': {
            fontSize: 24,
        },
    },
    success: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
    },
    error: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
    },
}))
const PageExamples = () => {
    const classes = usePageExamplesStyles()

    return (
        <Swiper
            className={classes.swiper}
            data-action="(swipe)"
            mousewheel
            freeMode
            scrollbar
            slidesPerView="auto"
            spaceBetween={16}
            modules={[Mousewheel, FreeMode, Scrollbar]}
        >
            <SwiperSlide className={classes.page}>
                <div className={classes.statusWrapper}>
                    <div className={cx(classes.status, classes.success)}>
                        <TickCircleIcon />
                    </div>
                </div>
                <img src={images.complete} />
            </SwiperSlide>
            <SwiperSlide className={classes.page}>
                <div className={classes.statusWrapper}>
                    <div className={cx(classes.status, classes.error)}>
                        <ExclamationCircleIcon />
                        <span>Must match full name</span>
                    </div>
                    <div className={cx(classes.status, classes.error)}>
                        <ExclamationCircleIcon />
                        <span>Date of birth must be visible</span>
                    </div>
                </div>
                <img src={images.missing} />
            </SwiperSlide>
            <SwiperSlide className={classes.page}>
                <div className={classes.statusWrapper}>
                    <div className={cx(classes.status, classes.error)}>
                        <ExclamationCircleIcon />
                        <span>Can't be blank</span>
                    </div>
                </div>
                <img src={images.blank} />
            </SwiperSlide>
        </Swiper>
    )
}

const useStepStyles = makeStyles((theme) => ({
    step: {
        display: 'flex',
        gap: theme.spacing(1),

        '&::before': {
            flexShrink: 0,
            counterIncrement: 'page',
            content: 'counter(page)',
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderRadius: 12,
            width: 24,
            height: 24,
            fontSize: 14,
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: '24px',
            marginTop: 2,
        }
    },
}))
const Step = ({ children }: { children: React.ReactNode }) => {
    const classes = useStepStyles()

    return (
        <PageSection>
            <div className={classes.step}>
                {children}
            </div>
        </PageSection>
    )
}

const useStyles = makeStyles((theme) => ({
    stepContainer: {
        counterReset: 'page',
    },
    step: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
    },
}))

export const ScanFile = ({ userName, onFileAdd, onBack }: {
    userName: string
    onFileAdd: (file: File, restartSubmission: boolean) => void
    onBack: () => void
    submissionStarted: boolean
}) => {
    const classes = useStyles()

    return (
        <BaseContainer
            header={
                <AppHeader onBack={onBack} />
            }
            showCta extraComponent={
                <StickyFooterGroup>
                    <FileInputButton primary onChange={(file) => onFileAdd(file, false)}>
                        Start submission
                    </FileInputButton>
                </StickyFooterGroup>
            }>

            <HeaderTitle>Add paper record</HeaderTitle>

            <Profile name={userName} />

            <PageSection>
                Upload images of vaccination records from other providers and Imunis will digitise and add them to your account.
            </PageSection>

            <PageSection>
                <Typography variant="nameDropdown" color="primary">
                    Things to know before you start
                </Typography>
            </PageSection>

            <div className={classes.stepContainer}>
                <Step>
                    Please create a separate submission for each vaccination document belonging to the same person
                </Step>

                <SubmissionExamples />

                <Step>
                    Each vaccination submission must include a page image with the full name and date of birth that matches those of the Imunis profile
                </Step>

                <PageExamples />

                <Step>
                    We accept images in any image format, but not PDFs. You may upload a photo/screenshot of a PDF.
                </Step>

                <Step>
                    We currently only accept English language documents. They can include foreign-language translations, but all information must be shown in English in order to be digitised.
                </Step>

                <Step>
                    Images must be clear, & vaccination entries including dates must be legible & unambiguous. Imunis reserves the right to reject records not conforming to standard.
                </Step>
            </div>

            <PageSection>
                <Typography variant="bodyTextMedium">
                    Account holders using this service accept responsibility for uploading valid & legitimate vaccination records and acknowledge that digitised records may alter the vaccination status of profiles within their Imunis account.
                </Typography>
            </PageSection>
        </BaseContainer>
    )
}
