import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../components/typography'
import { Button } from '../components/button'
import { backgroundStyle } from '../pages/splash'
import imunisTheme from '../styles/theme';
import ChevronRightIcon from '../assets/icons/chevron_right';
import { cx } from '../utils/common'

import { Parallax, Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/parallax'
import 'swiper/css/pagination'

import RecordBg from '../assets/onboarding/record-bg'
import RecordFg1 from '../assets/onboarding/record-fg-1'
import RecordFg2 from '../assets/onboarding/record-fg-2'
import TrackBg from '../assets/onboarding/track-bg'
import TrackFg1 from '../assets/onboarding/track-fg-1'
import TrackFg2 from '../assets/onboarding/track-fg-2'
import TrackFg3 from '../assets/onboarding/track-fg-3'
import ShareBg from '../assets/onboarding/share-bg'
import ShareFg1 from '../assets/onboarding/share-fg-1'
import ShareFg2 from '../assets/onboarding/share-fg-2'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        grid: '"header" max-content "slides" "footer" max-content',
        height: '100%',
        maxHeight: 'fill-available',
        maxWidth: '600px',
        margin: '0 auto',
        ...backgroundStyle,
        paddingTop: `env(safe-area-inset-top)`,
    },
    slideContainer: {
        gridArea: 'slides',
        width: '100%',
        maxWidth: '100vw', // iOS 12 needs this
    },

    slide: {
        display: 'grid',
        grid: '"svg" "text"',
        '@media (orientation: landscape) and (max-height: 600px)': {
            grid: '"svg text" / 1fr 1fr',
        },
        '& svg': {
            gridArea: 'svg',
            userSelect: 'none',
            maxWidth: '100%',
            maxHeight: '100%',
            justifySelf: 'center',
            alignSelf: 'center',
        },
        '& .text': {
            gridArea: 'text',
            alignSelf: 'center',
            '& p': {
                margin: theme.spacing(2)
            }
        }
    },

    header: {
        gridArea: 'header',
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
    },
    logIn: {
        marginLeft: 'auto',
    },
    alert: {
        margin: '20px 0 40px',
        display: 'inline-flex',
    },

    footer: {
        gridArea: 'footer',
        margin: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr max-content'
    },
    bulletActive: {
        opacity: 1,
        background: theme.palette.primary.main,
    },
    cta: {
        borderRadius: '40px',
        minWidth: 'unset',
        padding: '6px',
        '& svg': {
            height: '28px',
            fill: 'currentColor'
        }
    },
    ctaText: {
        paddingLeft: '46px',
        paddingRight: '46px',
    },
}));

const Homepage = (props) => {
    const token = props.location.state && props.location.state.token
    const type = props.location.state && props.location.state.type
    const [alert] = useState(props.location.state?.alert)
    const history = useHistory()
    const [bulletContainer, setBulletContainer] = useState(null);
    const [isLastSlide, setIsLastSlide] = useState();
    const [swiper, setSwiper] = useState();
    const classes = useStyles()

    useEffect(() => {
        if (alert) {
            // Refreshing the page should clear the alert message
            history.replace(history.location.pathname, "")
        }
    }, [alert])

    let buttonProps = {
        primary: true,
        className: cx(classes.cta, isLastSlide && classes.ctaText)
    }
    if (isLastSlide) {
        buttonProps.onClick = () => history.push('/signup', {token: token, type})
    } else {
        buttonProps.onClick = () => swiper.slideNext(imunisTheme.transitions.duration.standard)
        buttonProps['aria-label'] = 'Next'
    }

    return (
        <div className={classes.wrapper}>

            <div className={classes.header}>
                {alert && (
                    <Typography variant="bodyTextMediumBold">
                        {alert}
                    </Typography>
                )}

                <Typography variant="bodyTextMediumBold" className={classes.logIn}>
                    <Link to={{pathname: '/login', state: {token: token, type}}}>
                        Sign in
                    </Link>
                </Typography>
            </div>

            <Swiper
                onSwiper={setSwiper}
                className={classes.slideContainer}
                modules={[Parallax, Pagination, Mousewheel]}
                mousewheel
                parallax
                pagination={{
                    el: bulletContainer,
                    bulletActiveClass: classes.bulletActive
                }}
                onRealIndexChange={(swiper) => {
                    setIsLastSlide(swiper.slides.length === swiper.activeIndex + 1)
                }}
            >
                <SwiperSlide className={classes.slide}>
                    <RecordBg data-swiper-parallax="-200"/>
                    <RecordFg1 data-swiper-parallax="-500"/>
                    <RecordFg2 data-swiper-parallax="-600"/>
                    <div className="text">
                        <Typography variant="onboardingHeader">Record</Typography>
                        <Typography variant="bodyTextLargeBold">
                            Keep all my important immune-health information in one digital home - so I can store and locate it when I need it
                        </Typography>

                    </div>
                </SwiperSlide>
                <SwiperSlide className={classes.slide}>
                    <TrackBg data-swiper-parallax="-200"/>
                    <TrackFg1 data-swiper-parallax="-300"/>
                    <TrackFg2 data-swiper-parallax="-500"/>
                    <TrackFg3 data-swiper-parallax="-600"/>
                    <div className="text">
                        <Typography variant="onboardingHeader">Track</Typography>
                        <Typography variant="bodyTextLargeBold">
                            Keep up-to-date with official recommendations and access quality information wherever I might be
                        </Typography>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={classes.slide}>
                    <ShareBg data-swiper-parallax="-200"/>
                    <ShareFg1 data-swiper-parallax="-500"/>
                    <ShareFg2 data-swiper-parallax="-600"/>
                    <div className="text">
                        <Typography variant="onboardingHeader">Share</Typography>
                        <Typography variant="bodyTextLargeBold">
                            Share digitally and securely with trusted healthcare providers so they can provide their best care to me and my family
                        </Typography>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div className={classes.footer}>
                <div ref={el => setBulletContainer(el)}/>
                <Button {...buttonProps}>
                    {isLastSlide ? 'Sign up' : <ChevronRightIcon/>}
                </Button>
            </div>

        </div>
    )
}

export default Homepage
