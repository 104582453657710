import { SvgIcon, SvgIconProps } from "@material-ui/core";
import theme from '../../styles/theme';

export default function TermsFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path fill="currentColor" d="M18.8 6.1c.1.2.2.4.2.6v13.5c0 .2-.1.4-.2.6-.1.1-.3.2-.6.2H4.8c-.2 0-.4-.1-.6-.2s-.2-.4-.2-.6V3.8c0-.2.1-.4.2-.6.2-.1.4-.2.6-.2h10.5c.2 0 .4.1.6.2l2.9 2.9z"/>
            <path stroke={theme.palette.common.white} strokeWidth="1.5" d="M7.5 13.5h8m-8 3h8m-8-6h8m-8-3h4.4"/>
        </SvgIcon>
    )
}
