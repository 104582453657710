import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { InfoPanel } from "../info_panel";
import { pluralString } from "../../utils/common";
import { JsonContent } from "../static_content";
import hpvJson from "../../content/hpv.json";
import { VaccinationStatus } from "../vaccination_progress";

const pluralise_doses = (x: number) => {
    // Fortunately this won't be called with a value of 0 (expected: "no qualifying doses")
    // The underlying `pluralString` function isn't robust enough to handle this.
    return `${x} qualifying ${pluralString('dose', x)}`
}

type HpvContentProps = {
    status: VaccinationStatus
}

export const hpvStatusLabel = ({
    vaccine_status,
    action_status,
    doses_taken,
    age_bracket,
    interval_status,
    unavailable_reason,
}: HpvContentProps['status']) => {
    switch (vaccine_status) {
        case 'ineligible':
            return 'You are ineligible for vaccination as your current age falls below the minimum recommended age for HPV vaccination. See below for further schedule information.'

        case 'unavailable':
            switch (unavailable_reason) {
                case 'BELOW_AGE':
                    return 'Your records indicate vaccination below the recommended minimum age. Imunis cannot at this time calculate your vaccination status'
                case 'BELOW_INTERVAL':
                    return 'Your records indicate vaccination before the minimum dose interval. Imunis is currently unable to calculate your status. Please talk to your healthcare provider.'
                case 'CONFLICT':
                    return "Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing."
                case "UNSPECIFIED_PRODUCT":
                    return "Vaccination status is currently unavailable due to a lack of necessary information about the vaccine products/type of products taken."
                case 'DOB_UNAVAILABLE':
                    return 'Status is unavailable due to missing DOB'
            }
            break

        case 'unvaxed':
            switch (action_status) {
                case 'ELIGIBLE':
                    switch (age_bracket) {
                        case 'EARLY':
                            return 'You are eligible for HPV vaccination having reached the minimum vaccination age, although the target age for the first dose is 11 years of age. See below for more schedule guidance.'
                        case 'TARGET':
                            return 'You are eligible for HPV vaccination. Taking dose 1 before the age of 15 years will mean you are eligible for the 2-dose course. See below for further schedule information.'
                        case 'ABOVE':
                            return 'You are eligible for HPV vaccination with no qualifying doses held with Imunis.'
                        case 'LATE':
                            return 'You are eligible for HPV vaccination with no qualifying doses held with Imunis. It is recommended to talk with your health provider about HPV vaccination to determine whether it is a vaccine course that would be beneficial for you to take, now or in the future.'
                    }
                    break

                case 'MISSED':
                    return 'You have now passed the window for effective vaccination as you did not start the course before 26 years of age. Some patients may still be offered the vaccine, as it may offer protection for certain lifestyle choices or against particular pre-existing health conditions. Talk to your health provider for more information.'
            }
            break

        case 'partial':
            switch (action_status) {
                case 'ELIGIBLE':
                    if (interval_status === 'BELOW') {
                        return 'You are currently eligible for vaccination, with 2 qualifying doses held with Imunis. As the minimum interval of 5 months was not achieved between doses 1 and 2, you are recommended to take a third dose, 12 months or more after the 2nd dose.'
                    }

                    return `You are eligible for vaccination, with ${pluralise_doses(doses_taken)} held with Imunis and you have completed the minimum interval following dose ${doses_taken}.`
                case 'UP_TO_DATE':
                    if (interval_status === 'BELOW') {
                        return 'You are currently up to date, with 2 qualifying doses held with Imunis. As the dose interval was less than 5 months, a third dose is recommended for optimal protection. This should be taken from 12 months after the most recent dose.'
                    }

                    return `You are currently up to date with ${pluralise_doses(doses_taken)} held with Imunis, and need to complete the minimum interval before being eligible for dose ${doses_taken + 1}. See below for further schedule information.`
            }
            break

        case 'full':
            if (interval_status === 'BELOW') {
                return 'You are currently up to date and have completed your course. You followed a 3-dose course due to incomplete minimal intervals between initial doses.'
            }

            return `You are currently up to date and have completed your course, with ${pluralise_doses(doses_taken)} held with Imunis.`
    }
}

export const hpvStatusWarnings = ({overvaxed, doses_taken, has_gardasil9}: HpvContentProps['status']) => {
    return <>
        {overvaxed &&
        <PageSubsection>
            <InfoPanel severity="info">
                <Typography variant="bodyTextLarge">
                    You may have taken more doses than the latest official recommendation for full vaccination. Imunis will only show dose counts based on the official recommendation
                </Typography>
            </InfoPanel>
        </PageSubsection>}

        {doses_taken > 0 && has_gardasil9 === false &&
        <PageSubsection>
            <InfoPanel severity="info">
                <Typography variant="bodyTextLarge">
                    Your record does not contain a history of taking any doses of Gardasil 9. Gardasil 9 offers protection against the broadest range of HPV strange which may cause an HPV infection. Gardasil 9 is interchangeable with other HPV vaccines and may be offered as an additional dose. Speak to your health provider for further information, or if you have additional product information to add to your vaccine records.
                </Typography>
            </InfoPanel>
        </PageSubsection>}
    </>
}


export const HpvContent = ({ status }: HpvContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {hpvStatusLabel(status)} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    {hpvStatusWarnings(status)}

    <Divider />

    <JsonContent>
        {hpvJson}
    </JsonContent>
</>)
