import { AxiosRequestConfig } from "axios"
import { axiosAuthedInstance } from "../utils/axiosApi"
import { PendingDependant } from "./connections/onboard_adult"

export type Action = {
    key: string
    type: "vaccinations" | "sharing" | "school" | "clinic"
    title: string
    content: string
    action: {
        type: "link"
        url: string
    }
    state: VaccinationData | SharingData | SchoolData | ClinicData
    priority: number
}

type VaccinationData = {
    user: {
        preferred_name: string
        uuid: string
    }
}

type SharingData = {
    token: string
}

type SchoolData = {
    user: {
        full_name: string
        uuid: string
        year: string
        organisation: string
    },
    type: "school"
}

type ClinicData = {
    user: {
        full_name: string,
        uuid: string,
        organisation: string,
    },
    type: "clinic"
}

type UserEligibility = {
    uuid: string
    full_name: string
    diseases: {
        name: string
    }[]
}

type GetActionsResponse = {
    /**
     * IDs of additional quick actions to display for the user
     */
    quick_actions: ('paper_records')[]

    actions: Action[]

    pending_dependants: PendingDependant[]

    eligibility: UserEligibility[]
}

export const getActions = (config?: AxiosRequestConfig) => (
    axiosAuthedInstance.get<GetActionsResponse>('/user/get-actions/', config)
)

/**
 * Transforms a GetActionsResponse's "state" object from snake_cased keys to camelCased.
 * We can't do this under the hood for the entire response as there's existing code that expects
 * snake_cased keys.
 */
export const transformActionState = (action: Action) => {
    const state = action.state
    switch (action.type) {
        case 'vaccinations':
            return {
                user: {
                    preferredName: (state as VaccinationData).user.preferred_name,
                    uuid: (state as VaccinationData).user.uuid,
                }
            }
        case 'sharing':
            return state
        case 'school':
        case 'clinic':
            return state
    }
}
