import ErrorPage from "../components/error_page";

const NotFound = (props) => {
    return (
        <ErrorPage 
            search 
            title={'Page not found'}
            content={"The page you were looking for doesn't exist"}
            location={'/'}
            cta_label={"Go to homepage"}
        />
    )
}
export default NotFound;