import { ReactNode } from "react"
import { ModalTitle, PageDescription } from "./page_sections"
import Typography from "./typography"

type DrawerContentProps = {
    /** Title of drawer content */
    title: string,

    /** Contents of the drawer */
    children?: ReactNode

    /** Contents of the drawer, but as a string.
     * Note when providing content via this prop, the `bodyTextLarge` style will apply to it.
     * To provide arbitrary content, use `children`.
     */
    content?: string,
}

/** Simple drawer content of just title and content */
export const DrawerContent = ({title, content, children}: DrawerContentProps) => {
    return (
        <>
            <ModalTitle>
                {title}
            </ModalTitle>
            {content &&
                <PageDescription>
                    <Typography variant='bodyTextLarge'>
                        {content}
                    </Typography>
                </PageDescription>
            }
            {children}
        </>
    )
}
