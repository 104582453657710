import { makeStyles } from "@material-ui/core"
import { cx } from "../utils/common"

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(1.5, 0),
        width: '100%',
        height: '1px',
        background: theme.palette.grey[200],
    }
}))

type DividerProps = {
    /** extra styles */
    className?: string
}

export const Divider = (props: DividerProps) => {
    const classes = useStyles()
    return (
        <div className={cx(classes.divider, props.className)}/>
    )
}

