import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const Typos = (props) => {
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Box display="flex" flexDirection="column" >
                <Typography className={classes.margin} variant="h6" gutterBottom>
                    Font Size
                </Typography>
                <Typography gutterBottom variant="h1">Headline 1</Typography>
                <Typography gutterBottom variant="h2">Headline 2</Typography>
                <Typography gutterBottom variant="h3">Headline 3</Typography>
                <Typography gutterBottom variant="h4">Headline 4</Typography>
                <Typography gutterBottom variant="h5">Headline 5</Typography>
                <Typography gutterBottom variant="h6">Headline 6</Typography>
                <Typography gutterBottom variant="subtitle1">Subtitle 1</Typography>
                <Typography gutterBottom variant="subtitle2">Subtitle 2</Typography>
                <Typography gutterBottom variant="body1">Body 1</Typography>
                <Typography gutterBottom variant="body2">Body 2</Typography>
                <Typography gutterBottom variant="button">Button</Typography>
                <Typography gutterBottom variant="caption">Caption</Typography>
                <Typography gutterBottom variant="overline">Overline</Typography>
            </Box>
        </Box>
    )
}

export default Typos