import SvgIcon from '@material-ui/core/SvgIcon';

export default function SettingsIcon() {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" clipRule="evenodd" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.5">
                <path d="m10.22 4-.1.5-.37 1.83a6.3 6.3 0 0 0-1.64.94l-1.84-.62-.5-.15-.25.44-1.26 2.12L4 9.5l.37.33 1.43 1.2c-.05.32-.12.64-.12.97 0 .33.07.65.12.96l-1.43 1.21-.37.33.26.44 1.26 2.12.26.44.5-.15 1.83-.62c.5.39 1.04.71 1.64.94l.37 1.83.1.5h3.56l.1-.5.37-1.83a6.3 6.3 0 0 0 1.64-.94l1.84.62.5.15.25-.44 1.26-2.12.26-.44-.38-.33-1.42-1.2c.05-.32.12-.64.12-.97 0-.33-.07-.65-.12-.96l1.42-1.21.38-.33-.26-.44-1.26-2.12-.26-.44-.5.15-1.83.62a6.3 6.3 0 0 0-1.64-.94l-.37-1.83-.1-.5h-3.56Z" />
                <path d="M14.29 12a2.29 2.29 0 1 1-4.58 0 2.29 2.29 0 0 1 4.58 0Z" />
            </g>
        </SvgIcon>

    )
}
