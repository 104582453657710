import SvgIcon from '@material-ui/core/SvgIcon';

function UserIcon(props) {
    const opacityVal = props.active ? 1 : 0.25
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <circle opacity={opacityVal} cx="16.5" cy="7.5" r="5.5" fill="#FBD202"/>
            <path fill="none" d="M20 21.0952V19.0635C20 17.9858 19.5786 16.9522 18.8284 16.1902C18.0783 15.4281 17.0609 15 16 15H8C6.93913 15 5.92172 15.4281 5.17157 16.1902C4.42143 16.9522 4 17.9858 4 19.0635V21.0952" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M11.8095 11.619C13.9135 11.619 15.619 9.91347 15.619 7.80952C15.619 5.70558 13.9135 4 11.8095 4C9.70558 4 8 5.70558 8 7.80952C8 9.91347 9.70558 11.619 11.8095 11.619Z" stroke={props.strokecolor || "#333"} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default UserIcon