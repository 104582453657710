import { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders, positions } from '@material-ui/system';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FamilyListItem from '../lists/family_list_item';
import AccountListItem from '../lists/account_list_item';
import Action from '../action';
import HealthListItem from '../lists/health_list_item';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}${positions}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Lists = (props) => {
    const classes = useStyles()

    return (
        <Box display="flex" flexDirection="column" border={2} borderRadius={16} m={2} p={2}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" flex={1} margin={1}>
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            List Item
                        </Typography>
                        <FamilyListItem
                            title="List Item"
                            ellipsisItems={[{content: 'Menu Item'}, {content: 'Menu Item 2'}]}
                        />
                        <FamilyListItem
                            title="List Item"
                            caption="Caption"
                            check
                            ellipsisItems={[{content: 'Menu Item'}, {content: 'Menu Item 2'}]}
                        />
                        <FamilyListItem
                            title="List Item"
                            disabled
                            ellipsisItems={[{content: 'Menu Item'}, {content: 'Menu Item 2'}]}
                        />
                        <AccountListItem
                            title="Jenny Child"
                            caption="Primary Guardian of"
                            ellipsisItems={[{content: 'Menu Item'}, {content: 'Menu Item 2'}]}
                        />
                        <HealthListItem
                            title="Comirty COVID-19 mRNA vaccine"
                            caption="HK Bubble :"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column">
                        <Typography className={classes.margin} variant="h6" gutterBottom>
                            Actions
                        </Typography>
                        <Action
                            type="school"
                            title="Connect dependant Child at Test School"
                            content="You have received an invitation to link your account to test school"
                        />
                        <Action
                            type="vaccinations"
                            title="Upload COVID vaccine record"
                            content="Quickly upload your first vacinnation record to your profile"
                        />
                        <Action
                            type="sharing"
                            title="Test Person has shared information"
                            content="Accept or decline their invitation"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Lists
