const Svg = () => (
    <svg viewBox="0 0 300 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x={85} y={23} width={181} height="106.829" rx="6.64" fill="white" />
        <rect x="85.2768" y="23.2768" width="180.446" height="106.275" rx="6.36544" stroke="#C4C4C4" strokeWidth="0.553517" />
        <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="8.85627" fontWeight={600} letterSpacing="0px"><tspan x="111.569" y="41.292">Jenny</tspan></text>
        <text fill="#767676" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="6.6422" letterSpacing="0px"><tspan x="215.576" y="40.5936">2 shares</tspan></text>
        <path d="M250.518 35.4507C250.627 35.4501 250.734 35.4711 250.835 35.5124C250.936 35.5537 251.027 35.6146 251.105 35.6915L254.41 39.0126C254.487 39.09 254.548 39.1819 254.59 39.2831C254.631 39.3842 254.653 39.4926 254.653 39.6021C254.653 39.7116 254.631 39.82 254.59 39.9212C254.548 40.0223 254.487 40.1142 254.41 40.1916C254.333 40.269 254.241 40.3304 254.141 40.3723C254.04 40.4142 253.932 40.4358 253.823 40.4358C253.714 40.4358 253.606 40.4142 253.506 40.3723C253.405 40.3304 253.314 40.269 253.236 40.1916L250.518 37.4434L247.791 40.0837C247.715 40.1686 247.622 40.2369 247.519 40.2842C247.415 40.3315 247.303 40.3568 247.19 40.3587C247.076 40.3605 246.963 40.3387 246.858 40.2947C246.753 40.2507 246.659 40.1854 246.58 40.103C246.501 40.0205 246.44 39.9226 246.401 39.8154C246.362 39.7082 246.345 39.5941 246.352 39.48C246.358 39.366 246.388 39.2546 246.44 39.1527C246.491 39.0509 246.563 38.9608 246.651 38.8881L249.956 35.6832C250.107 35.5368 250.308 35.4537 250.518 35.4507Z" fill="#767676" />
        <path d="M104.143 43V43.5C104.419 43.5 104.643 43.2761 104.643 43H104.143ZM97 43H96.5C96.5 43.2761 96.7239 43.5 97 43.5V43ZM102.17 35.1415C102.17 36.0478 101.435 36.7829 100.527 36.7829V37.7829C101.986 37.7829 103.17 36.6007 103.17 35.1415H102.17ZM100.527 36.7829C99.6191 36.7829 98.8839 36.0478 98.8839 35.1415H97.8839C97.8839 36.6007 99.0674 37.7829 100.527 37.7829V36.7829ZM98.8839 35.1415C98.8839 34.2351 99.6191 33.5 100.527 33.5V32.5C99.0674 32.5 97.8839 33.6822 97.8839 35.1415H98.8839ZM100.527 33.5C101.435 33.5 102.17 34.2351 102.17 35.1415H103.17C103.17 33.6822 101.986 32.5 100.527 32.5V33.5ZM99.1429 39.9309H102V38.9309H99.1429V39.9309ZM102 39.9309C102.907 39.9309 103.643 40.6662 103.643 41.5734H104.643C104.643 40.1137 103.459 38.9309 102 38.9309V39.9309ZM97.5 41.5734C97.5 40.6662 98.2354 39.9309 99.1429 39.9309V38.9309C97.6834 38.9309 96.5 40.1137 96.5 41.5734H97.5ZM103.643 41.5734V43H104.643V41.5734H103.643ZM96.5 41.5734V43H97.5V41.5734H96.5ZM104.143 42.5H97V43.5H104.143V42.5Z" fill="#333333" />
        <rect x="93.8563" y="52.8899" width="163.287" height="0.553517" fill="#E7E7E7" />
        <rect x="93.8563" y="53.4434" width="163.287" height="37.0856" fill="white" />
        <text fill="#767676" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="7.74924" letterSpacing="0px"><tspan x="96.0703" y="81.3228">1 Mar 2022</tspan></text>
        <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="7.74924" fontWeight={600} letterSpacing="0px"><tspan x="96.0703" y="68.5919">Share with Alan</tspan></text>
        <rect x="93.8563" y="89.9755" width="163.287" height="0.553517" fill="#E7E7E7" />
        <path d="M249.948 62.8532L246.627 70.6025" stroke="#333333" strokeWidth="0.830275" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M248.21 64.0986H245.52C244.068 64.0986 242.891 65.2758 242.891 66.7278C242.891 68.1799 244.068 69.3571 245.52 69.3571H245.956L246.312 68.5268H245.52C244.526 68.5268 243.721 67.7214 243.721 66.7278C243.721 65.7343 244.526 64.9289 245.52 64.9289H247.854L248.21 64.0986ZM248.721 68.5268H251.055C252.049 68.5268 252.854 67.7214 252.854 66.7278C252.854 65.7343 252.049 64.9289 251.055 64.9289H250.263L250.619 64.0986H251.055C252.507 64.0986 253.684 65.2758 253.684 66.7278C253.684 68.1799 252.507 69.3571 251.055 69.3571H248.365L248.721 68.5268Z" fill="#333333" />
        <rect x="93.8563" y="90.5291" width="163.287" height="37.0856" fill="white" />
        <text fill="#767676" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="7.74924" letterSpacing="0px"><tspan x="96.0703" y="118.408">1 Mar 2022</tspan></text>
        <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize="7.74924" fontWeight={600} letterSpacing="0px"><tspan x="96.0703" y="105.678">Share with Linda</tspan></text>
        <path d="M249.948 99.9389L246.627 107.688" stroke="#333333" strokeWidth="0.830275" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M248.21 101.184H245.52C244.068 101.184 242.891 102.361 242.891 103.813C242.891 105.266 244.068 106.443 245.52 106.443H245.956L246.312 105.612H245.52C244.526 105.612 243.721 104.807 243.721 103.813C243.721 102.82 244.526 102.015 245.52 102.015H247.854L248.21 101.184ZM248.721 105.612H251.055C252.049 105.612 252.854 104.807 252.854 103.813C252.854 102.82 252.049 102.015 251.055 102.015H250.263L250.619 101.184H251.055C252.507 101.184 253.684 102.361 253.684 103.813C253.684 105.266 252.507 106.443 251.055 106.443H248.365L248.721 105.612Z" fill="#333333" />
        <rect x="43.5" y="76.5" width={132} height={45} rx="3.5" fill="white" />
        <text fill="#333333" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Open Sans" fontSize={16} fontWeight={600} letterSpacing="0px"><tspan x="87.3125" y="105.207">Share</tspan></text>
        <rect x="43.5" y="76.5" width={132} height={45} rx="3.5" stroke="#767676" />
    </svg>

)

export default Svg
