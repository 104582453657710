import SvgIcon from '@material-ui/core/SvgIcon';

export default function SignOutIcon() {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5" d="M16.5 8.5 20 12l-3.5 3.79M20 12H8.92m4.66 7H6V5h7.58" />
        </SvgIcon>

    );
}
