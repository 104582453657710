import { makeStyles } from "@material-ui/core"
import Typography from "../../../components/typography"

const useStyles = makeStyles((theme) => ({
    text: {
        marginLeft: 0,
        padding: theme.spacing(1, 0, 1, 2)
    },
}))

export const SingleFormulaContent = () => {
    const classes = useStyles()
    return (
        <Typography variant="bodyTextMediumDark" component="ol" className={classes.text}>
            <li>
                If you were vaccinated as part of a regular seasonal vaccination programme, the formula was likely the Seasonal  Vaccination Formula shown above.
                If you were vaccinated as a ‘travel vaccine’ in order to protect you in a different geography, it was likely the alternative formula.
            </li>
            <li>
                If you have a vaccine record, it may tell you.
                Northern hemisphere vaccines may contain a time reference following this example format - ‘2022-2023, while southern hemisphere formulas would show for example ‘2022’.
            </li>
        </Typography>
    )
}

export const DualFormulaContent = () => {
    const classes = useStyles()
    return (
        <Typography variant="bodyTextMediumDark" component="ol" className={classes.text}>
            <li>
                If you have a vaccine record, it may tell you.
                Northern hemisphere vaccines may contain a time reference following this example format - ‘2022-2023, while southern hemisphere formulas would show for example ‘2022’.
            </li>
            <li>
                Ask your vaccine administrator.
            </li>
        </Typography>
    )
}
