import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {spacing, sizing, flexbox, display} from '@material-ui/system';
import styled from 'styled-components';
import {Container} from '@material-ui/core';


const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        position: 'fixed',
        alignItems: 'center'
    },

    gapper: {
        top: 'auto',
        bottom: 0,
        padding: theme.spacing(5)
    },

    singleCta: {
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(444px + 1rem)'
        }
    },

    multiCta: {
        padding: theme.spacing(2, 2),
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(444px + 1rem)'
        }
    }
}));

export const FloatButtonFooter = (props) => {
    const classes = useStyles();

    return (
        <AppBar color='inherit' className={classes.appBar} elevation={0}>
            {props.singleCta &&
            <Box className={classes.singleCta} width="100%" display="Grid" alignItems="center">
                {props.children}
            </Box>
            }
            {props.multiCta &&
            <Box className={classes.multiCta} width="100%" display="Grid" alignItems="center">
                {props.children}
            </Box>
            }
        </AppBar>
    )
}
