import { makeStyles } from "@material-ui/core"
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

const Loading = (props) => {
    const classes = useStyles()
    if (!props.loading) return null;

    return (
        <div className={classes.wrapper}>
            <CircularProgress />
        </div>
    )
}

export default Loading
