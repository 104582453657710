import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import Typography from './typography';
import EmptyListIcon from '../assets/icons/emptyListIcon';

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    text: {
        margin: theme.spacing(1, 0),
        fontSize: theme.typography.fontSize * 0.875,
    },
    fontSizeLarge: {
        color: theme.palette.grey[400],
        width:'24px',
        height:'24px',
    },
    empty:{
        textAlign: 'center'
    },
}))

const EmptyList = (props) => {
    const classes = useStyles()
    return(
        <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <Box className={classes.empty}>
                <EmptyListIcon classes={{fontSizeLarge: classes.fontSizeLarge}} fontSize="large"/>
                <Typography variant='bodyTextSmall'>{props.caption || 'Your list is empty'}</Typography>
            </Box>
            <Typography variant="body2" className={classes.text} >{props.caption}</Typography>
        </Box>
    )

}

export default EmptyList
