import SvgIcon from '@material-ui/core/SvgIcon';

function ClinicHouseIcon(props) {
    return (
        <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
            <ellipse cx="34.1252" cy="15.9977" fill="#FBD202" opacity=".6" rx="12" ry="12.0978"/>
            <g fill="none" stroke="currentColor">
                <path strokeMiterlimit="10" strokeWidth="2.2" d="M29.7363 17.4932h9.6496v25.0566H6.7381V17.4932h9.8195M16.4339 17.1412H4l6.9002-9.333C11.2751 7.3006 12.0466 7 12.6914 7h21.0887c.6599 0 1.2764.3156 1.6497.8433L42 17.1412H29.5927"/>
                <path strokeMiterlimit="10" strokeWidth="2" d="M23.0476 24.3597c3.7842 0 6.8519-3.0742 6.8519-6.8665 0-3.7923-3.0677-6.8666-6.8519-6.8666-3.7841 0-6.8518 3.0743-6.8518 6.8666s3.0677 6.8665 6.8518 6.8665Z"/>
                <path strokeMiterlimit="10" strokeWidth="1.2682" d="M14.0793 27.5108h-3.4026a.4716.4716 0 0 1-.4715-.4726v-3.3481a.4716.4716 0 0 1 .4715-.4726h3.4026a.4717.4717 0 0 1 .4716.4726v3.3481c.0017.2605-.21.4726-.4716.4726ZM35.3379 27.5609h-3.4025a.4716.4716 0 0 1-.4716-.4726v-3.3982a.4716.4716 0 0 1 .4716-.4726h3.4025a.4717.4717 0 0 1 .4716.4726v3.3982c.0017.2605-.21.4726-.4716.4726ZM35.3379 37.166h-3.4025a.4716.4716 0 0 1-.4716-.4726v-4.4586a.4716.4716 0 0 1 .4716-.4725h3.4025c.2616 0 .4716.212.4716.4725v4.4586c.0017.2605-.21.4726-.4716.4726ZM14.0793 37.166h-3.4026a.4716.4716 0 0 1-.4715-.4726v-4.4586a.4716.4716 0 0 1 .4715-.4725h3.4026c.2616 0 .4716.212.4716.4725v4.4586c.0017.2605-.21.4726-.4716.4726Z"/>
                <path strokeMiterlimit="10" strokeWidth="1.7" d="M27.3751 42.5497h-8.8597V30.9674c0-.521.4249-.9468.9448-.9468h6.9718c.5199 0 .9448.4258.9448.9468v11.5823h-.0017Z"/>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8117" d="M23.1152 14.5152v5.4312M20.3739 17.2308h5.4825"/>
            </g>
            <ellipse cx="20.8207" cy="37.7821" rx=".632" ry=".6334"/>
        </SvgIcon>
    );
}

export default ClinicHouseIcon
