import * as React from 'react'
import {TypographyProps as MuiTypographyProps} from '@material-ui/core/Typography'
import {makeStyles, Theme, createStyles, Typography as MuiTypography} from "@material-ui/core"
import { cx } from '../utils/common'

export const styles = (theme: Theme) => ({
    // Headers / Titles
    onboardingHeader: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '33px',
    },
    pageHeader: {
        fontSize: '21px',
        fontWeight: 600,
        lineHeight: '32px',
        color: theme.palette.primary.main,
    },
    modalHeader: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27px',
    },
    nameLabelMedium: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px',
    },
    categoryHeader: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        color: theme.palette.primary.main,
    },
    listTitle: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
        color: theme.palette.primary.main,
    },

    // Body / Content

    nameDropdown: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '25px',
    },

    bodyTextLargeBold: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px',
    },
    bodyTextLarge: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
    },

    bodyTextMediumBold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px'
    },
    bodyTextMedium: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.grey[600]
    },
    bodyTextMediumPrimary: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.primary.main
    },
    bodyTextMediumDark: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        color: theme.palette.grey[800]
    },
    bodyTextButton: {
        fontSize: '14px',
        fontWeight: 600,
    },

    bodyTextSmallBold: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        color: theme.palette.grey[600]
    },
    bodyTextSmall: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.grey[600]
    },
    bodyTextSmallDark: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.grey[800]
    },
    bodyTextSmallBoldDark: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        color: theme.palette.grey[800]
    },
    bodyTextSmallPrimary: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.primary.main
    },

    statusText: {
        fontSize: '10px',
        fontWeight: 400,
    },
})

export const useStyles = makeStyles(theme => createStyles(styles(theme)))

type TypographyProps = {
    variant: keyof ReturnType<typeof styles>,
    className?: string,
    children?: React.ReactNode
} & Omit<MuiTypographyProps<'span', { component?: React.ElementType }>, 'variant'>
// See: https://v4.mui.com/guides/typescript/#usage-of-component-prop

const Typography = ({children, variant, className, ...props}: TypographyProps) => {
    const classes = useStyles()

    const MuiProps: MuiTypographyProps = {
        component: 'p',
        ...props
    }

    return (
        <MuiTypography className={cx(classes[variant], className)} {...MuiProps}>
            {children}
        </MuiTypography>
    )
}

export default Typography
