import { Fragment } from "react";
import { makeStyles, Theme, createStyles, useTheme, alpha } from "@material-ui/core"
import ShieldIcon from '../assets/icons/shield_icon'
import Typography from "./typography"


export type DoseStep = {
    circle_text: string,
    label_text: string,
    fill: string,
    stroke: string,
    past: boolean
}

/**
 * Vaccination status as returned by the rules engine.
 * This should be aligned with `app.rules_engine.base.VaccinationStatus`.
 */
export type VaccinationStatus = {
    vaccine_status: 'ineligible' | 'unvaxed' | 'partial' | 'full' | 'full_plus_delay' | 'expired' | 'unavailable'
    action_status: 'UP_TO_DATE' | 'ELIGIBLE' | 'MISSED' | 'LATE' | 'UNAVAILABLE'

    /* Total number of doses taken for all products */
    doses_taken: number
    doses_total: number

    /* The current course that you are on, and the corresponding dose totals for that
       course. This is used for scenarios where there are multiple courses which can
       be taken. For example - an initial one dose course of zostavax, followed by a
       subsequent two dose course of shingrix */
    course: string
    course_taken: number
    course_target: number

    /** ISO duration, e.g. P14D */
    immunity_delay: string
    booster: number
    emergency: number

    age_bracket: "BELOW" | "EARLY" | "TARGET" | "ABOVE" | "LATE"
    interval_status: "BELOW" | "ABOVE"
    unavailable_reason:
    "BELOW_AGE" | "BELOW_INTERVAL" |
    "CONFLICT" | "DOB_UNAVAILABLE" |
    "INVALID_AGE_DOSE" | "GENERIC_PRODUCT" | "UNSUPPORTED_PRODUCT" |
    "MIXED_PRODUCTS" | "UNSPECIFIED_PRODUCT" | "PARTIAL_RECORD"

    overvaxed: boolean
    crossover_dose: boolean

    /* Either an ISO date, null or a text string to be displayed verbatim
       or non existant if not relvant for the status */
    next_dose_due?: string

    /**
     * Set for Covid only; true when a record indicates more doses were taken than we have records
     * for
     */
    partial_vaccine_record?: boolean
    /**
     * Set for Covid only; set to an ISO date when there is a partial vaccine record indiciating the
     * date of the latest certificate
     */
    partial_certificate_date?: string
    /**
     * Set for Covid only; total doses received indicated by QR record (may be higher than the record
     * count.
     */
    covid_dose_count?: number
    /**
     * Set for Covid only; age bracket. Used for customising the text output
    */
    covid_age_bracket: "infant" | "child" | "adult" | "elder"
    /**
     * Set for Covid only; age bracket. Used for customising the text output
    */
    booster_dosing: "" | "≥6m <18y" | "≥18y"
    /**
     * Set for Covid only; age bracket. Used for customising the text output
    */
    infection_history: "" | "≥6m <5y" | "≥5y"

    /** Applicable only for HPV */
    has_gardasil9: boolean

    /** Applicable only for Shingles */
    zostavax_upgrade: boolean

    /** Application only for Shingles, but could be extended to others as needed
        semantics may vary depending on the ruleset requirements */
    dose_too_soon: boolean

    /** Applicable only for Influenza */
    previous_dose: boolean

    /** New form structure to return individual doses to display */
    steps?: DoseStep[],
}

export type ProgressProps = {
    /** Amount of doses taken */
    dosesTaken: number,

    /** Total number of doses required to be taken */
    dosesTotal: number,

    /** Defined as follows:
     * * success: current dose will be green
     * * warning: next dose will be yellow
     * * error: next dose will be red
     * * late: current dose will be yellow
     */
    action: "success" | "warning" | "error" | "late"

    /** Defined as follows:
     * * -1: Don't show shield
     * *  0: inactive shield
     * *  1: active shield
     */
    shield: -1 | 0 | 1,

    /** The number of existing doses to label as "booster" */
    booster?: number

    /** Number of additional doses to add as emergency doses */
    emergency?: number

    /** Indicates the hemisphere of the last dose taken */
    crossoverDose?: "N" | "S"

    /** List of dose numbers which should be marked as invalid */
    invalidDoses: number[]

    /** New form structure to return individual doses to display */
    steps?: DoseStep[],
}

/** Transforms a vaccination status as returned by the API into props for the component */
export const getProgressProps = (diseaseId: string, status: VaccinationStatus): ProgressProps | null => {
    if (!status) {
        return null
    }

    if (typeof status.steps !== "undefined" && status.steps !== null) {
        return {
            dosesTaken: 0,
            dosesTotal: 0,
            action: 'success',
            shield: -1,
            booster: 0,
            emergency: 0,
            invalidDoses: [],
            steps: status.steps,
        }
    }

    let shield: ProgressProps['shield'] = 0
    if (status.vaccine_status === 'full_plus_delay') {
        shield = 1
    } else if (!status.immunity_delay) {
        shield = -1
    }

    let crossoverDose: ProgressProps['crossoverDose']
    if (status.crossover_dose) {
        switch (diseaseId) {
            case '6142004-NORTH':
                crossoverDose = 'S';
                break;
            case '6142004-SOUTH':
                crossoverDose = 'N';
                break;
        }
    }

    let action: ProgressProps['action']
    switch (status.action_status) {
        case 'UP_TO_DATE':
            action = 'success'
            break
        case 'LATE':
            action = 'late'
            break
        case 'MISSED':
            action = 'warning'
            break
        case 'UNAVAILABLE':
        case 'ELIGIBLE':
            action = 'error'
            break
    }

    /* For shingles only. The dose_too_soon effectively means that the second dose is invalid */
    let invalidDoses: number[]
    if (status.dose_too_soon) {
        invalidDoses = [2];
    } else {
        invalidDoses = [];
    }

    return {
        dosesTaken: status.course_taken == null ? status.doses_taken : status.course_taken,
        dosesTotal: status.course_target == null ? status.doses_total : status.course_target,
        action,
        shield,
        booster: status.booster,
        emergency: status.emergency,
        crossoverDose,
        invalidDoses: invalidDoses,
    }
}

const useStyles = makeStyles<Theme, ProgressProps>((theme) => createStyles({
    progressWrapper: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        /** Extra margins for any "booster" labels */
        marginBottom: '32px',
    },
    circleContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
    },
    circle: {
        borderRadius: '50%',
        minWidth: '24px',
        maxWidth: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        fontSize: '12px',
        fontWeight: 700,
    },
    circleDivider: {
        display: 'flex',
        backgroundColor: theme.palette.grey[400],
        height: '1px',
        width: '70px',
        minWidth: '10px',
        marginLeft: '3px',
        marginRight: '3px',
    },
    shield: {
        height: '24px',
        width: '24px',
        color: props => props.shield === 1 ? theme.palette.success.main : theme.palette.grey[400],
    },
    captionText: {
        position: 'absolute',
        top: '28px',
        lineHeight: 1.2,
        textAlign: 'center',
    }
}))

export const VaccinationProgress = (props: ProgressProps) => {
    const classes = useStyles(props)
    const theme = useTheme()

    const booster = props.booster || 0
    const hideShield = props.shield === -1

    const doses = []

    if (typeof props.steps !== "undefined") {
        const steps: DoseStep[] = props.steps
        console.log("steps")
        console.log(steps)
        for (let i = 0; i < steps.length; i++) {
            doses.push({
                label: steps[i].circle_text,
                caption: steps[i].label_text,
                backgroundColor: steps[i].fill,
            })
        }
    } else {

        if (props.emergency) {
            for (let i = 0; i < props.emergency; i++) {
                doses.push({
                    label: "E",
                    backgroundColor: alpha(theme.palette.success.main, 0.5),
                })
            }
        }

        for (let i = 1; i <= props.dosesTotal; i++) {
            const isBooster = props.dosesTotal - i < booster

            let backgroundColor = theme.palette.grey[400]

            if (i <= props.dosesTaken) {
                if (props.action === 'late' && i == props.dosesTaken) {
                    backgroundColor = theme.palette.warning.main
                } else {
                    backgroundColor = theme.palette.success.main
                }

            }

            if (i === props.dosesTaken + 1) {
                if (props.action === 'error') {
                    backgroundColor = theme.palette.error.main
                }

                if (props.action === 'warning') {
                    backgroundColor = theme.palette.warning.main
                }
            }

            let label = String(i);

            if (props.crossoverDose) {
                const isLastDose = i === props.dosesTaken
                if (isLastDose) {
                    label = props.crossoverDose
                    backgroundColor = alpha(theme.palette.success.main, 0.5)
                }
            }

            if (props.invalidDoses.indexOf(i) > -1) {
                label = 'i'
                backgroundColor = alpha(theme.palette.success.main, 0.5)
            }

            doses.push({
                label,
                backgroundColor,
                caption: isBooster ? "Booster" : null,
            })
        }
    }

    return (
        <div className={classes.progressWrapper}>
            {doses.map(({label, backgroundColor, caption}, i) => {
                return (
                    <Fragment key={i}>
                        <div className={classes.circleContainer} key={i}>
                            <div className={classes.circle} style={{backgroundColor}} key={i}>
                                {label}
                            </div>
                            {caption &&
                                <Typography variant="statusText" className={classes.captionText}>
                                    {caption}
                                </Typography>
                            }
                        </div>
                        {!(i == doses.length - 1 && hideShield) &&
                            <div className={classes.circleDivider} />
                        }
                    </Fragment>
                )})}
            {!hideShield &&
                <ShieldIcon className={classes.shield}/>
            }
        </div>
    )
}
