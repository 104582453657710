import * as React from 'react'
import RadioGroup from "@material-ui/core/RadioGroup"

type RadioCardGroupProps = {
    // Need to save the selected option such that this prop is passed down
    children: React.ReactNode,
    name?: string,
    value?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioCardGroup = (props: RadioCardGroupProps) => {
    const [selected, setSelected] = React.useState('')

    const childrenWithProps = React.Children.map(props.children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            // @ts-expect-error: FIXME by setting the type of children to JSX.Element[]
            return React.cloneElement(child, { selected: selected || props.value });
        }
        return child;
    });

    return (
        <RadioGroup
            name={props.name}
            value={props.value}
            onChange={(event) => {
                setSelected(event.target.value)
                if (props.onChange) props.onChange(event)
            }}
        >{childrenWithProps}</RadioGroup>
    )
}

export default RadioCardGroup
