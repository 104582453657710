import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect, useRef, forwardRef, ReactNode, useState, useCallback } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { SwipeableDrawer, SwipeableDrawerProps, createStyles } from "@material-ui/core";
import { createMemoryHistory } from 'history';

const useStyles = makeStyles(theme => ({
    paper: {
        maxHeight: "calc(100% - env(safe-area-inset-top) - 40px)",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: "auto",
        "&::before": {
            content: "''",
            display: "block",
            background: theme.palette.grey[200],
            height: 5,
            width: 40,
            borderRadius: 5,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 10,
            margin: '0 auto',
        }
    },
    contents: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        paddingTop: 0,
        overflow: "auto"
    }
}))

export type SwipableDrawerProps = {
    /** If true, the drawer is open */
    open: boolean

    /** Callback fired when the component requests to be closed */
    onClose: (e?: React.KeyboardEvent) => void

    /** Contents of Drawer */
    children: React.ReactNode

    ref?: React.Ref<HTMLDivElement>
} & Omit<SwipeableDrawerProps, "onOpen">

const scrollIntoView = (el?: HTMLElement | null) => {
    if (!el) return
    el.scrollIntoView()
    const e = new Event("custom.scrolledTo", { bubbles: true })
    el.dispatchEvent(e)
}

export const Drawer = forwardRef<HTMLDivElement, SwipableDrawerProps>(function Drawer(
    {open, onClose, children, ...props}: SwipableDrawerProps,
    ref
) {
    const classes = useStyles()

    // If we don't receive a ref from the parent, create one locally and use that instead.
    const localRef = useRef<HTMLDivElement>()
    const contentsRef = (ref || localRef) as typeof localRef

    const fakeHistory = useRef(createMemoryHistory<{
        scrollPosition?: number
    }>({initialEntries: []}))

    // A ref provided by a parent could be a function (a callback ref). Handling both types of refs
    // here is a bit cumbersome, so assume we won't receive these (and do check, just in case)
    // More info: https://react.dev/reference/react-dom/components/common#ref-callback
    if (typeof contentsRef === 'function') {
        console.error("Unexpected callback ref provided to <Drawer>:", contentsRef)
    }

    useEffect(() => {
        if (Capacitor.getPlatform() !== 'ios') {
            return
        }

        if (open) {
            void StatusBar.setStyle({ style: Style.Dark })
        } else {
            void StatusBar.setStyle({ style: Style.Light })
        }
    }, [open])

    /**
     * Attaches back/forward button logic for internal navigation in Drawer contents.
     * Expected behaviour:
     *
     * - Open a drawer
     * - Press back
     * Expected: drawer closes
     *
     * - Open a drawer
     * - Click an internal link
     * Expected: Drawer scrolls to link target
     * - Press back
     * Expected: Drawer scrolls back to link anchor (scroll position is restored)
     * - Press back
     * Expected: drawer closes
     *
     * Forwards button behaviour is not defined.
     */
    useEffect(() => {
        const onPopState = (_e: PopStateEvent) => {
            // console.debug("onPopState", location.hash, fakeHistory.current)

            // All events here are back as we only call history.replaceState (not push)

            if (fakeHistory.current.index > 1) {
                fakeHistory.current.go(-1)
                scrollIntoView(contentsRef.current?.querySelector(fakeHistory.current.location.hash))
                history.pushState(null, '', fakeHistory.current.location.hash)
            } else if (fakeHistory.current.index === 1) {
                fakeHistory.current.go(-1)

                const scrollPosition = fakeHistory.current.location.state.scrollPosition
                contentsRef.current?.scrollTo({ top: scrollPosition })

                history.pushState(null, '', '#')
            } else {
                history.replaceState(null, '', window.location.pathname)
                onClose()
            }
        }

        if (open) {
            // console.debug("+++Drawer opened")

            // Push a dummy history item to allow back-to-close-drawer behaviour
            history.pushState(null, '', '#')
            fakeHistory.current.push('#')
            window.addEventListener("hashchange", onPopState)

            return () => {
                // console.debug("---Drawer closed", fakeHistory.current)
                fakeHistory.current = createMemoryHistory({initialEntries: []})
                window.removeEventListener("hashchange", onPopState)
            }
        }
    // The onClose dependency changes on every render here (parent components aren't memoising it).
    // Ignore the missing dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, contentsRef])

    // This serves as a "content rendered" hook to attach/detach click handlers on the contents,
    // as the contents renders async from the `open` state due to animations.
    const setContentsRef = useCallback((node: HTMLDivElement) => {
        const onContentClick = (e: PointerEvent) => {
            const el = e.target as HTMLElement

            if (el && el.nodeName === 'A') {
                const href = el.getAttribute("href")

                if (href && href[0] === "#") {
                    e.preventDefault()

                    // Save scroll position to history before we navigate, but only when a fragment
                    // hasn't already been scrolled to
                    if (location.hash === "") {
                        fakeHistory.current.replace('#', {
                            scrollPosition: contentsRef.current?.scrollTop
                        })
                    }

                    // Set hash in the URL and scroll to the element
                    fakeHistory.current.push(href)
                    history.replaceState(null, '', href)
                    scrollIntoView(contentsRef.current?.querySelector(href))
                }
            }
        }

        if (contentsRef.current) {
            contentsRef.current.removeEventListener('click', onContentClick)
        }

        if (node) {
            node.addEventListener('click', onContentClick)
        }

        contentsRef.current = node
    }, [contentsRef]);

    return <SwipeableDrawer
        onClick={(e) => {e.stopPropagation()}}
        anchor="bottom"
        disableBackdropTransition={true}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        PaperProps={{
            square: false,
        }}
        classes={{
            paper: classes.paper
        }}
        open={open}
        // no-op, since Drawers will be opened explicitly from an action and not via a swipe gesture
        onOpen={() => undefined}
        onClose={() => {
            history.replaceState(null, '', '#')
            history.go(-1)
            onClose()
        }}
        {...props}>
        <div className={classes.contents} ref={setContentsRef}>
            {children}
        </div>
    </SwipeableDrawer>
})

const useDrawerTriggerStyles = makeStyles(() => createStyles({
    trigger: {
        cursor: 'pointer',
    },
}))

type DrawerTriggerProps = {
    /** Contents (typically JSX, but can be a string) that opens the drawer when clicked */
    trigger: ReactNode

    /** Contents of the drawer */
    children: ReactNode

    /** Text used for the Datadog RUM event when the drawer is opened */
    label: string
}

/** Opens a drawer (with arbitrary contents), from a trigger (which is also arbitrary content) */
export const DrawerTrigger = ({trigger, label, children}: DrawerTriggerProps) => {
    const [open, setOpen] = useState(false)
    const classes = useDrawerTriggerStyles()

    return <>
        <div className={classes.trigger} onClick={() => setOpen(true)} data-action={label}>
            {trigger}
        </div>
        <Drawer
            open={open}
            ModalProps={{
                BackdropProps: {
                    "aria-label": "Close drawer",
                }
            }}
            onClose={() => setOpen(false)}
        >
            {children}
        </Drawer>
    </>
}
