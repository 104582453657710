import { makeStyles, MenuItem, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { CountryDropdown } from "../../../components/country_dropdown"
import { HeaderTitle } from "../../../components/page_sections"
import SelectInput from "../../../components/select_input"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { RecordContext, useRecordContext } from "../../../components/context/recordState"
import { RecordSteps } from "../../../components/context/recordReducer"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(() => ({
    sectionGap: {
        marginTop: '30px'
    },
    smallGap: {
        marginTop: '15px'
    },
    mediumGap: {
        marginTop: '20px'
    }
}))

export const SetLocation = () => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState('')
    const [selected, setSelected] = useState('')
    const [isFormValid, setIsFormValid] = useState(false)
    const [otherProvider, setOtherProvider] = useState('')
    const [showTextField, setShowTextField] = useState(false)
    const { state, dispatch } = useRecordContext(RecordContext)

    useEffect(() => {
        if (state.country) {
            setCountry(state.country)
        }
        if (state.provider) {
            setSelected(state.provider)
        }
        if (state.otherProvider) {
            setOtherProvider(state.otherProvider)
        }
    }, [state.country, state.provider, state.otherProvider])

    useEffect(() => {
        if (country && selected && selected != 'Other') {
            setIsFormValid(true)
        }
        if(selected == 'Other') {
            setShowTextField(true)
            if(otherProvider) {
                setIsFormValid(true)
            }else {
                setIsFormValid(false)
            }
        } else if (selected) {
            setShowTextField(false)
            setOtherProvider('')
        }
    }, [country, otherProvider, selected])

    function dispatchData() {
        dispatch({
            type: RecordSteps.SET_LOCATION,
            payload: {
                country: country,
                provider: selected,
                otherProvider: otherProvider
            }
        })
    }

    function handleSubmit() {
        setLoading(true)
        dispatchData()
        history.push(`/add-record/${state.user.uuid}/hemisphere`)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                    Next
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton />
            <HeaderTitle>
                Where were you vaccinated?
            </HeaderTitle>

            <CountryDropdown
                value={country}
                onChange={(countryCode) => {
                    setCountry(countryCode)
                }}
            />

            <div className={classes.sectionGap}>
                <SelectInput
                    value={selected}
                    name="provider"
                    label="Provider type"
                    fullWidth
                    onChange={(e) => setSelected(e.target.value as string)}
                    margin="none"
                    error={false}
                    helperText=""
                    required
                >
                    <MenuItem value="School / education">School / education</MenuItem>
                    <MenuItem value="Employer">Employer</MenuItem>
                    <MenuItem value="Healthcare provider">Healthcare provider</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                </SelectInput>
                {showTextField &&
                    <TextField
                        id="providerType"
                        name="providerType"
                        placeholder="Provider Type"
                        label = "Provider Type"
                        fullWidth
                        margin="normal"
                        defaultValue={otherProvider}
                        onChange={(e) => setOtherProvider(e.target.value)}
                        required
                    />
                }
            </div>
            <div className={classes.sectionGap}>
                <TextButton
                    onClick={() => {
                        dispatchData()
                        history.push(`/add-record/${state.user.uuid}/location-cannot-proceed`)
                    }}
                >
                    I don't know
                </TextButton>
            </div>
        </BaseContainer>
    )
}
