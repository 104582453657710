import { cloneElement, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Logo from "../../../../assets/img/imunis-logo.svg";
import { IconButton } from '../../../../components/button';
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import {display, flexbox, sizing, spacing} from "@material-ui/system";

const drawerWidth = 240;
const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 0,
        botton: 'auto',
        position: 'fixed'
    },

    drawer: {
        width: drawerWidth,
    },

    toolbar: {
        ...theme.mixins.toolbar,
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center"
    },

    drawerPaper: {
        width: drawerWidth,
    },

    gapper: {
        display: "flex",
        padding: theme.spacing(5)
    }
}));

function ElevationScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return cloneElement(children, {
        elevation: trigger ? 2 : 0,
    });
}

export const ElevationScrollHeader = (props) => {
    let history = useHistory();
    const {window} = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <div py={5} className={classes.toolbar}>
                <Typography>Hi, John</Typography>
            </div>
            <Divider/>
            <>
                <List>
                    <ListItem button>
                        <ListItemText primary='Vaccinations' onClick={() => history.push('/vaccine-records')}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary='My Family' onClick={() => history.push('/dependants')}/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button>
                        <Button variant="contained" color="primary"
                            onClick={() => history.push('/logout')}>Logout</Button>
                    </ListItem>
                </List>
            </>
        </div>
    );

    return (
        <>
            <CssBaseline/>
            <ElevationScroll {...props}>
                <AppBar color="inherit" className={classes.appBar} elevation={0}>
                    <Box py={1} px={{xs: 2, sm: 4}} width="100%" display="flex" justifyContent="space-between"
                        alignItems="center">
                        <a href="/">
                            <img src={Logo} width="120px" alt="Imunis Logo"/>
                        </a>
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </ Box>
                    <nav className={classes.drawer}>
                        <Hidden implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                anchor='right'
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                </AppBar>
            </ElevationScroll>
            <Container className={classes.gapper}/>
        </>
    );
}
