import { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Checkbox, Collapse, FormControlLabel, TextField, InputAdornment, Grid, Link } from "@material-ui/core";
import Alert from "../../components/alert";
import { BaseContainer } from "../../components/container"
import Typography from "../../components/typography"
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import { validateEmail } from "../../components/text_input";
import { Button, IconButton } from "../../components/button";
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { BackButton } from "../../components/buttons/back_btn";
import { ConnectionInviteContext } from "../../components/context/connection_invite";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const SignIn = (props) => {
    const { state } = useContext(ConnectionInviteContext)
    const [email, setEmail] = useState(state.email)
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [formError, setFormError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)
    const [staySignedIn, setStaySignedIn] = useState(true)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmailError(validateEmail(event.target.value))
        setEmail(event.target.value)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true)

        try {
            await props.signIn(email, password, staySignedIn)
        } catch (error) {
            if (error.response?.status >= 400 && error.response?.status < 500) {
                setFormError("The credentials you provided are incorrect.")
            } else {
                // Unexpected error
                console.error(error)
                setFormError(error.response?.data?.error || "Server error. Please try again later.")
            }
            setErrorOpen(true)
            setLoading(false)
        }
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth type="submit" form="sign-in"
                    disabled={!email || !password}
                    loading={loading}
                >
                    Sign in
                </Button>
                <div>
                    New user?
                    {' '}
                    <Link onClick={() => {
                        history.push('/connection/adult/sign-up')
                    }}>Sign up</Link>
                </div>
            </StickyFooterGroup>
        }>
            <BackButton />

            <Box marginTop={1} marginBottom={2}>
                <Typography variant="onboardingHeader">
                    Sign In
                </Typography>
            </Box>

            <Box marginTop={2} marginBottom={3}>
                <Typography variant="bodyTextLarge">
                    Sign in to complete the connection with {state.organisation}
                </Typography>
            </Box>

            <Collapse in={errorOpen}>
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formError}
                </Alert>
            </Collapse>

            <form id="sign-in" onSubmit={handleSubmit}>
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    autoComplete="username"
                    placeholder="Email address"
                    fullWidth
                    margin="normal"
                    value={email}
                    error={!!emailError}
                    helperText={emailError}
                    onChange={handleEmailChange}
                />
                <TextField
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Grid container>
                    <Grid item xs>
                        <FormControlLabel
                            control={<Checkbox checked={staySignedIn} onChange={() => setStaySignedIn(!staySignedIn)} color="primary" />}
                            label={<Typography variant="bodyTextMedium">Stay signed in</Typography>}
                        />
                    </Grid>
                    <Grid item>
                        <Box display="flex" alignItems="center" width="100%" height="100%">
                            <Typography variant="bodyTextMediumBold">
                                <Link underline="none" onClick={() => {
                                    history.push('/connection/adult/forgot-password')
                                }}>
                                    Forgot password?
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </form>

        </BaseContainer>
    )
}

export default SignIn
