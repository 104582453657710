import { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { spacing, sizing, flexbox, display } from '@material-ui/system';
import { BaseContainer } from "../components/container";
import FamilyIcon from "../assets/icons/family";
import ConnectionIcon from "../assets/icons/connection";
import NewsIcon from "../assets/icons/news";
import HealthIcon from '../assets/icons/health'
import { GlobalContext } from "../components/context/globalState";
import { axiosAuthedInstance } from "../utils/axiosApi";
import ErrorDialog from "../components/dialogs/error_dialog";
import EmptyList from "../components/empty_list";
import { notifications_polling_interval } from "../utils/constants";
import Loading from '../components/loading';
import { BackButton } from "../components/buttons/back_btn";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        margin: "1px 0",
        padding: theme.spacing(2, 3),
        position: 'relative'
    },
    active: {
        backgroundColor: alpha(theme.palette.secondary.dark, 0.05)
    },
    activeBall: {
        width: '5px',
        height: '5px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        position: 'absolute',
        top: '25px',
        left: '11px',
        cursor: 'pointer',
    },
    fontSizeSmall: {
        width: '15px',
        height: '15px',
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 1.5
    },
    category: {
        margin: theme.spacing(0, 0, 0, 1),
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 0.75
    },
    time: {
        fontWeight: 400,
        fontSize: theme.typography.fontSize * 0.75,
    },
    content: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize
    },
    button: {
        fontWeight: 600,
        fontSize: theme.typography.fontSize * 0.875,
        fontFamily: theme.typography.fontFamily
    }
}))

const Notifications = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const theme = useTheme()
    const { state, dispatch } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [notifications, setNotifications] = useState([])
    const [showError, setShowError] = useState(false)
    const [error, setError] = useState('')

    const fetchNotifications = () => {
        setLoading(true)
        axiosAuthedInstance.get('/user/notifications/').then(result => {
            setNotifications(result.data.data)
        }).catch(err => {
            // We silently ignore failure, next polling will try again
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }


    const startNotificationsPolling = () => {
        // short polling notifications every 30 seconds
        return setInterval(() => {
            fetchNotifications()
        }, notifications_polling_interval)
    }

    useEffect(() => {
        fetchNotifications()
        const intervalId = startNotificationsPolling()
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        const unread = notifications.reduce((prev, curr) => {return curr.read_at ? prev : prev + 1}, 0)
        dispatch({type: 'SET_NOTIFICATIONS', payload: unread})
    }, [notifications])

    const markRead = (notification) => {
        setLoading(true)
        axiosAuthedInstance.post(`/user/notification/read/${notification.uuid}/`).then(() => {
            setLoading(false)
            setNotifications(prev => {
                return prev.map(noti => (noti.uuid === notification.uuid ? {...noti, read_at: true} : noti))
            })
        }).catch(err => {
            setLoading(false)
            let msg = "Error setting notification as read"
            if (err.response?.data?.error) {
                console.error(err)
                msg = err.response.data.error
            }
            setShowError(true)
            setError(msg)
        })
    }

    const markAllRead = () => {
        setLoading(true)
        axiosAuthedInstance.post('user/notification/read-all/').then(() => {
            setLoading(false)
            setNotifications(prev => {
                return prev.map(noti => ({...noti, read_at: true}))
            })
        }).catch(err => {
            setLoading(false)
            let msg = "Error setting notifications as read"
            if (err.response?.data?.error) {
                console.error(err)
                msg = err.response.data.error
            }
            setShowError(true)
            setError(msg)
        })
    }

    const haveNotification = notifications.some(noti => noti.read_at === null)

    return (
        <BaseContainer noHeader noPadding {...props}>
            <Box ml={3} mt={2}>
                <BackButton />
            </Box>
            {loading && <Loading loading={loading} />}
            <Box marginBottom={3} width="100%" display="flex" justifyContent="space-around" alignItems="center">
                <Typography variant="h5" className={classes.title}>Notifications</Typography>
                <Button style={{color: theme.palette.primary.main, opacity: !haveNotification ? '50%' : '100%'}}
                    className={classes.button} disabled={!haveNotification}
                    onClick={markAllRead}>
                    Mark all as read
                </Button>
            </Box>
            {notifications.length === 0 && <EmptyList/>}
            {notifications.map(notification => {
                let Icon
                let text
                switch (notification.category) {
                    case "FAMILY": {
                        Icon = <FamilyIcon classes={{fontSizeSmall: classes.fontSizeSmall}} fontSize="small"/>;
                        text = "My family"
                        break;
                    }
                    case "CONNECTIONS": {
                        Icon = <ConnectionIcon classes={{fontSizeSmall: classes.fontSizeSmall}} fontSize="small"/>;
                        text = "Connections"
                        break;
                    }
                    case "NEWS": {
                        Icon = <NewsIcon classes={{fontSizeSmall: classes.fontSizeSmall}} fontSize="small"/>
                        text = "News"
                        break;
                    }
                    case "VACCINE": {
                        Icon = <HealthIcon classes={{fontSizeSmall: classes.fontSizeSmall}} fontSize="small"/>
                        text = "Vaccine"
                        break;
                    }
                    default: break;
                }
                return (
                    <Box className={[classes.container, notification.read_at ? '' : classes.active]} key={notification.uuid} width="100%">
                        {!notification.read_at &&
                            <div className={classes.activeBall} onClick={() => markRead(notification)}/>
                        }
                        <Box style={{cursor: 'pointer'}} width="100%"
                            data-action="Read notification"
                            onClick={() => {
                                if (!notification.read_at) {
                                    markRead(notification)
                                }
                                history.push(notification.url)
                            }}>
                            <Box width="100%" display="flex" justifyContent="space-between">
                                <Box marginBottom={1} display="flex" alignItems="center">
                                    {Icon}
                                    <Typography className={classes.category}>{text}</Typography>
                                </Box>
                                <Typography className={classes.time}>{notification.time_passed_label}</Typography>
                            </Box>
                            <Typography variant="body1" className={classes.content}>{notification.message}</Typography>
                        </Box>
                    </Box>
                )})}
            <ErrorDialog open={showError} setOpen={setShowError} error={error}/>
        </BaseContainer>
    )}

export default Notifications
