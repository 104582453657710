import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { axiosInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container";
import { onLogIn } from "../../utils/auth";
import { GlobalContext } from "../../components/context/globalState";

const useStyles = makeStyles(() => ({
    header: {
        fontSize: '1.5rem',
        fontWeight: '600',
        lineHeight: '2rem',
    },
}))

const VerifyEmail = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { dispatch: dispatchGlobal } = useContext(GlobalContext)

    useEffect(() => {
        async function verifyEmailApi() {
            try {
                const response = await axiosInstance.post(
                    `/user/verify-email/${props.match.params.token}/`
                )

                onLogIn({
                    accessToken: response.data.access,
                    refreshToken: response.data.refresh,
                    // We never gave the user the option for this
                    staySignedIn: false,
                })
                dispatchGlobal({
                    type: "SET_USER",
                    payload: response.data
                })

                setLoading(false);
                history.push('/home');
            } catch (error) {
                setLoading(false);
                if (error.response.status == 400) {
                    setError(error.response.data.error_message);
                    setShowError(true);
                } else {
                    console.error(error)
                    setError("Server Error");
                    setShowError(true);
                }
            }
        }

        verifyEmailApi();
    }, []);

    return (
        <BaseContainer {...props}>
            {loading ? <CircularProgress style={{ marginTop: '4rem' }} /> :
                (showError && <div>
                    <Typography component="h1" className={classes.header} gutterBottom>
                        Email Verification Failed
                    </Typography>
                    <Typography gutterBottom>
                        {error}
                    </Typography>
                </div>)
            }
        </BaseContainer>
    )
}
export default VerifyEmail;
