import SvgIcon from '@material-ui/core/SvgIcon';

export default function FAQIcon() {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" stroke="none" viewBox="0 0 24 24">
            <path d="M8 15.9928H8.75V15.2428H8V15.9928ZM8 18.9908H7.25C7.25 19.2749 7.41045 19.5345 7.66448 19.6616C7.91852 19.7887 8.22253 19.7613 8.44981 19.591L8 18.9908ZM12 15.9928V15.2428H11.7501L11.5502 15.3927L12 15.9928ZM9 8.24738H8.25V9.74738H9V8.24738ZM15 9.74738H15.75V8.24738H15V9.74738ZM9 11.2454H8.25V12.7454H9V11.2454ZM13 12.7454H13.75V11.2454H13V12.7454ZM7.25 15.9928V18.9908H8.75V15.9928H7.25ZM8.44981 19.591L12.4498 16.5929L11.5502 15.3927L7.55019 18.3907L8.44981 19.591ZM12 16.7428H18V15.2428H12V16.7428ZM18 16.7428C18.9667 16.7428 19.75 15.9608 19.75 14.9935H18.25C18.25 15.1314 18.1393 15.2428 18 15.2428V16.7428ZM19.75 14.9935V5.99933H18.25V14.9935H19.75ZM19.75 5.99933C19.75 5.03199 18.9667 4.25 18 4.25V5.75C18.1393 5.75 18.25 5.86138 18.25 5.99933H19.75ZM18 4.25H6V5.75H18V4.25ZM6 4.25C5.03327 4.25 4.25 5.03199 4.25 5.99933H5.75C5.75 5.86138 5.86073 5.75 6 5.75V4.25ZM4.25 5.99933V14.9935H5.75V5.99933H4.25ZM4.25 14.9935C4.25 15.9608 5.03327 16.7428 6 16.7428V15.2428C5.86073 15.2428 5.75 15.1314 5.75 14.9935H4.25ZM6 16.7428H8V15.2428H6V16.7428ZM9 9.74738H15V8.24738H9V9.74738ZM9 12.7454H13V11.2454H9V12.7454Z" fill="currentColor"/>
        </SvgIcon>
    )
}

