import { useContext } from "react"
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import Consent from "./consent"
import SecurityCheck from "./security_check"
import SignUp from "./sign_up"
import SignIn from "./sign_in"
import { ConnectionInviteContext, ConnectionInviteContextType, ConnectionInviteProvider } from "../../components/context/connection_invite"
import { AxiosError, axiosAuthedInstance, axiosInstance } from "../../utils/axiosApi"
import { clearStorage, isLoggedIn, onLogIn } from "../../utils/auth"
import { TDateISODate, checkAll, logEvent } from "../../utils/common";
import { ResendEmail } from "../../components/resend_email";
import { DobMismatch } from './dob_mismatch';
import ForgotPassword from "../user/forgot_password";
import { SubroutineContext, SubroutineProvider } from "../../components/context/subroutine";
import InviteError from "../invite_error";
import { InvitedDependantsPostOnboarding } from "../invited_dependants";
import { GlobalContext } from "../../components/context/globalState";

type ConfirmSecurityResponse = {
    status: 'ok' | 'fail'
    attempts_left: number
    data: {
        gender: 'M' | 'F'
        id_type: "hkid" | "passport" | "unknown"
        id_number: string
    }
}

export type PendingDependant = {
    uuid: string
    full_name: string
    organisation: string
    organisation_email: string
}

type PostSignupLoginResponse = {
    data: {
        error_type?: 'dob_conflict' | 'already_connected'
        error: string
        clinic_name: string
        clinic_phone: string
        clinic_email: string,
        clinic_dob: TDateISODate
        user_dob: TDateISODate

        uuid: string
        full_name: string
        preferred_name: string
        email: string

        /** A list of pending dependants, if any. */
        dependants: PendingDependant[]
    }
}

type LoginResponse = {
    refresh: string
    access: string
    last_login: TDateISODate | null
}

/**
 * The signup response can contain `uuid` and some other user data, or can be empty, depending on
 * whether the invite email was used for sign up or not
 */
type SignupResponse = {
    uuid: string
    full_name: string
    preferred_name: string
    email: string
    access: string
    refresh: string
    dependants?: PendingDependant[]
} | {
    uuid: undefined
}

const OnboardAdultStep: React.FC<{
    step: TOnboardAdultStep
}> = ({ step }) => {
    const { state, dispatch } = useContext(ConnectionInviteContext)
    const { exitWizard } = useContext(SubroutineContext)
    //@ts-expect-error: GlobalContext doesn't have type annotations yet
    const { dispatch: dispatchGlobal } = useContext(GlobalContext)
    const history = useHistory()
    const { state: locationState } = useLocation<Record<string, unknown>>()

    /**
     * Handles invite non-successful connection attempts.
     * Can be triggered from a sign in or sign up.
     * Returns true if an error/redirection occurred
    */
    const inviteErrorRedirect = (response: PostSignupLoginResponse) => {
        if (!response.data.error_type) {
            return false
        }

        switch (response.data.error_type) {
            case 'dob_conflict':
                exitWizard(() => history.push('/connection/adult/invalid-dob', {
                    organisation: {
                        name: response.data.clinic_name,
                        phone: response.data.clinic_phone,
                        email: response.data.clinic_email,
                    },
                    clinicDob: response.data.clinic_dob,
                    userDob: response.data.user_dob
                }))
                break
            case 'already_connected':
                exitWizard(() => history.push('/connection/adult/already-connected', {
                    title: `Could not open clinic invite for ${state.account_holder as string}`,
                    error: response.data.error,
                }))
                break
            default:
                console.error("Unhandled error_type", response.data.error_type)
                return false
        }

        return true
    }

    const declineConsent = async () => {
        await axiosInstance.post('user/invite/decline/', {
            token: state.token
        })
        exitWizard(() => history.push('/'))
    }

    const confirmSecurity = async (dob: TDateISODate) => {
        const response = await axiosInstance.post<ConfirmSecurityResponse>(
            'user/invite/security-check/',
            { token: state.token, dob })

        if (response.status == 200) {
            if (response.data.status == 'ok') {
                if (isLoggedIn()) {
                    return axiosAuthedInstance.post<PostSignupLoginResponse>(
                        'user/invite/post-signup-login/',
                        { token: state.token, dob }
                    ).then((result) => {
                        if (inviteErrorRedirect(result.data)) return

                        logEvent('onboard_clinic_account_holder', {
                            journey: "existing_user_logged_in"
                        })

                        checkDependantStatus(result.data.data.dependants)
                    })
                } else {
                    dispatch({
                        type: 'SET_SIGN_UP_DATA',
                        payload: {
                            dob,
                            gender: response.data.data.gender,
                            idType: response.data.data.id_type,
                            idNumber: response.data.data.id_number
                        }
                    })

                    return axiosInstance.post(
                        '/user/email-unique-check/',
                        { email: state.email }
                    ).then(() => {
                        history.push('/connection/adult/sign-up')
                    }).catch((error: AxiosError) => {
                        if (error.response?.status == 400) {
                            history.push('/connection/adult/sign-in')
                        } else {
                            throw error
                        }
                    })
                }
            } else {
                // Security check failed with wrong dob
                const attemptsLeft = response.data.attempts_left
                if (attemptsLeft === 0) {
                    // Exiting the wizard wipes context, but we need to display clinic info here
                    exitWizard(() => history.push('/connection/adult/security-fail', state))
                }
                return Promise.reject({
                    cause: attemptsLeft > 0 ? 'SECURITY_FAIL_RETRY' : 'SECURITY_FAIL_NO_RETRY',
                    msg: `The date of birth does not match the clinic record. ` +
                    `You have ${attemptsLeft} ${attemptsLeft > 1 ? 'attempts' : 'attempt'} remaining until the invitation is cancelled.`,
                })
            }
        } else {
            // TODO: test this path
            return Promise.reject(response.data)
        }
    }

    const signIn = async (email: string, password: string, staySignedIn: boolean) => {
        const responseToken = await axiosInstance.post<LoginResponse>('/token/obtain/', {
            email,
            password,
        })

        onLogIn({
            accessToken: responseToken.data.access,
            refreshToken: responseToken.data.refresh,
            lastLogin: responseToken.data.last_login || undefined,
            staySignedIn,
        })

        try {
            const response = await axiosAuthedInstance.post<PostSignupLoginResponse>(
                'user/invite/post-signup-login/', {
                    token: state.token,
                    dob: state.dob,
                })

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatchGlobal({
                type: "SET_USER",
                payload: response.data.data,
            })

            if (inviteErrorRedirect(response.data)) return

            logEvent('onboard_clinic_account_holder', {
                journey: "existing_user_sign_in"
            })

            checkDependantStatus(response.data.data.dependants)
        } catch (error) {
            // Remove auth tokens
            clearStorage()

            if ((error as AxiosError).response?.status == 403) {
                exitWizard(() => history.push('/connection/adult/verify-email', { email }))
            } else {
                throw error
            }
        }
    }

    const signUp = async ({
        name, preferredName, email, password, dob, gender, idType, idNumber, token
    }: {
        name: string
        preferredName: string
        email: string
        password: string
        dob: string
        gender: string
        idType: string
        idNumber: string
        token: string
    }
    ) => {
        const response = await axiosInstance.post<SignupResponse>('/user/signup/', {
            data: {
                full_name: name,
                preferred_name: preferredName,
                email: email,
                password: password,
                dob: dob,
                gender: gender,
                id_type: idType.toLowerCase(),
                id_number: idNumber,
            },
            token: token,
        })

        if (response.data.uuid) {
            onLogIn({
                accessToken: response.data.access,
                refreshToken: response.data.refresh,
                // We never gave the user the option for this
                staySignedIn: false,
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatchGlobal({
                type: "SET_USER",
                payload: response.data
            })
            logEvent('onboard_clinic_account_holder', {
                journey: "user_sign_up"
            })
            checkDependantStatus(response.data.dependants)
        } else {
            logEvent('onboard_clinic_account_holder', {
                journey: "user_sign_up_different_email"
            })
            exitWizard(() => history.push('/connection/adult/verify-email', { email }))
        }
    }

    /**
     * If we have any pending dependant invitations, show an additional page.
     * Otherwise, redirect to /home
     */
    const checkDependantStatus = (dependants?: PostSignupLoginResponse['data']['dependants']) => {
        if (dependants?.length) {
            exitWizard(() => history.push('/connection/adult/invited-dependants', {
                organisation: state.organisation,
                dependants,
            }))
        } else {
            exitWizard(() => history.push('/home'))
        }
    }

    switch (step) {
        case 'consent':
            if (checkAll(state.token, state.journey)) {
                return (
                    <Consent decline={declineConsent} confirmConsent={() => {
                        dispatch({ type: 'GIVE_CONSENT' })
                        history.push('/connection/adult/security')
                    }} />
                )
            }
            break
        case 'security':
            if (checkAll(state.token, state.journey, state.consentGiven)) {
                return (
                    <SecurityCheck confirmSecurity={confirmSecurity} />
                )
            }
            break
        case 'security-fail':
            if (checkAll(state.token, state.journey, state.consentGiven)) {
                return (
                    <SecurityCheck
                        noRetries={true}
                        exitWizard={exitWizard} />
                )
            }
            break
        case 'sign-up':
            if (checkAll(state.token, state.journey, state.consentGiven)) {
                return (
                    <SignUp signUp={signUp} />
                )
            }
            break
        case 'sign-in':
            if (checkAll(state.token, state.journey, state.consentGiven)) {
                return (
                    <SignIn signIn={signIn} />
                )
            }
            break

        case 'invited-dependants':
            if (checkAll(locationState?.organisation)) {
                return <InvitedDependantsPostOnboarding />
            }
            break

        case 'verify-email':
            return <ResendEmail />

        case 'invalid-dob':
            return <DobMismatch />

        case 'already-connected':
            return <InviteError />

        case 'forgot-password':
            return <ForgotPassword />
    }

    // If we reach this, we have inconsistent state / context.
    // Reloading won't help (we've lost the context), we need a "Re-open link from email" page
    // We can possibly mitigate this with a <Prompt>: https://v5.reactrouter.com/core/api/Prompt
    history.replace('/')

    return null
}

type TOnboardAdultStep = 'consent' | 'security' | 'security-fail' |  'sign-in' | 'sign-up' |
'verify-email' | 'invited-dependants' | 'invalid-dob' | 'forgot-password' | 'already-connected'

export const OnboardAdult = ({ match }: RouteComponentProps<{
    step: TOnboardAdultStep
}>) => {
    const location = useLocation<ConnectionInviteContextType>()

    return (
        <ConnectionInviteProvider value={location.state || {}}>
            <SubroutineProvider>
                <OnboardAdultStep step={match.params.step}/>
            </SubroutineProvider>
        </ConnectionInviteProvider>
    )
}
