import { makeStyles } from '@material-ui/core/styles'
import {Button} from '../button';


const useStyles = makeStyles((theme) => ({
    ctaWrapper: {
        backgroundColor: 'white',
        padding: theme.spacing(1,3,0),
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(444px + 1rem)'
        },
        "&:last-child":{
            paddingBottom: theme.spacing(1)
        }
    },
    cta: {
        textAlign: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '1.25px',
        color: theme.palette.grey[800]
    },
    ctaCancel: {
        color: '#333333',
        border: '1px solid #333333'
    }
}))

/** @deprecated
 * Instead of using this component, wrap a regular <Button> in a <StickyFooterGroup>:
 * ```
 * <StickyFooterGroup>
 *     <Button fullWidth>Label</Button>
 * </StickyFooterGroup>
 * ```
 */
const CTAButton = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.ctaWrapper}>
            <Button {...props}>
                {props.children}
            </Button>
        </div>
    )
}

export default CTAButton
