import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { AppBar, Box, ButtonBase, Theme } from "@material-ui/core";
import Typography from './typography';
import HomeIcon from "../assets/icons/home";
import HomeFilledIcon from '../assets/icons/home_filled';
import RecordsIcon from "../assets/icons/records";
import RecordsFilledIcon from '../assets/icons/records_filled';
import ConnectionIcon from "../assets/icons/connection";
import ConnectionFilledIcon from '../assets/icons/connection_filled';
import SharingIcon from '../assets/icons/sharing';
import SharingFilledIcon from '../assets/icons/sharing_filled';
import { cx } from '../utils/common'
import { AppIntro } from "./app_intro";
import { useRef } from "react";
import SearchIcon from "../assets/icons/search";
import SearchFilledIcon from "../assets/icons/search_filled";

const useNavigationItemStyles = makeStyles<Theme, Pick<NavigationItemProps, "to" | "active">>((theme) => ({
    navbarItem: {
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        padding: theme.spacing(1.25, 0),
        color: ({active}) => (
            active ? darken(theme.palette.primary.main, theme.palette.action.selectedOpacity)
                : theme.palette.grey[600]
        ),
    }
}))

type NavigationItemProps = {
    children: string
    icon: JSX.Element

    to?: string
    active?: boolean
    iconActive?: JSX.Element
}

const NavigationItem = (props: NavigationItemProps) => {
    const {active, to, icon, iconActive, children} = props
    const classes = useNavigationItemStyles({active, to})

    let buttonProps;

    if (to) {
        buttonProps = {
            to: to,
            component: Link,
            focusRipple: true,
        }
    }

    return (
        <ButtonBase {...buttonProps} className={classes.navbarItem}>
            {active ? iconActive : icon}

            <Typography variant="bodyTextSmallBold" style={{color: "inherit"}}>
                {children}
            </Typography>
        </ButtonBase>
    )
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        marginTop: theme.spacing(1),
        position: 'sticky',
        zIndex: 100, // set higher such that the child backdrop can cover elevated content elements (e.g. the label of the outlined dropdowns)
    },
    navBar: {
        display: "grid",
        gridTemplateColumns: 'repeat(5, 1fr)',
        boxShadow: '0px -2px 3px rgba(0, 0, 0, 0.15)',
        backgroundColor: 'white'
    },
    ctaArea: {
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    // for the navBar to appear above the backdrop for onboarding steps
    elevatedBar: {
        zIndex: 120,
        pointerEvents: 'none', // disable clicking on the navbar when onboarding steps are shown
        touchAction: 'none' // disable touching on mobile
    }
}));

type NavBarProps = {
    className: string

    showCta: boolean
    showNav: boolean
    showOnboardingSteps: boolean
    dismissOnboarding: () => void
    extraComponent: React.ReactNode

    // Set these to activate the respective navigation item
    home?: boolean
    records?: boolean
    sharing?: boolean
    connection?: boolean
    discover?: boolean
}

export const NavBar = (props: NavBarProps) => {
    const classes = useStyles();
    const navRef = useRef<HTMLDivElement>(null)

    return (
        <AppBar color="inherit" className={cx(classes.appBar, props.className)} elevation={0}
            component="div">

            {/*FLOATING CTA AREA*/}
            {props.showCta &&
                <Box className={classes.ctaArea} width="100%">
                    {props.extraComponent}
                </Box>
            }

            {/*BOTTOM NAV BAR AREA*/}
            {props.showNav && props.showOnboardingSteps &&
                <AppIntro dismissOnboarding={props.dismissOnboarding} navRef={navRef}/>}

            {props.showNav &&
                <nav
                    aria-label="Main"
                    ref={navRef}
                    className={cx(classes.navBar, props.showOnboardingSteps && classes.elevatedBar)}>
                    <NavigationItem to="/home" active={props.home}
                        icon={<HomeIcon />} iconActive={<HomeFilledIcon />}
                    >
                        Home
                    </NavigationItem>

                    <NavigationItem to="/records" active={props.records}
                        icon={<RecordsIcon />} iconActive={<RecordsFilledIcon />}
                    >
                        Records
                    </NavigationItem>

                    <NavigationItem to="/sharing" active={props.sharing}
                        icon={<SharingIcon />} iconActive={<SharingFilledIcon />}
                    >
                        Sharing
                    </NavigationItem>

                    <NavigationItem to="/connections" active={props.connection}
                        icon={<ConnectionIcon />} iconActive={<ConnectionFilledIcon />}
                    >
                        Connections
                    </NavigationItem>

                    <NavigationItem to="/discover" active={props.discover}
                        icon={<SearchIcon/>} iconActive={<SearchFilledIcon/>}>
                        Discover
                    </NavigationItem>
                </nav>
            }
        </AppBar>
    )
}
