import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@material-ui/lab';
import { forwardRef } from 'react';

/**
 * Alert is used in existing places, but this variant doesn't seem to conform to any Figma components
 */
const Alert = forwardRef(function Alert(props: MuiAlertProps, ref) {
    return <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />;
})
export default Alert
