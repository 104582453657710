import SvgIcon from '@material-ui/core/SvgIcon';

function ConnectionFilledIcon() {
    return (
        <SvgIcon>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 6.286a2.287 2.287 0 1 1-4.573-.002A2.287 2.287 0 0 1 19 6.286ZM19 17.714a2.287 2.287 0 1 1-4.573-.001 2.287 2.287 0 0 1 4.573.001ZM7.571 12a2.287 2.287 0 1 1-4.573-.002A2.287 2.287 0 0 1 7.571 12Z"
            />
            <path
                d="M7.571 12.005h4.572M15 8.003 12.143 12 15 16.008m4-9.722a2.287 2.287 0 1 1-4.573-.002A2.287 2.287 0 0 1 19 6.286Zm0 11.428a2.287 2.287 0 1 1-4.573-.001 2.287 2.287 0 0 1 4.573.001ZM7.571 12a2.287 2.287 0 1 1-4.573-.002A2.287 2.287 0 0 1 7.571 12Z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="square"
                fill="none"
            />
        </SvgIcon>
    );
}

export default ConnectionFilledIcon
