import { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { cx } from "../utils/common";
import DropDownMenuIcon from "../assets/icons/dropdown";

const useStyles = makeStyles<Theme, CollapsedSectionProps>((theme) => ({
    collapsedSection: {
        marginTop: ({marginTop}) => {
            if (marginTop) return theme.spacing(marginTop)
            return theme.spacing(4)
        },
        marginBottom: ({marginBottom}) => {
            if (marginBottom) return theme.spacing(marginBottom)
            return theme.spacing(4)
        }
    },
    header: {
        color: ({color}) => {
            if (color === 'primary') return theme.palette.primary.main
        },
        display: 'flex',
        gap: theme.spacing(1),
        borderBottom: ({borderBottom}) => {
            if (borderBottom === false) return

            return `1px solid ${theme.palette.grey[400]}`
        },
        paddingBottom: ({borderBottom}) => {
            if(borderBottom == false) return
            return theme.spacing(1)
        }
    },
    title: {
        flexGrow: 1,
    },
    icon: {
        // Only the "dropdown" icon is this size.
        fontSize: '24px',
    },
    expand: {
        color: ({color}) => {
            if (color !== 'primary') return theme.palette.grey[600]
        },
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}))

type CollapsedSectionProps = {
    defaultOpen?: boolean,

    title: React.ReactNode,

    children: React.ReactNode,

    color?: "primary",
    borderBottom?: boolean,
    marginTop?: number,
    marginBottom?: number,

    /** If set to true, will use the "dropdown" icon. Otherwise the default is the chevron */
    circleChevron?: boolean,
}

/**
 * We need to detect when an element inside a collapsed section has been scrolled to (via navigation
 * from an internal link) in order to expand the section.
 *
 * Such a mechanism doesn't exist: https://github.com/w3c/csswg-drafts/issues/3744
 * So we implement our own custom event to detect this.
 */
export const scrollIntoView = (el?: HTMLElement | null) => {
    if (!el) return
    el.scrollIntoView()
    const e = new Event("custom.scrolledTo", { bubbles: true })
    el.dispatchEvent(e)
}

export const CollapsedSection = (props: CollapsedSectionProps) => {
    const [open, setOpen] = useState(props.defaultOpen || false)
    const classes = useStyles(props)
    const ref = useRef<HTMLDivElement>()

    // We sometimes want to avoid animations
    const timeoutRef = useRef<number>()

    const toggleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if (!ref.current) return
        const container = ref.current
        container.addEventListener('custom.scrolledTo', (e: Event) => {
            if (open) return

            const el = e.target as HTMLElement
            timeoutRef.current = 0
            setOpen(true)
            setTimeout(() => {
                el.scrollIntoView()
                timeoutRef.current = undefined
            }, 0)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.collapsedSection}>
            <div className={classes.header} onClick={toggleOpen}>
                <div className={classes.title}>
                    {props.title}
                </div>
                {props.circleChevron ?
                    <DropDownMenuIcon
                        className={cx(classes.expand, open && classes.expandOpen, classes.icon)}
                        fill='#FF5300' stroke='#FFFFFF' />
                    :
                    <ExpandMore className={cx(classes.expand, open && classes.expandOpen)}/>
                }
            </div>
            <Collapse in={open} ref={ref} timeout={timeoutRef.current}>
                {props.children}
            </Collapse>
        </div>
    )
}
