
import { makeStyles } from "@material-ui/core/styles"
import TrashIcon from "../../../assets/icons/trash"
import { BaseContainer } from "../../../components/container"
import { AppHeader } from "../../../components/header"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { FileInputButton, SubmissionImage } from "./utils"
import { PageSection } from "../../../components/page_sections"
import { Button, IconButton } from "../../../components/button"

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%'
    },
    delete: {
        color: theme.palette.error.main
    },
}))

export const Page = ({ imageUrl, onClose, showDone, onDelete, onReplace}: {
    imageUrl: string
    onClose: () => void
    showDone?: boolean
    onDelete?: (imageUrl: string) => void
    onReplace?: (file: File) => void
}) => {
    const classes = useStyles()

    return (
        <BaseContainer
            header={
                <AppHeader
                    onBack={onClose}
                    {...(onDelete && {
                        rightSlot: (
                            <IconButton aria-label="Delete" edge="end" onClick={() => onDelete(imageUrl)}>
                                <TrashIcon/>
                            </IconButton>
                        )
                    })}
                />
            }
            // extraComponent implies (and requires) showCta. Refactor this
            showCta extraComponent={
                <StickyFooterGroup>
                    { onReplace ? (
                        <FileInputButton onChange={onReplace}>
                            Replace image
                        </FileInputButton>
                    ) : null }

                    { showDone ? (
                        <Button fullWidth>
                            Done
                        </Button>
                    ) : null }
                </StickyFooterGroup>
            }>
            <PageSection>
                <SubmissionImage className={classes.image} src={imageUrl} />
            </PageSection>
        </BaseContainer>
    )
}
