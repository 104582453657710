export enum HemisphereMap {
    NORTH = "NORTH",
    SOUTH = "SOUTH",
    DUAL = "DUAL",
}
export const countryHemisphereMap = new Map<string, HemisphereMap>([
    ["AD", HemisphereMap.NORTH],
    ["AE", HemisphereMap.NORTH],
    ["AF", HemisphereMap.NORTH],
    ["AG", HemisphereMap.SOUTH],
    ["AI", HemisphereMap.SOUTH],
    ["AL", HemisphereMap.NORTH],
    ["AM", HemisphereMap.NORTH],
    ["AO", HemisphereMap.SOUTH],
    ["AQ", HemisphereMap.SOUTH],
    ["AR", HemisphereMap.SOUTH],
    ["AS", HemisphereMap.NORTH],
    ["AT", HemisphereMap.NORTH],
    ["AU", HemisphereMap.SOUTH],
    ["AW", HemisphereMap.SOUTH],
    ["AX", HemisphereMap.NORTH],
    ["AZ", HemisphereMap.NORTH],
    ["BA", HemisphereMap.NORTH],
    ["BB", HemisphereMap.SOUTH],
    ["BD", HemisphereMap.SOUTH],
    ["BE", HemisphereMap.NORTH],
    ["BF", HemisphereMap.NORTH],
    ["BG", HemisphereMap.NORTH],
    ["BH", HemisphereMap.NORTH],
    ["BI", HemisphereMap.SOUTH],
    ["BJ", HemisphereMap.SOUTH],
    ["BL", HemisphereMap.SOUTH],
    ["BM", HemisphereMap.NORTH],
    ["BN", HemisphereMap.DUAL],
    ["BO", HemisphereMap.SOUTH],
    ["BQ", HemisphereMap.NORTH],
    ["BR", HemisphereMap.SOUTH],
    ["BS", HemisphereMap.SOUTH],
    ["BT", HemisphereMap.SOUTH],
    ["BV", HemisphereMap.SOUTH],
    ["BW", HemisphereMap.SOUTH],
    ["BY", HemisphereMap.NORTH],
    ["BZ", HemisphereMap.SOUTH],
    ["CA", HemisphereMap.NORTH],
    ["CC", HemisphereMap.SOUTH],
    ["CD", HemisphereMap.NORTH],
    ["CF", HemisphereMap.SOUTH],
    ["CG", HemisphereMap.NORTH],
    ["CH", HemisphereMap.NORTH],
    ["CI", HemisphereMap.SOUTH],
    ["CK", HemisphereMap.DUAL],
    ["CL", HemisphereMap.SOUTH],
    ["CM", HemisphereMap.SOUTH],
    ["CN", HemisphereMap.NORTH],
    ["CO", HemisphereMap.SOUTH],
    ["CR", HemisphereMap.SOUTH],
    ["CU", HemisphereMap.NORTH],
    ["CV", HemisphereMap.SOUTH],
    ["CW", HemisphereMap.NORTH],
    ["CX", HemisphereMap.SOUTH],
    ["CY", HemisphereMap.NORTH],
    ["CZ", HemisphereMap.NORTH],
    ["DE", HemisphereMap.NORTH],
    ["DJ", HemisphereMap.NORTH],
    ["DK", HemisphereMap.NORTH],
    ["DM", HemisphereMap.SOUTH],
    ["DO", HemisphereMap.SOUTH],
    ["DZ", HemisphereMap.NORTH],
    ["EC", HemisphereMap.SOUTH],
    ["EE", HemisphereMap.NORTH],
    ["EG", HemisphereMap.NORTH],
    ["EH", HemisphereMap.NORTH],
    ["ER", HemisphereMap.SOUTH],
    ["ES", HemisphereMap.NORTH],
    ["ET", HemisphereMap.NORTH],
    ["FI", HemisphereMap.NORTH],
    ["FJ", HemisphereMap.SOUTH],
    ["FK", HemisphereMap.SOUTH],
    ["FM", HemisphereMap.NORTH],
    ["FO", HemisphereMap.NORTH],
    ["FR", HemisphereMap.NORTH],
    ["GA", HemisphereMap.NORTH],
    ["GB", HemisphereMap.NORTH],
    ["GD", HemisphereMap.SOUTH],
    ["GE", HemisphereMap.NORTH],
    ["GF", HemisphereMap.SOUTH],
    ["GG", HemisphereMap.NORTH],
    ["GH", HemisphereMap.SOUTH],
    ["GI", HemisphereMap.SOUTH],
    ["GL", HemisphereMap.NORTH],
    ["GM", HemisphereMap.SOUTH],
    ["GN", HemisphereMap.SOUTH],
    ["GP", HemisphereMap.NORTH],
    ["GQ", HemisphereMap.SOUTH],
    ["GR", HemisphereMap.SOUTH],
    ["GS", HemisphereMap.SOUTH],
    ["GT", HemisphereMap.SOUTH],
    ["GU", HemisphereMap.NORTH],
    ["GW", HemisphereMap.SOUTH],
    ["GY", HemisphereMap.SOUTH],
    ["HK", HemisphereMap.NORTH],
    ["HM", HemisphereMap.SOUTH],
    ["HN", HemisphereMap.SOUTH],
    ["HR", HemisphereMap.NORTH],
    ["HT", HemisphereMap.SOUTH],
    ["HU", HemisphereMap.NORTH],
    ["ID", HemisphereMap.NORTH],
    ["IE", HemisphereMap.NORTH],
    ["IL", HemisphereMap.NORTH],
    ["IM", HemisphereMap.NORTH],
    ["IN", HemisphereMap.DUAL],
    ["IO", HemisphereMap.SOUTH],
    ["IQ", HemisphereMap.NORTH],
    ["IR", HemisphereMap.NORTH],
    ["IS", HemisphereMap.NORTH],
    ["IT", HemisphereMap.NORTH],
    ["JE", HemisphereMap.NORTH],
    ["JM", HemisphereMap.NORTH],
    ["JO", HemisphereMap.NORTH],
    ["JP", HemisphereMap.SOUTH],
    ["KE", HemisphereMap.DUAL],
    ["KG", HemisphereMap.NORTH],
    ["KH", HemisphereMap.DUAL],
    ["KI", HemisphereMap.NORTH],
    ["KM", HemisphereMap.SOUTH],
    ["KN", HemisphereMap.SOUTH],
    ["KP", HemisphereMap.NORTH],
    ["KR", HemisphereMap.NORTH],
    ["KW", HemisphereMap.NORTH],
    ["KY", HemisphereMap.SOUTH],
    ["KZ", HemisphereMap.NORTH],
    ["LA", HemisphereMap.DUAL],
    ["LB", HemisphereMap.NORTH],
    ["LC", HemisphereMap.SOUTH],
    ["LI", HemisphereMap.NORTH],
    ["LK", HemisphereMap.NORTH],
    ["LR", HemisphereMap.SOUTH],
    ["LS", HemisphereMap.SOUTH],
    ["LT", HemisphereMap.NORTH],
    ["LU", HemisphereMap.NORTH],
    ["LV", HemisphereMap.NORTH],
    ["LY", HemisphereMap.NORTH],
    ["MA", HemisphereMap.NORTH],
    ["MC", HemisphereMap.NORTH],
    ["MD", HemisphereMap.NORTH],
    ["ME", HemisphereMap.NORTH],
    ["MF", HemisphereMap.NORTH],
    ["MG", HemisphereMap.SOUTH],
    ["MH", HemisphereMap.DUAL],
    ["MK", HemisphereMap.NORTH],
    ["ML", HemisphereMap.NORTH],
    ["MM", HemisphereMap.SOUTH],
    ["MN", HemisphereMap.NORTH],
    ["MO", HemisphereMap.NORTH],
    ["MP", HemisphereMap.NORTH],
    ["MQ", HemisphereMap.NORTH],
    ["MR", HemisphereMap.NORTH],
    ["MS", HemisphereMap.SOUTH],
    ["MT", HemisphereMap.SOUTH],
    ["MU", HemisphereMap.SOUTH],
    ["MV", HemisphereMap.SOUTH],
    ["MW", HemisphereMap.NORTH],
    ["MX", HemisphereMap.NORTH],
    ["MY", HemisphereMap.DUAL],
    ["MZ", HemisphereMap.SOUTH],
    ["NA", HemisphereMap.SOUTH],
    ["NC", HemisphereMap.NORTH],
    ["NE", HemisphereMap.NORTH],
    ["NF", HemisphereMap.SOUTH],
    ["NG", HemisphereMap.SOUTH],
    ["NI", HemisphereMap.SOUTH],
    ["NL", HemisphereMap.NORTH],
    ["NO", HemisphereMap.NORTH],
    ["NP", HemisphereMap.SOUTH],
    ["NR", HemisphereMap.SOUTH],
    ["NU", HemisphereMap.SOUTH],
    ["NZ", HemisphereMap.SOUTH],
    ["OM", HemisphereMap.NORTH],
    ["PA", HemisphereMap.SOUTH],
    ["PE", HemisphereMap.SOUTH],
    ["PF", HemisphereMap.SOUTH],
    ["PG", HemisphereMap.SOUTH],
    ["PH", HemisphereMap.SOUTH],
    ["PK", HemisphereMap.NORTH],
    ["PL", HemisphereMap.NORTH],
    ["PM", HemisphereMap.SOUTH],
    ["PN", HemisphereMap.SOUTH],
    ["PR", HemisphereMap.NORTH],
    ["PS", HemisphereMap.NORTH],
    ["PT", HemisphereMap.NORTH],
    ["PW", HemisphereMap.NORTH],
    ["PY", HemisphereMap.SOUTH],
    ["QA", HemisphereMap.NORTH],
    ["RE", HemisphereMap.SOUTH],
    ["RO", HemisphereMap.NORTH],
    ["RS", HemisphereMap.NORTH],
    ["RU", HemisphereMap.NORTH],
    ["RW", HemisphereMap.NORTH],
    ["SA", HemisphereMap.NORTH],
    ["SB", HemisphereMap.NORTH],
    ["SC", HemisphereMap.SOUTH],
    ["SD", HemisphereMap.NORTH],
    ["SE", HemisphereMap.NORTH],
    ["SG", HemisphereMap.DUAL],
    ["SH", HemisphereMap.SOUTH],
    ["SI", HemisphereMap.NORTH],
    ["SJ", HemisphereMap.SOUTH],
    ["SK", HemisphereMap.NORTH],
    ["SL", HemisphereMap.SOUTH],
    ["SM", HemisphereMap.NORTH],
    ["SN", HemisphereMap.SOUTH],
    ["SO", HemisphereMap.NORTH],
    ["SR", HemisphereMap.SOUTH],
    ["SS", HemisphereMap.NORTH],
    ["ST", HemisphereMap.NORTH],
    ["SV", HemisphereMap.SOUTH],
    ["SX", HemisphereMap.NORTH],
    ["SY", HemisphereMap.NORTH],
    ["SZ", HemisphereMap.SOUTH],
    ["TC", HemisphereMap.SOUTH],
    ["TD", HemisphereMap.NORTH],
    ["TF", HemisphereMap.SOUTH],
    ["TG", HemisphereMap.SOUTH],
    ["TH", HemisphereMap.SOUTH],
    ["TJ", HemisphereMap.NORTH],
    ["TK", HemisphereMap.SOUTH],
    ["TL", HemisphereMap.SOUTH],
    ["TM", HemisphereMap.NORTH],
    ["TN", HemisphereMap.NORTH],
    ["TO", HemisphereMap.SOUTH],
    ["TR", HemisphereMap.NORTH],
    ["TT", HemisphereMap.SOUTH],
    ["TV", HemisphereMap.SOUTH],
    ["TW", HemisphereMap.NORTH],
    ["TZ", HemisphereMap.NORTH],
    ["UA", HemisphereMap.NORTH],
    ["UG", HemisphereMap.SOUTH],
    ["UM", HemisphereMap.NORTH],
    ["US", HemisphereMap.NORTH],
    ["UY", HemisphereMap.SOUTH],
    ["UZ", HemisphereMap.NORTH],
    ["VA", HemisphereMap.NORTH],
    ["VC", HemisphereMap.SOUTH],
    ["VE", HemisphereMap.SOUTH],
    ["VG", HemisphereMap.NORTH],
    ["VI", HemisphereMap.NORTH],
    ["VN", HemisphereMap.SOUTH],
    ["VU", HemisphereMap.SOUTH],
    ["WF", HemisphereMap.NORTH],
    ["WS", HemisphereMap.NORTH],
    ["YE", HemisphereMap.NORTH],
    ["YT", HemisphereMap.SOUTH],
    ["ZA", HemisphereMap.SOUTH],
    ["ZM", HemisphereMap.SOUTH],
    ["ZW", HemisphereMap.SOUTH],
])
