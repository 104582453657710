import { makeStyles } from "@material-ui/core"
import { cloneElement, useContext, useEffect, useState } from 'react';
import { Button } from '../../components/button';
import { BackButton } from '../../components/buttons/back_btn';
import { formatDate, inviteTypeLabel } from '../../utils/common'
import { BaseContainer } from "../../components/container"
import { ConnectionInviteContext } from '../../components/context/connection_invite';
import { StickyFooterGroup } from '../../components/sticky_footer_group';
import Typography from '../../components/typography';
import { HeaderTitle, PageDescription } from '../../components/page_sections';
import Card from "../../components/card";
import { TwoLineText } from "../../components/two_line_text";
import ChevronRightIcon from "../../assets/icons/chevron_right";
import { StaticHTML } from "../../components/static_content";
import { useHistory } from "react-router-dom";
import ActionDialog from "../../components/dialogs/action_dialog";
import ExclamationCircleIcon from "../../assets/icons/exclamation_circle";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import TickCircleIcon from "../../assets/icons/tick_circle";

const useStyles = makeStyles((theme) => ({
    dependantOption: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        minHeight: '42px',

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5)
        }
    },
    cardSuccess: {
        borderColor: theme.palette.success.main,
    },
    cardWarning: {
        borderColor: theme.palette.warning.main,
    },
    alertIcon: {
        fontSize: '42px',
        color: theme.palette.error.main,
    },
    successIcon: {
        fontSize: '42px',
        color: theme.palette.success.main,
    },
}))

const ProfileCard = ({dob, fullName}) => {
    return (
        <div style={{textAlign:'center'}}>
            <Typography variant="bodyTextLargeBold">
                {fullName}
            </Typography>

            <Typography variant="bodyTextLarge" component='span'>
                Date of birth:
            </Typography>
            {' '}
            <Typography variant="bodyTextLargeBold" component='span'>
                {formatDate(dob)}
            </Typography>
        </div>
    )
}

export const DependantError = (props) => {
    const { state } = useContext(ConnectionInviteContext)
    const [promptProps, setPromptProps] = useState(null)
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    const {
        uuid: dependantId,
        match_status: matchStatus,
        guardian,
    } = state.dependant

    const {
        invite_type: inviteType,
        organisation,
        organisation_email: organisationEmail
    }  = state

    const deleteDependant = (dependantId) => {
        return async (e) => {
            setLoading(true)
            e.preventDefault()
            try {
                await props.deleteDependant(dependantId)
                setPromptProps({
                    title: 'Profile deleted',
                    action_label: 'Continue',
                    action_callback: history.goBack,
                    close_callback: history.goBack,
                    icon: <TickCircleIcon className={classes.successIcon} />,
                    setOpenDialog: closePrompt,
                })
            } catch (error) {
                console.error(error)
                let message = 'An unexpected error occurred'
                if (error.response?.data?.error) {
                    message = error.response.data.error
                }
                setPromptProps({
                    title: message,
                    action_label: 'Continue',
                    setOpenDialog: closePrompt,
                })
            }
            setLoading(false)
        }
    }

    const declineInvite = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            await axiosAuthedInstance.post('/user/invite/decline-connection/', {
                uuid: state.uuid
            })

            setPromptProps({
                title: 'Invite declined',
                action_callback: props.exitWizard,
                close_callback: props.exitWizard,
                action_label: 'Continue',
                icon: <TickCircleIcon className={classes.successIcon} />,
                setOpenDialog: closePrompt,
            })

        } catch (err) {
            console.error(err)
            let message = 'An unexpected error occurred'
            if (err.response?.data?.error) {
                message = err.response?.data?.error
            }

            setPromptProps({
                title: message,
                action_label: 'Continue',
                setOpenDialog: closePrompt,
            })
        }
        setLoading(false)
    }

    const closePrompt = () => {
        setPromptProps(null)
    }

    useEffect(() => {
        /**
         * Hack to have the loading prop we need deep inside the `promptProps` object/compoment to
         * react to the loading state set at the top-level.
         * If we had a normal setup in the render function like this:
         * ```
         * <ActionDialog openDialog={openConfirm} actions={<Button loading={loading}>Confirm</Button>} />
         * ```
         * We wouldn't need to do this, but we're multi-dispatching prompts using a single object here.
         */
        if ('loading' in (promptProps?.actions?.props || {})) {
            if (loading === promptProps?.actions?.props.loading) return
            setPromptProps(p => ({
                ...p,
                actions: cloneElement(p.actions, { ...p.actions.props, loading })
            }))
        }
    }, [loading, promptProps])

    let matchExplanation, matchAction
    switch (matchStatus) {
        case 'DOB_CONFLICT':
            matchExplanation = <>
                <p>
                    These profiles cannot be matched as the dates of birth are different.
                </p>
                <h4>How to action</h4>
                <p>
                    If the details on the existing profile are incorrect, please delete the existing profile, and you will then be guided to create a new profile with the correct date of birth.
                </p>
                <p>
                    <b>Note</b>: any self-recorded vaccine records on the existing profile will need to be re-added to the new profile.
                </p>
            </>

            matchAction = <Button primary onClick={() => {
                setPromptProps({
                    title: 'Delete profile?',
                    content: 'This action cannot be undone. All data and records will be removed.',
                    actions: (
                        <Button fullWidth primary loading={loading} onClick={deleteDependant(dependantId)}>
                            Delete profile
                        </Button>
                    ),
                    icon: <ExclamationCircleIcon className={classes.alertIcon} />,
                    cancel_label: 'Cancel',
                    setOpenDialog: closePrompt,
                })
            }}>Delete existing profile</Button>
            break
        case 'NOT_PRIMARY_GUARDIAN':
            matchExplanation = <>
                <p>
                    This profile cannot be matched as you are not the primary account holder for the existing profile.
                    {' '}
                    {inviteTypeLabel(inviteType)} connections can only be authorised by the primary account holder.
                </p>
                <h4>How to action</h4>
                <p>
                    Please decline this invitation and ask the issuing {inviteTypeLabel(inviteType).toLowerCase()} to resend the invitation to {guardian}.
                    { ' ' }
                    {organisationEmail && <>
                        You can do this by sending an email to <a href={`mailto:${organisationEmail}`}>{organisationEmail}</a>.
                    </>}
                </p>
            </>

            matchAction = <Button primary onClick={() => {
                setPromptProps({
                    title: 'Decline invite?',
                    content: `You will need to ask ${organisation} to send a new invite`,
                    actions: (
                        <Button fullWidth primary loading={loading} onClick={declineInvite}>
                            Decline invite
                        </Button>
                    ),
                    icon: <ExclamationCircleIcon className={classes.alertIcon} />,
                    cancel_label: 'Cancel',
                    setOpenDialog: closePrompt,
                })
            }}>Decline invite</Button>
            break
        case 'EXISTING_CONNECTION':
            matchExplanation = <>
                <p>
                    This profile cannot be connected to {organisation} as a connection already exists.
                </p>

                <h4>How to action</h4>

                <p>
                    If you have selected the dependant in error, go back and re-select the target dependant.
                </p>

                <p>
                    If you are still not sure how to proceed, please contact <a href="mailto:support@imunis.com">support@imunis.com</a>.
                </p>
            </>

            matchAction = <Button primary onClick={props.exitWizard}>Close</Button>
            break
    }

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            {matchAction}
        </StickyFooterGroup>}>
            <BackButton onClick={props.goBack} />

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    You are creating this dependant:
                </Typography>
            </PageDescription>

            <PageDescription>
                <ProfileCard fullName={state.full_name} dob={state.dob} />
            </PageDescription>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    You selected to match this dependant with this existing profile:
                </Typography>
            </PageDescription>

            <PageDescription>
                <ProfileCard fullName={state.dependant.full_name} dob={state.dependant.dob} />
            </PageDescription>

            <StaticHTML>
                {matchExplanation}
            </StaticHTML>

            <ActionDialog openDialog={Boolean(promptProps)} {...promptProps}/>
        </BaseContainer>
    )
}

export const SelectDependant = (props) => {
    const classes = useStyles()
    const { state } = useContext(ConnectionInviteContext)

    const dependantsList = props.dependants.map((dependant) => {
        const {uuid, full_name, dob, match_status} = dependant
        let className
        if (match_status == 'MATCH_OK') {
            className = classes.cardSuccess
        } else {
            className = classes.cardWarning
        }

        return (
            <Card key={uuid} onClick={() => props.selectExistingDependant(dependant)} className={className}>
                <div className={classes.dependantOption}>
                    <div>
                        <TwoLineText>
                            <Typography variant="bodyTextLargeBold">{full_name}</Typography>
                        </TwoLineText>
                        <Typography variant="bodyTextMedium">
                            Date of birth: {formatDate(dob)}
                        </Typography>
                    </div>
                </div>
            </Card>
        )
    })

    return (
        <BaseContainer noHeader>
            <BackButton onClick={props.goBack} />

            <HeaderTitle>
                Manage account profiles
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    You are creating a profile for this dependant
                </Typography>
            </PageDescription>

            <PageDescription>
                <ProfileCard fullName={state.full_name} dob={state.dob} />
            </PageDescription>


            <PageDescription>
                <Typography variant="bodyTextLarge">
                    Is this dependant listed on your account already? If so, select their profile.
                </Typography>
            </PageDescription>

            {dependantsList}

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    If not, then please create a new profile
                </Typography>
            </PageDescription>

            <Card onClick={props.selectNewDependant}>
                <div className={classes.dependantOption}>
                    <Typography variant="bodyTextLargeBold">
                        Create new profile
                    </Typography>
                    <ChevronRightIcon />
                </div>
            </Card>
        </BaseContainer>
    )
}
