import SvgIcon from '@material-ui/core/SvgIcon';

function GroupIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M4 7L12 3.5L20 7L12 10.5L4 7Z"/>
                <path d="M4 11.5L12 15L20 11.5"/>
                <path d="M4 16L12 19.5L20 16"/>
            </g>
        </SvgIcon>
    )
}

export default GroupIcon
