import { Button } from "../../../components/button"
import Typography from "../../../components/typography"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { RecordContext, useRecordContext } from "../../../components/context/recordState"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { makeStyles } from "@material-ui/core"
import PatientRecordsIcon from "../../../assets/icons/patient_records"
import { useEffect, useState } from "react"
import { formatDate } from "../../../utils/common"
import theme from "../../../styles/theme"
import { InfoPanel } from "../../../components/info_panel"

const useStyles = makeStyles(() => ({
    vaccineSubtitleIcon: {
        fontSize: '24px',
    },
    details: {
        marginTop: '15px'
    },
    vaccineSubtitle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        marginTop: theme.spacing(1, 0)
    },
}));


export const RecordDuplicate: React.FC<{
    exitWizard: () => void
}> = ({exitWizard}) => {
    const classes = useStyles()
    const { state } = useRecordContext(RecordContext)
    const [productName, setProductName] = useState('')

    useEffect(() => {
        if (state.otherProduct) {
            setProductName(`Other (${state.otherProduct})`)
        } else if (state.product?.id) {
            if (state.product.isGeneric) {
                setProductName("Unknown Product")
            } else {
                setProductName(state.product.label || '')
            }
        }
    }, [state.product, state.otherProduct])

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={() => exitWizard()}>
                    OK
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>

            <InfoPanel severity="error" title="Cannot proceed">
                A record already exists for an influenza vaccination on this date
            </InfoPanel>

            <Typography variant="bodyTextSmall" className={classes.details}>
                Patient name
            </Typography>
            {state.user &&
                <Typography variant="bodyTextLargeBold">
                    {state.user.full_name}
                </Typography>
            }
            <Typography variant="bodyTextSmall" className={classes.details}>
                Vaccine provider
            </Typography>
            <div className={classes.vaccineSubtitle}>
                <PatientRecordsIcon className={classes.vaccineSubtitleIcon} />
                <Typography variant="bodyTextLargeBold">
                    Patient-certified
                </Typography>
            </div>
            <Typography variant="bodyTextSmall" className={classes.details}>
                Vaccine product
            </Typography>
            <Typography variant="bodyTextLargeBold">
                {productName}
            </Typography>
            <Typography variant="bodyTextSmall" className={classes.details}>
                Date administered
            </Typography>
            {state.date &&
                <Typography variant="bodyTextLargeBold">
                    {formatDate(new Date(state.date))}
                </Typography>
            }
        </BaseContainer>
    )
}
