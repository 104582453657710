import { MenuItem } from "@material-ui/core";
import SelectInput from "./select_input";
import countries from "i18n-iso-countries"
import en from "i18n-iso-countries/langs/en.json"
countries.registerLocale(en)

type CountryDropdownType = {
    value: string,
    onChange(country: string): void
}

// We're actually using a subset of countries. Sorted by label, with HK at the top
const {
    // Prioritised
    HK,
    // Excluded
    XK,
    ...rest
} = countries.getNames("en", {select: "official"})

const prioritisedCountries = Object.entries({HK})
const remainingSortedCountries = Object.entries(rest).sort(([, c1], [, c2]) => c1.localeCompare(c2))

export const countryList = new Map(prioritisedCountries.concat(remainingSortedCountries))

export const CountryDropdown = (props: CountryDropdownType) => {
    return (
        <SelectInput
            value={props.value}
            name="country"
            label="Country / region"
            fullWidth
            onChange={(event) => {
                props.onChange(event.target.value as string)
            }}
            margin="none"
            error={false}
            helperText=""
            required
        >
            {
                Array.from(countryList).map(([code, label]) => (
                    <MenuItem key={code} value={code}>
                        {label}
                    </MenuItem>
                ))
            }
        </SelectInput>
    )
}
