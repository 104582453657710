import { createContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import History from 'history'

/**
 * This type is what what is set from history.push(...) calls.
 * See https://github.com/remix-run/history/blob/v4.10.1/modules/createBrowserHistory.js#L179
 */
type HistoryState = {
    state: History.LocationState
    key: History.LocationKey
}

export const SubroutineContext = createContext<{
    /**
     * Restores the browser history stack to the point where the wizard was entered
     * (thus discarding all steps made inside the wizard)
     *
     * cb: Callback that will be run once the history stack has been popped back to the page
     * that launched the wizard.
     *
     * This should a function that does a history.push() -- this then removes all the entries from
     * the history stack that contain wizard steps. Note that using history.replace will not erase
     * the future steps.
     *
     * If no callback is provided, we can still navigate "forwards" into the wizard which isn't
     * ideal, but it's not a deal breaker.
     */
    exitWizard: (cb?: (e: PopStateEvent) => void) => void

}>({ exitWizard: () => null })

/**
 * Provides context for a "subroutine" -- a set of navigations that allow for a
 */
export const SubroutineProvider: React.FC = ({ children }) => {
    const history = useHistory()
    const [navSteps, setNavSteps] = useState<string[]>([])

    /**
     * These two hooks keep track of the history stack within the wizard;
     * - When a new page is pushed (history.push()), we push the page key to `navSteps`.
     * - When we navigate back/forwards, we pop/push to `navSteps` accordingly.
     */

    useEffect(() => {
        if (!history.location.key) return

        const k = history.location.key
        setNavSteps(navSteps => {
            // Replace entry at the top
            if (history.action === 'REPLACE') {
                return [...navSteps.slice(0, -1), k]
            }

            // When the location changes from a navigate back action, we will see the current page
            // key at the top of the stack. No action is needed.
            if (navSteps[navSteps.length - 1] == k) {
                return navSteps
            }

            return [...navSteps, k]
        })
    }, [history.location.key, history.action])

    // Note the popstate event fires before the location changes (the above effect)
    const onPopState = (e: PopStateEvent) => {
        if (!e.state || !('key' in e.state)) {
            return
        }

        setNavSteps(navSteps => {
            const k = (e.state as HistoryState).key
            const i = navSteps.indexOf(k)

            if (i === -1) {
                // Nav forwards; push
                return [...navSteps, k]
            } else {
                // Nav back; set the current page to the top (backs may be over multiple pages)
                return navSteps.slice(0, i + 1)
            }
        })
    }
    useEffect(() => {
        addEventListener('popstate', onPopState)
        return () => removeEventListener('popstate', onPopState)
    }, [])

    const exitWizard = (cb?: (e: PopStateEvent) => void) => {
        addEventListener('popstate', (e) => {
            if (cb) cb(e)
        }, { once: true })

        // Going back by the number of navigations made inside the wizard should take us back to the
        // page we entered the wizard from
        history.go(-navSteps.length)
    }

    return (
        <SubroutineContext.Provider value={{exitWizard}}>
            {children}
        </SubroutineContext.Provider>
    )
}
