const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <path d="M112.434 111.048L103.447 127.515C102.079 130.021 104.016 133 107.013 133H124.987C127.984 133 129.921 130.021 128.553 127.515L119.566 111.048C118.076 108.317 113.924 108.317 112.434 111.048Z" fill="#FBEAE7"/>
        <path d="M114.664 126.723H117.336V129.234H114.664V126.723Z" fill="#D82C0D"/>
        <path d="M114.664 116.679H117.336V124.212H114.664V116.679Z" fill="#D82C0D"/>
        <path d="M176 121C176 128.18 170.404 134 163.5 134C156.596 134 151 128.18 151 121C151 113.82 156.596 108 163.5 108C170.404 108 176 113.82 176 121Z" fill="#E5F2EE"/>
        <path d="M158.865 121.557C158.636 121.362 158.339 121.256 158.031 121.261C157.724 121.265 157.43 121.38 157.208 121.582C156.987 121.783 156.853 122.057 156.835 122.348C156.817 122.639 156.916 122.926 157.112 123.151L159.814 125.842C159.927 125.955 160.063 126.044 160.214 126.106C160.365 126.167 160.527 126.199 160.691 126.2C160.854 126.201 161.015 126.171 161.166 126.111C161.317 126.052 161.453 125.964 161.567 125.853L169.821 117.768C169.933 117.659 170.021 117.53 170.08 117.389C170.139 117.247 170.169 117.096 170.167 116.944C170.164 116.791 170.13 116.641 170.067 116.501C170.003 116.361 169.911 116.234 169.796 116.128C169.681 116.022 169.545 115.938 169.396 115.882C169.247 115.826 169.088 115.798 168.927 115.8C168.766 115.802 168.608 115.834 168.461 115.895C168.313 115.955 168.18 116.042 168.068 116.151L160.703 123.382L158.865 121.557Z" fill="#008040"/>
        <path d="M223 121C223 128.18 217.404 134 210.5 134C203.596 134 198 128.18 198 121C198 113.82 203.596 108 210.5 108C217.404 108 223 113.82 223 121Z" fill="#FFF4DD"/>
        <path d="M209.25 124.9H211.75V127.5H209.25V124.9Z" fill="#333333"/>
        <path d="M209.25 114.5H211.75V122.3H209.25V114.5Z" fill="#333333"/>
        <path d="M271 121C271 128.18 265.18 134 258 134C250.82 134 245 128.18 245 121C245 113.82 250.82 108 258 108C265.18 108 271 113.82 271 121Z" fill="#E7E7E7"/>
        <path d="M256.7 114.5H259.3V117.1H256.7V114.5Z" fill="#333333"/>
        <path d="M256.7 119.7H259.3V127.5H256.7V119.7Z" fill="#333333"/>
    </svg>
)

export default Svg
