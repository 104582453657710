const Svg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 437" {...props}>
        <g filter="url(#filter1_dd_3_3291)">
            <rect x="54" y="236" width="268" height="76" rx="12" fill="white"/>
        </g>
        <text xmlSpace="preserve" fill="#767676" fontFamily="Open Sans" fontSize="14" fontWeight="400">
            <tspan x="77" y="292">Connected on 1 Mar 2022</tspan>
        </text>
        <text xmlSpace="preserve" fill="#333" fontFamily="Open Sans" fontSize="16" fontWeight="600">
            <tspan x="77" y="271">School</tspan>
        </text>
        <path fillRule="evenodd" clipRule="evenodd" d="M293.394 256.078C293.902 256.289 294.137 256.858 293.919 257.349L285.919 275.413C285.702 275.904 285.114 276.132 284.606 275.922C284.098 275.711 283.863 275.142 284.081 274.651L292.081 256.587C292.298 256.096 292.886 255.868 293.394 256.078Z" fill="#333333"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M288.813 259.871H282.333C278.836 259.871 276 262.615 276 266C276 269.385 278.836 272.129 282.333 272.129H283.384L284.242 270.193H282.333C279.94 270.193 278 268.316 278 266C278 263.684 279.94 261.807 282.333 261.807H287.956L288.813 259.871ZM290.044 270.193H295.667C298.06 270.193 300 268.316 300 266C300 263.684 298.06 261.807 295.667 261.807H293.758L294.616 259.871H295.667C299.164 259.871 302 262.615 302 266C302 269.385 299.164 272.129 295.667 272.129H289.187L290.044 270.193Z" fill="#333333"/>
        <defs>
            <filter id="filter1_dd_3_3291" x="50" y="235" width="276" height="85" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_3291"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_3_3291" result="effect2_dropShadow_3_3291"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3_3291" result="shape"/>
            </filter>
        </defs>
    </svg>
)

export default Svg
