import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import theme from '../../styles/theme';

type IconProps = {
    variant?: 'successSubdued';
}

function TickCircleSolidIcon({variant, ...props}: IconProps & SvgIconProps) {
    let fill, stroke;

    switch (variant) {
        case 'successSubdued':
            fill = theme.palette.success.light
            stroke = theme.palette.success.main
            break;
        default:
            fill = 'currentColor'
            stroke = theme.palette.common.white
    }

    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <circle cx="12" cy="12" r="10" fill={fill}/>
            <path d="M8.3 12.4a1 1 0 0 0-.7-.2 1 1 0 0 0-.6.2l-.3.6c0 .3 0 .5.2.7l2.2 2 .3.2a1 1 0 0 0 1-.2l6.7-6.2.2-.3a.8.8 0 0 0 0-.7l-.3-.2-.3-.2a1 1 0 0 0-1 .2l-6 5.5-1.4-1.4Z" fill={stroke}/>
        </SvgIcon>
    );
}

export default TickCircleSolidIcon
