import { makeStyles, Theme, createStyles } from "@material-ui/core"
import FormControlLabel, { FormControlLabelProps } from "@material-ui/core/FormControlLabel"
import TickRadio from './tick_radio'

const useStyles = makeStyles<Theme, RadioCardProps>((theme) => createStyles({
    label_root: {
        border: '1px solid',
        borderColor: ({selected, value}) => (
            selected === value ? theme.palette.primary.main : theme.palette.grey[400]
        ),
        borderRadius: '12px',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        minHeight: ({compact}) => compact ? 'auto' : '78px'
    },
    label: {
        flex: 1,
        marginRight: theme.spacing(2),
    },
}))

type RadioCardProps = {
    /** Text to show in the UI */
    label: React.ReactNode,

    /** Internal value of option */
    value: string,

    compact?: boolean

    /** Used internally to highlight the selected option. Not required to be provided */
    selected?: string,
} & Omit<FormControlLabelProps, 'control'>

const RadioCard = (props: RadioCardProps) => {
    const classes = useStyles(props)
    const {compact, selected, ...rest} = props

    return (
        <FormControlLabel
            {...rest}
            data-action="Radio card"
            control={<TickRadio />}
            labelPlacement='start'
            classes={{
                root: classes.label_root,
                label: classes.label,
            }}
        />
    )
}

export default RadioCard
