import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { RecordContext, useRecordContext } from "../../../components/context/recordState"
import { countryList } from "../../../components/country_dropdown"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import Typography from "../../../components/typography"
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import { formatDate } from "../../../utils/common"
import ErrorDialog from "../../../components/dialogs/error_dialog"

const useStyles = makeStyles(() => ({
    consent: {
        alignItems: 'flex-start',
        marginTop: '15px'
    },
    consentContent: {
        marginTop: '12px'
    },
    details: {
        marginTop: '15px'
    }
}));


type AddRecordResponse = {
    event_key: string
}

export const AddRecordSummary: React.FC<{
    exitWizard: (cb: () => void) => void
}> = ({exitWizard}) => {
    const history = useHistory()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [consent, setConsent] = useState(false)
    const { state } = useRecordContext(RecordContext)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [productName, setProductName] = useState('')
    const [providerName, setProviderName] = useState('')

    useEffect(() => {
        if (state.otherProduct) {
            setProductName(`Other (${state.otherProduct})`)
        } else if (state.product?.id) {
            if (state.product.isGeneric) {
                setProductName("Unknown Product")
            } else {
                setProductName(state.product.label || '')
            }
        } else {
            // error?
        }

        if (state.otherProvider) {
            setProviderName(state.otherProvider)
        } else if (state.provider) {
            setProviderName(state.provider)
        }
    }, [])

    useEffect(() => {
        if (consent) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [consent])

    function handleSubmit() {
        setLoading(true)

        const payload = {
            country: state.country,
            provider: providerName,
            date: state.date as string,
            product_id: state.product?.id,
            product_label: state.otherProduct || state.product?.label,
        }

        axiosAuthedInstance.post<AddRecordResponse>(`/user/${state.user.uuid}/add-record/`, payload)
            .then((response) => {
                exitWizard(() => {
                    history.push(`/records/${state.user.uuid}/health`, {
                        addedRecords: [response.data.event_key],
                    })
                })
            }).catch(error => {
                console.error(error)
                setError('Error adding flu record.');
                setShowError(true)
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                    Add vaccination
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton />
            <HeaderTitle>
                Summary
            </HeaderTitle>
            <Typography variant="bodyTextSmall">
                Patient name
            </Typography>
            {state.user &&
                <Typography variant="bodyTextLargeBold">
                    {state.user.full_name}
                </Typography>
            }
            <Typography variant="bodyTextSmall" className={classes.details}>
                Vaccine product
            </Typography>
            <Typography variant="bodyTextLargeBold">
                {productName}
            </Typography>
            <Typography variant="bodyTextSmall" className={classes.details}>
                Date administered
            </Typography>
            {state.date &&
                <Typography variant="bodyTextLargeBold">
                    {formatDate(new Date(state.date))}
                </Typography>
            }
            <Typography variant="bodyTextSmall" className={classes.details}>
                Location administered
            </Typography>
            {providerName && state.country &&
                <Typography variant="bodyTextLargeBold">
                    {providerName}, {countryList.get(state.country)}
                </Typography>
            }
            <FormControlLabel
                control={<Checkbox checked={consent} onChange={() => setConsent(!consent)} color="primary" />}
                label={<Typography variant="bodyTextMedium" className={classes.consentContent}>
                    I accept responsibility for the recording of this information and understand that it may affect my vaccination recommendations. I will not hold Imunis Limited or connected health providers responsible for any associated health recommendations connected to patient-certified records.
                </Typography>}
                className={classes.consent}
            />
            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />
        </BaseContainer>
    )
}
