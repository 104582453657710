import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MultipleRecordsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} >
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeWidth="1.5" d="M16.75 20.11c0 .63-.46 1.14-1.03 1.14H5.78c-.57 0-1.03-.5-1.03-1.15V6.4c0-.64.46-1.15 1.03-1.15"/>
                <path strokeLinejoin="round" d="M18.97 18H9.03C8.46 18 8 17.51 8 16.93V4.07C8 3.48 8.46 3 9.03 3h6.44c.27 0 .53.11.73.31L19.7 7c.2.19.3.47.3.75v9.2c0 .58-.46 1.06-1.03 1.06Z"/>
                <path strokeLinejoin="round" d="M14.55 3v5H20"/>
            </g>
        </SvgIcon>
    )
}
