import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { BaseContainer } from "../container";
import CTAButton from "../buttons/cta";
import SuccessDialog from "../dialogs/success_dialog";

const useStyles = makeStyles((theme) => ({
    heading: {
        marginBottom: theme.spacing(3),
    },
    scroll_text: {
        marginBottom: theme.spacing(4),
    },
    fixed_bottom: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        transform: "translateX(-1.5rem)",
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(444px + 1rem)`
        },
    }
}))

const FloatingCTAPage = (props) => {
    const classes = useStyles();
    const [success, setSuccess] = useState('')
    const [open, setOpen] = useState(false)

    let text_array = []
    for (let i = 0; i < 20; i++) {
        text_array.push('Text to scroll through')
    }

    return (
        <BaseContainer {...props}>
            <Typography variant="h5" className={classes.heading}>Page Heading</Typography>
            {text_array.map((el, index) => {
                return <Typography className={classes.scroll_text} key={index}>{el}</Typography>
            })}
            <div className={classes.fixed_bottom}>
                <CTAButton onClick={() => {
                    setSuccess('Task performed successfully!')
                    setOpen(true)
                }}>Perform action here</CTAButton>
            </div>
            <SuccessDialog 
                success={success} 
                setSuccess={setSuccess} 
                open={open} 
                setOpen={setOpen} />
        </BaseContainer>
    )
}
export default FloatingCTAPage;
