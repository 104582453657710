import { BaseContainer } from "../../../components/container"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { FileInputButton, ImageCarousel, Profile, ScrollPosition } from "./utils"
import { MAX_PAGES } from "./new"
import { Button } from "../../../components/button"
import ScanDocumentIcon from "../../../assets/icons/scan_document"
import { EmptyContent } from "../../../components/empty_content"
import { InfoPanel } from "../../../components/info_panel"
import { pluralString } from "../../../utils/common"
import { AppHeader } from "../../../components/header"

export const SubmissionSummary = ({
    userName, imageUrls, onBack, onContinue, onPreviewFile, isLoading, uploadProgress, onFileAdd,
    scrollPosition
}: {
    userName: string
    imageUrls: string[]
    onBack: () => void
    onContinue: () => void
    isLoading: boolean,
    uploadProgress: number,
    onPreviewFile: (i: number, scrollPosition: ScrollPosition) => void
    onFileAdd: (file: File) => void
    scrollPosition?: ScrollPosition
}) => {
    const pageCount = imageUrls.length
    const zeroPages = pageCount === 0
    const maxPages = pageCount === MAX_PAGES

    return (
        <BaseContainer
            header={
                <AppHeader onBack={onBack} />
            }
            showCta extraComponent={
                <StickyFooterGroup>
                    <FileInputButton primary disabled={isLoading || maxPages} onChange={onFileAdd}>
                        Add page
                    </FileInputButton>
                    <Button
                        onClick={onContinue}
                        loading={isLoading}
                        loadingProgress={uploadProgress}
                        disabled={zeroPages}
                        aria-label="Submit">
                        {zeroPages ?
                            'Submit' :
                            `Submit ${pageCount} ${pluralString('page', pageCount)}`
                        }
                    </Button>
                </StickyFooterGroup>
            }>

            <HeaderTitle>Submission summary</HeaderTitle>

            <Profile name={userName} />

            {pageCount > 0 ? (
                <ImageCarousel
                    imageUrls={imageUrls}
                    onClick={onPreviewFile}
                    scrollPosition={scrollPosition} />
            ) : (
                <EmptyContent icon={ScanDocumentIcon}>
                    No pages have been added
                </EmptyContent>
            )}

            { pageCount == MAX_PAGES ? (
                <InfoPanel severity="warning">
                    Submissions cannot contain more than {MAX_PAGES} pages.
                    Please create separate submissions to add all your records
                </InfoPanel>
            ) : null}

        </BaseContainer>
    )
}
