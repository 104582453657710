import { useContext, useState } from "react"
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import Consent from "./consent"
import SecurityCheck from "./security_check"
import { ConnectionInviteContext, ConnectionInviteContextType, ConnectionInviteProvider, DependantMatch, OnboardingJourney } from "../../components/context/connection_invite"
import { axiosAuthedInstance, axiosInstance } from "../../utils/axiosApi"
import { TDateISODate, checkAll } from "../../utils/common";
import { SubroutineContext, SubroutineProvider } from "../../components/context/subroutine";
import { DependantError, SelectDependant } from "./select_dependant";
import AddDependant from "./add_dependant";

type ConfirmSecurityResponse = {
    status: 'ok' | 'fail'
    /** Only present for clinic invites */
    attempts_left: number | null
    data: {
        gender: 'M' | 'F'
        id_type: "hkid" | "passport" | "unknown"
        id_number: string
    }
}

type DependantMatchResponse = {
    dependants: DependantMatch[]
}

const OnboardDependantStep: React.FC<{
    step: TOnboardDependantStep
}> = ({ step }) => {
    const { state, dispatch } = useContext(ConnectionInviteContext)
    const { exitWizard } = useContext(SubroutineContext)
    const [dependants, setDependants] = useState<DependantMatch[]>([])
    const history = useHistory()

    const declineConsent = async () => {
        await axiosInstance.post('/user/invite/decline-connection/', {
            uuid: state.uuid
        })
        exitWizard(() => history.push('/home'))
    }

    const confirmSecurity = async (dob: TDateISODate) => {
        const securityCheckResponse = await axiosAuthedInstance.post<ConfirmSecurityResponse>(
            '/user/invite/connect-dependant-security-check/', {
                dob,
                uuid: state.uuid
            })

        if (securityCheckResponse.status == 200) {
            if (securityCheckResponse.data.status == 'ok') {
                // security check passed
                dispatch({
                    type: 'SET_SIGN_UP_DATA',
                    payload: {
                        dob: dob,
                        gender: securityCheckResponse.data.data.gender,
                        idType: securityCheckResponse.data.data.id_type,
                        idNumber: securityCheckResponse.data.data.id_number
                    }
                })
                const matchedDependantsResponse = await axiosAuthedInstance.post<DependantMatchResponse>(
                    '/user/invite/get-matched-user/', {
                        uuid: state.uuid
                    })

                const matchedDependants = matchedDependantsResponse.data.dependants
                if (matchedDependants.length === 0) {
                    // No dependants at all, so skip the select profile step
                    history.push('/connection/dependant/create-profile')
                    return
                }

                setDependants(matchedDependants)
                history.push('/connection/dependant/select-profile')

            } else {
                // security check is done but failed with wrong dob
                if (state.invite_type === 'school') {
                    // School dependant invites have unlimited retries
                    return Promise.reject({
                        cause: 'SECURITY_FAIL_RETRY',
                        msg: `The date of birth does not match the school record. ` +
                        `Please contact ${state.organisation} if needed.`
                    })
                } else {
                    const attemptsLeft = securityCheckResponse.data.attempts_left as number

                    if (attemptsLeft === 0) {
                        // Exiting the wizard wipes context, but we need to display clinic info here
                        exitWizard(() => history.push('/connection/dependant/security-fail', state))
                    }

                    return Promise.reject({
                        cause: attemptsLeft > 0 ? 'SECURITY_FAIL_RETRY' : 'SECURITY_FAIL_NO_RETRY',
                        msg: `The date of birth does not match the clinic record. ` +
                        `You have ${attemptsLeft} ${attemptsLeft > 1 ? 'attempts' : 'attempt'} remaining until the invitation is cancelled.`,
                    })
                }
            }
        } else {
            // TODO: test this path
            return Promise.reject(securityCheckResponse.data)
        }
    }

    const selectExistingDependant = (dependant: DependantMatch) => {
        dispatch({ type: 'SET_DEPENDANT', payload: dependant })

        if (dependant.match_status == 'MATCH_OK') {
            history.push('/connection/dependant/update-profile')
        } else {
            switch (dependant.match_status) {
                case 'DOB_CONFLICT':
                    history.push('/connection/dependant/invalid-dob')
                    break
                case 'EXISTING_CONNECTION':
                    history.push('/connection/dependant/invalid-connection')
                    break
                case 'NOT_PRIMARY_GUARDIAN':
                    history.push('/connection/dependant/invalid-relationship')
                    break
            }
        }
    }

    const selectNewDependant = () => {
        dispatch({ type: 'UNSET_DEPENDANT' })
        history.push('/connection/dependant/create-profile')
    }


    const deleteDependant = async (dependantId: string) => {
        await axiosAuthedInstance.delete(`/user/dependants/remove/${dependantId}/`)
        // If successful, remove the deleted dependant from our list of dependants
        setDependants(users => users.reduce<DependantMatch[]>((acc, u) => {
            if (u.uuid !== dependantId) acc.push(u)
            return acc
        }, []))
    }

    switch (step) {
        case 'consent':
            if (checkAll(state.uuid, state.journey, state.invite_type)) {
                return (
                    <Consent decline={declineConsent} confirmConsent={() => {
                        dispatch({ type: 'GIVE_CONSENT' })
                        history.push('/connection/dependant/security')
                    }} />
                )
            }
            break
        case 'security':
            if (checkAll(state.uuid, state.consentGiven)) {
                return (
                    <SecurityCheck confirmSecurity={confirmSecurity} />
                )
            }
            break
        case 'security-fail':
            if (checkAll(state.uuid, state.consentGiven)) {
                return (
                    <SecurityCheck
                        noRetries={true}
                        exitWizard={() => exitWizard(() => history.push('/home'))} />
                )
            }
            break
        case 'select-profile':
            if (checkAll(state.uuid, state.consentGiven)) {
                return (
                    <SelectDependant dependants={dependants}
                        selectExistingDependant={selectExistingDependant}
                        selectNewDependant={selectNewDependant} />
                )
            }
            break
        case 'create-profile':
        case 'update-profile':
            if (checkAll(state.uuid, state.consentGiven)) {
                return (
                    <AddDependant
                        exitWizard={exitWizard} />
                )
            }
            break

        case 'invalid-dob':
        case 'invalid-relationship':
        case 'invalid-connection':
            if (checkAll(state.uuid, state.consentGiven)) {
                return (
                    <DependantError
                        exitWizard={() => exitWizard(() => history.push('/home'))}
                        deleteDependant={deleteDependant}
                    />
                )
            }

    }

    // If we reach this, we have inconsistent state / context.
    // We can possibly mitigate this with a <Prompt>: https://v5.reactrouter.com/core/api/Prompt
    console.log(step, state)
    history.replace('/home')

    return null
}

type TOnboardDependantStep =
'consent' | 'security' | 'security-fail' |
'select-profile' | 'create-profile' | 'update-profile' |
'invalid-dob' | 'invalid-relationship' | 'invalid-connection'

export const OnboardDependant = ({ match }: RouteComponentProps<{
    step: TOnboardDependantStep
}>) => {
    const location = useLocation<ConnectionInviteContextType>()

    let journey
    switch (location.state?.invite_type as NonNullable<ConnectionInviteContextType['invite_type']>) {
        case 'clinic':
            journey = OnboardingJourney.CLINIC_DEPENDANT
            break
        case 'school':
            journey = OnboardingJourney.SCHOOL_DEPENDANT
            break
    }

    return (
        <ConnectionInviteProvider value={{ ...location.state, journey, }}>
            <SubroutineProvider>
                <OnboardDependantStep step={match.params.step}/>
            </SubroutineProvider>
        </ConnectionInviteProvider>
    )
}
