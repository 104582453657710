import SvgIcon from '@material-ui/core/SvgIcon';

function FamilyActionIcon(props) {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>  
            <circle opacity="0.6" cx="33.7789" cy="17.6769" r="12" fill="#FBD202"/>
            <path fill="none" d="M33.7526 41V37.1809C33.7526 35.1552 32.9479 33.2124 31.5155 31.78C30.083 30.3475 28.1402 29.5428 26.1145 29.5428H10.8382C8.8124 29.5428 6.86961 30.3475 5.43718 31.78C4.00474 33.2124 3.20001 35.1552 3.20001 37.1809V41" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M18.4825 22.0763C22.7009 22.0763 26.1206 18.6566 26.1206 14.4381C26.1206 10.2197 22.7009 6.79999 18.4825 6.79999C14.264 6.79999 10.8443 10.2197 10.8443 14.4381C10.8443 18.6566 14.264 22.0763 18.4825 22.0763Z" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M43.4416 40.7518V36.9327C43.4403 35.2403 42.877 33.5963 41.8402 32.2588C40.8033 30.9212 39.3516 29.9659 37.713 29.5428" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M31.8431 7.0506C33.4861 7.47127 34.9424 8.42681 35.9823 9.76656C37.0223 11.1063 37.5867 12.7541 37.5867 14.4501C37.5867 16.1461 37.0223 17.7938 35.9823 19.1336C34.9424 20.4733 33.4861 21.4289 31.8431 21.8495" stroke={props.strokecolor || "#333"} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default FamilyActionIcon