import { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { IconButton } from "../../components/button";
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { axiosAuthedInstance, axiosInstance } from "../../utils/axiosApi";
import Alert from '../../components/alert';
import { nestFlexStyle, BaseContainer } from '../../components/container'
import { clearStorage, onLogIn } from "../../utils/auth";
import { validateEmail } from '../../components/text_input'
import CTAButton from "../../components/buttons/cta";
import Typography from '../../components/typography'
import { GlobalContext } from "../../components/context/globalState";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    }
}));

const Login = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [formError, setFormError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)
    const [staySignedIn, setStaySignedIn] = useState(true)
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    const { dispatch: dispatchGlobal } = useContext(GlobalContext)

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmailError(validateEmail(event.target.value))
        setEmail(event.target.value)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }

        if (loading) return

        // Validate input
        if (!email || !password) {
            setErrorOpen(true)
            setFormError("Please submit both your email and password.")
            return;
        }

        setLoading(true)

        try {
            // Login by sending credentials and saving the access_token
            const response_login = await axiosInstance.post('/token/obtain/', {
                email,
                password,
            });

            onLogIn({
                accessToken: response_login.data.access,
                refreshToken: response_login.data.refresh,
                lastLogin: response_login.data.last_login,
                staySignedIn
            })

            // This sets recipient of SharingInvite to current user / opens SchoolInvite / accepts ClinicInvite
            const response = await axiosAuthedInstance.post(
                'user/invite/post-signup-login/',
                { token: props.location.state?.token }
            )

            // this doesn't work as expected, Django returns a 403 for us
            if (!response.email_verified) {
                setFormError("Please verify your email before signing in.")
            }

            dispatchGlobal({
                type: "SET_USER",
                payload: response.data.data
            })

            // Get a parsed query string object from window.location.search
            const qs = (function (queryStringParts) {
                if (queryStringParts == "") return {};
                let queryString = {};
                for (let i = 0; i < queryStringParts.length; ++i) {
                    let pair = queryStringParts[i].split('=', 2);
                    if (pair.length == 1)
                        queryString[pair[0]] = "";
                    else
                        queryString[pair[0]] = decodeURIComponent(pair[1].replace(/\+/g, " "));
                }
                return queryString;
            })(window.location.search.substr(1).split('&'));

            if (response.data.data.token_type == 'SharingInvite') {
                if (response.data.data.error) {
                    let errorMsg = response.data.data.error
                    if (response.data.data.error_type == 'same_user') {
                        errorMsg = "It looks like you have tried to accept your own sharing invitation. " + errorMsg
                    }
                    history.push('/invite/error', { type: 'sharing', error: errorMsg })
                } else {
                    history.push('/sharing/confirm', { token: props.location.state?.token })
                }
            } else if (qs['next']) {
                // push user to the original requested url
                history.push(qs['next']);
            } else {
                history.push('/home');
            }
        } catch (error) {
            if (error.response.status == 403) {
                // Remove auth tokens
                clearStorage()
                history.push('/signup/resend', { email })
            } else if (error.response.status >= 400 && error.response.status < 500) {
                setFormError("The credentials you provided are incorrect.")
            } else {
                setFormError("Server error. Please try again later.")
                console.error(error)
            }
            setErrorOpen(true)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        clearStorage()
        axiosAuthedInstance.defaults.headers['Authorization'] = null;
    }, [])

    return (
        <BaseContainer {...props} showCta extraComponent={
            <CTAButton
                fullWidth
                primary
                form="login"
                loading={loading}
                type="submit"
            >
                Sign In
            </CTAButton>
        }>
            <Box style={nestFlexStyle} position="relative" width="100%">
                <Typography component="h1" variant="onboardingHeader" gutterBottom>
                    Welcome back
                </Typography>

                {/* Form Errors */}
                <Collapse in={errorOpen}>
                    <Alert
                        severity='error'
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formError}
                    </Alert>
                </Collapse>
                <form className={classes.form} style={nestFlexStyle} onSubmit={handleSubmit} id="login">
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        autoComplete="username"
                        placeholder="Email address"
                        fullWidth
                        margin="normal"
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={handlePasswordChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label="Toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Grid container>
                        <Grid item xs>
                            <FormControlLabel
                                control={<Checkbox checked={staySignedIn} onChange={() => setStaySignedIn(!staySignedIn)} color="primary" />}
                                label={<Typography variant="bodyTextMedium">Stay signed in</Typography>}
                            />
                        </Grid>
                        <Grid item>
                            <Box display="flex" alignItems="center" width="100%" height="100%">
                                <Typography variant="bodyTextMediumBold">
                                    <Link underline="none" to="/forgot-password" className={classes.link}>
                                        Forgot password?
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </BaseContainer>
    )
}

export default Login
