import { useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom";
import { TextField, Link, Collapse, InputAdornment } from "@material-ui/core"
import { DatePicker } from "../../components/date_picker"
import { BaseContainer } from "../../components/container"
import { Button, IconButton } from "../../components/button"
import { validateName, validateEmail, validatePassword, validateConfirmPassword } from '../../components/text_input'
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '../../components/alert';
import { axiosInstance } from "../../utils/axiosApi"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { BackButton } from "../../components/buttons/back_btn";
import { default as SelectInput, validateRequired } from "../../components/select_input"
import MenuItem from '@material-ui/core/MenuItem'
import ErrorDialog from "../../components/dialogs/error_dialog"
import { IdentityNumberField } from '../../components/identity_number_field'
import { ConnectionInviteContext } from "../../components/context/connection_invite";
import { HeaderTitle, PageDescription } from "../../components/page_sections";

const Step = {
    USER_INFO: 0,
    PASSWORD: 1,
}

export const SignUp = (props) => {
    const [step, setStep] = useState(Step.USER_INFO)
    const { state: clinicContext } = useContext(ConnectionInviteContext)
    const [userInfo, setUserInfo] = useState({
        name: clinicContext.account_holder,
        preferredName: '',
        email: clinicContext.email,
        gender: clinicContext.gender,
        idType: clinicContext.idType,
        idNumber: clinicContext.idNumber,
        dob: clinicContext.dob,
    })
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    const history = useHistory()
    const location = useLocation()
    useEffect(() => {
        const stepFromHistory = location.state?.step || Step.USER_INFO
        setStep(stepFromHistory)
    }, [location])

    const signUp = async (userInfo) => {
        try {
            await props.signUp({
                name: userInfo.name,
                preferredName: userInfo.preferredName,
                email: userInfo.email,
                password: userInfo.password,
                dob: userInfo.dob,
                gender: userInfo.gender,
                idType: userInfo.idType,
                idNumber: userInfo.idNumber,
                token: clinicContext.token
            })
        } catch (error) {
            console.error(error)
            if (error.response.status == 400) {
                let response = 'Error Creating an Account'
                if (error.response.data?.error) {
                    response = error.response.data.error
                }
                setError(response);
                setShowError(true)
            } else {
                setError(error.response.data.detail || error.response.data.error || 'Server error. Try again later');
                setShowError(true)
            }
        }
    }

    let content
    switch (step) {
        case Step.USER_INFO:
            content = <Page1
                userInfo={userInfo}
                handleSubmit={newState => {
                    setUserInfo(newState)
                    history.push(location.pathname, { step: Step.PASSWORD })
                    setStep(Step.PASSWORD)
                }} />
            break;
        case Step.PASSWORD:
            content = <Page2
                handleSubmit={password => {
                    const newState = { ...userInfo, password }
                    setUserInfo(newState)
                    return signUp(newState)
                }}
                email={userInfo.email} />
            break;
        default:
            content = null;
    }

    return (
        <>
            {content}
            <ErrorDialog
                setOpen={setShowError}
                open={showError}
                error={error}
            />
        </>
    )
}

const Page1 = (props) => {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [errorMsgs, setErrorMsgs] = useState({
        name: '',
        preferredName: '',
        email: '',
        gender: '',
        idNumber: ''
    })
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    // Validate sign up form on first load
    useEffect(() => {
        validateInput(userInfo.name, 'name')
        validateInput(userInfo.email, 'email')
        validateInput(userInfo.gender, 'gender')
    }, [])

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault()
        }

        setLoading(true)

        try {
            await axiosInstance.post('/user/email-unique-check/', { email: userInfo.email });
            props.handleSubmit(userInfo)
        } catch (error) {
            // All success responses navigate away from this page, we only need to
            // `setLoading(false)` in the error cases
            setLoading(false)
            if (error.response?.status == 400) {
                setErrorMsgs(prevState => ({
                    ...prevState,
                    email: error.response.data.error_message
                }))
            } else {
                console.error(error)
                setError('Server Error');
                setShowError(true)
            }
        }
    }

    const validateInput = (text, type) => {
        let error
        switch (type) {
            case 'name':
            case 'preferredName':
                error = validateName(text)
                break;
            case 'email':
                error = validateEmail(text)
                break;
            case 'gender':
                error = validateRequired(text, "gender")
                break;
            default:
                break;
        }
        setErrorMsgs(prevState => ({ ...prevState, [type]: error }))
        setUserInfo(prevState => ({ ...prevState, [type]: text }))
    }

    const handleChange = (event) => {
        validateInput(event.target.value, event.target.name)
    }

    const onIdNumberChange = (idType, value, error) => {
        setUserInfo(prevState => ({
            ...prevState,
            idType,
            idNumber: value
        }))
        setErrorMsgs(prevState => ({
            ...prevState,
            idNumber: error
        }))
    }

    const { name, preferredName, email, idType, idNumber, gender } = userInfo

    const validated = !(
        errorMsgs.name ||
        errorMsgs.preferredName ||
        errorMsgs.email ||
        errorMsgs.gender ||
        errorMsgs.idNumber
    )

    const isFormValid = name && preferredName && email && gender && validated

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth type="submit" form="sign-up"
                    disabled={!isFormValid}
                    loading={loading}
                >
                    Continue
                </Button>
                <div>
                    Existing user?
                    {' '}
                    <Link onClick={() => {
                        history.push('/connection/adult/sign-in')
                    }}>Sign in</Link>
                </div>
            </StickyFooterGroup>
        }>
            <BackButton />

            <HeaderTitle>
                Welcome on board
            </HeaderTitle>

            <PageDescription>
                Hello, we will use this information to ensure your health records are correctly matched to you, and to provide you with the most relevant vaccine recommendations.
            </PageDescription>

            <Collapse in={showError}>
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowError(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {error}
                </Alert>
            </Collapse>

            <form id="sign-up" onSubmit={handleSubmit}>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    fullWidth
                    error={!!errorMsgs.name}
                    helperText={errorMsgs.name || "Use name and format on HKID/ID document."}
                    margin="normal"
                    value={name}
                    onChange={handleChange}
                    required
                />
                <TextField
                    id="preferredName"
                    name="preferredName"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    error={!!errorMsgs.preferredName}
                    helperText={errorMsgs.preferredName || "How we refer to you - only in the Imunis App."}
                    margin="normal"
                    value={preferredName}
                    onChange={handleChange}
                    required
                />
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Email address"
                    fullWidth
                    margin="normal"
                    value={userInfo.email}
                    error={!!errorMsgs.email}
                    helperText={errorMsgs.email || "Used to sign in to your Imunis account."}
                    onChange={handleChange}
                    required
                />
                <IdentityNumberField
                    fullWidth
                    margin="normal"
                    defaultValue={idNumber}
                    idType={idType}
                    onChange={onIdNumberChange}
                    error={errorMsgs.idNumber}
                />
                <SelectInput
                    name="gender"
                    label="Gender"
                    fullWidth
                    margin="normal"
                    value={userInfo.gender}
                    onChange={handleChange}
                    error={!!errorMsgs.gender}
                    helperText={errorMsgs.gender}
                    required
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                </SelectInput>
                <DatePicker
                    fullWidth
                    disabled={true}
                    id="dob"
                    name="dob"
                    margin="normal"
                    label="Date of birth"
                    value={userInfo.dob}
                    helperText={'Matched with your clinic records'}
                    required
                />
            </form>
        </BaseContainer>

    )
}

const Page2 = (props) => {
    const [state, setState] = useState({ password: '', confirmPassword: '' })
    const [errorMsgs, setErrorMsgs] = useState({ password: '', confirmPassword: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        return () => {
            setLoading(false)
        };
    }, [])

    const handleChange = (event) => {
        let error
        const text = event.target.value
        switch (event.target.name) {
            case 'password':
                error = validatePassword(text)
                if (state.confirmPassword) {
                    setErrorMsgs(prevState => ({
                        ...prevState,
                        'confirmPassword': validateConfirmPassword(state.confirmPassword, text)
                    }))
                }
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(text, state.password)
                break;
            default:
                break;
        }

        setErrorMsgs(prevState => ({ ...prevState, [event.target.name]: error }))
        setState(prevState => ({ ...prevState, [event.target.name]: text }))
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault()
        }
        setLoading(true)
        props.handleSubmit(state.password).then(() => {
            setLoading(false)
        })
    }

    const { password, confirmPassword } = state
    const isFormValid = password && confirmPassword && !errorMsgs.password & !errorMsgs.confirmPassword

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth type="submit" form="sign-up"
                    disabled={!isFormValid}
                    loading={loading}
                >
                    Continue
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton />

            <HeaderTitle>
                Let’s make it secure
            </HeaderTitle>

            <form id="sign-up" onSubmit={handleSubmit}>
                {/* For browser autocomplete / password managers */}
                <input hidden type="text" autoComplete="username" value={props.email} readOnly />
                <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    error={!!errorMsgs.password}
                    helperText={errorMsgs.password}
                    value={password}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirm ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    error={!!errorMsgs.confirmPassword}
                    helperText={errorMsgs.confirmPassword}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowConfirm(!showConfirm)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
            </form>
        </BaseContainer>
    )
}

export default SignUp
