import SvgIcon from '@material-ui/core/SvgIcon';

function ConnectionDotIcon(props) {
    return (
        <SvgIcon {...props}>
            <circle cx="17" cy="6" r="5" fill="#FBD202"/>
            <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="square">
                <path d="M7.57143 12.0052L12.1429 12.0052M15 8.00261L12.1429 12L15 16.0078M19 6.28572C19 7.54743 17.976 8.57143 16.7143 8.57143C15.4526 8.57143 14.4286 7.54743 14.4286 6.28572C14.4286 5.024 15.4526 4 16.7143 4C17.976 4 19 5.024 19 6.28572ZM19 17.7143C19 18.976 17.976 20 16.7143 20C15.4526 20 14.4286 18.976 14.4286 17.7143C14.4286 16.4526 15.4526 15.4286 16.7143 15.4286C17.976 15.4286 19 16.4526 19 17.7143ZM7.57143 12C7.57143 13.2617 6.54743 14.2857 5.28571 14.2857C4.024 14.2857 3 13.2617 3 12C3 10.7383 4.024 9.71428 5.28571 9.71428C6.54743 9.71428 7.57143 10.7383 7.57143 12Z"/>
            </g>

        </SvgIcon>
    );
}

export default ConnectionDotIcon
