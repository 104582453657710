import SvgIcon from '@material-ui/core/SvgIcon';

function VerifyEmailIcon(props) {
    return (
        <SvgIcon viewBox="0 0 375 279"{...props}>
            <path d="M109.375 90.875C109.375 86.4567 112.957 82.875 117.375 82.875H271.375C275.793 82.875 279.375 86.4567 279.375 90.875V193.875C279.375 198.293 275.793 201.875 271.375 201.875H117.375C112.957 201.875 109.375 198.293 109.375 193.875V90.875Z" fill="#FFA980"/>
            <path d="M111 75.5C106.029 75.5 102 79.5294 102 84.5V187.5C102 192.471 106.029 196.5 111 196.5H265C269.971 196.5 274 192.471 274 187.5V84.5C274 79.5294 269.971 75.5 265 75.5H111Z" stroke="black" strokeWidth="2" fill="none"/>
            <path d="M260.25 182.75L226.25 148.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M115.75 182.75L149.75 148.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M115.75 89.25L185.693 138.622C187.076 139.598 188.924 139.598 190.307 138.622L260.25 89.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <circle opacity="0.1" cx="335.5" cy="49.5" r="34.5" fill="#FF5300"/>
            <circle opacity="0.1" cx="37" cy="250" r="29" fill="#FF5300"/>
            <circle opacity="0.1" cx="309.5" cy="241.5" r="37.5" fill="#FD9801"/>
            <circle opacity="0.1" cx="36.5" cy="78.5" r="15.5" fill="#FD9801"/>
        </SvgIcon>
    );
}

export default VerifyEmailIcon