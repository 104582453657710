import SvgIcon from '@material-ui/core/SvgIcon';

function SharingDotIcon(props) {
    return (
        <SvgIcon {...props}>
            <circle cx="18" cy="6" r="5" fill="#FBD202"/>
            <g fill="currentColor">
                <path d="M15.429 19.99v1h1v-1h-1ZM4 19.99H3v1h1v-1ZM20 20v1h1v-1h-1ZM12.143 7.426a2.427 2.427 0 0 1-2.429 2.427v2a4.427 4.427 0 0 0 4.429-4.427h-2ZM9.714 9.853a2.427 2.427 0 0 1-2.428-2.427h-2a4.427 4.427 0 0 0 4.428 4.427v-2ZM7.286 7.426A2.427 2.427 0 0 1 9.714 5V3a4.427 4.427 0 0 0-4.428 4.426h2ZM9.714 5a2.427 2.427 0 0 1 2.429 2.426h2A4.427 4.427 0 0 0 9.714 3v2Zm5.715 13.99H4v2h11.429v-2ZM5 19.99v-2.283H3v2.283h2Zm2.429-4.711H12v-2H7.429v2Zm7 2.428v2.283h2v-2.283h-2ZM12 15.279a2.428 2.428 0 0 1 2.429 2.428h2A4.428 4.428 0 0 0 12 13.279v2Zm-7 2.428a2.428 2.428 0 0 1 2.429-2.428v-2A4.428 4.428 0 0 0 3 17.707h2Zm14 .579V20h2v-1.714h-2ZM20 19h-2.857v2H20v-2Zm-2.857-2.571c1.026 0 1.857.831 1.857 1.857h2a3.857 3.857 0 0 0-3.857-3.857v2ZM16.5 12a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2Zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm-1-1a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2Zm0-2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1V8Z"/>
            </g>
        </SvgIcon>
    );
}

export default SharingDotIcon
