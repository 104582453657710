import SvgIcon from '@material-ui/core/SvgIcon';
import theme from '../../styles/theme';

function SharingIcon(props) {
    return (
        <SvgIcon>
            <path
                d="M19.9286 20V21C20.4809 21 20.9286 20.5523 20.9286 20H19.9286ZM15.4286 20V21C15.9808 21 16.4286 20.5523 16.4286 20H15.4286ZM3.99999 20H2.99999C2.99999 20.5523 3.4477 21 3.99999 21V20ZM12.0714 7.42634C12.0714 8.76592 10.9849 9.85267 9.64286 9.85267V11.8527C12.0882 11.8527 14.0714 9.87172 14.0714 7.42634H12.0714ZM9.64286 9.85267C8.30081 9.85267 7.21429 8.76592 7.21429 7.42634H5.21429C5.21429 9.87172 7.19748 11.8527 9.64286 11.8527V9.85267ZM7.21429 7.42634C7.21429 6.08676 8.30081 5 9.64286 5V3C7.19748 3 5.21429 4.98096 5.21429 7.42634H7.21429ZM9.64286 5C10.9849 5 12.0714 6.08676 12.0714 7.42634H14.0714C14.0714 4.98096 12.0882 3 9.64286 3V5ZM18.9286 18.2857V20H20.9286V18.2857H18.9286ZM19.9286 19H16.9286V21H19.9286V19ZM16.9286 16.4286C18.0182 16.4286 18.9286 17.3219 18.9286 18.2857H20.9286C20.9286 16.0936 18.9948 14.4286 16.9286 14.4286V16.4286ZM16.9286 12C16.3763 12 15.9286 11.5523 15.9286 11H13.9286C13.9286 12.6569 15.2717 14 16.9286 14V12ZM17.9286 11C17.9286 11.5523 17.4809 12 16.9286 12V14C18.5854 14 19.9286 12.6569 19.9286 11H17.9286ZM16.9286 10C17.4809 10 17.9286 10.4477 17.9286 11H19.9286C19.9286 9.34315 18.5854 8 16.9286 8V10ZM16.9286 8C15.2717 8 13.9286 9.34315 13.9286 11H15.9286C15.9286 10.4477 16.3763 10 16.9286 10V8ZM7.42856 15.2894H12V13.2894H7.42856V15.2894ZM12 15.2894C13.3415 15.2894 14.4286 16.3764 14.4286 17.7174H16.4286C16.4286 15.2714 14.4456 13.2894 12 13.2894V15.2894ZM4.99999 17.7174C4.99999 16.3764 6.08707 15.2894 7.42856 15.2894V13.2894C4.98295 13.2894 2.99999 15.2714 2.99999 17.7174H4.99999ZM14.4286 17.7174V20H16.4286V17.7174H14.4286ZM2.99999 17.7174V20H4.99999V17.7174H2.99999ZM15.4286 19H3.99999V21H15.4286V19Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

export default SharingIcon
