import {
    PropTypes,
    InputLabel,
    Select, SelectProps,
    FormHelperText,
    FormControl, FormControlProps
} from "@material-ui/core"

type SelectInputProps = {
    fullWidth: boolean,
    margin: PropTypes.Margin,
    name: string,
    label: string,
    children: React.ReactNode,
    error: boolean,
    helperText: string,
} & SelectProps

export function validateRequired(selection: string, fieldName: string) {
    if (selection == "") {
        return `Please select your ${fieldName}`
    }
}

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
    fullWidth, margin, name, label, children, error, helperText, required,
    ...props
}) => {
    const controlProps: FormControlProps = {
        margin,
        fullWidth,
        error,
        required,
    }
    const selectProps: SelectProps = {
        id: name,
        name: name,
        label: label,
        ...props
    }

    return (
        <FormControl variant="standard" {...controlProps}>
            <InputLabel id={`${selectProps.id}-label`}>{selectProps.label}</InputLabel>
            <Select
                labelId={`${selectProps.id}-label`}
                {...selectProps}
            >{children}</Select>
            {helperText && !selectProps.value && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default SelectInput
