import Typography from './typography'
import { makeStyles, Theme } from "@material-ui/core"
import TickCircleSolidIcon from '../assets/icons/tick_circle_solid'
import ExclamationTriangleSolid from '../assets/icons/exclamation_triangle_solid'
import ExclamationCircleSolidIcon from '../assets/icons/exclamation_circle_solid'
import InformationCircleSolid from '../assets/icons/information_circle_solid'
import { VaccinationStatus as VaccinationStatusApi } from './vaccination_progress'
import { pluralString } from '../utils/common'

const pluraliseDoses = (count: number): string => (
    `${count} ${pluralString('qualifying dose', count)}`
)

type VaccinationStatusProps = {
    actionStatus?: VaccinationStatusApi['action_status']
    dosesTaken?: number
    dosesTotal?: number
    vaccineEventsCount?: number | null
}

const actionStyles = makeStyles<Theme>(() => ({
    statusWrapper: {
        display: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        gap: '12px',
    },
    icon: {
        fontSize: '24px',
    },
    spacing: {
        marginTop: '2px',
    },
}))

export const VaccinationStatus = (props: VaccinationStatusProps) => {
    const classes = actionStyles();
    const {actionStatus, dosesTaken, dosesTotal, vaccineEventsCount} = props
    let icon, label, caption

    switch (actionStatus) {
        case 'UP_TO_DATE':
            label = 'Up-to-date'
            icon = <TickCircleSolidIcon variant='successSubdued' className={classes.icon} />
            break;
        case 'ELIGIBLE':
            label = 'Eligible for vaccination'
            icon = <ExclamationTriangleSolid className={classes.icon} />
            break;
        case 'MISSED':
            label = 'Missed Vaccination'
            icon = <ExclamationCircleSolidIcon className={classes.icon} />
            break;
        case 'LATE':
            label = 'Late Vaccination'
            icon = <ExclamationCircleSolidIcon className={classes.icon} />
            break;
        case 'UNAVAILABLE':
            label = 'Unavailable'
            icon = <InformationCircleSolid className={classes.icon}/>
            break;
    }

    if (typeof vaccineEventsCount === 'number') {
        if (dosesTaken !== undefined && dosesTotal !== undefined) {
            if (actionStatus === 'UNAVAILABLE') {
                caption = pluraliseDoses(vaccineEventsCount)
            } else {
                caption = `${dosesTaken}/${pluraliseDoses(dosesTotal)}`
            }
        } else {
            caption = pluraliseDoses(vaccineEventsCount)
        }
    } else {
        caption = ''
    }

    return (<>
        <div className={classes.statusWrapper}>
            {icon}
            <div>
                {label &&
                    <Typography variant='bodyTextMediumDark'>
                        {label}
                    </Typography>
                }
                {caption &&
                    <Typography className={classes.spacing} variant='bodyTextMedium'>
                        {caption}
                    </Typography>
                }
            </div>
        </div>
    </>)
}

