import { TextField } from '@material-ui/core';
import { DatePicker } from "../../components/date_picker";
import { useContext, useState, useEffect } from 'react';
import { Button } from '../../components/button';
import { BackButton } from '../../components/buttons/back_btn';
import { BaseContainer } from '../../components/container';
import { ConnectionInviteContext } from '../../components/context/connection_invite';
import ErrorDialog from '../../components/dialogs/error_dialog';
import SuccessDialog from '../../components/dialogs/success_dialog';
import { HeaderTitle, PageDescription } from '../../components/page_sections';
import { StickyFooterGroup } from '../../components/sticky_footer_group';
import { validateName } from '../../components/text_input';
import Typography from '../../components/typography';
import { axiosAuthedInstance } from '../../utils/axiosApi';
import { default as SelectInput, validateRequired } from '../../components/select_input';
import MenuItem from '@material-ui/core/MenuItem'
import { IdentityNumberField } from "../../components/identity_number_field";
import { logEvent } from '../../utils/common';
import { useHistory } from 'react-router-dom';

const ACTION_ADD = 'ACTION_ADD'
const ACTION_EDIT = 'ACTION_EDIT'

const AddDependant = (props) => {
    const [depState, setDepState] = useState({
        name: '',
        preferredName: '',
        idType: '',
        hkid: '',
        passport: '',
        gender: ''
    })
    const [errors, setErrors] = useState({ name: '', preferredName: '', idNumber: '', gender: '' })
    const [success, setSuccess] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const { state } = useContext(ConnectionInviteContext)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const action = state.dependant ? ACTION_EDIT : ACTION_ADD
    const updateText = state.dependant ?
        `Here you may edit Full Name (recommended to match ID) and Display Name. These will be stored with Imunis and not transferred back to ${state.organisation}.`
        :
        `You may edit the name provided by the ${state.invite_type} to match ID document and add a Display Name. These will be stored with Imunis and not transferred back to ${state.organisation}.`

    useEffect(() => {
        validateInput(action === ACTION_ADD ? state.full_name : state.dependant.full_name, 'name')
        let idState
        // Pre-fill the identity field with the EMR value, unless we're editing an existing
        // dependant with a valid ID type
        if (state.idType && state.idType !== 'UNKNOWN' &&
            (action === ACTION_ADD || state.dependant?.id_type === "unknown" || state.dependant?.id_number === "")) {
            // Use EMR value
            idState = {
                idType: state.idType,
                idNumber: state.idNumber,
            }
        } else if (state.dependant && state.dependant.id_type !== "unknown") {
            // Use existing profile's value
            idState = {
                idType: state.dependant.id_type.toUpperCase(),
                idNumber: state.dependant.id_number,
            }
        }

        setDepState(prevState => ({
            ...prevState,
            preferredName: action === ACTION_ADD ? '' : state.dependant.preferred_name,
            gender: action === ACTION_ADD ? (state.gender ?? '') : state.dependant.gender,
            ...idState,
        }))
    }, [action, state])

    const validateInput = (text, type) => {
        let error
        switch (type) {
            case 'name':
                error = validateName(text)
                break;
            case 'preferredName':
                error = validateName(text)
                break;
            case 'gender':
                error = validateRequired(text, "gender")
                break
            default:
                break;
        }

        setErrors(prevState => ({ ...prevState, [type]: error }))
        setDepState(prevState => ({ ...prevState, [type]: text }))
    }

    const handleChange = (event) => {
        validateInput(event.target.value, event.target.name)
    }

    const onIdNumberChange = (idType, value, error) => {
        setDepState(prevState => ({
            ...prevState,
            idType,
            idNumber: value
        }))
        setErrors(prevState => ({
            ...prevState,
            idNumber: error
        }))
    }

    const connectProfile = (profileId) => {
        return axiosAuthedInstance.post('/user/invite/connect-profile/', {
            dob: state.dob,
            dependant: profileId,
            profile: state.uuid,
        }).then(() => {
            logEvent(`onboard_${state.invite_type}_depdendant`)
        })
    }

    const addDependant = () => {
        setLoading(true)
        axiosAuthedInstance.post('/user/dependants/add/', {
            full_name: depState.name,
            preferred_name: depState.preferredName,
            dob: state.dob,
            gender: depState.gender,
            id_type: depState.idType.toLowerCase(),
            id_number: depState.idNumber,
        }).then(res => {
            return connectProfile(res.data.dependant)
                .then(() => {
                    setSuccess(`Dependant has been created & connected to ${state.invite_type} successfully`)
                    setShowSuccess(true)
                })
                .catch(err => {
                    handleError(err, 'Error connecting with profile')
                })
        }).catch(err => {
            handleError(err, 'Error creating new dependant')
        }).finally(() => {
            setLoading(false)
        })
    }

    const editDependant = () => {
        setLoading(true)
        axiosAuthedInstance.post(`/user/${state.dependant.uuid}/profile/`, {
            full_name: depState.name,
            preferred_name: depState.preferredName,
            dob: state.dob,
            gender: depState.gender,
            id_type: depState.idType.toLowerCase(),
            id_number: depState.idNumber,
        }).then(() => {
            return connectProfile(state.dependant.uuid)
                .then(() => {
                    setSuccess(`Dependant has been connected to ${state.invite_type} successfully`)
                    setShowSuccess(true)
                })
                .catch(err => {
                    handleError(err, 'Error connecting with profile')
                })
        }).catch(err => {
            handleError(err, 'Error creating new dependant')
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (loading) return

        switch (action) {
            case ACTION_EDIT:
                editDependant()
                return
            case ACTION_ADD:
                addDependant()
                return
        }
    }

    const handleError = (err, defaultMsg) => {
        console.error(err)
        let msg = defaultMsg
        if (err.response?.data?.error) {
            msg = err.response?.data?.error
        }
        setError(msg)
        setShowError(true)
    }

    const { name, preferredName, gender, idType, idNumber } = depState
    const isFormValid = !!depState.name && !errors.name &&
        !!depState.preferredName && !errors.preferredName &&
        !!depState.gender && !errors.gender && !errors.idNumber
    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth type="submit" form="dependant"
                disabled={!isFormValid}
                loading={loading}
            >
                Continue
            </Button>
        </StickyFooterGroup>}>
            <BackButton />

            <HeaderTitle>
                Check & Update information
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    {updateText}
                </Typography>
            </PageDescription>

            <form id="dependant" onSubmit={handleSubmit}>
                <TextField
                    name="name"
                    label="Name"
                    fullWidth
                    margin="normal"
                    error={!!errors.name}
                    helperText={
                        errors.name || "Use name and format on dependant’s HKID/ID document."
                    }
                    value={name}
                    onChange={handleChange}
                    required
                />

                <TextField
                    id="preferredName"
                    name="preferredName"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    error={!!errors.preferredName}
                    helperText={
                        errors.preferredName || "The label we apply to this person to help navigate - only in the Imunis App."
                    }
                    margin="normal"
                    value={preferredName}
                    onChange={handleChange}
                    required
                />

                <IdentityNumberField
                    fullWidth
                    margin="normal"
                    defaultValue={idNumber}
                    idType={idType}
                    onChange={onIdNumberChange}
                    error={errors.idNumber}
                />

                <SelectInput
                    name="gender"
                    label="Gender"
                    fullWidth
                    margin="normal"
                    value={gender}
                    onChange={handleChange}
                    error={!!errors.gender}
                    helperText={errors.gender}
                    required
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                </SelectInput>

                <DatePicker
                    fullWidth
                    disabled
                    name="dob"
                    margin="normal"
                    label="Date of birth"
                    value={state.dob}
                    helperText={
                        `Matched with the ${state.invite_type} records.`
                    }
                    required
                />
            </form>

            <SuccessDialog
                open={showSuccess}
                setOpen={setShowSuccess}
                success={success}
                dismiss_callback={() => {
                    // Update state; remove the pending connection if it's there
                    props.exitWizard((e) => {
                        const { pendingConnections } = e.state.state || {}
                        if (pendingConnections?.length) {
                            history.replace('/pending-connections', {
                                pendingConnections: pendingConnections.filter(d => (
                                    d.uuid !== state.uuid
                                ))
                            })
                        }
                        history.push('/home')
                    })
                }} />

            <ErrorDialog open={showError} setOpen={setShowError} error={error}
                dismiss_callback={() => props.exitWizard(() => history.push('/home'))} />
        </BaseContainer>
    )
}

export default AddDependant
