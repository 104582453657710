import { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, sizing, flexbox, display, borders } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import QuickAction from '../quick_action';
import AddFamilyActionIcon from '../../assets/icons/add_family_action';
import ScanQRActionIcon from '../../assets/icons/scan_qr_action';
import TabButton from '../tab_button';
import HealthIcon from '../../assets/icons/health';
import UserIcon from '../../assets/icons/user';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0, 0, 3),
    },
}))

const Box = styled.div`${spacing}${sizing}${display}${flexbox}${borders}`;

const Components = (props) => {
    const [activeTab, setActiveTab] = useState('health')
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection="row" p={2}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.margin} variant="h6" gutterBottom>
                        Quick actions
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={6}>
                    <Grid item xs={6}>
                        <QuickAction icon={ScanQRActionIcon} content={'Scan QR'} onClick={() => console.log('scanning')} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuickAction icon={AddFamilyActionIcon} content={'Add family'} onClick={() => console.log('add family')} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.margin} variant="h6" gutterBottom>
                        Tab Buttons
                    </Typography>
                    <Box width="100%" display="flex" justifyContent="space-around">
                        <TabButton active={activeTab == "health"} icon={HealthIcon} title="Health" onClick={() => setActiveTab('health')} />
                        <TabButton active={activeTab == "profile"} icon={UserIcon} title="Profile" onClick={() => setActiveTab('profile')} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Components
