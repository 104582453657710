import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing, display, flexbox } from '@material-ui/system';
import styled from 'styled-components';
import Typography from '../../components/typography';

import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";
import Card from "../../components/card";
import ListItem from "../../components/lists/list_item";
import DisconnectIcon from "../../assets/icons/disconnect";
import SuccessDialog from "../../components/dialogs/success_dialog";
import ErrorDialog from "../../components/dialogs/error_dialog";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import { formatDateLocal } from "../../utils/common";
import { getPreferredName } from "../../utils/auth";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { Button, IconButton } from "../../components/button";
import { PageDescription } from "../../components/page_sections";
import SimpleUserIcon from "../../assets/icons/simple_user";
import SelectSharingUsers from "./select_sharing_users";
import SharingDotIcon from "../../assets/icons/sharing_dot";
import { TooltipDrawer } from "../../components/tooltip_drawer";
import { DrawerContent } from "../../components/drawer_content";
import { Divider } from "../../components/divider";
import DisconnectSecondaryDialog from "../../components/dialogs/action_dialog";
import DisconnectPrimaryDialog from "../../components/dialogs/action_dialog"
import { EmptyUsers } from "../../components/empty_content";
import { CardCaption, CardSubtitle } from "../../components/card_components";
import { isCancel } from "axios";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles(() => ({
    icon: {
        width: '24px',
        height: '24px',
    },
    dependantName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

const Sharing = (props) => {
    const [loading, setLoading] = useState(false)
    const [showPrimaryDialog, setShowPrimaryDialog] = useState(false)
    const [showSecondaryDialog, setShowSecondaryDialog] = useState(false)
    const [selectedShare, setSelectedShare] = useState({})
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [shares, setShares] = useState([])
    const [sharing, setSharing] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        getShares()
    },[])

    const getShares = async () => {
        setLoading(true)
        try {
            const response = await axiosAuthedInstance.get('user/shares/')
            setShares(response.data.data)
        } catch (err) {
            if (isCancel(err)) return

            console.error(err)
            let msg = 'Error getting shares'
            if (err.response?.data?.error) {
                msg = err.response.data.error
            }
            setError(msg)
            setShowError(true)
        }
        setLoading(false)
    }

    const disconnect = async (id) => {
        setLoading(true)
        try {
            await axiosAuthedInstance.delete(`user/dependant/permissions/remove/${id}/`)
            setShowSuccessDialog(true)
            getShares()
        } catch (err) {
            console.error(err)
            let msg = 'Error deleting permission'
            if (err.response?.data?.error) {
                msg = err.response.data.error
            }
            setError(msg)
            setShowError(true)
        }
        setLoading(false)
    }

    const renderShares = (shareArray = []) => {
        return (
            <div>
                <Divider />
                {shareArray.length ?
                    shareArray.map((share, index) => {
                        return (
                            <ListItem
                                key={share.share_uuid}
                                divider={index + 1 < shareArray.length}
                                title={`Shared with ${share.full_name}`}
                                subtitle={formatDateLocal(share.share_date)}
                                extra={
                                    <IconButton
                                        className="disconnect-icon"
                                        size="small"
                                        aria-label="Disconnect"
                                        onClick={() => {
                                            setSelectedShare(share)
                                            setShowPrimaryDialog(true)
                                        }}
                                    >
                                        <DisconnectIcon />
                                    </IconButton>
                                }
                            />
                        )
                    })
                    :
                    <Typography align="center" variant="bodyTextSmall">
                        You haven't shared with any parties.
                    </Typography>
                }
            </div>
        )
    }

    let me = {}
    let primaryList = []
    let secondaryList = []
    shares.map(share => {
        if (share.relationship === 'PG') {
            primaryList.push(share)
        } else if (share.relationship === 'SG') {
            secondaryList.push(share)
        } else {
            me = share
        }
    })

    const primaryTitle = "Dependants you manage"
    const primaryContent = "For the profiles you manage, you can choose to share or stop sharing with other Imunis account holders."
    const secondaryTitle = "Dependants shared with you"
    const secondaryContent = "As profiles shared with you have read-only access, you are unable to share them with anyone else. You are able to stop the sharing, in which case the profiles will no longer be visible in your account."

    if (sharing) {
        return <SelectSharingUsers
            dependants={primaryList}
            stopSharing={() => setSharing(false)}
        />
    }

    return (
        <BaseContainer showNav sharing showCta extraComponent={<StickyFooterGroup>
            <Button fullWidth disabled={!primaryList.length} onClick={() => setSharing(true)}>
                Share
            </Button>
        </StickyFooterGroup>
        } {...props}>
            {loading && <Loading loading={loading}/>}

            <Box display="flex" mb={1.5}>
                <Box marginRight={1}>
                    <SharingDotIcon/>
                </Box>
                <Typography variant="modalHeader">Sharing</Typography>
            </Box>

            <PageDescription>
                <Typography variant="bodyTextMedium">
                    View or edit sharing with parties you trust.
                </Typography>
            </PageDescription>

            <PageDescription>
                <Card
                    icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                    title={me.name || getPreferredName()}
                />
            </PageDescription>

            <PageDescription>
                <div id="primary_sharing_list">
                    <TooltipDrawer title={primaryTitle}>
                        <DrawerContent title={primaryTitle} content={primaryContent}/>
                    </TooltipDrawer>
                    {primaryList.length > 0 ? primaryList.map(primary => (
                        <Card
                            key={primary.rel_uuid}
                            icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                            title={primary.preferred_name}
                            caption={<CardCaption amount={primary.shares?.length || 0} item="share" /> }
                            content={renderShares(primary.shares)}
                        />
                    ))
                        : <EmptyUsers emptyText="No profile you manage"/>
                    }
                </div>
            </PageDescription>

            <PageDescription>
                <div id="secondary_sharing_list">
                    <TooltipDrawer title={secondaryTitle}>
                        <DrawerContent title={secondaryTitle} content={secondaryContent}/>
                    </TooltipDrawer>
                    {secondaryList.length > 0 ? secondaryList.map(secondary => (
                        <Card
                            key={secondary.rel_uuid}
                            icon={<SimpleUserIcon fontSize="large" classes={{fontSizeLarge: classes.icon}}/>}
                            title={secondary.preferred_name}
                            subtitle={<CardSubtitle>Shared by {secondary.shares[0].full_name}</CardSubtitle>}
                            extra={
                                <IconButton
                                    className="disconnect-icon"
                                    size="small"
                                    aria-label="Disconnect"
                                    onClick={() => {
                                        setSelectedShare(secondary.shares[0])
                                        setShowSecondaryDialog(true)
                                    }}
                                >
                                    <DisconnectIcon />
                                </IconButton>
                            }
                        />
                    ))
                        : <EmptyUsers emptyText="No profile shared with you"/>
                    }
                </div>
            </PageDescription>

            <DisconnectSecondaryDialog
                title="Remove dependant shared with you?"
                content={"You will no longer be able to view the dependant's information and records. " +
                    "In order to view the records in the future, you will need to receive and accept a new sharing " +
                    "invite from the managing account holder."}
                action_label="Remove"
                cancel
                openDialog={showSecondaryDialog}
                setOpenDialog={setShowSecondaryDialog}
                action_callback={() => disconnect(selectedShare.share_uuid)}
            />

            <DisconnectPrimaryDialog
                title="Stop sharing?"
                content={`${selectedShare.full_name} will be no longer be able to view dependant’s information ` +
                    "and records. In order to share again in the future, a new sharing invitation will need to be " +
                    "issued and accepted."}
                action_label="Confirm"
                cancel
                openDialog={showPrimaryDialog}
                setOpenDialog={setShowPrimaryDialog}
                action_callback={() => disconnect(selectedShare.share_uuid)}
            />

            <SuccessDialog
                success={'Connection removed successfully!'}
                open={showSuccessDialog}
                setOpen={setShowSuccessDialog}
                dismiss_label={'Got it'}
            />
            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />
        </BaseContainer>
    )
}

export default Sharing
