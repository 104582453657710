import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { InfoPanel } from "../info_panel";
import { pluralString } from "../../utils/common";
import { JsonContent } from "../static_content";
import diseaseInfoJson from "../../content/shingles.json";
import { VaccinationStatus } from "../vaccination_progress";

const pluralise_doses = (x: number) => {
    // Fortunately this won't be called with a value of 0 (expected: "no qualifying doses")
    // The underlying `pluralString` function isn't robust enough to handle this.
    return `${x} qualifying ${pluralString('dose', x)}`
}

const capitalise_name = (x: string) => {
    return x.charAt(0).toUpperCase() + x.slice(1);
}

type ShinglesContentProps = {
    status: VaccinationStatus
}

export const shinglesStatusLabel = ({
    vaccine_status,
    action_status,
    course,
    course_taken,
    dose_too_soon,
    unavailable_reason,
}: ShinglesContentProps['status']) => {
    switch (vaccine_status) {
        case 'ineligible':
            switch (course) {
                case 'shingrix':
                    return "Shingles vaccination is generally recommended for people over the age of 50 years. A vaccine is licensed from 18 years and recommended for those who are immuno-compromised, have experienced a shingles infection, or available for those who choose to take it for added protection."
                case null:
                case '':
                    return "You are ineligible for vaccination as your current age falls below the minimum recommended age for shingles vaccination."
            }
            break

        case 'unavailable':
            switch (unavailable_reason) {
                case 'BELOW_AGE':
                    return 'Your records indicate vaccination below the minimum recommended age. As a result, Imunis cannot at this time calculate your vaccination status.'
                case 'CONFLICT':
                    return 'Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing.'
                case "UNSPECIFIED_PRODUCT":
                    return "Vaccination status is currently unavailable due to a lack of necessary information about the vaccine products/type of products taken."
                case 'DOB_UNAVAILABLE':
                    return 'Status is unavailable due to missing DOB.'
                case 'MIXED_PRODUCTS':
                    return 'Due to the combination of Zostavax and Shingrix vaccine records held with Imunis, we are unable to provide a vaccination status for you. Please talk to your healthcare provider.'
                case 'GENERIC_PRODUCT':
                    return 'One or more of your records indicates shingles vaccination, but the vaccine product is not specified. As dosing and timing recommendations vary depending on the shingles vaccine taken, and you do not have a record of the specific vaccine administered, we are unable to provide a vaccination status for you at this time. Please talk to your healthcare provider.'
            }
            break

        case 'unvaxed':
            switch (action_status) {
                case 'ELIGIBLE':
                    return 'You are eligible for shingles vaccination, with no qualifying doses held with Imunis.'
            }
            break

        case 'partial':
            if (dose_too_soon) {
                switch (action_status) {
                    case 'ELIGIBLE':
                        return `You are eligible for vaccination, with ${pluralise_doses(course_taken - 1)} of ${capitalise_name(course)} held with Imunis. As your second dose was taken within 28 days of your first dose, it is not considered a valid dose. You are recommended to take a further dose 8 weeks after the invalid dose, and are now eligible for that dose.`
                    case 'UP_TO_DATE':
                        return `You are up-to-date and partially-vaccinated with ${capitalise_name(course)}, with ${pluralise_doses(course_taken - 1)} held with Imunis. As your second dose was taken within 28 days of your first dose, it is not considered a valid dose. You are recommended to take a further dose, a minimum of 8 weeks after the invalid dose.`
                }
            } else {
                switch (action_status) {
                    case 'ELIGIBLE':
                        return `You are eligible for vaccination, with ${pluralise_doses(course_taken)} of ${capitalise_name(course)} held with Imunis. You are now eligible for dose ${course_taken + 1}.`
                    case 'UP_TO_DATE':
                        return `You are up-to-date and partially-vaccinated with ${capitalise_name(course)}, with ${pluralise_doses(course_taken)} held with Imunis. You need to complete the minimum interval before being eligible for dose ${course_taken + 1}.`
                }
            }
            break

        case 'full':
            if (dose_too_soon) {
                return `You are up-to-date and fully-vaccinated with ${capitalise_name(course)}, with ${pluralise_doses(course_taken - 1)} held with Imunis. As your initial second dose was taken within 28 days of your first dose, it was invalidated. You have completed the catch-up as recommended.`
            } else {
                return `You are up-to-date and fully-vaccinated with ${capitalise_name(course)}, with ${pluralise_doses(course_taken)} held with Imunis.`
            }
    }
}

export const zostavaxUpgradeText = ({
    course,
    vaccine_status,
}: ShinglesContentProps['status']) => {
    switch (course) {
        case 'zostavax':
            return 'Your Imunis account indicates previous vaccination with Zostavax. Shingrix is an updated shingles vaccine with improved protection, recommended by many health professionals. It can be taken as a 2-dose product by patients over 50 years of age who have completed a course of Zostavax, 8 weeks or more after completion of the Zostavax course.'
        case 'shingrix':
            switch (vaccine_status) {
                case 'partial':
                    return "Your Imunis account indicates previous vaccination with Zostavax. You’ve since started a course of Shingrix, which is a 2-dose course."
                case 'full':
                    return "Your Imunis account indicates previous vaccination with Zostavax. You’ve since completed a course of Shingrix, which is a 2-dose course."
            }
    }

    /* Should never be reached */
    return ""
}

export const shinglesStatusWarnings = (status: ShinglesContentProps['status']) => {
    const {
        zostavax_upgrade,
        vaccine_status,
        action_status,
        doses_taken,
        course
    } = status;

    return <>
        {zostavax_upgrade &&
        <PageSubsection>
            <InfoPanel severity="warning">
                <Typography variant="bodyTextLarge">
                    {zostavaxUpgradeText(status)}
                </Typography>
            </InfoPanel>
        </PageSubsection>}
        {vaccine_status == 'ineligible' && course == 'shingrix' &&
        <PageSubsection>
            <InfoPanel severity="warning">
                <Typography variant="bodyTextLarge">
                    Vaccination is recommended for people who have previously contracted shingles. Speak to your health provider to discuss the timing of vaccination where applicable.
                </Typography>
            </InfoPanel>
        </PageSubsection>}
        {action_status == 'ELIGIBLE' && doses_taken == 0 && zostavax_upgrade == false &&
        <PageSubsection>
            <InfoPanel severity="warning">
                <Typography variant="bodyTextLarge">
                    Vaccination is still recommended for people who have previously contracted shingles. Speak to your health provider to discuss the timing of vaccination if post-infection.
                </Typography>
            </InfoPanel>
        </PageSubsection>}
    </>
}

export const ShinglesContent = ({ status }: ShinglesContentProps) => (<>

    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {shinglesStatusLabel(status)} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    {shinglesStatusWarnings(status)}

    <Divider />

    <JsonContent>
        {diseaseInfoJson}
    </JsonContent>
</>)
