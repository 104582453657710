import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { spacing, sizing, flexbox, display } from '@material-ui/system';
import styled from 'styled-components';
import { Container } from '@material-ui/core';


const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        boxShadow: '0px -0.5px 3px grey',
        position: 'fixed'
    },

    gapper: {
        top: 'auto',
        bottom: 0,
        padding: theme.spacing(5)
    }
}));

export const StickyFooter = (props) => {
    const classes = useStyles();

    return (
        <>
            <Container className={classes.gapper} />
            <AppBar color="inherit" className={classes.appBar} >
                <Box py={"2vh"} px={{xs: 2, sm: 4}} width="100%" display="grid" justifyContent="space-evenly"
                    alignItems="center">
                    {props.children}
                </Box>
            </AppBar>
        </>
    );
}
