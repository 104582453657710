import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {BaseContainer} from "../../components/container";


const useStyles = makeStyles((theme) => ({
    cta: {
        width: '80%'
    }
}));

export const ShowNavOnly = (props) => {
    const classes = useStyles();

    /**
     *  <TestBaseContainer showNav home>
     *  'showNav' enables the navbar
     *  'home' indicates which page you are staying at
     *   1. home
     *   2. family
     *   3. health
     *   Please indicate it in the tag for navbar icon state change
     */

    return (
        <BaseContainer showNav home>

        </BaseContainer>
    )

}

export const ShowCtaOnly = (props) => {
    const classes = useStyles();

    /**
     *  <TestBaseContainer showCta extraComponent={<Button className={classes.cta}> Remove record </Button>}>
     *  'showCta' enables the CTA area
     *  'extraComponent' should include the CTA button element for display
     */

    return (
        <BaseContainer showCta extraComponent={<Button className={classes.cta} variant='outlined'> Remove
            record </Button>}>

        </BaseContainer>
    )

}

export const ShowNavCta = (props) => {
    const classes = useStyles();
    /**
     *  this function demonstrate how to enable both navbar and CTA
     */
    return (
        <BaseContainer showNav health showCta
            extraComponent={<Button className={classes.cta} variant='outlined'> Remove
                                     record </Button>}>

        </BaseContainer>
    )

}
