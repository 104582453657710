import { useEffect, useRef, useState } from 'react'
import { createReactEditorJS } from 'react-editor-js'
import { EditorCore } from '@react-editor-js/core'
import { OutputData, ToolConstructable } from '@editorjs/editorjs'

// @ts-expect-error: types not available
import Header from '@editorjs/header'
// @ts-expect-error: types not available
import List from '@editorjs/list'
// @ts-expect-error: types not available
import DragDrop from 'editorjs-drag-drop'
// @ts-expect-error: types not available
import Delimiter from '@editorjs/delimiter'
// @ts-expect-error: types not available
import Image from '@editorjs/image'

import { JsonContent, StaticHTML } from '../components/static_content'
import { makeStyles } from '@material-ui/core'
import { scrollIntoView } from '../components/collapsed_section'

const HELP_URL = "https://imunis.atlassian.net/wiki/spaces/MVP/pages/285016066/Disease+Info+Editor"

import exampleBlocks from './editor.example.json'

const context = require.context('../content/', false, /\.json$/)

const Dropdown = ({ onChange }: { onChange: (d: OutputData['blocks']) => void }) => {
    const CLIPBOARD = '_CLIPBOARD'
    const EXAMPLE = '_EXAMPLE'

    return (
        <select onChange={(e) => {
            if (!onChange) return

            const option = e.target.value

            if (!option) return

            e.target.value = ''

            switch (option) {
                case CLIPBOARD: {
                    const userInput = prompt("Paste JSON here:")
                    if (userInput) {
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            onChange(JSON.parse(userInput))
                        } catch (error) {
                            alert("Could not read JSON")
                        }
                    }
                    break
                }
                case EXAMPLE:
                    onChange(exampleBlocks)
                    break
                default:
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    onChange(context(option))
            }
        }}>
            <option value="">Load preset</option>
            <option value={CLIPBOARD}>From clipboard</option>
            <option value={EXAMPLE}>Example</option>
            <optgroup label="Diseases">
                {context.keys().map(c => {
                    return <option key={c} value={c}>{c.slice(2, -5)}</option>
                })}
            </optgroup>
        </select>
    )
}

/** Same as the stock delimiter but renders a simple <hr> in the editor instead of asterisks */
class SimpleDelimiter extends Delimiter {
    drawView() {
        const el = document.createElement('div')
        el.style.height = '25px'
        el.style.border = 'solid white'
        el.style.borderWidth = '12px 0'
        el.style.background = '#E7E7E7'

        return el
    }

    // Typescript complains without these methods defined explicitly.
    save(blockContent: HTMLDivElement) {
        return super.save(blockContent)
    }

    render() {
        return super.render()
    }
}

/** Modify the default header block to remove support for handling H1s */
class MyHeader extends Header {
    static get pasteConfig() {
        return {
            tags: ["H2", "H3", "H4", "H5", "H6"],
        };
    }

    save = super.save
    render = super.render
}

/** Modify the default header block to add support for *only* H1s */
class Section extends Header {
    // Render section titles in the editor as <h2>s, just for its appearance
    get defaultLevel() {
        return {
            number: 2,
            tag: 'H2'
        }
    }

    static get pasteConfig() {
        return {
            tags: ["H1"],
        };
    }

    getTag() {
        const tag = super.getTag() as HTMLHeadingElement

        tag.style.borderBottom = "1px solid #C4C4C4"
        tag.style.paddingBottom = "8px"
        tag.style.margin = "8px 0"

        return tag
    }

    save(toolsContent: HTMLHeadingElement) {
        return {
            text: toolsContent.innerHTML
        };
    }

    renderSettings() {
        return []
    }

    static get toolbox() {
        return {
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.97 17.5a1.49 1.49 0 0 1-1.06-.43l-5.97-6a1.5 1.5 0 0 1 2.12-2.13l4.91 4.97 4.93-4.77a1.5 1.5 0 0 1 2.44 1.68c-.1.18-.22.34-.38.48l-5.97 5.79c-.28.26-.64.41-1.02.42Z"/></svg>',
            title: 'Section',
        };
    }

    render = super.render
}

class MyImage extends Image {
    // Disable the border, stretch and background options, we control this ourselves
    renderSettings() {
        return []
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    save = super.save
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    render = super.render
}

const EDITOR_JS_INLINE_TOOLBAR = ['bold', 'italic', 'link']

const EDITOR_JS_TOOLS = {
    list: {
        class: List as ToolConstructable,
        inlineToolbar: true,
        config: {
            defaultStyle: 'unordered',
        }
    },
    header: {
        class: MyHeader,
        config: {
            levels: [2, 3, 4, 5, 6],
            defaultLevel: 2,
        },
    },
    section: {
        class: Section,
        config: {
            // When pasting <h1>s, we want to render section titles in the editor as h2 elements
            // See https://github.com/editor-js/header/blob/80278ee75146ff461e9dcaeff1a337167ef97162/src/index.js#L438-L443
            levels: [2],
        },
    },
    delimiter: SimpleDelimiter,
    image: {
        class: MyImage,
        config: {
            uploader: {
                /** Reads uploaded file and returns its data URL */
                uploadByFile: (file: File) => (
                    new Promise(resolve => {
                        const reader = new FileReader()

                        reader.addEventListener("load", () => {
                            resolve({
                                success: 1,
                                file: {
                                    url: reader.result
                                }
                            })
                        })
                        reader.addEventListener("error", () => {
                            console.error(reader.error)
                            resolve({success: 0})
                        })

                        reader.readAsDataURL(file)
                    })
                )
            },
        },
    },
};

const EditorJs = createReactEditorJS()

const formatJSON = (obj: unknown) => JSON.stringify(obj, null, 2)

const useStyles = makeStyles(() => ({
    root: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto min-content',
        height: '100vh',
    },
    editor: {
        padding: '0 10px 10px',
        overflow: 'auto',
    },
    editorControls: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, auto) 1fr',
        gridTemplateRows: 'auto fit-content(100%)',
        columnGap: '10px',
        position: 'sticky',
        top: '0',
        background: '#fff',
        zIndex: 2,

        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
        fontSize: '12px',
        padding: '10px',
        maxHeight: '50vh',
        overflow: 'hidden',
        borderBottom: '1px solid',

        '& details': {
            marginLeft: 'auto',
            display: 'contents',

            '& summary': {
                whiteSpace: 'nowrap',
                textAlign: 'right',
            },
            '& div': {
                gridColumn: '1 / span 8',
                overflow: 'auto',
            },
        },
    },
    previewOuter: {
        border: '0.5vh solid #111',
        borderWidth: '5vh 0.5vh',
        borderRadius: '4.5vh',
        margin: '10px',
        width: '50vh',
        resize: 'horizontal',
        minWidth: '375px',
        maxWidth: '50vw',
        height: 'calc(100vh - 20px)',
        overflow: 'auto',
    },
    previewInner: {
        padding: '20px',
    }
}))

/**
 * Same as btoa() but handles utf8 strings
 *
 * See https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem for more info
 */
const b64utf8 = (str: string): string => btoa(unescape(encodeURIComponent(str)))

const Editor = () => {
    const classes = useStyles()
    const instanceRef = useRef<EditorCore | null>(null)
    const editorStateRef = useRef<OutputData>()

    const [editorState, seteditorState,] = useState<OutputData>({
        blocks: []
    })

    useEffect(() => {
        let blocks = [
            {
                "id": "d89vF7G3Ke",
                "type": "header",
                "data": {
                    "text": "Example Heading",
                    "level": 2
                }
            },
            {
                "id": "ZRfi2fWNrA",
                "type": "paragraph",
                "data": {
                    "text": "Click to edit..."
                }
            }
        ]
        try {
            blocks = JSON.parse(decodeURIComponent(window.location.hash.substring(1)))
        } catch {
            // Don't worry about it
        }

        if (blocks?.length) {
            seteditorState({ blocks })
            // Stash the data into a ref for initialising the editor.
            // Using seteditorState doesn't sync it up correctly with the onReady handler
            editorStateRef.current = { blocks }
        }
    }, [])

    useEffect(() => {
        history.replaceState(null, '', `#${JSON.stringify(editorState.blocks)}`)
    }, [editorState])

    const handleUpdate = async () => {
        if (!instanceRef.current) return

        const blockData = await instanceRef.current.save()

        // Hackery to get desired attributes on <a> tags on external URLs (but not internal ones).
        // There's the editorjs-hyperlink package (see
        // https://github.com/editor-js/paragraph/issues/18) but that seems broken in numerous ways
        const transformedData = JSON.stringify(blockData).replaceAll(
            /<a href=\\"(?!#)/g,
            '<a target=\\"_blank\\" rel=\\"noreferrer\\" href=\\"'
        )
        seteditorState(JSON.parse(transformedData) as OutputData)
    }

    /** Opens a save prompt for the JSON contents */
    const saveData = () => {
        const fileName = prompt("Enter a filename")

        if (fileName === null) {
            // Prompt was cancelled, do nothing
            return
        }

        const jsonString = formatJSON(editorState.blocks)
        const dataUrl = `data:application/json;base64,${b64utf8(jsonString)}`

        const el = document.createElement('a')
        el.setAttribute('href', dataUrl)
        el.setAttribute('download', fileName)
        el.setAttribute('target', '_blank')
        el.style.display = 'none'

        document.body.appendChild(el)
        el.click()
        document.body.removeChild(el)
    }

    const resetData = () => {
        if (!confirm("Clear the editor's contents?\n\nThis action cannot be undone.")) return

        void instanceRef.current?.clear()
    }

    const loadData = async (blocks: OutputData['blocks']) => {
        if (!instanceRef.current) return

        await instanceRef.current.render({ blocks })
        seteditorState({ blocks })
    }

    const loadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return
        }
        if (!instanceRef.current) return

        try {
            const dataString = await event.target.files[0].text()
            const dataBlocks = JSON.parse(dataString) as OutputData['blocks']
            await instanceRef.current.render({ blocks: dataBlocks })
            seteditorState({ blocks: dataBlocks })
        } catch (err) {
            alert("Could not recognise the selected file")
            // Reset content
            await instanceRef.current.render(editorState)
            seteditorState(editorState)
        }

        event.target.value = ''
    }

    // Re-create the link handling logic the drawer component wraps around <CollapsedSection>s
    const previewRef = useRef<HTMLDivElement>(null)
    const onPreviewClick = (e: React.MouseEvent ) => {
        const el = e.target as HTMLElement

        if (el && el.nodeName === 'A') {
            const href = el.getAttribute("href")

            if (href && href[0] === "#") {
                e.preventDefault()
                scrollIntoView(previewRef.current?.querySelector(href))
            }
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.editor}>
                <div className={classes.editorControls}>
                    <Dropdown onChange={d => void loadData(d)} />
                    <button onClick={saveData}>Save</button>
                    <button>
                        <label htmlFor="file-upload">Load from file</label>
                        <input id="file-upload" type="file" accept="application/json" hidden
                            onChange={e => void loadFile(e)} />
                    </button>
                    <button onClick={resetData}>Reset</button>
                    <button onClick={() =>{ window.open(HELP_URL) }}>Help</button>
                    <details>
                        <summary>Debug</summary>
                        <div>{formatJSON(editorState.blocks)}</div>
                    </details>
                </div>
                <EditorJs
                    autofocus
                    onInitialize={editorCore => {
                        instanceRef.current = editorCore
                    }}
                    onReady={() => {
                        if (editorStateRef.current) {
                            // Render initial data, if present
                            void instanceRef.current?.render(editorStateRef.current)

                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            new DragDrop(instanceRef.current?.dangerouslyLowLevelInstance)
                        }
                    }}
                    inlineToolbar={EDITOR_JS_INLINE_TOOLBAR}
                    tools={EDITOR_JS_TOOLS}
                    onChange={(_instance, _event) => {
                        void handleUpdate()
                    }}
                />
            </div>
            <div className={classes.previewOuter} onClick={onPreviewClick} ref={previewRef}>
                <StaticHTML className={classes.previewInner}>
                    <JsonContent>
                        {editorState.blocks}
                    </JsonContent>
                </StaticHTML>
            </div>
        </div>)
}

export default Editor
