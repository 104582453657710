import { makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { countryList } from "../../../components/country_dropdown"
import { HeaderTitle } from "../../../components/page_sections"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import Typography from "../../../components/typography"
import RadioCardGroup from '../../../components/radio_card_group';
import RadioCard from '../../../components/radio_card';
import { RecordContext, useRecordContext } from "../../../components/context/recordState"
import { RecordSteps } from "../../../components/context/recordReducer"
import { useHistory } from "react-router-dom"
import { countryHemisphereMap, HemisphereMap } from "./hemisphere_mappings"
import { DualFormulaContent, SingleFormulaContent } from "./formula_content"
import { InfoPanel } from "../../../components/info_panel"
import { CollapsedSection } from "../../../components/collapsed_section"

const useStyles = makeStyles((theme) => ({
    sectionGap: {
        marginTop: '20px'
    },
    disclaimerItem: {
        textAlign: "end"
    },
    suggestion: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    suggestionContent: {
        textAlign: "right"
    }
}))

type Hemisphere = 'NORTH' | 'SOUTH'

const HemisphereContent: React.FC<{hemisphere: HemisphereMap}> = ({hemisphere}) => {
    if (hemisphere === HemisphereMap.DUAL) {
        return <DualFormulaContent />
    } else {
        return <SingleFormulaContent />
    }
}

export const SetHemisphere = () => {
    const classes = useStyles()
    const history = useHistory()
    const [hemisphere, setHemisphere] = useState<Hemisphere>()
    const [isFormValid, setIsFormValid] = useState(false)
    const { state, dispatch } = useRecordContext(RecordContext)
    const countryHemisphere = countryHemisphereMap.get(state.country || '')
    const countryFullName = countryList.get(state.country || '')

    useEffect(() => {
        if (state.hemisphere) {
            setHemisphere(state.hemisphere)
        }
    }, [state.hemisphere])

    useEffect(() => {
        if (hemisphere) {
            setIsFormValid(true)
        }
    }, [hemisphere])

    /** submit form.. performs validation  */
    function dispatchData() {
        if (!hemisphere) {
            throw "Hemisphere not defined"
        }

        dispatch({
            type: RecordSteps.SET_HEMISPHERE,
            payload: {
                hemisphere: hemisphere
            }
        })
    }

    function handleSubmit() {
        dispatchData()
        history.push(`/add-record/${state.user.uuid}/date`)
    }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} disabled={!isFormValid}>
                    Next
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton />
            <HeaderTitle>
                Which formula (season) vaccine was used?
            </HeaderTitle>
            <Typography variant="bodyTextMediumDark">
                Influenza vaccines are reformulated twice a year to maintain protection against mutating viruses. The majority of countries choose one of these vaccine formulas to protect against seasonal influenza, usually the latest formula that coincides with their influenza season.
            </Typography>
            <div className={classes.sectionGap}>
                <InfoPanel severity="info" title="Formula suggestion">
                    <div className={classes.suggestion}>
                        <Typography variant="bodyTextSmall">
                            My vaccination location
                        </Typography>
                        <Typography variant="bodyTextMediumPrimary" className={classes.suggestionContent}>
                            {countryFullName}
                        </Typography>
                    </div>
                    <div className={classes.suggestion}>
                        <Typography variant="bodyTextSmall">
                            Seasonal vaccination formula in use
                        </Typography>
                        <Typography variant="bodyTextMediumPrimary" className={classes.suggestionContent}>
                            {countryHemisphere == 'NORTH' && 'Northern'}
                            {countryHemisphere == 'SOUTH' && 'Southern'}
                            {countryHemisphere == 'DUAL' && 'Northern & Southern'}
                        </Typography>
                    </div>
                </InfoPanel>
            </div>
            <CollapsedSection color="primary" borderBottom={false} title={
                <Typography variant="bodyTextLargeBold">
                    Tips if unsure
                </Typography>} marginTop={1} marginBottom={1} circleChevron={true}
            >
                {countryHemisphere && <HemisphereContent hemisphere={countryHemisphere}/>}
            </CollapsedSection>
            <Typography variant="bodyTextMediumPrimary">
                Choose my vaccine formula:
            </Typography>
            <div className={classes.sectionGap}>
                <RadioCardGroup
                    name="hemisphere"
                    onChange={(e) => setHemisphere(e.target.value as Hemisphere)}
                    value={hemisphere || ''}
                >
                    <RadioCard
                        label={<Typography variant="bodyTextLargeBold">Northern</Typography>}
                        value="NORTH"
                        compact
                    />
                    <RadioCard
                        label={<Typography variant="bodyTextLargeBold">Southern</Typography>}
                        value="SOUTH"
                        compact
                    />
                </RadioCardGroup>
            </div>
            <TextButton
                onClick={() => {
                    if (hemisphere) {
                        dispatch({
                            type: RecordSteps.SET_HEMISPHERE,
                            payload: {
                                hemisphere: hemisphere
                            }
                        })
                    }
                    history.push(`/add-record/${state.user.uuid}/hemisphere-cannot-proceed`)
                }}
            >
                I don't know
            </TextButton>

        </BaseContainer>
    )
}
