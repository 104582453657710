import { useState } from "react";
import Container from '@material-ui/core/Container';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NoShadowFooter} from "./templates/no_shadow_footer";
import {NoShadowHeader} from "./templates/no_shadow_header";
import {nestFlexStyle} from "../../../components/container";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {FloatButtonFooter} from "./templates/float_button_footer";


const useStyles = makeStyles((theme) => ({
    viewport: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    siteContainer: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(444px + 1rem)'
        },
        marginBottom: '5vh'
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    staySignedIn: {
        fontSize: theme.typography.fontSize * 0.9,
        color: theme.palette.text.secondary
    },
    links: {
        fontSize: theme.typography.fontSize * 0.85
    }
}));

export const FloatButtonDemo = (props) => {
    const classes = useStyles();
    const [showCta, setShow] = useState(false);

    return (
        <div>
            <NoShadowHeader{...props} />
            <Container className={classes.siteContainer}>
                <Box style={nestFlexStyle} position="relative">
                    <Typography component="h1" variant="h5" gutterBottom>
                        Hello! Get started with your information
                    </Typography>
                    <form className={classes.form} style={nestFlexStyle}>
                        <TextField
                            id="fullName"
                            name="fullName"
                            label="Full Name"
                            placeholder="Full Name"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="preferredName"
                            name="preferredName"
                            label="Display Name"
                            placeholder="Display Name"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email address"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="phoneNo"
                            name="phoneNo"
                            label="Phone Number"
                            placeholder="Phone Number"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="hkid"
                            name="hkid"
                            label="HKID"
                            placeholder="HKID"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="dob"
                            name="dob"
                            label="Date of Birth (DD/MM/YY)"
                            placeholder="Date of Birth (DD/MM/YY)"
                            fullWidth
                            margin="normal"
                        />
                    </form>
                </Box>
            </Container>
            <FloatButtonFooter>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShow(prev => !prev)}
                        >
                            Click to see 2 CTA
                        </Button>
                    </Grid>
                    {showCta && <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Sign Up
                        </Button>
                    </Grid> }
                </Grid>
            </FloatButtonFooter>
        </div>
    );
}


