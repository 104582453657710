import SvgIcon from '@material-ui/core/SvgIcon';

function RecordsFilledIcon() {
    return (
        <SvgIcon>
            <path
                d="M14.25 2H9.75C9.33579 2 9 2.44772 9 3V5C9 5.55228 9.33579 6 9.75 6H14.25C14.6642 6 15 5.55228 15 5V3C15 2.44772 14.6642 2 14.25 2Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00497 5.37469V3.65033H6.74999C6.06043 3.65033 5.39911 3.92426 4.91152 4.41185C4.42392 4.89945 4.14999 5.56077 4.14999 6.25033V18.5003C4.14999 19.1899 4.42392 19.8512 4.91152 20.3388C5.39911 20.8264 6.06043 21.1003 6.74999 21.1003H17.25C17.9396 21.1003 18.6009 20.8264 19.0885 20.3388C19.5761 19.8512 19.85 19.1899 19.85 18.5003V6.25033C19.85 5.56077 19.5761 4.89945 19.0885 4.41185C18.6009 3.92426 17.9396 3.65033 17.25 3.65033H16.005V5.37469C16.005 6.13409 15.3894 6.74969 14.63 6.74969H9.37997C8.62058 6.74969 8.00497 6.13409 8.00497 5.37469ZM14.0884 8.34467C13.9477 8.20402 13.7569 8.125 13.558 8.125C13.3591 8.125 13.1683 8.20402 13.0277 8.34467L7.97214 13.4002C7.83149 13.5409 7.75247 13.7316 7.75247 13.9306V15.875C7.75247 16.2892 8.08826 16.625 8.50247 16.625H10.4469C10.6458 16.625 10.8366 16.546 10.9772 16.4053L16.0328 11.3498C16.3257 11.0569 16.3257 10.582 16.0328 10.2891L14.0884 8.34467ZM9.25247 15.125V14.2412L13.558 9.93566L14.4418 10.8194L10.1363 15.125H9.25247Z"
            />
        </SvgIcon>
    );
}

export default RecordsFilledIcon
