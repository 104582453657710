import { axiosAuthedInstance } from "../../utils/axiosApi";
import axios from 'axios'
import { HTTP_STATUS } from "../../utils/constants";


export default function LongPollDemo(props) {

    // For cancel request
    let cancelTokenSource;

    var startLongPoll = async () => {
        // get source each time
        cancelTokenSource = axios.CancelToken.source();
        await axiosAuthedInstance.get('/user/dependants/share/token/status/', {cancelToken: cancelTokenSource.token})
            .then(response => {
            // TODO: the polling response
                console.log(response.data)
                if (response.data['status'] === "FAIL") {
                    startLongPoll()
                }
            })
            .catch(err => {
            // TODO: retry flow
                console.log(err)
                if (err.response.status === HTTP_STATUS.CLIENT_CLOSED_REQUEST) {
                    console.log("polling stopped by cancel")
                    return;
                }

                // TODO: avoid keep calling when server down e.g. 404, 500, 503
                if (err.message === "Network Error") {
                    setTimeout(() => { startLongPoll() }, 10000);
                    return;
                }
                console.log(err.code);
                console.log(err.message);
                console.log(err.stack);
                startLongPoll()
            });
    };

    // cancel the request
    function localCancelPolling() {
        cancelTokenSource.cancel();
    }

    return (
        <div> 
            <button onClick={startLongPoll}>Start Long Poll</button>
            <button onClick={localCancelPolling}>Stop Long Poll</button>
        </div>
    )
    
}



// Backend example
// path('user/dependants/share/<str:token>/status', api_views.get_sharing_status, name='get_sharing_status'),

//  @api_view(('GET',))
//  @permission_classes([AllowAny])
//  @authentication_classes([])
//  @renderer_classes((JSONRenderer,))
//  def get_sharing_status(request, token):
//      # TODO: keep query the status during a period
//      for i in range(5):
//          temp = randrange(10000)
//          if temp % 10 == 0:
//              return Response(status=status.HTTP_200_OK, data={"token": token, "status": "DONE"})
//          else:
//              logger.warning("get_sharing_status: i =" + temp.__str__())
//              time.sleep(1)

//      return Response(status=status.HTTP_200_OK, data={"token": token, "status": "FAIL"})



