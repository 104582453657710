import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Typography from "./typography";

const useStyles = makeStyles<Theme, {
    color: ChipProps['color']
}>((theme) => createStyles({
    chip: {
        borderRadius: '4px',
        padding: theme.spacing(0.5),
        textTransform: 'lowercase',
        backgroundColor: (({color}) => {
            switch (color) {
                case 'info':
                    return theme.palette.info.light
                case 'success':
                    return theme.palette.success.light
                case 'warn':
                    return theme.palette.warning.light
                case 'error':
                    return theme.palette.error.light
                case 'neutral':
                    return theme.palette.grey[200]
            }
        }),
        color: (({color}) => {
            switch (color) {
                case 'info':
                    return theme.palette.info.main
                case 'success':
                    return theme.palette.success.main
                case 'warn':
                    return theme.palette.grey[800]
                case 'error':
                    return theme.palette.error.main
                case 'neutral':
                    return theme.palette.grey[800]
            }
        })

    },
}))

type ChipProps = {
    label: string
    color?: 'info' | 'success' | 'warn' | 'error' |  'neutral'
}

export const Chip = ({ label, color }: ChipProps) => {
    if (!color) color = 'success'

    const classes = useStyles({color})

    return (
        <Typography className={classes.chip} component="span" variant="bodyTextSmall">
            {label}
        </Typography>
    )
}
