import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TrashIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <g stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path strokeWidth="2" d="M18 6v14.25c0 .2-.07.39-.2.53a.69.69 0 0 1-.5.22H6.7a.69.69 0 0 1-.5-.22.77.77 0 0 1-.2-.53V6m2.63-1.08V3.25c0-.22.07-.43.21-.59a.71.71 0 0 1 .54-.24h5.24c.2 0 .4.08.54.24.14.16.21.37.21.59v1.67m-11.99 1h17.25"/>
                <path strokeWidth="1.6" d="M9 11v7m3-9v9m3-7v7"/>
            </g>
        </SvgIcon>
    )
}

export default TrashIcon
