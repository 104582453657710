import { FormGroup } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "../../components/button"
import { BackButton } from "../../components/buttons/back_btn"
import CheckboxCard from "../../components/checkbox_card"
import { BaseContainer } from "../../components/container"
import ActionDialog from "../../components/dialogs/action_dialog"
import ErrorDialog from "../../components/dialogs/error_dialog"
import Loading from "../../components/loading"
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import Typography from "../../components/typography"
import { axiosAuthedInstance } from "../../utils/axiosApi"
import { possessiveCheck } from "../../utils/common"
import { isCancel } from "axios"

const ShareConfirm = (props) => {
    const history = useHistory()
    const [selected, setSelected] = useState([])
    const [error, setError] = useState(null)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showDecline, setShowDecline] = useState(false)
    const [dependants, setDependants] = useState([])
    const [sharer, setSharer] = useState('')
    const token = props.location.state?.token

    useEffect(() => {
        getInviteInfo()
    }, [])

    const getInviteInfo = () => {
        setLoading(true)
        axiosAuthedInstance.get(`/user/sharing-invite/${token}/`)
            .then(res => {
                setDependants(res.data.dependants)
                setSelected(res.data.dependants.map(dep => dep.uuid))
                setSharer(res.data.sharer)
                setLoading(false)
            }).catch(err => {
                if (isCancel(err)) return

                let msg = 'Unable to accept sharing invite, please try again later.'
                if (err.response?.data?.error) {
                    console.error(err)
                    msg = err.response.data.error
                }
                setLoading(false)
                setError(msg)
                setShowError(true)
            })
    }

    const addToSharing = (event) => {
        const dependant = event.target.value
        const checked = event.target.checked
        if (checked) {
            setSelected(old => [...old, dependant])
        } else {
            setSelected(old => {
                const newArr = old.filter(dep => dep !== dependant)
                return newArr
            })
        }
    }

    const acceptSharingInvite = () => {
        setLoading(true)
        axiosAuthedInstance.post(`/user/accept-sharing-invite/${token}/`, {
            'accepted_dependants': selected
        }).then(() => {
            history.replace('/sharing')
        }).catch((err) => {
            let msg = 'Unable to accept sharing invite, please try again later.'
            if (err.response?.data?.error) {
                console.error(err)
                msg = err.response.data.error
            }
            setLoading(false)
            setError(msg)
            setShowError(true)
        })
    }

    const declineSharingInvite = () => {
        setLoading(true)
        axiosAuthedInstance.post(`/user/decline-sharing-invite/${token}/`)
            .then(() => {
                history.replace('/')
            }).catch((err) => {
                let msg = 'Unable to decline sharing invite, please try again later.'
                if (err.response?.data?.error) {
                    msg = err.response.data.error
                    console.error(error)
                }
                setLoading(false)
                setError(msg)
                setShowError(true)
            })

    }

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth disabled={!selected.length} onClick={acceptSharingInvite}>
                Accept
            </Button>
            <Button fullWidth onClick={() => setShowDecline(true)}>
                Decline
            </Button>
        </StickyFooterGroup>}>

            {loading && <Loading loading={loading}/>}

            <BackButton onClick={() => history.replace('/')}/>

            <HeaderTitle>
                Invitation to View Records
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    {sharer} has shared access to view immune health records for the following profiles
                </Typography>
            </PageDescription>
            <FormGroup>
                {dependants.map(dep => (
                    <CheckboxCard
                        key={dep.uuid}
                        label={dep.full_name}
                        value={dep.uuid}
                        onChange={addToSharing}
                        default
                    />
                ))}
            </FormGroup>

            <ErrorDialog open={showError} error={error} setOpen={setShowError} />
            <ActionDialog
                cancel
                action_callback={declineSharingInvite}
                title={`Decline ${possessiveCheck(sharer)} invite?`}
                content={`You will have to ask ${sharer} to send a new invite.`}
                action_label="Decline"
                openDialog={showDecline}
                setOpenDialog={setShowDecline}
            />


        </BaseContainer>
    )
}

export default ShareConfirm
