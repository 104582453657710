import SvgIcon from '@material-ui/core/SvgIcon';

function HomeFilledIcon() {
    return (
        <SvgIcon>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3L3 10.7143V19.7143C3 20.4244 3.57563 21 4.28571 21H9.42857V17.1429C9.42857 15.7227 10.5798 14.5714 12 14.5714C13.4202 14.5714 14.5714 15.7227 14.5714 17.1429V21H19.7143C20.4244 21 21 20.4244 21 19.7143V10.7143L12 3Z"
            />
            <path d="M12 3L12.6508 2.24074C12.2763 1.91975 11.7237 1.91975 11.3492 2.24074L12 3ZM3 10.7143L2.34921 9.95503L2 10.2544V10.7143H3ZM9.42857 21V22C9.98086 22 10.4286 21.5523 10.4286 21H9.42857ZM14.5714 21H13.5714C13.5714 21.5523 14.0191 22 14.5714 22V21ZM21 10.7143H22V10.2544L21.6508 9.95503L21 10.7143ZM4.28571 22H9.42857V20H4.28571V22ZM21.6508 9.95503L12.6508 2.24074L11.3492 3.75926L20.3492 11.4735L21.6508 9.95503ZM11.3492 2.24074L2.34921 9.95503L3.65079 11.4735L12.6508 3.75926L11.3492 2.24074ZM10.4286 21V17.1429H8.42857V21H10.4286ZM13.5714 17.1429V21H15.5714V17.1429H13.5714ZM14.5714 22H19.7143V20H14.5714V22ZM22 19.7143V10.7143H20V19.7143H22ZM2 10.7143V19.7143H4V10.7143H2ZM12 15.5714C12.8679 15.5714 13.5714 16.275 13.5714 17.1429H15.5714C15.5714 15.1704 13.9724 13.5714 12 13.5714V15.5714ZM12 13.5714C10.0276 13.5714 8.42857 15.1704 8.42857 17.1429H10.4286C10.4286 16.275 11.1321 15.5714 12 15.5714V13.5714ZM19.7143 22C20.9767 22 22 20.9767 22 19.7143H20C20 19.8721 19.8721 20 19.7143 20V22ZM4.28571 20C4.12792 20 4 19.8721 4 19.7143H2C2 20.9766 3.02335 22 4.28571 22V20Z" />
        </SvgIcon>
    )
}

export default HomeFilledIcon
