import SvgIcon from '@material-ui/core/SvgIcon';

function DisconnectIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 19" {...props}>
            <path d="M8.0384 14.2185H7.83101C5.23174 14.2185 3.01683 12.1978 3.06108 9.73093C3.08281 8.57415 3.58194 7.47165 4.45116 6.66045C5.32038 5.84925 6.49028 5.39414 7.70934 5.39294H11.5806C11.6196 5.3929 11.6581 5.40209 11.6925 5.41969C11.7268 5.43729 11.756 5.46273 11.7774 5.49373C11.7988 5.52472 11.8118 5.56029 11.8151 5.59722C11.8184 5.63415 11.8119 5.67127 11.7963 5.70524L11.1769 7.08562C11.1593 7.12614 11.1294 7.16076 11.091 7.18507C11.0525 7.20937 11.0074 7.22226 10.9612 7.22209H7.70934C7.00454 7.24344 6.33612 7.52417 5.84551 8.00489C5.3549 8.48561 5.08058 9.1286 5.08058 9.79785C5.08058 10.4671 5.3549 11.1101 5.84551 11.5908C6.33612 12.0715 7.00454 12.3523 7.70934 12.3736H8.6661C8.70466 12.3746 8.74238 12.3845 8.776 12.4025C8.80963 12.4204 8.83815 12.4459 8.85911 12.4766C8.88006 12.5073 8.89283 12.5425 8.8963 12.5789C8.89976 12.6154 8.89383 12.6521 8.87902 12.6859L8.25409 14.0637C8.23967 14.1076 8.21112 14.1462 8.17244 14.1739C8.13375 14.2017 8.08688 14.2173 8.0384 14.2185Z" fill={props.strokecolor || "#333"}/>
            <path d="M16.3477 14.2186H12.6783C12.6394 14.2188 12.601 14.21 12.5666 14.1927C12.5321 14.1754 12.5028 14.1503 12.4811 14.1196C12.4595 14.0889 12.4462 14.0536 12.4425 14.0168C12.4388 13.98 12.4447 13.9429 12.4598 13.9089L13.0378 12.5259C13.0547 12.484 13.0846 12.4479 13.1237 12.4226C13.1627 12.3973 13.2089 12.3839 13.2562 12.3842H16.4694C16.8414 12.3839 17.2094 12.3111 17.5506 12.1703C17.8917 12.0294 18.1987 11.8235 18.4526 11.5654C18.7064 11.3073 18.9016 11.0024 19.0262 10.6697C19.1507 10.337 19.2019 9.98352 19.1765 9.63126C19.1163 8.96915 18.7938 8.35335 18.2739 7.90816C17.754 7.46297 17.0755 7.22155 16.3753 7.23265H15.5596C15.5214 7.23258 15.4837 7.22367 15.45 7.20668C15.4162 7.18969 15.3873 7.16513 15.3657 7.13514C15.3442 7.10515 15.3307 7.07063 15.3265 7.03457C15.3222 6.99852 15.3272 6.96201 15.3412 6.92823L15.9274 5.54259C15.9445 5.5019 15.9738 5.46683 16.0116 5.44167C16.0494 5.4165 16.0941 5.40233 16.1403 5.40088H16.4694C17.687 5.40203 18.8557 5.85606 19.7247 6.66557C20.5937 7.47508 21.0938 8.57559 21.1176 9.73099C21.1619 12.1978 18.947 14.2186 16.3477 14.2186Z" fill={props.strokecolor || "#333"}/>
            <path d="M9.58412 16.8691C9.46223 16.8666 9.342 16.8417 9.23018 16.7956C9.11103 16.7523 9.00209 16.6871 8.90967 16.6036C8.81725 16.5202 8.74319 16.4201 8.69176 16.3094C8.64033 16.1986 8.61256 16.0792 8.61005 15.9581C8.60754 15.8371 8.63034 15.7168 8.67714 15.6041L13.7651 3.35386C13.8102 3.23964 13.8788 3.13509 13.9669 3.04631C14.055 2.95753 14.1609 2.8863 14.2784 2.83677C14.3958 2.78724 14.5225 2.7604 14.651 2.75783C14.7795 2.75526 14.9072 2.77701 15.0267 2.82179C15.1463 2.86658 15.2552 2.93352 15.3472 3.0187C15.4392 3.10387 15.5124 3.20559 15.5625 3.3179C15.6127 3.43021 15.6387 3.55088 15.6392 3.67285C15.6396 3.79482 15.6145 3.91566 15.5652 4.02831L10.4856 16.2865C10.4141 16.4581 10.29 16.6054 10.1292 16.7093C9.96849 16.8132 9.77858 16.8688 9.58412 16.8691Z" fill={props.strokecolor || "#333"}/>
        </SvgIcon>
    );
}

export default DisconnectIcon