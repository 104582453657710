import { Fragment } from "react"
import { Button } from "../../components/button"
import { Chip } from "../../components/chip"
import { BaseContainer } from "../../components/container"
import { InfoPanel } from "../../components/info_panel"
import ListItem from "../../components/lists/list_item"
import { HeaderTitle, PageDescription } from "../../components/page_sections"
import { StickyFooterGroup } from "../../components/sticky_footer_group"
import { formatDate } from "../../utils/common"
import { Divider } from "../../components/divider"
import { VaccineEventKey, VaccineRecord, vaccineEventName } from "../records/utils"

type SuccessProps = {
    addedRecords: VaccineRecord[]
    doseCount: number
    newRecordKeys: VaccineEventKey[]
    isDependant: boolean
    exitWizard: () => void
}

export const Success = ({
    exitWizard,
    doseCount,
    addedRecords,
    newRecordKeys,
    isDependant,
}: SuccessProps) => {
    const newKeys = new Set(newRecordKeys)

    let duplicateStatus: "NONE" | "SOME" | "ALL"
    if (newKeys.size === 0) {
        duplicateStatus = "ALL"
    } else if (newKeys.size === addedRecords.length) {
        duplicateStatus = "NONE"
    } else {
        duplicateStatus = "SOME"
    }

    const addedRecordsList = addedRecords.map((ve, index) => {
        const key: VaccineEventKey = `${ve.generic},${ve.vaccine_date}`
        const isNew = newKeys.has(key)

        return (
            <Fragment key={key}>
                <ListItem
                    title={formatDate(ve.vaccine_date)}
                    // @ts-expect-error: no idea what combination of event/source record these are
                    subtitle={vaccineEventName(ve)}
                    extra={isNew && <Chip label="new" />}
                />

                {index + 1 < addedRecords.length && <Divider/>}
            </Fragment>
        )
    })

    const isPartialRecord = addedRecords.length !== doseCount
    let partialRecordNotice = null
    if (isPartialRecord) {
        partialRecordNotice = <InfoPanel severity="warning" title="Partial HK Covid-19 record">
            <p>
                This Hong Kong Covid-19 certificate indicates that {isDependant ? "your dependant has" : "you have"}  received {doseCount} doses.

                The certificate only contains details for the most recent {addedRecords.length} doses, so you may not see all of the doses listed on the records page.
            </p>
            <p>
                {isDependant ? "Their" : "Your"} vaccination status will be calculated on the basis that at least {doseCount} doses have been taken.
            </p>
        </InfoPanel>
    }

    let duplicateNotice = null
    switch (duplicateStatus) {
        case "SOME":
            duplicateNotice = <>
                Some of the doses already exist on {isDependant ? "this" : "your"} profile. This record has been added as an additional source for these.
            </>
            break
        case "ALL":
            duplicateNotice = <>
                All of the doses already exist on {isDependant ? "this" : "your"} profile. This record has been added as an additional source for these.
            </>
            break
    }

    return (
        <BaseContainer showCta extraComponent={
            <StickyFooterGroup>
                <Button primary onClick={exitWizard}>
                    OK
                </Button>
            </StickyFooterGroup>
        }>
            <HeaderTitle>
                Record added
            </HeaderTitle>

            <PageDescription>
                The record you added contains information on the following doses:
            </PageDescription>

            <PageDescription>
                {addedRecordsList}
            </PageDescription>

            <PageDescription>
                {duplicateNotice}
            </PageDescription>

            {partialRecordNotice}
        </BaseContainer>
    )
}
