import { makeStyles } from "@material-ui/core"
import Logo from '../assets/img/imunis-logo.svg'
import { Transition } from 'react-transition-group'
import imunisTheme from '../styles/theme'
import { cx } from '../utils/common'

export const backgroundStyle = {
    '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundImage: 'linear-gradient(90deg, #fbd20280, #ff530080)',
        WebkitMaskImage: 'linear-gradient(#000 -100%, transparent 100%)',
        maskImage: 'linear-gradient(#000 -100%, transparent 100%)',
        zIndex: -1
    }
}

// const duration = imunisTheme.transitions.duration.short;
const duration = imunisTheme.transitions.duration.short;
const useStyles = makeStyles((theme) => ({
    splash: {
        transition: `opacity ${duration}ms`,
        zIndex: 2,
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        opacity: 0,
    },
    splashBg: backgroundStyle
}))

const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:   { opacity: 0 },
};

export const Splash = ({visible, noBackground}) => {
    const classes = useStyles()

    return (
        <Transition in={visible} timeout={duration} unmountOnExit>
            {state => (
                <div className={cx(classes.splash, !noBackground && classes.splashBg)} style={{...transitionStyles[state]}}>
                    <img width="183px" src={Logo}/>
                </div>
            )}
        </Transition>
    )
}
