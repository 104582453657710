import SvgIcon from '@material-ui/core/SvgIcon';

function CalendarIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M8 2V6.5M16 2V6.5M4 9H20M7 13H9M7 16H9M11 13H13M11 16H13M15 13H17M5.33333 20H18.6667C19.403 20 20 19.449 20 18.7692V4H4V18.7692C4 19.449 4.59695 20 5.33333 20Z"/>
            </g>
        </SvgIcon>
    )
}

export default CalendarIcon
