import Typography from '../typography'
import { makeStyles, Theme, createStyles} from "@material-ui/core"
import { Divider } from '../divider'
import { TwoLineText } from '../two_line_text'
import { cx } from '../../utils/common'
import { SvgIconComponent } from '@material-ui/icons'

const useStyles = makeStyles<Theme, ListItemProps>((theme) => createStyles({
    listItem: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto min-content',
        width: '100%',
        alignItems: 'center',
        gap: ({ extra }) => extra ? theme.spacing(1) : 0,
        cursor: props => { if (props.onClick) return 'pointer' },
    },
    content: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    subtitle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    highlight: {
        position: 'relative',
        "&::before": {
            content: "''",
            display: "block",
            position: 'absolute',
            width: "100%",
            height: "100%",
            zIndex: -1,
            backgroundColor: ({ highlight }) => {
                switch (highlight) {
                    case 'neutral':
                        return theme.palette.grey[200]
                    case 'success':
                        return theme.palette.success.light
                    case 'error':
                        return theme.palette.error.light
                }
            },
            // The highlight is extended beyond the dimensions of the list item.
            // These values are coupled to:
            // 1.5: Top/bottom margin of the <Divider>
            // 3  : Left/right padding of the global site container
            // 1px: Height of the divider
            boxSizing: 'content-box',
            padding: `${theme.spacing(1.5) + 1}px ${theme.spacing(3)}px`,
            left: `-${theme.spacing(3)}px`
        }
    },
}))

export type ListItemProps = {
    title?: React.ReactNode,
    subtitle?: React.ReactNode,

    /** If `title` is not provided, children must be */
    children?: React.ReactNode,

    /** Content to place on the right. Typically an Icon */
    extra?: React.ReactNode,
    divider?: boolean,

    /**
     * Sets a background highlight on the list item
     */
    highlight?: 'success' | 'neutral' | 'error'

    onClick?: React.MouseEventHandler

    /** For customised styling, define a classname and provide it */
    className?: string
}

export const ListItem = (props: ListItemProps) => {
    const classes = useStyles(props)
    let content

    if (props.children) {
        content = props.children
    } else {
        content = <div className={classes.content}>
            {props.title &&
                <TwoLineText>
                    <Typography variant="nameLabelMedium" className="card-title">
                        {props.title}
                    </Typography>
                </TwoLineText>}
            {props.subtitle &&
                <Typography variant="bodyTextMedium" className={cx("card-subtitle", classes.subtitle)} >
                    {props.subtitle}
                </Typography>
            }
        </div>
    }

    return <>
        <div
            className={cx(classes.listItem, props.className, props.highlight && classes.highlight)}
            onClick={props.onClick}
            {...(props.highlight && {'aria-current':true })}
        >
            {content}
            {props.extra}
        </div>
        {props.divider && <Divider />}
    </>
}

const useIconStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        color: theme.palette.grey[800],
        fontSize: '24px'
    },
}))

export const ListItemIcon = ({ icon: Icon }: { icon?: SvgIconComponent }) => {
    const classes = useIconStyles();
    if (!Icon) return null

    return <Icon classes={{ root: classes.root }} />
}

export default ListItem;
