import { TextField, makeStyles, Theme, createStyles, TextFieldProps} from "@material-ui/core"
import { useEffect, useState } from "react"
import { isValidHKID, isValidPassport } from '../utils/common'
import { TextButton } from "./button"
import { HKIDField } from './hkid_field'

/** Validates an HKID input. Returns a falsy value if valid, or an error string. */
export function validateHKID(value: string) {
    if (!value) return

    if (!isValidHKID(value)) {
        return 'Invalid HKID'
    }
}

/** Validates a passport input. Returns a falsy value if valid, or an error string. */
export function validatePassport(value: string) {
    if (!value) return

    if (!isValidPassport(value)) {
        return "Invalid Passport"
    }
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    labelButton: {
        marginBottom: theme.spacing(1),
    }
}))

type InputType = 'PASSPORT' | 'HKID'
type IdentityNumberFieldProps = {
    /** The initial type of input selected. Defaults to HKID. */
    idType: InputType,

    /** Callback fired when the value is changed. */
    onChange: (idType: InputType, value: string, error: string | false) => void
} & TextFieldProps

const sanitiseInputType = (v: string): InputType => {
    if (v === 'PASSPORT' || v === 'HKID') return v
    else return 'HKID'
}

export const IdentityNumberField = (props: IdentityNumberFieldProps) => {
    const {idType, defaultValue, onChange, ...rest} = props
    const classes = useStyles()
    const [error, setError] = useState<string | false>(false)
    const [inputType, setInputType] = useState(sanitiseInputType(idType))
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")

    // Update state when props change
    useEffect(() => {
        setInputType(sanitiseInputType(idType))
    }, [idType])

    useEffect(() => {
        setValue(String(defaultValue || ""))
    }, [defaultValue])

    const inputSwitcherLabel = inputType === 'PASSPORT' ?
        'Use HKID instead' : 'No HKID? Use passport instead'

    const validate = (inputType: InputType, value: string) => {
        const validationFn = inputType === 'PASSPORT' ? validatePassport : validateHKID
        const error = validationFn(value) || false
        setError(error)
        return error
    }

    const inputTypeToggle = () => {
        const newInputType = inputType === 'PASSPORT' ? 'HKID' : 'PASSPORT'
        setOtherValue(value)
        setValue(otherValue)
        setInputType(newInputType)

        const error = validate(newInputType, otherValue)

        // <HKIDField> triggers its onChange on mount, so do the same with the Passport's <TextField>
        // This allows parent component to have a consistent state.
        if (newInputType === 'PASSPORT') {
            if (onChange) onChange(newInputType, otherValue, error)
        }
    }

    const _onChange = (value: string) => {
        if (inputType === 'PASSPORT') value = value.toUpperCase()

        setValue(value)

        const error = validate(inputType, value)

        if (onChange) onChange(inputType, value, error)
    }

    const inputProps = {
        ...rest,
        value,
        error: !!error,
        helperText: error || "Used for records matching & identification.",
    }

    const inputComponent = inputType === 'PASSPORT' ? (
        <TextField
            id="id_number"
            label="Passport"
            placeholder="Passport"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                _onChange(e.target.value)
            }}
            {...inputProps}
        />
    ) : (
        <HKIDField
            id="id_number"
            label="HKID"
            placeholder="HKID"
            onChange={_onChange}
            {...inputProps}
        />
    )

    return <>
        {inputComponent}
        <TextButton primary className={classes.labelButton} onClick={inputTypeToggle}>
            {inputSwitcherLabel}
        </TextButton>
    </>
}
