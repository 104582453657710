import { BaseContainer } from "../../components/container";
import { Button } from '../../components/button';
import Typography from '../../components/typography';

const TestGenerateError = (props) => {
    const errorFns = [
        () => { throw "fail 1" },
        () => { console.error("fail 2") },
        () => { new Promise(() => { throw "fail 3" }) },
        () => { throw new Error("fail 4") },
        () => { new Promise(() => { throw new Error("fail 5") }) }
    ]

    return (
        <BaseContainer {...props}>
            <Typography variant="pageHeader">
                Error Generator
            </Typography>

            <table>
                <tbody>
                    {errorFns.map((fn, i) => (
                        <tr key={i}>
                            <td>
                                <Button onClick={fn}>
                                    exec
                                </Button>
                            </td>
                            <td>
                                <pre>{fn.toString()}</pre>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </BaseContainer>
    )
}
export default TestGenerateError;
