import { SvgIcon, SvgIconProps } from "@material-ui/core";
import theme from '../../styles/theme';

function ExclamationTriangleSolid(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M9.33052 3.51399L2.60381 16.6311C1.58005 18.6274 3.02973 21 5.27326 21H18.7267C20.9702 21 22.4199 18.6274 21.3961 16.6311L14.6694 3.51398C13.5542 1.33925 10.4458 1.33925 9.33052 3.51399Z" fill={theme.palette.error.light}/>
            <rect x="11" y="16" width="2" height="2" fill={theme.palette.error.main}/>
            <rect x="11" y="8" width="2" height="6" fill={theme.palette.error.main}/>
        </SvgIcon>
    )
}

export default ExclamationTriangleSolid
