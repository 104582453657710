import { BaseContainer } from "../../components/container"
import { PageTitle } from "../../components/page_sections"
import SearchDotIcon from "../../assets/icons/search_dot"
import Typography from "../../components/typography"
import Card from "../../components/card"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"

const Discover = () => {
    const history = useHistory()

    return (
        <BaseContainer showNav discover>
            <PageTitle icon={<SearchDotIcon/>}>
                Discover
            </PageTitle>

            <Box mb={3}>
                <Typography variant="bodyTextMedium">
                    Here you can find additional information on vaccinations and health
                </Typography>
            </Box>

            <Card title="Hong Kong Vaccine Schedule" onClick={() => {
                history.push('/discover/schedule')
            }}/>
        </BaseContainer>
    )
}

export default Discover
