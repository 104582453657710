import { useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { spacing, sizing, flexbox, display, positions } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '../../components/button';
import Typography from "../../components/typography"
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse';
import Alert from '../../components/alert';
import { axiosAuthedInstance } from "../../utils/axiosApi";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from '@material-ui/icons/Close';
import {BaseContainer} from '../../components/container'
import CTAButton from "../../components/buttons/cta";
import {validateName } from '../../components/text_input'
import { DatePicker, toISOString } from "../../components/date_picker";
import { default as SelectInput, validateRequired } from "../../components/select_input"
import MenuItem from '@material-ui/core/MenuItem'
import { IdentityNumberField } from '../../components/identity_number_field';


const Box = styled.div`${spacing}${sizing}${display}${flexbox}${positions}`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1, 0, 0)
    },
    fixed_bottom: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        transform: "translateX(-1.5rem)",
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(444px + 1rem)`
        },
    },
    backBtn: {
        marginLeft: '-16px',
    },
}))

const AddDependants = (props) => {
    const [state, setState] = useState({ name: '', preferredName: '', gender: '', idType: 'HKID', hkid: '', passport: ''})
    const [errorMsgs, setErrorMsgs] = useState({ name: '', preferredName: '', gender: '', idNumber: ''})
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dob, handleDateChange] = useState(null)
    const [dobError, setDobError] = useState('')
    const classes = useStyles()
    const history = useHistory()

    function handleChange(event, type) {
        let error
        const text = event.target.value
        switch (type) {
            case 'name':
                error = validateName(text)
                break;
            case 'gender':
                error = validateRequired(text, "gender")
                break
            default:
                break;
        }
        setErrorMsgs(prevState => ({...prevState, [event.target.name]: error}))
        setState(prevState => ({...prevState, [event.target.name]: text}))
    }

    async function handleSubmit(e) {
        if (e) e.preventDefault()

        setLoading(true)
        try {
            await axiosAuthedInstance.post('/user/dependants/add/', {
                full_name: state.name,
                preferred_name: state.preferredName,
                dob: toISOString(dob),
                gender: state.gender,
                id_type: state.idType.toLowerCase(),
                id_number: state.idNumber,
            });

            history.goBack()
        } catch (error) {
            console.error(error)
            setError('Error Creating a Dependant');
            setShowError(true)
        } finally {
            setLoading(false)
        }
    }

    const onIdNumberChange = (idType, value, error) => {
        setState(prevState => ({
            ...prevState,
            idType,
            idNumber: value
        }))
        setErrorMsgs(prevState => ({
            ...prevState,
            idNumber: error
        }))
    }

    const {name, preferredName, gender, idType, idNumber} = state
    const validated = !(errorMsgs.name || errorMsgs.preferredName || errorMsgs.gender || errorMsgs.idNumber || dobError)
    const enabled = name && preferredName && gender && dob && validated
    return (
        <BaseContainer {...props} noHeader showCta
            extraComponent={
                <Box display="flex" flexDirection="column" width="100%" alignItems="center">
                    <CTAButton fullWidth variant="contained" color="primary"
                        type="submit"
                        form="add-dependant"
                        disabled={!enabled}
                        loading={loading}
                    >
                        Add Dependant
                    </CTAButton>
                    <CTAButton fullWidth onClick={() => history.goBack()}>
                        Cancel
                    </CTAButton>
                </Box>
            }>
            <IconButton className={classes.backBtn} onClick={() => history.goBack()}>
                <ChevronLeftIcon/>
            </IconButton>
            <Typography variant="onboardingHeader" gutterBottom>
                Add a dependant
            </Typography>
            <Collapse in={showError}>
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowError(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {error}
                </Alert>
            </Collapse>
            <form id="add-dependant" onSubmit={handleSubmit}>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    fullWidth
                    error={!!errorMsgs.name}
                    helperText={errorMsgs.name || 'Use name and format on dependant’s HKID/ID document.'}
                    margin="normal"
                    value={name}
                    onChange={(event) => handleChange(event, 'name')}
                    required
                />
                <TextField
                    id="preferredName"
                    name="preferredName"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    error={!!errorMsgs.preferredName}
                    helperText={errorMsgs.preferredName || 'How we refer to your dependant - only in the Imunis App.'}
                    margin="normal"
                    value={preferredName}
                    onChange={(event) => handleChange(event, 'name')}
                    required
                />
                <IdentityNumberField
                    fullWidth
                    margin="normal"
                    defaultValue={idNumber}
                    idType={idType}
                    onChange={onIdNumberChange}
                    error={errorMsgs.idNumber}
                />
                <SelectInput
                    name="gender"
                    label="Gender"
                    fullWidth
                    margin="normal"
                    value={gender}
                    onChange={(event) => handleChange(event, 'gender')}
                    error={!!errorMsgs.gender}
                    helperText={errorMsgs.gender}
                    required
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                </SelectInput>
                <DatePicker
                    disableFuture
                    fullWidth
                    id="dob"
                    name="dob"
                    margin="normal"
                    openTo="year"
                    label="Date of birth"
                    views={["year", "month", "date"]}
                    placeholder={'dd/MMM/yyy'}
                    value={dob}
                    onError={(err) => setDobError(err)}
                    helperText={"Information is used for records matching and vaccination status. This cannot be changed once entered."}
                    onChange={handleDateChange}
                    required
                />
            </form>
        </BaseContainer>
    )
}

export default AddDependants
