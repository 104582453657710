import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ExclamationCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path fill="currentColor" d="M11 7v6h2V7h-2zm0 9h2-2zm0-1v2h2v-2h-2z" />
            <circle cx="12" cy="12" r="9" fill="none" stroke="currentColor" strokeWidth="2" />
        </SvgIcon>
    )
}
