import SvgIcon from '@material-ui/core/SvgIcon';

const ImunisSymbol = (props) => (
    <SvgIcon viewBox="0 0 176 176" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" fill="none">
        <path id="a" d="M72.4 136.3c-2.5 2.5-3.8 5.5-3.8 9.1s1.2 6.6 3.8 9.1c2.5 2.5 5.5 3.8 9.1 3.8s6.6-1.2 9.1-3.8c2.5-2.5 3.8-5.5 3.8-9.1s-1.2-6.5-3.8-9.1c-2.5-2.5-5.5-3.8-9.1-3.8s-6.6 1.3-9.1 3.8m3.2-29.7a7.63 7.63 0 0 0-2.3 5.6 7.87 7.87 0 0 0 7.9 7.9 7.87 7.87 0 0 0 7.9-7.9c0-2.1-.8-4.1-2.3-5.6s-3.4-2.4-5.6-2.4-4 .8-5.6 2.4m-51.1-1.7c-3.1 1.7-5.1 4.3-6 7.7-.9 3.4-.6 6.7 1.2 9.7s4.3 5.1 7.7 6c3.4 1 6.7.5 9.7-1.2s5.1-4.3 6-7.7c.9-3.4.6-6.7-1.2-9.7s-4.3-5.1-7.7-6c-1.2-.3-2.4-.5-3.5-.5-2.2 0-4.2.6-6.2 1.7m104.1-1.1c-3.4 1-6 3-7.7 6-1.7 3.1-2.1 6.3-1.2 9.7 1 3.4 3 6 6 7.7 3.1 1.7 6.3 2.1 9.7 1.2 3.4-.9 6-3 7.7-6 1.7-3.1 2.1-6.3 1.2-9.7a12.87 12.87 0 0 0-12.2-9.4c-1.1-.1-2.3.1-3.5.5M53 92.7a7.63 7.63 0 0 0-2.3 5.6c0 2.2.8 4.1 2.3 5.6s3.4 2.3 5.6 2.3a7.87 7.87 0 0 0 7.9-7.9c0-2.2-.8-4.1-2.3-5.6s-3.4-2.3-5.6-2.3c-2.2 0-4 .8-5.6 2.3m44.9 0a7.63 7.63 0 0 0-2.3 5.6c0 2.2.8 4.1 2.3 5.6s3.4 2.3 5.6 2.3 4.1-.8 5.6-2.3 2.3-3.5 2.3-5.6c0-2.2-.8-4.1-2.3-5.6s-3.4-2.3-5.6-2.3c-2.1 0-4 .8-5.6 2.3M53 67.4a7.63 7.63 0 0 0-2.3 5.6c0 2.2.8 4.1 2.3 5.6s3.4 2.3 5.6 2.3a7.87 7.87 0 0 0 7.9-7.9c0-2.2-.8-4.1-2.3-5.6s-3.4-2.3-5.6-2.3-4 .8-5.6 2.3m44.9 0a7.63 7.63 0 0 0-2.3 5.6c0 2.2.8 4.1 2.3 5.6s3.4 2.3 5.6 2.3 4.1-.8 5.6-2.3 2.3-3.5 2.3-5.6c0-2.2-.8-4.1-2.3-5.6s-3.4-2.3-5.6-2.3c-2.1 0-4 .8-5.6 2.3M75.6 53.3a7.63 7.63 0 0 0-2.3 5.6c0 2.2.8 4.1 2.3 5.6s3.4 2.3 5.6 2.3 4.1-.8 5.6-2.3 2.3-3.5 2.3-5.6c0-2.2-.8-4.1-2.3-5.6S83.4 51 81.2 51s-4 .8-5.6 2.3M27.4 46.5c-3.4 1-6 3-7.7 6-1.7 3.1-2.1 6.3-1.2 9.7.9 3.4 3 6 6 7.7 3.1 1.7 6.3 2.1 9.7 1.2 3.4-1 6-3 7.7-6 1.7-3.1 2.1-6.3 1.2-9.7-.9-3.4-3-6-6-7.7-2-1.1-4.1-1.7-6.2-1.7-1.1 0-2.3.1-3.5.5m98.3 1.2c-3.1 1.7-5.1 4.3-6 7.7-1 3.4-.5 6.7 1.2 9.7s4.3 5.1 7.7 6 6.7.5 9.7-1.2 5.1-4.3 6-7.7c1-3.4.5-6.7-1.2-9.7s-4.3-5.1-7.7-6c-1.2-.3-2.4-.5-3.5-.5-2.1 0-4.2.5-6.2 1.7M72.4 20.1c-2.5 2.5-3.8 5.5-3.8 9.1 0 3.5 1.2 6.6 3.8 9.1 2.5 2.5 5.5 3.8 9.1 3.8s6.6-1.2 9.1-3.8c2.5-2.5 3.8-5.5 3.8-9.1 0-3.5-1.2-6.6-3.8-9.1s-5.5-3.8-9.1-3.8-6.6 1.2-9.1 3.8" />
        <clipPath id="c">
            <use xlinkHref="#a" overflow="visible" />
        </clipPath>
        <linearGradient id="b" x1="-1861.89" x2="-1860.34" y1="504.35" y2="504.35" gradientTransform="translate(151862.34 -41044) scale(81.5538)" gradientUnits="userSpaceOnUse">
            <stop offset={0} stopColor="#f9dd02" />
            <stop offset=".18" stopColor="#fac702" />
            <stop offset=".54" stopColor="#fc8c01" />
            <stop offset={1} stopColor="#ff3c00" />
            <stop offset={1} stopColor="#ff3c00" />
        </linearGradient>
        <path fill="url(#b)" d="M17.5 16.3h438.1v141.9H17.5z" clipPath="url(#c)" />
    </SvgIcon>
)

export default ImunisSymbol
