import SvgIcon from '@material-ui/core/SvgIcon';
import theme from '../../styles/theme';

type IconProps = {
    variant?: 'active' | 'inactive';
}

export default function TermsIcon(props: IconProps) {
    let stroke;
    const { variant } = props

    switch (variant) {
        case 'active':
            stroke = theme.palette.primary.main
            break;
        case 'inactive':
            stroke = theme.palette.grey[600]
            break;
        default:
            stroke = 'currentColor'
    }

    return (
        <SvgIcon {...props} stroke={stroke}>
            <path fill="none" strokeWidth="1.5" d="M18.8 6.1c.1.2.2.4.2.6v13.5c0 .2-.1.4-.2.6-.1.1-.3.2-.6.2H4.8c-.2 0-.4-.1-.6-.2s-.2-.4-.2-.6V3.8c0-.2.1-.4.2-.6.2-.1.4-.2.6-.2h10.5c.2 0 .4.1.6.2l2.9 2.9z"/>
            <path strokeWidth="1.5" d="M7.5 13.5h8m-8 3h8m-8-6h8m-8-3h4.4"/>
        </SvgIcon>
    )
}




