import { Prompt, RouteChildrenProps, useHistory } from "react-router-dom"
import { SubroutineContext, SubroutineProvider } from "../../../components/context/subroutine"
import { useCallback, useContext, useEffect, useState } from "react"
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import ErrorDialog from "../../../components/dialogs/error_dialog"
import { TDateISODateTime } from "../../../utils/common"
import { SubmissionConfirmation } from "./confirmation"
import { ScanFile } from "./scan"
import { SubmissionSummary } from "./summary"
import { Page } from "./page"
import ActionDialog from "../../../components/dialogs/action_dialog"
import { ScrollPosition } from "./utils"

export const MAX_PAGES = 10

export enum Step {
    Scan = 'scan',
    Summary = 'summary',
    ViewPage = 'page',
    Confirmation = 'confirmation',
}

type FileSubmission = {
    file?: File
    url: string
}

export type SubmissionResponse = {
    status: 'ok' | 'fail'
    submissionNumber: number
    submittedAt?: TDateISODateTime
    resubmittedAt?: TDateISODateTime
    images: string[]
}


// let _DEV = {
//     subject: {uuid: '89e5ad68-ac4d-466d-9d96-1f2428afc09f', name: 'Jennie Child'},
//     // step: Step.ReviewSubmission,
//     files: [
//         {
//             file: new File([],'file1.jpg'),
//             url: '//picsum.photos/seed/x/300/200'
//         },
//         {
//             file: new File([],'file2.jpg'),
//             url: '//picsum.photos/seed/y/300/201'
//         },
//         {
//             file: new File([],'file2.jpg'),
//             url: '//picsum.photos/seed/z/300/202'
//         }
//     ]
// }

const AddRecordStep: React.FC<{
    step: Step
    state: {
        subject: {
            id: string
            fullName: string
        },
        submissionResponse?: SubmissionResponse
        /**
         * If provided, list of image paths of an existing submission we're re-submitting.
         * Will also set 'resubmission mode'
         */
        images?: string[]
        submissionId?: string
    }
}> = ({ step, state }) => {
    const {subject, submissionResponse} = state

    const { exitWizard } = useContext(SubroutineContext)
    const history = useHistory()

    const [isLoading, setLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadError, setUploadError] = useState(false)
    // const [files, setFiles] = useState<FileSubmission[]>(_DEV.files)
    const [files, setFiles] = useState<FileSubmission[]>(
        state.images ? state.images.map(path => ({  url: path })) : []
    )
    const [exitConfirmation, showExitConfirmation] = useState(false)

    /** Index of the file in the `files` array currently open */
    const [filePreview, setFilePreview] = useState<number|null>(null)

    /**
     * Index of the file in the `files` array last updated, or the position of the carousel
     * We use this to restore scroll position when viewing, deleting, or adding files
     */
    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>()

    const clearAllFiles = () => setFiles(fs => {
        fs.forEach(({url}) => URL.revokeObjectURL(url))
        return []
    })

    // When the component unmounts, discard all the file blobs we created
    useEffect(() => {
        return clearAllFiles
    }, [])

    const uploadFiles = () => {
        setLoading(true)

        const formData = new FormData()
        files.forEach(({file, url}) => {
            if (file) formData.append('images[]', file)
            else formData.append('images[]', url)
        })

        axiosAuthedInstance.post<SubmissionResponse>(
            state.submissionId ?
                `user/paper-record/${state.submissionId}/resubmit/`
                : `user/paper-record/${subject.id}/new/`
            ,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                // Override the default timeout, image uploads can be slow!
                timeout: 60000,
                onUploadProgress: (e) => {
                    if (!e.progress) return
                    setUploadProgress(Math.round(e.progress * 100))
                }
            })
            .then((response) => {
                // Artificial delay to allow the progress animation to complete on very short durations
                return new Promise<typeof response.data>((resolve) => {
                    setTimeout(() => resolve(response.data), 400)
                })
            }).then(({ status, ...response }) => {
                if (status === 'ok') {
                    clearAllFiles()
                    exitWizard(() => {
                        history.push(`/paper-records/new/${Step.Confirmation}`, {
                            subject,
                            submissionResponse: response
                        })
                    })
                } else {
                    setUploadError(true)
                }

            }).catch((error) => {
                console.error(error)
                setUploadError(true)
            }).finally(() => {
                setLoading(false)
                setUploadProgress(0)
            })
    }

    const addFile = (file: File, restartSubmission=false) => {
        const fileCount = restartSubmission ? 0 : files.length
        setFiles((fs) => {
            const newFile = {
                file,
                url: URL.createObjectURL(file)
            }

            if (restartSubmission) {
                fs.forEach(f => URL.revokeObjectURL(f.url))
                return [newFile]
            } else {
                return [...fs, newFile]
            }
        })
        setScrollPosition({index: fileCount})
        history.replace(Step.Summary)
    }

    /**
     * There are three types of 'confirm before leaving' behaviour we employ, in order or UX
     * - Click on the in-app back button: Show our own <Dialog> component.
     * - Detect a change in the SPA route: Show a <Prompt> (from React-Router)
     * - Reloading or closing the page: A browser-dependant widget
     */
    const unloadCallback = useCallback((event: BeforeUnloadEvent) => {
        if (files.length > 0) event.preventDefault()
    }, [files.length])

    useEffect(() => {
        window.addEventListener('beforeunload', unloadCallback)
        return () => window.removeEventListener('beforeunload', unloadCallback)
    }, [unloadCallback])

    useEffect(() => {
        if (step === Step.ViewPage && filePreview === null) {
            history.replace(Step.Summary)
        }
    }, [step, filePreview, history])

    const exitPrompt = (
        <Prompt message={(location, action) => {
            if (location.pathname === '/paper-records/new' && action === 'POP' && files.length > 0) {
                return 'Exiting this page will discard all selected photos'
            }
            return true
        }} />
    )

    switch (step) {
        case Step.Scan:
            return <>
                <ScanFile
                    userName={subject.fullName}
                    onFileAdd={addFile}
                    submissionStarted={files.length > 0}
                    onBack={() => {
                        history.push('/paper-records/new')
                    }} />
            </>
        case Step.Summary:
            return <>
                {exitPrompt}
                <SubmissionSummary
                    userName={subject.fullName}
                    imageUrls={files.map(({url}) => url)}
                    onBack={() => {
                        if (files.length > 0) {
                            showExitConfirmation(true)
                        } else {
                            exitWizard()
                        }
                    }}
                    onContinue={uploadFiles}
                    isLoading={isLoading}
                    uploadProgress={uploadProgress}
                    onPreviewFile={(i, scrollPosition) => {
                        setFilePreview(i)
                        setScrollPosition(scrollPosition)
                        history.push(Step.ViewPage)
                    }}
                    onFileAdd={addFile}
                    scrollPosition={scrollPosition}
                />
                <ActionDialog
                    title="Submission not complete"
                    content="Exiting this page will discard all selected photos"
                    openDialog={exitConfirmation}
                    setOpenDialog={showExitConfirmation}
                    action_label="Exit submission"
                    action_callback={() => {
                        clearAllFiles()
                        exitWizard()
                    }}
                    cancel={true} />
                <ErrorDialog
                    title="Upload failed"
                    error="An unexpected error occurred. Please retry or contact Imunis if this persists."
                    open={uploadError}
                    setOpen={setUploadError}
                />
            </>
        case Step.ViewPage:
            if (filePreview === null) {
                console.log('No file to preview')
                return null
            }

            // Note using `push(Step.Summary)` as the navigation action here will result in the back
            // button seemingly having no effect when navigating back after deleting a page.
            // To be consistent in behaviour we use `goBack()` in all cases here.

            return <>
                {exitPrompt}
                <Page
                    imageUrl={files[filePreview].url}
                    onClose={() => history.goBack()}
                    onDelete={() => {
                        setFiles(files => {
                            URL.revokeObjectURL(files[filePreview].url)
                            files.splice(filePreview, 1)
                            return files
                        })
                        setFilePreview(null)
                        history.goBack()
                    }}
                    onReplace={(file) => {
                        setFiles(files => {
                            URL.revokeObjectURL(files[filePreview].url)
                            files[filePreview] = {
                                file,
                                url: URL.createObjectURL(file)
                            }
                            return files
                        })
                        setScrollPosition({index: filePreview})
                        history.goBack()
                    }}
                />
            </>
        case Step.Confirmation:
            if (!submissionResponse) {
                // Redirect, maybe came here via direst URL
                throw new Error("No submission response")
            }

            return <SubmissionConfirmation
                userName={subject.fullName}
                submissionResponse={submissionResponse} />
    }
}

type AddRecordProps = RouteChildrenProps<{
    step: Step
}, {
    subject: {
        id: string
        fullName: string
    },
    submissionResponse?: SubmissionResponse
    images?: string[]
    submissionId?: string
}>

/**
 * Add paper record workflow (wizard)
 */
export const AddPaperRecords = ({ match, location }: AddRecordProps) => {
    const step = match?.params.step
    const [state] = useState(location.state)
    // const {subject} = _DEV
    const history = useHistory()

    useEffect(() => {
        if (!state?.subject) {
            history.push('/paper-records/new')
        }
    }, [history, state])

    return (
        <SubroutineProvider>
            {step && state && <AddRecordStep step={step} state={state} />}
        </SubroutineProvider>
    )
}
