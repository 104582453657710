import { useState, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { spacing, sizing, display, flexbox, } from '@material-ui/system';
import styled from 'styled-components';
import QRCode from 'qrcode.react'
import axios from 'axios'
import { HTTP_STATUS } from "../../utils/constants";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

import { axiosAuthedInstance } from "../../utils/axiosApi";
import { BaseContainer } from "../../components/container";
import Loading from "../../components/loading";
import SuccessDialog from "../../components/dialogs/success_dialog";
import ErrorDialog from "../../components/dialogs/error_dialog";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { Button } from "../../components/button"
import { BackButton } from "../../components/buttons/back_btn";
import { HeaderTitle, PageDescription } from "../../components/page_sections";
import Typography from "../../components/typography";
import { logEvent, reduceUserPrefNames } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
    linkText: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        fontWeight: 600,
        fontSize: '14px',
        marginLeft: theme.spacing(0.5)
    },
}))

const ShareQR = (props) => {
    const QR_VALID_TIME = 90

    let storedSeconds = props.location.state && props.location.state.seconds
    let storedInvite = ''

    // For cancel request
    let cancelTokenSource;
    let inviteToken = ''
    let timer

    if (typeof storedSeconds == 'undefined') {
        storedSeconds = QR_VALID_TIME
    } else if (storedSeconds > 0) {
        const prevNow = props.location.state && props.location.state.now
        const diff = Math.floor((Date.now() - prevNow) / 1000)
        storedSeconds -= diff
        let temp = storedInvite.split("/")
        inviteToken = temp[temp.length - 1]
        if (storedSeconds <= 0) {
            storedSeconds = QR_VALID_TIME
        } else {
            storedInvite = props.location.state && props.location.state.inviteText
        }
    } else {
        storedSeconds = QR_VALID_TIME
    }
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(storedSeconds)
    const [canShare, setCanShare] = useState(true)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [inviteText, setInviteText] = useState(storedInvite)
    const [noPermission, setNoPermission] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const dependants = props.location.state && props.location.state.dependants || []
    const classes = useStyles();
    const theme = useTheme()
    const history = useHistory();

    const updateSeconds = () => {
        if (seconds > 0) {
            setSeconds(old => {
                if (old <= 0) {
                    setCanShare(false)
                    return 0
                }
                return old - 1
            })
        } else { setCanShare(false) }
    }

    const resetTimer = () => {
        setSeconds(QR_VALID_TIME)
        setCanShare(true)
        setInviteText('')
        setLoading(true)
    }

    useEffect(() => {
        if (canShare) {
            if (!inviteText) {
                setLoading(true)
                axiosAuthedInstance.post('user/dependants/share/qr-invite/', {dependants})
                    .then(result => {
                        setLoading(false)
                        inviteToken = result.data.token
                        setInviteText(`${window.location.origin}/sharing/invite/${inviteToken}/`)
                        timer = setInterval(updateSeconds, 1000)
                        getShareStatus()
                        logEvent('share_dependant', { journey: 'qr' })
                    }).catch(err => {
                        setLoading(false)
                        console.error(err)
                        if (err.response && err.response.status == 403) {
                            setErrorMessage("You do not have permission to share this user's information")
                            setNoPermission(true)
                        } else {
                            setErrorMessage("Error creating invite. Please try again")
                            setNoPermission(true)
                        }
                    })
            } else {
                setLoading(false)
                timer = setInterval(updateSeconds, 1000)
                getShareStatus()
            }
        }
        else {
            clearInterval(timer)
            cancelPolling();
        }
        return () => {
            clearInterval(timer)
            cancelPolling();
        }
    }, [canShare])

    useEffect(() => {
        if (!dependants || !dependants.length) {
            setNoPermission(true)
            setErrorMessage('No dependants to share found!')
        }
    }, [seconds, inviteText])

    const getShareStatus = async() => {
        if (!canShare || !inviteToken) {
            return;
        }
        // ensure last connectopm stopped
        if (cancelTokenSource != null) {
            cancelPolling();
        }
        // get source each time
        cancelTokenSource = axios.CancelToken.source();

        await axiosAuthedInstance.get("/user/share/status/" + inviteToken + '/', {cancelToken: cancelTokenSource.token})
            .then(response => {
            // TODO: the polling response
                if (response.data['status'] === "PENDING") {
                    getShareStatus()
                    return;
                }

                switch(response.data["accept"]) {
                    case 'APP':
                        setSuccessMessage("Permission shared successfully!")
                        break;
                    case 'OPEN':
                        setSuccessMessage("Invitation sent successfully!")
                        break;

                }

                //open the success dialog
                setOpenSuccess(true)
                // clear the timer after success
                setCanShare(false)
            })
            .catch(err => {
                if (err.response.status === HTTP_STATUS.CLIENT_CLOSED_REQUEST) {
                    return;
                }
                if (err.code == HTTP_STATUS.HTTP_UNAUTH
                || err.code == HTTP_STATUS.HTTP_NOT_FOUND
                || err.code == HTTP_STATUS.HTTP_SERVICE_UNAVAILABLE
                || err.code == HTTP_STATUS.HTTP_INTERNAL_SERVER_ERROR) {
                // handle 401, 404, 500, 503
                // stop to call server
                    console.error(err)
                    setCanShare(false)
                    return;
                }

                // handle device diconnect to internet
                if (err.message === "Network Error") {
                    setTimeout(() => { getShareStatus() }, 5000);
                    return;
                }
            });
    };


    // cancel the request
    function cancelPolling() {
        if (cancelTokenSource == null) {
            return;
        }
        cancelTokenSource.cancel();
    }

    useEffect(() => {
        history.replace('/sharing/send/qr',{seconds: seconds -1, inviteText, now: Date.now(), dependants})
        return () => {
            if (history.action == "POP" && props.location.pathname === history.location.pathname) {
                history.replace('/sharing/send',{seconds: seconds -1, inviteText, now: Date.now(), dependants})
            }
        };
    },[inviteText]);

    const names = reduceUserPrefNames(dependants, true)

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button fullWidth onClick={() => history.push('/sharing/send/email', {dependants} )}>
                Enter email address
            </Button>
        </StickyFooterGroup>}>

            {loading && <Loading loading={loading}/>}

            <BackButton />

            <HeaderTitle>
                QR Code to View Records
            </HeaderTitle>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    {'Recipient can scan the QR code using Imunis app: Home or Records Page > Add Dependent > Scan QR code'}
                </Typography>
            </PageDescription>

            <Box width="100%" pt={3}>
                <Typography align="center" variant="bodyTextLargeBold">Records of {names}</Typography>
                <Box marginTop={2} marginBottom={2} display="flex" justifyContent="center">
                    <QRCode size={200} fgColor={canShare ? theme.palette.primary.main : theme.palette.primary.light} value={inviteText} />
                </Box>
                {canShare ?
                    <Typography align="center" variant="bodyTextMediumDark">QR code will expire in {seconds}s</Typography> :
                    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                        <Typography align="center" variant="bodyTextMediumDark">{'Code has expired. '}</Typography>
                        <div className={classes.linkText} onClick={resetTimer} color="primary">Refresh</div>
                    </Box>
                }
            </Box>

            <SuccessDialog success={successMessage} setSuccess={setSuccessMessage} open={openSuccess} setOpen={setOpenSuccess} dismiss_callback={() => {history.replace('/sharing')}}/>
            <ErrorDialog open={noPermission} error={errorMessage} setOpen={setNoPermission} dismiss_callback={() => history.replace('/sharing')}  />
        </BaseContainer>
    );
}
export default ShareQR;
